import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Header = () => {
    const [isToggled, setIsToggled] = useState(false);
    const toggleClass = () => {
        setIsToggled(!isToggled);
    };

    const [loadScript, setLoadScript] = useState(true);
    useEffect(() => {
        const script = document.createElement("script");

        script.src = `/zidni/assets/js/script.js`;
        script.async = true;

        document.body.appendChild(script);
        return () => {
            if (loadScript) {
                document.body.removeChild(script);
                setLoadScript(false);
            }
        };
    }, []);
    return (
        <>
            <header className="header bg-green">
                <div className="container">
                    <div className="nav">
                        <Link to="/" className="logo">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/zidni.png`} alt="" /></Link>
                        <ul className={isToggled ? 'mystyle' : ''}>
                            <li><Link to="/universities">Institutions</Link></li>
                            <li><Link to="/about">About zidni</Link></li>
                            <li><Link to="/webinars">Webinars</Link></li>
                            <li><Link to="/events">Events</Link></li>
                        </ul>
                        <div className="menu-toggle mob" onClick={toggleClass}>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}


export default Header
