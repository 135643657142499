import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import Header from "../../components/common/Header";
import { Link, useNavigate } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";
import Footer from "../../components/common/Footer";

const InstructorDashboars = () => {
  const userDetail = useSelector((state) => state.root.userDetails);
  const [dashboardData, setDashboardData] = useState({});
  const navigate = useNavigate();
  const [CallApi, setCallApi] = useState(true);
  // const [Loader,setLoader] = useState()

  useEffect(() => {
    if (CallApi == true) {
      setCallApi(false);
      // setLoader(true);
      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `instructor-dashboard?id=${userDetail?.id}`;
      callApi(apiUrl, { method: "GET", data: {} }).then((res) => {
        if (res.status == true) {
          setDashboardData(res.data);
        } else {
          // setLoader(false);
        }
      });
    }
  }, [CallApi]);


  return (
    <>
      <Header />
      <section className="create-course">
        <div className="container">
          <div className="header-tab">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <img src="./assets/images/homeiconblack.svg" alt="" />
                </button>
              </li> */}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => navigate("/")}
                >
                  <img src="./assets/images/homeiconblack.svg" alt="" />
                </button>
              </li>
              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-msg-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-msg"
                  type="button"
                  role="tab"
                  aria-controls="pills-msg"
                  aria-selected="false"
                >
                  <img src="./assets/images/messageiconblack.svg" alt="" />
                </button>
              </li> */}

              {/* <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-watch-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-watch"
                  type="button"
                  role="tab"
                  aria-controls="pills-watch"
                  aria-selected="false"
                >
                  <img src="./assets/images/questioniconblack.svg" alt="" />
                </button>
              </li> */}
            </ul>

            <div className="instru">
              <div className="img">
                <img src="./assets/images/inslogo.svg" alt="" />
              </div>
              <span>
                {" "}
                {userDetail?.firstName
                  ? userDetail?.firstName
                  : userDetail?.Profile?.firstName}
              </span>
              {/* <button>
                {" "}
                <img src="./assets/images/bell.svg" alt="" />
              </button> */}
            </div>
          </div>
        </div>
      </section>

      <div className="pt-0">
        <div className="tab-content" id="pills-tabContent">

          <div
            className="tab-pane fade show active"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div className="dashboard">
              <div className="container">
                <div className="d-flex justify-content-center" >
                  <h3 style={{ color: "#156B68", textDecoration: "underline" }}>Insights and Analytics at a Glance</h3>
                </div>
                <ul className="count">
                  <li onClick={() => navigate('/my-courses')}>
                    <span className="num">({dashboardData?.totalCourse})</span>
                    <span className="name">Total Course Add</span>
                  </li>
                  <li onClick={() => navigate('/my-courses')}>
                    <span className="num">({dashboardData?.publishedCourse})</span>
                    <span className="name">Total Course publish</span>
                  </li>
                  <li>
                    <span className="num">({dashboardData?.totalUserCourse})</span>
                    <span className="name">Registered Students</span>
                  </li>
                  <li>
                    <span className="num">($ {dashboardData?.totalCoursePurchaseSum})</span>
                    <span className="name">Total Purchase Course amount</span>
                  </li>
                </ul>
                <ul className="count">
                  <li onClick={() => navigate('/my-events')}>
                    <span className="num">({dashboardData?.totalEvent})</span>
                    <span className="name">Total Event Add</span>
                  </li>
                  <li onClick={() => navigate('/my-events')}>
                    <span className="num">({dashboardData?.publishedEvent})</span>
                    <span className="name">Total Event publish</span>
                  </li>
                  <li>
                    <span className="num">({dashboardData?.totalUserEvent})</span>
                    <span className="name">Registered Students</span>
                  </li>
                  <li>
                    <span className="num">($ {dashboardData?.totalEventPurchaseSum})</span>
                    <span className="name">Total Purchase Event amount</span>
                  </li>
                </ul>
                <ul className="count">
                  <li>
                    <span className="num">($ {dashboardData?.totalAmount})</span>
                    <span className="name">Total Profit amount </span>
                  </li>
                  <li>
                    <span className="num">($ {dashboardData?.zidniCommission})</span>
                    <span className="name">Zidni commision</span>
                  </li>
                </ul>
                {/* <div className="dash-content">
                  <div className="top">
                    <div className="co">Course</div>
                    <div className="amounts">Amount</div>
                    <div className="dates">Date</div>
                  </div>
                  <div className="cr-main">
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                  </div>
                  <div className="total-dash">
                    <div className="new">
                      <div className="co">Total</div>
                      <div className="amounts">$705</div>
                      <div className="dates"></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade "
            id="pills-msg"
            role="tabpanel"
            aria-labelledby="pills-msg-tab"
            tabindex="0"
          >
            <section className="message-sec">
              <div className="container">
                <div className="no-msgs">
                  <h4>
                    Direct messages are for you to communicate with your
                    students or other instructors privately. Here’s where
                    you’ll see them.
                  </h4>
                </div>
                <div className="messages-part">
                  <div className="msg-contents">
                    <div className="tds">
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:30 PM</span>
                          </h6>
                          <p>
                            Sint dolorem pariatur ut id numquam aut magni cum.
                          </p>
                        </div>
                      </div>
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Terry Altenwerth
                            <span className="msg-time">02:45 PM</span>
                          </h6>
                          <p>Adipisci et at quos ut aspernatur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="dates-sec">
                      <div className="date">Wednesday july 19th</div>
                    </div>
                    <div className="tds">
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:30 PM</span>
                          </h6>
                          <p>
                            Aut est quis. Assumenda et praesentium quibusdam
                            voluptatem veniam iusto. Quae praesentium quasi
                            ad. Nihil totam rerum placeat ullam consectetur
                            sapiente provident.
                          </p>
                        </div>
                      </div>
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:45 PM</span>
                          </h6>
                          <p>
                            Aut est quis. Assumenda et praesentium quibusdam
                            voluptatem veniam iusto. Quae praesentiu
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="newinputpartscourse">
              <div className="input-parts">
                <form action="">
                  <div className="inputevens">
                    <div className="msgbox">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Message...."
                        id="communication"
                      />
                    </div>
                    <div className="btnss">
                      <button className="send">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M0.768483 19.2791L23.1652 9.6759L0.768483 0.0727539L0.757812 7.54187L16.7631 9.6759L0.757812 11.8099L0.768483 19.2791Z"
                            fill="#BAB9B9"
                          />
                        </svg>
                      </button>
                      <button className="add">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M8.96094 1.67578V15.6758M1.96094 8.67578H15.9609"
                            stroke="#BAB9B9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {/* <img src="./assets/images/plusic.svg" alt="" /> */}
                      </button>
                      <button className="option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="22"
                          viewBox="0 0 6 22"
                          fill="none"
                        >
                          <path
                            d="M2.96021 5.48584C4.38744 5.48584 5.55518 4.3181 5.55518 2.89087C5.55518 1.46364 4.38744 0.295898 2.96021 0.295898C1.53297 0.295898 0.365234 1.46364 0.365234 2.89087C0.365234 4.3181 1.53297 5.48584 2.96021 5.48584ZM2.96021 8.08081C1.53297 8.08081 0.365234 9.24855 0.365234 10.6758C0.365234 12.103 1.53297 13.2708 2.96021 13.2708C4.38744 13.2708 5.55518 12.103 5.55518 10.6758C5.55518 9.24855 4.38744 8.08081 2.96021 8.08081ZM2.96021 15.8657C1.53297 15.8657 0.365234 17.0335 0.365234 18.4607C0.365234 19.8879 1.53297 21.0557 2.96021 21.0557C4.38744 21.0557 5.55518 19.8879 5.55518 18.4607C5.55518 17.0335 4.38744 15.8657 2.96021 15.8657Z"
                            fill="#BAB9B9"
                          />
                        </svg>
                        {/* <img src="./assets/images/optionbt.svg" alt="" /> */}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="pills-watch"
            role="tabpanel"
            aria-labelledby="pills-watch-tab"
            tabindex="0"
          >
            ...
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default InstructorDashboars;
