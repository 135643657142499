import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
import { callApi } from '../../api/ApiHelper';
import Layout from '../../components/common/Layout';
import Loader from '../../components/common/Loader';
import Rating from 'react-rating';
import TimeAgo from '../../components/common/TimeAgo';

const CourseReviews = () => {
    const [loader, setLoader] = useState(true);
    const [CallApi, setCallApi] = useState(true);
    const [courseReviewData, setCourseReviewData] = useState([]);
    const [allPages, setAllPages] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationRange, setPaginationRange] = useState([1, 4]);
    const location = useLocation();


    useEffect(() => {
        if (CallApi === true) {
            setCallApi(false);
            const apiUrl =
                APIURL.COURSE_URL +
                APIENDPOINTS.COURSE_END_POINT +
                `rating/list/?id=${location.state?.courseId}&pageNumber=${currentPage}&limit=10`;
            callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
                if (response.status === true) {
                    setCourseReviewData(response.data);
                    setLoader(false)
                    let arr = []
                    let toTal = response?.data?.totalPages
                    for (let i = 1; i <= toTal; i++) {
                        arr.push(i)
                    }
                    setAllPages(arr)
                } else {
                    setLoader(false)
                }
            });
        }

    }, [CallApi, location.state.courseId])

    return (
        <Layout> {loader ? <Loader /> : <>
            <section>
                <div className="mt-5">
                    <h3>Course Reviews</h3>
                    <div className="show-com  mt-2" style={{ maxHeight: "400px" }}>
                        {courseReviewData?.rating?.length > 0 ?
                            courseReviewData?.rating?.slice(0, 5)?.map((item, index) => (
                                <div className="comss m-lg-3" key={index}>
                                    <div className="d-flex gap-2 align-items-center mb-1">
                                        <div className="avatar-box ">
                                            <div className="avatar">
                                                {item.user.firstName.charAt(0) +
                                                    item.user.lastName.charAt(0)}
                                            </div>
                                        </div>
                                        <h5>
                                            {item.user.firstName} {item.user.lastName}
                                        </h5>
                                    </div>
                                    <div className='mx-5'>
                                        <Rating
                                            readonly
                                            initialRating={item?.rating}
                                            emptySymbol={<i className="fa-regular fa-star" style={{ color: 'grey' }}></i>}
                                            fullSymbol={<i className="fa-solid fa-star" style={{ color: 'gold' }}></i>}
                                        />
                                        <p>{item.comment}</p>
                                        <div className="tago">
                                            <TimeAgo createdAt={item.createdAt} />
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <div className="mt-4">
                                <h5 className="text-muted mx-2">No Review Yet!</h5>
                            </div>
                        }
                    </div>
                </div>
                {allPages?.length > 0 && (
                    <div className="pagination-pointer mt-3">
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className="page-item ">
                                    <a href="javascript:void(0);" className="page-link"
                                        onClick={() => {
                                            if (currentPage > 0) {
                                                setCurrentPage(currentPage - 1);
                                                setCallApi(true)
                                            }
                                            if (paginationRange[0] > 1) {
                                                setPaginationRange([
                                                    paginationRange[0] - 1,
                                                    paginationRange[1] - 1,
                                                ]);
                                                setCallApi(true)

                                            }
                                        }}
                                    >Previous</a>
                                </li>
                                {allPages &&
                                    allPages?.length > 0 &&
                                    allPages
                                        .slice(paginationRange[0] - 1, paginationRange[1])
                                        .map((item, key) => (
                                            <li className={
                                                currentPage === item ? "active page-item" : "page-item"
                                            } aria-current="page" key={key}>
                                                <a className="page-link" href="javascript:void(0);" onClick={() => {
                                                    setCurrentPage(item);
                                                    setCallApi(true)
                                                }} >{item}</a>
                                            </li>
                                        ))}
                                <li className="page-item">
                                    <a className="page-link" href="javascript:void(0);" onClick={() => {
                                        if (
                                            currentPage < allPages?.length - 1
                                        ) {
                                            setCurrentPage(currentPage + 1);
                                            setPaginationRange([
                                                paginationRange[0] + 1,
                                                paginationRange[1] + 1,
                                            ]);
                                            setCallApi(true)
                                        }
                                    }}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}
            </section>
        </>
        }
        </Layout>
    )
}

export default CourseReviews