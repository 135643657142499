import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useLocation } from "react-router-dom";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const CourseCreditHouors = ({
  navigate,
  activeTab,
  allCourseData,
  setAllCourseData,
  getCourseDetail,
  toggleTab,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({ mode: "all" });

  const location = useLocation()

  useEffect(() => {
    if (location.state?.courseId !== undefined || allCourseData.id !== undefined) {
      reset({
        courseTime: JSON.parse(allCourseData.courseTimeDurationAndCreditHour)?.courseTime,
        courseCreditHour: JSON.parse(allCourseData.courseTimeDurationAndCreditHour)?.courseCreditHour
      })
    }
  }, [])


  const onSubmit = (data) => {
    console.log("Form Data:", data);
    let finalData = {
      courseTime: data.courseTime,
      courseCreditHour: data.courseCreditHour
    }
    const Data = new FormData();

    Data.append("courseTimeDurationAndCreditHour", JSON.stringify(finalData));

    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/update/${allCourseData.id}`;
    const multipart = true;

    callApi(apiUrl, { method: "PUT", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        sendSuccessInfo(res.message);
        toggleTab(activeTab + 1);
        getCourseDetail();
      } else {
        sendErrorInfo(res.data.message);
      }
    });
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4>let's find out what type of course you're making.</h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          {/* <div className="form-check certific">
            <input
              className="form-check-input in"
              type="checkbox"
              value=""
              id="freecheck"
            />
            <label className="form-check-label cer" for="freecheck">
              Certificate
            </label>
          </div> */}
          <div className="mb-3">
            <label for="graphicdesign" className="form-label">
              Estimated time duration
            </label>
            <input
              type="number"
              className="form-control"
              id="graphicdesign"
              aria-describedby="emailHelp"
              placeholder="e.g.10 hours"
              {...register("courseTime", {
                required: "Estimated time duration is required",
              })}
            />
            <p className="mb-0 text-danger">
              {errors?.courseTime?.message}
            </p>

            <div id="graphicdesign" className="form-text"></div>
          </div>
          <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Add credit hours{" "}
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Add course credit hours"
              {...register("courseCreditHour", {
                required: "credit hours is required",
              })}
            />
            <p className="mb-0 text-danger">
              {errors?.courseCreditHour?.message}
            </p>
            <div id="emailHelp" className="form-text"></div>
          </div>
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 8 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default CourseCreditHouors;
