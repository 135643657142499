import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const CourseInnerDetail = () => {
  const location = useLocation();
  const [CallApi, setCallApi] = useState(true);
  const [allCourseData, setAllCourseData] = useState({});
  const [activeLesson, setActiveLesson] = useState({});
  const [activeLessonkey, setActiveLessonKey] = useState(0);
  const [likedData, setLikedData] = useState([]);
  const userDetail = useSelector((state) => state.root.userDetails);
  const [activeCurriculums, setActiveCurriculums] = useState([])
  const [loader, setLoader] = useState(true)
  const [activeCurriculumsKey, setActiveCurriculumsKey] = useState(0)
  const navigate = useNavigate()
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reviewStar, setReviewStar] = useState(0)
  const [reviewComment, setReviewComment] = useState('')


  const toggle = () => {
    setModal(!modal)
  }

  useEffect(() => {
    if (CallApi) {
      setLoader(true)
      getCourseDetail();
      setCallApi(false)
    }
  }, [CallApi]);

  const handlenextlesson = () => {
    console.log("first", activeCurriculumsKey, activeCurriculums.length - 1, activeLessonkey, allCourseData?.Assessments?.length - 1)
    if (allCourseData?.isCoursePurchased) {
      if (activeLessonkey == allCourseData?.Assessments?.length - 1 && (activeCurriculumsKey == activeCurriculums.length - 1 || activeCurriculums.length == 0) && (activeCurriculums?.[activeCurriculums?.length - 1]?.Progresses?.[0]?.isCompleted == true || activeCurriculums.length == 0)) {
        sendSuccessInfo('All lessons and lectures are completed')
        navigate('/my-purchase-course');

      } else {
        if (activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.isCompleted == true) {
          if (activeCurriculumsKey !== activeCurriculums?.length - 1) {
            let key = activeCurriculumsKey + 1;
            setActiveCurriculumsKey(key)
            if (activeCurriculums?.[key]?.curriculumType == 3 && activeCurriculums?.[key]?.Progresses?.[0]?.isCompleted != true) {
              navigate('/course-quize', {
                state: { quize: activeCurriculums?.[key], assessments: activeLesson }
              })
            } else {

              let key = activeCurriculumsKey + 1;
              setActiveCurriculumsKey(key)
            }
          } else {
            if (activeLessonkey !== allCourseData?.Assessments?.length - 1) {
              let key = activeLessonkey + 1;
              setActiveLesson(allCourseData?.Assessments?.[key]);
              setActiveCurriculums(allCourseData?.Assessments?.[key]?.Curriculums)
              setActiveCurriculumsKey(0)
              setActiveLessonKey(key);
            }
          }
        } else {
          if (activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.curriculumId) {

            const data = {
              curriculumId: activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.curriculumId,
              userCourseId: activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.userCourseId,
              isCompleted: true
            }

            // data.append('curriculumId', activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.curriculumId);
            // data.append('userCourseId',activeCurriculums?.[activeCurriculumsKey]?.Progresses?.[0]?.userCourseId);
            // data.append('isCompleted', true);
            const apiUrl =
              APIURL.COURSE_URL +
              APIENDPOINTS.COURSE_END_POINT +
              `update-progress`;
            // const multipart = true;

            callApi(apiUrl, { method: "POST", data: data, }).then((res) => {
              if (res.status == true) {
                activeCurriculums[activeCurriculumsKey].Progresses[0].isCompleted = true
                if (activeCurriculumsKey !== activeCurriculums?.length - 1) {
                  let key = activeCurriculumsKey + 1;
                  setActiveCurriculumsKey(key)
                  if (activeCurriculums?.[key]?.curriculumType == 3) {
                    navigate('/course-quize', {
                      state: { quize: activeCurriculums?.[key], assessments: activeLesson }
                    })
                  }
                } else {
                  if (activeLessonkey !== allCourseData?.Assessments?.length - 1) {
                    let key = activeLessonkey + 1;
                    setActiveLesson(allCourseData?.Assessments?.[key]);
                    setActiveCurriculums(allCourseData?.Assessments?.[key]?.Curriculums)
                    setActiveCurriculumsKey(0)
                    setActiveLessonKey(key);
                  }
                }
                sendSuccessInfo(res.message);
              } else {
                sendErrorInfo(res.data.message);
              }
            });
          }
        }
      }
    } else {
      sendErrorInfo('First purchased course then see all lecture and videos')
    }
  };

  const getCourseDetail = () => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/${location.state?.courseId}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setAllCourseData(response.data);
        setLikedData(response.data.Likes.filter((item) => item.isLiked));
        let res = response?.data?.Assessments?.sort((a, b) => a.lessonIndex - b.lessonIndex)
        setActiveLesson(res?.[0]);
        setActiveCurriculums(res?.[0]?.Curriculums)
        setLoader(false)

      } else {
        setLoader(false)
      }
    });
  };

  const handleLikeAndDislike = (isLike, type) => {
    let apiUrl = "";
    if (userDetail?.type) {
      if (
        allCourseData.isEventLiked === 0 ||
        allCourseData.isEventLiked !== type
      ) {
        apiUrl =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `course/like/${location.state?.courseId}?isLiked=${isLike}`;
      } else {
        apiUrl =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `course/like/${location.state?.courseId}?isLiked=${isLike}&type=remove`;
      }
      // console.log("apiUrl", apiUrl);
      callApi(apiUrl, { method: "GET" }).then((response) => {
        if (response.status === true) {
          getCourseDetail()
        }
      });
    } else {
      sendErrorInfo('Please login then like and dislike course')
    }
  };

  const handleActiveLesson = (item, key) => {
    if (allCourseData?.isCoursePurchased || key == 0) {
      if (key > 0) {
        const dd = allCourseData?.Assessments[key - 1]?.Curriculums?.filter(el => el.curriculumType == 3)
        if (dd && dd?.length > 0) {
          if (!dd?.[0]?.Progresses?.[0]?.isCompleted) {
            sendErrorInfo('First complete the above lesson Quiz')
            return
          }
        }
      }
      setActiveLesson(item)
      setActiveCurriculums(item?.Curriculums)
      setActiveCurriculumsKey(0)
      setActiveLessonKey(0)
    } else {
      sendErrorInfo('First purchased course then see all lecture and videos')
    }
  }

  const handleAddReview = () => {
    setLoading(true)
    let apiUrl = "";
    if (userDetail?.type) {
      if (reviewStar != 0) {

        apiUrl =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `course/rate/${location.state?.courseId}?rating=${reviewStar}&comment=${reviewComment}`;
        // console.log("apiUrl", apiUrl);
        callApi(apiUrl, { method: "GET" }).then((response) => {
          if (response.status === true) {
            sendSuccessInfo('Rating add successfully')
            getCourseDetail()
            setReviewComment('')
            setReviewStar(0)
            setLoading(false)
            toggle()
          }
        });
      } else {
        sendErrorInfo('Please fill rating then submit')
      }

    } else {
      sendErrorInfo('Please login then give review to course')
    }
  };

  return (
    <Layout>
      {loader ? <Loader /> :
        <section className="course-material">
          <div className="container">
            <div className="cour-met">
              <h3>Course materials </h3>
              <div className="accordion-part">
                <div className="accordion" id="accordionExample">
                  {allCourseData?.Assessments &&
                    allCourseData.Assessments.sort((a, b) => a.lessonIndex - b.lessonIndex).map((item, key) => (
                      <div
                        className="accordion-item"
                        key={key}
                      >
                        <h2 className="accordion-header" onClick={() => handleActiveLesson(item, key)}>
                          <button
                            className={activeLessonkey == key ? "accordion-button accr-bdy" : "accordion-button accr-bdy collapsed"}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${key}`}
                            aria-expanded={key == 0 ? "true" : "false"}
                            aria-controls={`collapseOne${key}`}
                          >
                            <div className="bt-div">
                              <div className="why module-wh">
                                <div className="less-btn">Lessons-{key + 1}</div>
                                <span className="intr">{item?.lessonName}</span>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`collapseOne${key}`}
                          className={activeLessonkey == key ? "accordion-collapse collapse show" : "accordion-collapse collapse"}
                          data-bs-parent="#accordionExample"
                        >
                          {item?.Curriculums &&
                            item?.Curriculums.map((item2, key2) => {
                              return (
                                <div className="accordion-body accr-bdy" key={key2}>
                                  <div className="why module-wh">
                                    {item2?.curriculumType == 2 ? (
                                      <span>
                                        <img
                                          src="./assets/images/play-btn.svg"
                                          alt=""
                                        />
                                      </span>
                                    ) : (
                                      <span>
                                        <img
                                          src="./assets/images/lock-btn.svg"
                                          alt=""
                                        />
                                      </span>
                                    )}
                                    {item2?.curriculumType == 1 ?

                                      <p className="pla">
                                        Lecture
                                      </p> : item2?.curriculumType == 2 ?
                                        <p className="pla">
                                          Video
                                        </p> :
                                        <p className="pla">
                                          Quiz
                                        </p>}
                                  </div>
                                  {/* <div className="con module-con">
                                    <div className="min module-min">
                                      20:15 min
                                    </div>
                                  </div> */}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="intro">
              {/* <h3>Introduction to Course : by Suzanne Weber</h3> */}
              {activeCurriculums?.[activeCurriculumsKey]?.curriculumType == 2 ?
                <div className="my-5" key={activeCurriculums?.[activeCurriculumsKey]?.curriculumPath}>
                  <div className="videos" >
                    <video width="100%" controls >
                      <source src={activeCurriculums?.[activeCurriculumsKey]?.curriculumPath} />
                    </video>
                  </div>
                </div>
                : activeCurriculums?.[activeCurriculumsKey]?.curriculumType == 1 ?
                  <div className="my-5">
                    <h3 className="mb-3">Lecture</h3>
                    {/* <div className="why module-wh" > */}
                    <p className="" >{activeCurriculums?.[activeCurriculumsKey]?.curriculumPath}</p>
                    {/* </div> */}
                  </div> : ''
                //     <div className="my-5">
                //     <h3 className="mb-3">Quize</h3>
                //   {/* <div className="why module-wh" > */}
                //     <p className="" >{activeCurriculums?.[activeCurriculumsKey]?.Quiz.quizName}</p>
                //     <button type='button' className='btn btn-success' onClick={()=>navigate('/course-quize',{
                //       state:{quize : activeCurriculums?.[activeCurriculumsKey] , assessments:activeLesson}
                //     })} >Fill Question</button>
                //   {/* </div> */}
                // </div>
              }

              <h3>Overview</h3>
              <p>{activeLesson?.lessonDescription}</p>
              <div className="review-content">
                <div className="revi">
                  <div className="like">
                    <button
                      className={allCourseData.isCourseLiked === 1 && "active"}
                      onClick={() => handleLikeAndDislike(true, 1)}
                    >
                      <span>
                        <img src="./assets/images/like.svg" alt="" />
                      </span>
                      Like {likedData?.length}
                    </button>
                  </div>
                  <div className="dislike">
                    <button
                      className={allCourseData.isCourseLiked === 2 && "active"}
                      onClick={() => handleLikeAndDislike(false, 2)}
                    >
                      <span>
                        <img src="./assets/images/dislike.svg" alt="" />
                      </span>
                      Dislike
                    </button>
                  </div>
                  {allCourseData?.isCoursePurchased &&
                    <div className="ques">
                      <button onClick={() => toggle()}>
                        {/* <span>
                      <img src="./assets/images/question.svg" alt="" />
                    </span> */}
                        Add Review
                      </button>
                    </div>
                  }
                </div>
                {allCourseData?.isCoursePurchased &&
                  <div className="next">
                    <button className="next-btn" onClick={() => handlenextlesson()}>
                      {" "}
                      Next
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      }
      {/* <!-- course material end --> */}

      <Modal isOpen={modal} centered toggle={toggle} className=' modal'>
        <ModalHeader className='border-bottom ' toggle={toggle}>
          Give Review
        </ModalHeader>
        <ModalBody className='p-3 pt-4'>
          <form >
            <section className="p-2 rounded box-shadow ratesdetails">
              <div className="container">
                <div className="row rating-info">
                  <div className='ratesmsgs'>
                    <div className='rinner d-flex align-items-center mb-3'>
                      <div className='rate'>
                        <input type="radio" id="star1" name="rate" value={5} onChange={(e) => {
                          setReviewStar(e.target.value)
                        }} />
                        <label for="star1" title="text"></label>
                        <input type="radio" id="star2" name="rate" value={4} onChange={(e) => {
                          setReviewStar(e.target.value)
                        }} />
                        <label for="star2" title="text"></label>
                        <input type="radio" id="star3" name="rate" value={3} onChange={(e) => {
                          setReviewStar(e.target.value)
                        }} />
                        <label for="star3" title="text"></label>
                        <input type="radio" id="star4" name="rate" value={2} onChange={(e) => {
                          setReviewStar(e.target.value)
                        }} />
                        <label for="star4" title="text"></label>
                        <input type="radio" id="star5" name="rate" value={1} onChange={(e) => {
                          setReviewStar(e.target.value)
                        }} />
                        <label for="star5" title="text"></label>
                      </div>
                      <div>Rate this course</div>
                    </div>
                  </div>
                  <div>
                    <label for="addcomments" class="form-label"><h4>Leave your comment here</h4></label>
                    <textarea class="form-control" placeholder='Add comment.....' id="addcomments" rows="3" onChange={(e) => {
                      setReviewComment(e.target.value)
                    }}></textarea>
                  </div>
                </div>
              </div>
            </section>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => toggle()}
              >
                Close
              </button>
              <button type='button' className="btn btn-primary" onClick={handleAddReview}>
                {loading == true ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default CourseInnerDetail;
