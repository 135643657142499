import React, { useEffect, useState } from 'react'
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
import { callApi } from '../../api/ApiHelper';
import { sendSuccessInfo } from '../../utility/helperFunctions';
import Cookies from 'js-cookie';

const SuccessPage = () => {

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [allData, setAllData] = useState(null);
  const [orderid, setOrderId] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    let id = searchParams.get('id');
    const appToken = Cookies.get('zidniCheckoutToken') ?? ""

    if (id) {
      const apiUrl =
        APIURL.CART_URL +
        APIENDPOINTS.CART_END_POINT +
        `get-checkout/${id}`;
      callApi(apiUrl, { method: "GET", data: {} }, appToken).then((response) => {
        if (response.status === true) {
          setAllData(response?.data)
          setLoader(false)
          if (response?.data?.result?.code == "000.100.110") {
            setPaymentSuccess(true)
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({ code: "000.100.110", status: true })
            );
            return
          }
          setPaymentSuccess(false)
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ code: "000.400.401", status: false })
          );
        } else {
          setPaymentSuccess(false)
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ code: "000.400.401", status: false })
          );
        }
      });

    }
  }, [])


  if (loader) {
    return (
      <main className="main-container">
        <div class="loader" style={{ transform: "scale(1.4)" }}></div>
      </main>
    );
  }
  return (
    <main className="main-container">
      {/* {/ order-success section start here... /} */}
      {paymentSuccess === true ? (
        <section id="success" className="orders">
          <div className="container">
            <div className="logo-img">
              <img className="w-100" src="./assets/img/fanzat.png" alt="" />
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-12">
                <h4 className="thankyou-heading">
                  <span className="d-flex align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" />
                    </svg>
                  </span>
                  Thank you
                </h4>
                <div className="order-confirm-card">
                  <p className="order-id" id="odr_id">
                    {/* Order No : #{orderid} */}
                  </p>
                  <h5>Your order is confirmed</h5>
                  <p>
                    We've accepted your order, and we're getting it ready,
                    <br className="d-none d-md-block" />
                    come back to this page for update on your shipment status.
                  </p>
                </div>
                {/* <div className="text-center">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={() => {
                    backApp();
                  }}
                >
                  {" "}
                  Back to App{" "}
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section id="fail" className="orders ">
          <div className="container">
            <div className="logo-img">
              <img className="w-100" src="./assets/img/fanzat.png" alt="" />
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-12">
                <h4 className="thankyou-heading">
                  <span className="d-flex align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z" />
                    </svg>
                  </span>
                  Order cancel
                </h4>
                <div className="order-confirm-card">
                  <h5>Your order is Canceled</h5>
                  <p>
                    A canceled order is a previously submitted order
                    <br className="d-none d-md-block" />
                    to buy or sell a security that gets canceled before it
                    executes on an exchange
                  </p>
                </div>
                {/* <div className="text-center">
                <a
                  href="#"
                  className="btn btn-primary"
                  onClick={() => {
                    backApp();
                  }}
                >
                  {" "}
                  Back to App{" "}
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </section>
      )}

      {/* {/ order-success section end here... /} */}
    </main>
  )
}

export default SuccessPage