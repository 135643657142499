import React from 'react'

const PaginationComponent = (props) => {
    return (
        <section className='pt-0'>

            <div className="container pt-0">
                {props?.allPages?.length > 0 && (
                    <div className="pagination-pointer ">
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className="page-item ">
                                    <a
                                        href="javascript:void(0);"
                                        className="page-link"
                                        onClick={() => {
                                            if (props?.currentPage > 0) {
                                                props?.setCurrentPage(props?.currentPage - 1);
                                                // setCallApi(true);
                                            }
                                            if (props?.paginationRange[0] > 1) {
                                                props?.setPaginationRange([
                                                    props?.paginationRange[0] - 1,
                                                    props?.paginationRange[1] - 1,
                                                ]);
                                                // setCallApi(true);
                                            }
                                        }}
                                    >
                                        Previous
                                    </a>
                                </li>
                                {props?.allPages &&
                                    props?.allPages?.length > 0 &&
                                    props?.allPages
                                        .slice(props?.paginationRange[0] - 1, props?.paginationRange[1])
                                        .map((item, key) => (
                                            <li
                                                className={
                                                    props?.currentPage === item
                                                        ? "active page-item"
                                                        : "page-item"
                                                }
                                                aria-current="page"
                                                key={key}
                                            >
                                                <a
                                                    className="page-link"
                                                    href="javascript:void(0);"
                                                    onClick={() => {
                                                        props?.setCurrentPage(item);
                                                        // setCallApi(true);
                                                    }}
                                                >
                                                    {item}
                                                </a>
                                            </li>
                                        ))}
                                <li className="page-item">
                                    <a
                                        className="page-link"
                                        href="javascript:void(0);"
                                        onClick={() => {
                                            if (props?.currentPage < props?.allPages?.length - 1) {
                                                props?.setCurrentPage(props?.currentPage + 1);
                                                props?.setPaginationRange([
                                                    props?.paginationRange[0] + 1,
                                                    props?.paginationRange[1] + 1,
                                                ]);
                                                // setCallApi(true);
                                            }
                                        }}
                                    >
                                        Next
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}
            </div>
        </section>

    )
}

export default PaginationComponent