import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../components/common/Layout";
import { useSelector } from "react-redux";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import Loader from "../../components/common/Loader";
import Rating from "react-rating";

const CourseList = () => {
  const [CallApi, setCallApi] = useState(true);
  const userDetail = useSelector((state) => state.root.userDetails);
  const location = useLocation();

  const [courseList, setCourseList] = useState([]);
  const [categoryName_Count, setCategoryName_Count] = useState({ "categoryName": "", "count": "" });
  const [filterValue, setFilterValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [priceValue, setPriceValue] = useState('')
  const [sortByValue, setSortByValue] = useState('')
  const [sortByField, setSortByField] = useState('')
  const [loader, setLoader] = useState(true)
  const [allPages, setAllPages] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);

  useEffect(() => {
    if (location?.state?.priceValue != undefined) {
      setPriceValue(location.state.priceValue)
    }
  }, [])

  useEffect(() => {
    setCallApi(true)
  }, [location?.state?.catId])

  useEffect(() => {
    // console.log("sdffffff", location?.state?.priceValue)


    if (CallApi == true) {
      setCallApi(false);
      setLoader(true)
      let Data = {

        where: {
          courseStatus: 1
        },
        paranoid: true,
        sortField: sortByField,
        sortType: sortByValue,
        pageNumber: currentPage,
        limit: 10,
      };
      if (filterValue != '') {
        if (filterValue !== 'all') {
          Data.where['courseStatus'] = filterValue
        }
      }
      if (searchValue != '') {
        Data.where['courseName'] = searchValue
      }
      if (priceValue != '' || location?.state?.priceValue != undefined) {
        Data.where['coursePricing'] = priceValue != '' ? priceValue : location.state.priceValue
      }
      if (location?.state?.catId !== undefined) {
        Data.where['categoryId'] = location?.state?.catId
      }

      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "course/list";
      callApi(apiUrl, { method: "POST", data: Data }).then((res) => {
        if (res.status == true) {
          setCourseList(res.data.course);
          setCategoryName_Count({
            categoryName: res?.data?.course[0] ? res?.data?.course[0]?.Category?.categoryName : "Selected",
            count: res?.data?.count
          })
          setLoader(false)
          let arr = []
          let toTal = res?.data?.totalPages
          for (let i = 1; i <= toTal; i++) {
            arr.push(i)
          }
          setAllPages(arr)
          console.log("mnxshs", res.data.course);
        } else {
          setLoader(false)
        }
      });
    }
  }, [CallApi, location?.state?.catId]);


  const handelFilter = (value) => {
    if (value == 'Paid' || value == 'Free') {
      setPriceValue(value)
      setFilterValue('')

    } else {
      setPriceValue('')
      setFilterValue(value)
    }
    setCallApi(true)
  }

  const handleSortBy = (value) => {
    if (value !== sortByValue) {

      if (value == 'asc' || value == 'desc') {
        setSortByField('courseName')
        setSortByValue(value)
      } else {
        if (value == 'Date old') {
          setSortByValue('asc')
          setSortByField('createdAt')
        } else {
          setSortByValue('desc')
          setSortByField('createdAt')
        }
      }
      setCallApi(true)
    }
  }

  const handleSearch = (value) => {

    setSearchValue(value)
    setCallApi(true)
  }


  return (
    <Layout>

      <section className="course-list">
        <div className="container">
          <div className="filter-by">
            {location?.state?.catId ?
              <h3>Showing {categoryName_Count?.count} result in {categoryName_Count?.categoryName} Speciality</h3>
              : <h3>Showing {categoryName_Count?.count} result in all speciality </h3>}
            {/* <div className="fill">
              <div className="pretty-select id-sort-by">
                <select
                  id="sort-by"
                  name="sort_by"
                  className="sort-by form-control replaced"
                >
                  <option value="manual" selected="selected">
                    Filter by
                  </option>
                  <option value="title-ascending">Alphabetically, A-Z</option>
                  <option value="title-descending">Alphabetically, Z-A</option>
                  <option value="price-ascending">Price, low to high</option>
                  <option value="price-descending">Price, high to low</option>
                  <option value="created-ascending">Date, old to new</option>
                  <option value="created-descending">Date, new to old</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="bottom-bar">
            {/* <div className="badges-content">
              <div className="badgess">
                <span className="badge rounded-pill">
                  Free
                  <button
                    type="button"
                    className="btn-close"
                    disabled
                    aria-label="Close"
                  ></button>
                </span>
                <span className="badge rounded-pill">
                  Recent
                  <button
                    type="button"
                    className="btn-close"
                    disabled
                    aria-label="Close"
                  ></button>
                </span>
                <span className="badge rounded-pill">
                  Pediatric Specialty
                  <button
                    type="button"
                    className="btn-close"
                    disabled
                    aria-label="Close"
                  ></button>
                </span>
                <span className="badge rounded-pill">
                  Less then 2 hours
                  <button
                    type="button"
                    className="btn-close"
                    disabled
                    aria-label="Close"
                  ></button>
                </span>
              </div>
              <button className="clearall">Clear all</button>
            </div> */}
            <div className="main-courses">
              {loader ? <Loader /> :
                <div className="course-card cards">
                  {courseList && courseList?.length > 0 ?
                    courseList.filter((e) => e.deletedAt == null).map((item, key) => (
                      <div className="card round" key={key}>
                        <div className="img">
                          <img src={item?.courseImage} alt="" />
                        </div>
                        <div className="univ">
                          <div className="university">
                            {/* <span className="img round">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/univ-logo.svg`} alt="" />
                  </span> */}
                            <h5>
                              <Link
                                to={`/course-detail/${item?.id}`}
                              >
                                {item?.courseName}
                              </Link>
                            </h5>
                          </div>
                          <Link
                            to={`/course-detail/${item?.id}`}
                            className="oph"
                          >
                            {item?.Category?.categoryName}
                          </Link>
                        </div>
                        <div className="review">
                          <span className="star">
                            <Rating
                              readonly
                              initialRating={item?.averageRating}
                              emptySymbol={<i className="fa-regular fa-star" style={{ color: 'grey' }}></i>}
                              fullSymbol={<i className="fa-solid fa-star" style={{ color: 'gold' }}></i>}
                            />
                            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/star.svg`} alt="" />
                  4.8 (233K Reviews) */}
                          </span>
                          {/* <div className="credit">Credit hr:20</div> */}
                        </div>
                        <div className="price">
                          <div className="name">
                            By: {item?.instructor?.firstName}{" "}
                            {item?.instructor?.lastName}
                          </div>
                          <div className="pr">
                            {JSON?.parse(item?.coursePricing)
                              ?.actualValue != undefined ?
                              <>
                                <div>
                                  <span className={JSON?.parse(item?.coursePricing)
                                    ?.actualValue != undefined && 'text-muted text-decoration-line-through fs-6'}>  {item?.coursePricing &&
                                      JSON?.parse(item?.coursePricing)
                                        ?.coursePaymentType == "Paid"
                                      ? `$ ${JSON?.parse(item?.coursePricing)
                                        ?.coursePricing}`
                                      : "Free"}
                                  </span>

                                  <span >  {item?.coursePricing &&
                                    JSON?.parse(item?.coursePricing)
                                      ?.coursePaymentType == "Paid"
                                    ? `$ ${JSON?.parse(item?.coursePricing)
                                      ?.actualValue.toFixed(2)}`
                                    : "Free"}
                                  </span>
                                </div>
                                <span className="fs-6">
                                  (University discount)
                                </span>
                              </>
                              :

                              <span>  {item?.coursePricing &&
                                JSON?.parse(item?.coursePricing)
                                  ?.coursePaymentType == "Paid"
                                ? `$ ${JSON?.parse(item?.coursePricing)
                                  ?.coursePricing}`
                                : "Free"}
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    )) :
                    <div className="d-flex justify-content-center mt-5">
                      <p className="text-muted fw-medium fs-4">NO course found for selected speciality</p>
                    </div>
                  }
                </div>
              }
              <div className="fil-by">
                <div className="fill-box">
                  <div className="inner-fill">
                    <h4>Filter by</h4>
                    <div className="short-by checks">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Free"
                          id="freecheck"
                          name="filterBy"
                          checked={priceValue == 'Free' ? true : false}
                          onChange={(e) => handelFilter(e.target.value)}
                        />
                        <label className="form-check-label" for="freecheck">
                          Free
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="Paid"
                          id="paidcheck"
                          name="filterBy"
                          checked={priceValue == 'Paid' ? true : false}
                          onChange={(e) => handelFilter(e.target.value)}
                        />
                        <label className="form-check-label" for="paidcheck">
                          Paid
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="inner-fill">
                    <h4>Sort by</h4>
                    <div className="short-by checks">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortBy"
                          value="asc"
                          onClick={(e) => handleSortBy(e.target.value)}
                          id="recentcheck"
                        />
                        <label className="form-check-label" for="recentcheck">
                          Alphabetically, A-Z
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortBy"
                          value="desc"
                          onClick={(e) => handleSortBy(e.target.value)}
                          id="newcheck"
                        />
                        <label className="form-check-label" for="newcheck">
                          Alphabetically, Z-A
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortBy"
                          id="oldestcheck"
                          value={'Date new'}
                          onClick={(e) => handleSortBy(e.target.value)}
                        />
                        <label className="form-check-label" for="oldestcheck">
                          Date, new to old
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sortBy"
                          value="Date old"
                          onClick={(e) => handleSortBy(e.target.value)}
                          id="oldestcheck1"
                        />
                        <label className="form-check-label" for="oldestcheck1">
                          Date, old to new
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* <div className="inner-fill">
                    <h4>Specialities</h4>
                    <div className="short-by checks">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="pediatricspecialtycheck"
                        />
                        <label
                          className="form-check-label"
                          for="pediatricspecialtycheck"
                        >
                          Pediatric Specialty
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="psychiatryspecialtycheck"
                        />
                        <label
                          className="form-check-label"
                          for="psychiatryspecialtycheck"
                        >
                          Psychiatry Specialty
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="internalmedicinespecialtycheck"
                        />
                        <label
                          className="form-check-label"
                          for="internalmedicinespecialtycheck"
                        >
                          Internal Medicine Specialty
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="generalsurgerycheck"
                        />
                        <label
                          className="form-check-label"
                          for="generalsurgerycheck"
                        >
                          General Surgery
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="familymedicinecheck"
                        />
                        <label
                          className="form-check-label"
                          for="familymedicinecheck"
                        >
                          Family Medicine
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="ophthalmologyspecialtycheck"
                        />
                        <label
                          className="form-check-label"
                          for="ophthalmologyspecialtycheck"
                        >
                          Ophthalmology Specialty
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {allPages?.length > 0 && (
              <div className="pagination-pointer mt-3">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li className="page-item ">
                      <a href="javascript:void(0);" className="page-link"
                        onClick={() => {
                          if (currentPage > 0) {
                            setCurrentPage(currentPage - 1);
                            setCallApi(true)
                          }
                          if (paginationRange[0] > 1) {
                            setPaginationRange([
                              paginationRange[0] - 1,
                              paginationRange[1] - 1,
                            ]);
                            setCallApi(true)

                          }
                        }}
                      >Previous</a>
                    </li>
                    {allPages &&
                      allPages?.length > 0 &&
                      allPages
                        .slice(paginationRange[0] - 1, paginationRange[1])
                        .map((item, key) => (
                          <li className={
                            currentPage === item ? "active page-item" : "page-item"
                          } aria-current="page" key={key}>
                            <a className="page-link" href="javascript:void(0);" onClick={() => {
                              setCurrentPage(item);
                              setCallApi(true)
                            }} >{item}</a>
                          </li>
                        ))}
                    <li className="page-item">
                      <a className="page-link" href="javascript:void(0);" onClick={() => {
                        if (
                          currentPage < allPages?.length - 1
                        ) {
                          setCurrentPage(currentPage + 1);
                          setPaginationRange([
                            paginationRange[0] + 1,
                            paginationRange[1] + 1,
                          ]);
                          setCallApi(true)
                        }
                      }}>Next</a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CourseList;
