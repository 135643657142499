import Cookies from "js-cookie";
import { Store } from "react-notifications-component";
export const getToken = () => {
  return Cookies.get(process.env.REACT_APP_BUSINESS + "zidniToken");
};


export const sendErrorMessage = (response) => {
  Store.addNotification({
    // title: "Error!",
    message: response?.data?.message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
  //   swal("Error", response.data.message, "error");
};


export const sendSuccessMessage = (response) => {
  //   swal("Success", response.message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: response?.message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};


export const sendErrorInfo = (message) => {
  //   swal("Error", message, "error");
  Store.addNotification({
    // title: "Error!",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

export const sendSuccessInfo = (message) => {
  //   swal("Success", message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export const sendNotificationInfo = (message) => {
  //   swal("Success", message, "success");
  Store.addNotification({
    // title: "Seccess!",
    message: message,
    type: "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};