import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import Loader from "../../components/common/Loader";

const University = () => {
  const [sortByValue, setSortByValue] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [allUniversity, setAllUniversity] = useState([]);
  const [loader, setLoader] = useState(true);
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);

  const [CallApi, setCallApi] = useState(true);
  useEffect(() => {
    if (CallApi == true) {
      setLoader(true);
      setCallApi(false);
      let eventData = {
        where: {},
        pageNumber: 1,
        limit: 10,
      };
      const apiUrl =
        APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `university/get`;
      callApi(apiUrl, { method: "POST", data: eventData }).then((rseponse) => {
        if (rseponse.status === true) {
          setAllUniversity(rseponse.data.university);
          setLoader(false);
          let arr = [];
          let toTal = rseponse?.data?.totalPages;
          for (let i = 1; i <= toTal; i++) {
            arr.push(i);
          }
          setAllPages(arr);
        } else {
          setLoader(false);
        }
      });
    }
  }, [CallApi]);
  return (
    <Layout>
      {/* breadcrumb start */}
      <section className="breadcrumb-sec">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                University
              </li>
            </ol>
          </nav>
        </div>
      </section>
      {/* breadcrumb end */}

      <section className="univ-header">
        <div className="container">
          <h2 className="text-center">
            ″University teaches us to see things as they are, to go right to the
            point, to disentangle a skein of thought to detect what is
            sophistical and to discard what is irrelevant.”
          </h2>
          <div className="banner round-ban">
            <div className="content">
              <div className="gra-icon">
                <img src="./assets/images/gra-logo.svg" alt="" />
              </div>
              <h2 className="text-white">
                Join our top leading <br /> universities partners that <br />{" "}
                choose Zidni for
                <span> career growth</span>
              </h2>
              <p className="text-white">
                We collaborate with 100+ leading <br /> university partners
              </p>
            </div>
            <div className="img-block">
              <img src="./assets/images/univ-banner.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      {loader ? (
        <Loader />
      ) : (
        <>
          {/* explore-univ start */}
          <section className="explore-univ p-0">
            <div className="container">
              <h2>Explore our universities</h2>
              <div className="universities">
                {allUniversity &&
                  allUniversity.length > 0 &&
                  allUniversity.map((item, key) => (
                    <div className="univ-card round" key={key}>
                      <div className="left-content">
                        <div className="card-image round">
                          <img src={item?.universityImage} alt="" />
                        </div>
                        <div className="card-detail">
                          <h4>{item?.universityName}</h4>
                          <p>{item?.universityDescription}</p>
                        </div>
                      </div>
                      <div className="right-content">
                        <Link
                          to="/university-detail"
                          state={{ id: item?.userId }}
                          className="round"
                        >
                          View university
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </section>
          {/* explore-univ end */}

          <section className="pt-0">
            <div className="container">
              {allPages?.length > 0 && (
                <div className="pagination-pointer mt-3">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li className="page-item ">
                        <a
                          href="javascript:void(0);"
                          className="page-link"
                          onClick={() => {
                            if (currentPage > 1) {
                              setCurrentPage(currentPage - 1);
                              setCallApi(true);
                            }
                            if (paginationRange[0] > 1) {
                              setPaginationRange([
                                paginationRange[0] - 1,
                                paginationRange[1] - 1,
                              ]);
                              setCallApi(true);
                            }
                          }}
                        >
                          Previous
                        </a>
                      </li>
                      {allPages &&
                        allPages?.length > 0 &&
                        allPages
                          .slice(paginationRange[0] - 1, paginationRange[1])
                          .map((item, key) => (
                            <li
                              className={
                                currentPage === item
                                  ? "active page-item"
                                  : "page-item"
                              }
                              aria-current="page"
                              key={key}
                            >
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                                onClick={() => {
                                  setCurrentPage(item);
                                  setCallApi(true);
                                }}
                              >
                                {item}
                              </a>
                            </li>
                          ))}
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="javascript:void(0);"
                          onClick={() => {
                            if (currentPage < allPages?.length) {
                              setCurrentPage(currentPage + 1);
                              setPaginationRange([
                                paginationRange[0] + 1,
                                paginationRange[1] + 1,
                              ]);
                              setCallApi(true);
                            }
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default University;
