import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link, useLocation } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import Loader from "../../components/common/Loader";

const UniversityDetail = () => {
  const [universityDetail, setUniversityDetail] = useState({})
  const location = useLocation()
  const [loader, setLoader] = useState(true)
  const [CallApi, setCallApi] = useState(true);

  useEffect(() => {
    if (CallApi == true) {
      setLoader(true)
      setCallApi(false);
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `university/${location.state?.id}`;
      callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
        if (response.status === true) {
          setUniversityDetail(response.data);
          setLoader(false)
        } else {
          setLoader(false)
        }
      });
    }
  }, [CallApi])

  const handleOpneTab = () => {
    const url = JSON.parse(universityDetail?.urls)?.[0]?.url;
    const absoluteUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
    console.log("Opening URL:", absoluteUrl);
    window.open(absoluteUrl, '_blank');
    // window.open(url, '_blank');
  }
  return (
    <Layout>
      {loader ? <Loader /> : <>
        {/* breadcrumb start */}
        <section className="breadcrumb-sec">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/universities">University</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {universityDetail?.universityName}
                </li>
              </ol>
            </nav>
          </div>
        </section>
        {/* breadcrumb end */}
        {/* bible-open open */}
        <section className="bible-open">
          <div className="container round">
            <div className="content-box">
              <div className="img round">
                <img src={universityDetail?.universityImage} alt="" />
              </div>
              <h2 className="text-white">{universityDetail?.universityName}</h2>
              <p className="text-white">
                {universityDetail?.universityDescription}
              </p>
              {/* {universityDetail?.urls && JSON.parse(universityDetail?.urls).map((item, key) => ( */}

              <a onClick={() => {
                handleOpneTab()
              }} type="button" className="text-white">
                {JSON.parse(universityDetail?.urls)?.[0]?.url}{" "}
                <span>
                  <img src="./assets/images/linking.svg" alt="" />
                </span>
              </a>
              {/* ))} */}
            </div>
            <div className="img-box">
              <img src={universityDetail?.bannerImage} alt="" />
            </div>
          </div>
        </section>
        {/* bible-open close */}
        <section className="ydetail">
          <div className="container round">
            <h4>
              “This is the way to make progress this is the way to arrive at
              results”
            </h4>
            <ul className="count">
              <li>
                <h5 className="num">{universityDetail?.courses?.length}</h5>
                <h5 className="name">Enrolled Courses</h5>
              </li>
              <li>
                <h5 className="num">12</h5>
                <h5 className="name">Certified Students</h5>
              </li>
              <li>
                <h5 className="num">{universityDetail?.instructor?.length}</h5>
                <h5 className="name">Instructor</h5>
              </li>
            </ul>
          </div>
        </section>
        {universityDetail?.courses && universityDetail?.courses?.length > 0 &&
          <section className="popular explore">
            <div className="container">
              <div className="heading">
                <h2>Explore trending course</h2>
                <Link to="/courses">View all</Link>
              </div>
              <div className="cards">
                {universityDetail?.courses && universityDetail?.courses?.filter((e) => e.deletedAt == null && e.courseStatus == 1)?.map((item, key) => {
                  if (key <= 2) {
                    return (
                      <div className="card round" key={key}>
                        <div className="img">
                          <img src={item?.courseImage} alt="" />
                        </div>
                        <div className="univ">
                          {/* <div className="university">
                  <span className="img round">
                    <img src="assets/images/univ-logo.svg" alt="" />
                  </span>
                  <h5>
                    <Link to="/universities">
                      Bible Open University of India
                    </Link>
                  </h5>
                </div> */}
                          <div className="specialty">{item?.Category?.categoryName}</div>
                          <Link to={`/course-detail/${item?.id}`}
                            state={{ insDetail: item?.instructor }} className="oph">
                            {item?.courseName}
                          </Link>
                        </div>
                        {/* <div className="review">
                <span className="star">
                  <img src="assets/images/star.svg" alt="" />
                  4.8 (233K Reviews)
                </span>
                <div className="credit">Credit hr:20</div>
              </div> */}
                        <div className="price">
                          <div className="name">By: {item?.instructor?.firstName} {item?.instructor?.lastName}</div>
                          <div className="pr">{JSON?.parse(item?.coursePricing)
                            ?.actualValue != undefined ?
                            <>
                              <span className={JSON?.parse(item?.coursePricing)
                                ?.actualValue != undefined && 'text-muted text-decoration-line-through fs-6'}>  {item?.coursePricing &&
                                  JSON?.parse(item?.coursePricing)
                                    ?.coursePaymentType == "Paid"
                                  ? `$ ${JSON?.parse(item?.coursePricing)
                                    ?.coursePricing}`
                                  : "Free"}
                              </span>

                              <span >  {item?.coursePricing &&
                                JSON?.parse(item?.coursePricing)
                                  ?.coursePaymentType == "Paid"
                                ? `$ ${JSON?.parse(item?.coursePricing)
                                  ?.actualValue.toFixed(2)}`
                                : "Free"}
                              </span>
                            </>
                            :

                            <span>  {item?.coursePricing &&
                              JSON?.parse(item?.coursePricing)
                                ?.coursePaymentType == "Paid"
                              ? `$ ${JSON?.parse(item?.coursePricing)
                                ?.coursePricing}`
                              : "Free"}
                            </span>
                          }</div>
                        </div>
                      </div>
                    )

                  }

                })}
              </div>
            </div>
          </section>
        }
        {universityDetail?.specialities?.length > 0 &&
          <section className="popular-specialities">
            <div className="container">
              <h2>Explore popular specialities</h2>
              <div className="content">
                {universityDetail?.specialities?.map((item, key) => (
                  <h6 className="blocks round" key={key}>{item?.categoryName}</h6>
                ))}
              </div>
            </div>
          </section>
        }
      </>}

    </Layout>
  );
};

export default UniversityDetail;
