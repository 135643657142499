export const APIURL = {
  AUTH_URL: "https://dev.zidni.academy/",
  COURSE_URL: "https://dev.zidni.academy/",
  EVENT_URL: "https://dev.zidni.academy/",
  LIVE_URL: "https://dev.zidni.academy/",
  CART_URL: "https://dev.zidni.academy/",
  SOCKET_URL: "https://websocket.zidni.academy",
  NOTIFICATION_URL: "https://dev.zidni.academy/",
};

export const APIENDPOINTS = {
  AUTH_END_POINT: "Z0001/api/v1/",
  COURSE_END_POINT: "Z0002/api/v1/",
  EVENT_END_POINT: "Z0003/api/v1/",
  LIVE_END_POINT: "Z0004/api/v1/",
  CART_END_POINT: "Z0005/api/v1/",
  NOTIFICATION_END_POINT: "Z0006/api/v1/"
};
