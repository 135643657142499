import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { PostData, callApi } from "../../api/ApiHelper";
import Cookies from "js-cookie";
import {
  saveUserDetails,
  setShowLoginPopup,
} from "../../store/actions/userActions";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";

// import { getTokens } from "../../firebase";
import { v4 as uuidv4 } from 'uuid';
import ForgetPassModal from "./forgetPassModel";
import SetNewPassModal from "./setNewPassModal";


const LoginFrom = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });
  const [loading, setLoading] = useState(false);
  const [fcmToken, setFcmToken] = useState();
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();
  const [showForgetPassModal, setShowForgetPassModal] = useState(false);
  const toggleForgetPassModal = () => { setShowForgetPassModal(!showForgetPassModal) }
  const [showSetNewPassModal, setShowSetNewPassModal] = useState(false);
  const toggleSetNewPassModal = () => { setShowSetNewPassModal(!showSetNewPassModal) }
  const randomDeviceId = uuidv4();
  const [email, setEmail] = useState("");
  // const { requestNotificationPermission } = useFirebaseMessaging()

  // useEffect(() => {
  //   let data;
  //   async function tokenFunc() {
  //     data = await getTokens(setTokenFound);
  //     if (data) {
  //       console.log("token generated from loginForm >>>>> ", data);
  //     }
  //     setFcmToken(data);
  //     return data;
  //   }

  //   tokenFunc();
  // }, []);

  const handleLogin = (data) => {
    setLoading(true);
    let deviceId = "";
    if (localStorage.getItem('deviceId')) {
      deviceId = localStorage.getItem('deviceId');
    } else {
      deviceId = "2024_" + randomDeviceId;
    }
    localStorage.setItem("deviceId", deviceId);
    let Data = {
      ...data,
      deviceId: deviceId,
      deviceToken: fcmToken ? fcmToken : randomDeviceId.slice(0, 10),
    };

    const apiUrl = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "login";
    callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
      if (response.status == true) {
        setLoading(false);
        sendSuccessMessage(response);
        dispatch(saveUserDetails(response.data));
        Cookies.set("deviceId", response.data.deviceId);
        Cookies.set("zidniToken", response.data.accessToken);
        Cookies.set("refreshZidniToken", response.data.refreshToken);
        Cookies.set("userDetail", JSON.stringify(response?.data));
        dispatch(setShowLoginPopup(false));
      } else {
        sendErrorMessage(response);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <form
        id="loginForm"
        className="fade-in log-form"
        onSubmit={handleSubmit(handleLogin)}
      >
        <div className="top-pop">
          <h4>Login</h4>
          {/* <label className="select-label" htmlFor="signupgender">
          Select roll
        </label>
        <select
          id="signupgender"
          name="signupgender"
          className="signupgender form-select"
          aria-label="Default select example"
          {...register("type", {
            required: "Field is required",
          })}
        >
          <option value={""} selected="selected">
            Select roll
          </option>
          <option value={1}>Student</option>
          <option value={2}>Instructor</option>
        </select>
        <p className="mb-0 text-danger">{errors?.type?.message}</p> */}

          <div className="mb-3">
            <label htmlFor="loginUsername" className="form-label">
              Email or username
            </label>
            <input
              className="form-control"
              type="email"
              id="loginUsername"
              name="loginUsername"
              placeholder="Username"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
            />
            <p className="text-danger m-0">{errors?.email?.message}</p>
          </div>
          <div className="mb-3">
            <label htmlFor="loginPassword" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="loginPassword"
              name="loginPassword"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
            />
            <p className="text-danger m-0">{errors?.password?.message}</p>
          </div>
          <div className="forgot-pass text-end" style={{ fontSize: "14px" }} type='button' onClick={() => toggleForgetPassModal()}>
            Forgot password
          </div>
        </div>
        <div className="bottom-pop">
          {/* <div className="or-login">
          <div className="or">Or login with</div>
          <button className="google-login">
            <span>
              <img src="./assets/images/googleicon.svg" alt="" />
            </span>
            Google
          </button>
        </div> */}
          <button type="submit" id="login">
            {loading == true ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              "Login"
            )}
          </button>
        </div>
      </form>
      <ForgetPassModal
        showForgetPassModal={showForgetPassModal}
        toggleForgetPassModal={toggleForgetPassModal}
        toggleSetNewPassModal={toggleSetNewPassModal}
        setEmail={setEmail}
      />
      <SetNewPassModal
        showSetNewPassModal={showSetNewPassModal}
        toggleSetNewPassModal={toggleSetNewPassModal}
        email={email}
      />
    </>
  );
};

export default LoginFrom;
