import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import { TabContent, TabPane } from "reactstrap";
import { useNavigate, useLocation } from "react-router";
import EventType from "../../components/create-event/EventType";
import CreateEventDetail from "../../components/create-event/CreateEventDetail";
import EventDateTime from "../../components/create-event/EventDateTime";
import EventProgram from "../../components/create-event/EventProgram";
import EventHostSponsor from "../../components/create-event/EventHostSponsor";
import EventPrice from "../../components/create-event/EventPrice";
import PublishEvent from "../../components/create-event/PublishEvent";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { sendErrorInfo } from "../../utility/helperFunctions";

const CreateEvent = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [isEventPublic, setIsEventPublic] = useState(true);
  const [eventId, setEventId] = useState(null);
  const location = useLocation();
  const [eventDetail, setEventDetail] = useState({});
  const [eventTags, setEventTags] = useState([]);

  useEffect(() => {
    if (location?.state?.eventId !== undefined) {
      getEventDetail();
    }
  }, []);

  const getEventDetail = () => {
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/${location?.state?.eventId ? location?.state?.eventId : eventDetail?.id
      }`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response.status === true) {
        console.log("response", response.data);
        setEventDetail(response.data);
        setEventTags(JSON.parse(response.data.eventTags));
      }
    });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleEventTab = (tab) => {

    if (eventDetail?.id !== undefined) {
      toggleTab(tab);
    } else {
      sendErrorInfo("First create event than go next tab");
    }
  };


  return (
    <>
      <Header />

      <section className="tab-section assa">
        <div className="container">
          {activeTab === 1 && (
            <div className="maincor">
              <div className="new-cont nnn">
                <h4>Create event</h4>
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(-1)}
                  className="close-btn"
                >
                  Close
                </a>
              </div>
            </div>
          )}
          <div className={activeTab !== 1 ? "view-tabs-content" : ''}>
            {activeTab !== 1 && (
              <ul className="tab">
                <li
                  className={activeTab === 2 ? "active tablinks" : "tablinks"}
                  onClick={() => toggleTab(2)}
                  id="defaultOpen"
                >
                  Add event details
                </li>
                <li
                  className={activeTab === 3 ? "active tablinks" : "tablinks"}
                  onClick={() => handleEventTab(3)}
                >
                  Date time and location
                </li>
                <li
                  className={activeTab === 4 ? "active tablinks" : "tablinks"}
                  onClick={() => handleEventTab(4)}
                >
                  Add programmes{" "}
                </li>
                <li
                  className={activeTab === 5 ? "active tablinks" : "tablinks"}
                  onClick={() => handleEventTab(5)}
                >
                  Invite co-hosts and sponsors{" "}
                </li>
                <li
                  className={activeTab === 6 ? "active tablinks" : "tablinks"}
                  onClick={() => handleEventTab(6)}
                >
                  Pricing
                </li>
                <li
                  className={activeTab === 7 ? "active tablinks" : "tablinks"}
                  onClick={() => handleEventTab(7)}
                >
                  Publish the event
                </li>
              </ul>
            )}
            <div
              className={
                activeTab === 1
                  ? "pt-0 tab-iner event-inner"
                  : "tab-iner event-inner"
              }
            >
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <EventType
                    toggleTab={toggleTab}
                    setIsEventPublic={setIsEventPublic}
                    eventDetail={eventDetail}
                  />
                </TabPane>
                <TabPane tabId={2}>
                  {activeTab === 2 && (
                    <CreateEventDetail
                      navigate={navigate}
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      setEventId={setEventId}
                      setEventDetail={setEventDetail}
                      eventDetail={eventDetail}
                      getEventDetail={getEventDetail}
                    />
                  )}
                </TabPane>
                <TabPane tabId={3}>
                  {activeTab === 3 && (
                    <EventDateTime
                      navigate={navigate}
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      eventId={eventId}
                      eventDetail={eventDetail}
                      getEventDetail={getEventDetail}
                    />
                  )}
                </TabPane>
                <TabPane tabId={4}>
                  {activeTab === 4 && (
                    <EventProgram
                      navigate={navigate}
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      eventId={eventId}
                      eventDetail={eventDetail}
                      getEventDetail={getEventDetail}
                    />
                  )}
                </TabPane>
                <TabPane tabId={5}>
                  {activeTab === 5 && (
                    <EventHostSponsor
                      navigate={navigate}
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      eventId={eventId}
                      eventDetail={eventDetail}
                      getEventDetail={getEventDetail}
                    />
                  )}
                </TabPane>
                <TabPane tabId={6}>
                  {activeTab === 6 && (
                    <EventPrice
                      navigate={navigate}
                      activeTab={activeTab}
                      toggleTab={toggleTab}
                      eventId={eventId}
                      eventDetail={eventDetail}
                      getEventDetail={getEventDetail}
                    />
                  )}
                </TabPane>
                <TabPane tabId={7}>
                  {activeTab === 7 && (
                    <PublishEvent
                      navigate={navigate}
                      eventId={eventId}
                      isEventPublic={isEventPublic}
                    />
                  )}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateEvent;
