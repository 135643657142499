import React, { useState } from 'react'
import Header from './Header'
import SearchBar from './SearchBar'
import Footer from './Footer'
import LoginModal from './LoginModal'
import { useSelector } from 'react-redux'

const Layout = ({children}) => {
  const showLoginPopup = useSelector((state)=>state.root.showLoginPopup)
  return (
    <>
    <Header/>
    <SearchBar  />
    {showLoginPopup && <LoginModal />}
    {children}
    <Footer/>
    </>
  )
}

export default Layout