import { APIENDPOINTS, APIURL } from "../../../api/apiUrl";

let url = APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + 'rtc';
export const config = {
    uid: '',
    appId: "7b1408dde4054d9fa4515d61af7f02b2",
    channelName: "",
    rtcToken: null,
    serverUrl: url,
    proxyUrl: "",
    tokenExpiryTime: 600,
    token: null,
    encryptionMode: "aes-128-gcm2",
    salt: "",
    encryptionKey: "",
    destChannelName: "",
    destChannelToken: "",
    destUID: 2,
    secondChannel: "",
    secondChannelToken: "",
    secondChannelUID: 2,
    selectedProduct: "rtc"
};

