
import React from "react";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const PublishCourse = ({
  navigate,
  activeTab,
  setAllCourseData,
  allCourseData,
}) => {
  const onSubmit = (status) => {

    const Data = new FormData();

    Data.append("courseStatus", status);

    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/update/${allCourseData.id}`;
    const multipart = true;

    callApi(apiUrl, { method: "PUT", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        sendSuccessInfo(res.message);
        navigate('/my-courses')
      } else {
        sendErrorInfo(res.data.message);
      }
    });
  };



  return (
    <>
      {/* <div className="see-pre">
        <button>
          <img src="./assets/images/preview-icon.svg" alt="" />
          <span>See preview</span>
        </button>
      </div> */}
      <div className="course-details publish-course">
        <h3>
          Congratulation you have successfully created a public event would you
          like to publish it now or you want to do it latter after some
          amendments{" "}
        </h3>
        <p>
          Your course has been successfully reviewed by the experts now its
          ready to publish
        </p>
        <div className="shar">
          <button className="later" onClick={() => onSubmit(0)}>
            i’ll do it later
          </button>
          <button className="revie" id="sendforreview" onClick={() => onSubmit(2)}>
            Send for review
          </button>
        </div>
        <section className="step-section">
          <div className="step-full">
            <div className="step-content">
              <h5 className="steps">
                Step / <span>{activeTab}</span>
              </h5>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PublishCourse;
