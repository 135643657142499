

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { throttle } from "lodash";
import store from "./store";
import { Provider } from "react-redux";
import { saveState } from "./utility/LocalStorage";
import { ReactNotifications } from "react-notifications-component";

import "react-notifications-component/dist/theme.css";

store.subscribe(
  throttle(() => {
    saveState({
      root: store.getState().root,
    });
  }, 1000)
);



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router
      basename={process.env.PUBLIC_URL}
    >
      <ReactNotifications />
      <App />
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a functions
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
