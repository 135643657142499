import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/Layout'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { callApi } from '../../api/ApiHelper';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
import Loader from '../../components/common/Loader';
import { sendErrorInfo, sendSuccessInfo } from '../../utility/helperFunctions';

const Profile = () => {
  const userDetail = useSelector((state) => state.root.userDetails);
  const [showExperience, setShowExperience] = useState(false);
  const [showQualifications, setShowQualifications] = useState(false);
  const [showWorkExperience, setShowWorkExperience] = useState(false);
  const [showInterest, setShowInterest] = useState(false);
  const [detail, setDetail] = useState({})
  const [callingApi, setCallingApi] = useState(true)

  const [experienceValue, setExperienceValue] = useState('');
  const [qualificationsValue, setQualificationsValue] = useState('');
  const [workExperienceValue, setWorkExperienceValue] = useState('');
  const [interestValue, setInterestValue] = useState('');

  useEffect(() => {
    if (callingApi) {
      setCallingApi(false)
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `users/get/${userDetail?.id}`;
      callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
        if (response.status === true) {
          setDetail(response.data);
          setQualificationsValue(response.data?.Profile?.qualification)
          if (response.data?.Profile?.instructorDetail != null) {
            setExperienceValue(JSON.parse(response.data?.Profile?.instructorDetail)?.experience ? JSON.parse(response.data?.Profile?.instructorDetail)?.experience : '')
            setWorkExperienceValue(JSON.parse(response.data?.Profile?.instructorDetail)?.workExperience ? JSON.parse(response.data?.Profile?.instructorDetail)?.workExperience : '')
            setInterestValue(JSON.parse(response.data?.Profile?.instructorDetail)?.interest ? JSON.parse(response.data?.Profile?.instructorDetail)?.interest : '')
          }
        }
      });
    }
  }, [callingApi])

  const handleAddExperience = () => {
    if (experienceValue !== '') {
      let data = detail?.Profile?.instructorDetail !== null && JSON.parse(detail?.Profile?.instructorDetail)
      let finalData = {
        ...data,
        experience: experienceValue
      }
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `set-profile`;
      callApi(apiUrl, { method: "PUT", data: { instructorDetail: JSON.stringify(finalData) } }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response.message)
          setShowExperience(false)
          setCallingApi(true)
        } else {
          sendErrorInfo(response.data.message)
        }
      });
    } else {
      sendErrorInfo('Please fill experience field')
    }
  }

  const handleAddQualification = () => {
    if (qualificationsValue !== '') {
      let finalData = {
        qualification: qualificationsValue
      }
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `set-profile`;
      callApi(apiUrl, { method: "PUT", data: finalData }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response.message)
          setShowQualifications(false)
          setCallingApi(true)
        } else {
          sendErrorInfo(response.data.message)
        }
      });
    } else {
      sendErrorInfo('Please fill qualification field')
    }
  }

  const handleAddWorkExperience = () => {
    if (workExperienceValue !== '') {
      let data = detail?.Profile?.instructorDetail !== null && JSON.parse(detail?.Profile?.instructorDetail)
      let finalData = {
        ...data,
        workExperience: workExperienceValue
      }
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `set-profile`;
      callApi(apiUrl, { method: "PUT", data: { instructorDetail: JSON.stringify(finalData) } }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response.message)
          setShowWorkExperience(false)
          setCallingApi(true)
        } else {
          sendErrorInfo(response.data.message)
        }
      });
    } else {
      sendErrorInfo('Please fill work experience field')
    }
  }

  const handleAddInterest = () => {
    if (interestValue !== '') {
      let data = detail?.Profile?.instructorDetail !== null && JSON.parse(detail?.Profile?.instructorDetail)
      let finalData = {
        ...data,
        interest: interestValue
      }
      const apiUrl =
        APIURL.AUTH_URL +
        APIENDPOINTS.AUTH_END_POINT +
        `set-profile`;
      callApi(apiUrl, { method: "PUT", data: { instructorDetail: JSON.stringify(finalData) } }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response.message)
          setShowInterest(false)
          setCallingApi(true)
        } else {
          sendErrorInfo(response.data.message)
        }
      });
    } else {
      sendErrorInfo('Please fill interest field')
    }
  }

  
  const handleUploadProfile = (e, key) => {
    if (!e.target.files[0]?.type.includes("image")) {
      sendErrorInfo("Please Upload Image File");
      return;
    }else{
      const apiUrl =
      APIURL.AUTH_URL +
      APIENDPOINTS.AUTH_END_POINT +
      `user/update-image/${userDetail?.id}`;
      const multipart = true;
      let data = new FormData()
      data.append('profilePhoto',e.target.files[0])

    callApi(apiUrl, { method: "POST", data: data , multipart }).then((response) => {
      if (response.status === true) {
        sendSuccessInfo(response.message)
        setCallingApi(true)
      } else {
        sendErrorInfo(response.data.message)
      }
    });
    }
  };

  return (
    <Layout>
      <section className="profile">
        <form action="">
          <div className="container">
            <div className="cli-card">
              <div className="univ-card round">
                <div className="left-content">
                  <div className="card-image round">
                    <img src={detail?.Profile?.profilePhoto ? detail?.Profile?.profilePhoto : "./assets/images/avatarimg.svg"} alt="" />
                  </div>
                  <div className="card-detail">
                    {/* <div className="cate">Chief Technology Officer</div> */}
                    <h4>{userDetail?.Profile?.firstName} {userDetail?.Profile?.lastName}</h4>
                    <div className='editprofile'>
                      <label htmlFor="profileEdit" className='btn btn-success'>Edit profile</label>
                      <input type="file" id='profileEdit' className='d-none' onChange={(e) => handleUploadProfile(e)} />
                    </div>
                  </div>
                </div>
                <div className="right-content">
                  <Link to="/university-detail" state={{ id: userDetail?.Profile?.University?.userId }} className="round">
                    View University
                  </Link>
                </div>
              </div>
            </div>
            <div className="expre">
              <div className="cards">
                <h3>Experience</h3>
                <div className="gray-crd">
                  <div className='grinner'>
                    <div className="left-content">
                      <h4>Showcase your skills and experience here!</h4>
                      <p>
                        {detail?.Profile?.instructorDetail != null && `${JSON.parse(detail?.Profile?.instructorDetail)?.experience} years`}
                      </p>
                    </div>
                    <div className="right-content">
                      <button type='button' className="round" onClick={() => setShowExperience(true)}>
                        <span>
                          <img src="./assets/images/plus.svg" alt="" />
                        </span>
                        Add your experience
                      </button>
                    </div>
                  </div>
                  {showExperience &&
                    <div className='adddetail mt-3'>
                      <div className='mb-3'>
                        <label htmlFor="addYourExperience"></label>
                        <input value={experienceValue} type="number" className='form-control bg-white' placeholder='Add your experience' onChange={(e) => setExperienceValue(e.target.value)} id='addYourExperience' />
                        <p className='text-danger'></p>
                      </div>
                      <div className='btssub'>
                        <button type='button' className='btn btn-success' onClick={() => handleAddExperience()}>Submit</button>
                        <button type='button' className='btn btn-danger' onClick={() => setShowExperience(false)}>Cancel</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="cards">
                <h3>Qualifications</h3>
                <div className="gray-crd">
                  <div className="grinner">
                    <div className="left-content">
                      <h4>Showcase your qualifications here!</h4>
                      <p>
                        {detail?.Profile?.qualification}
                      </p>
                    </div>
                    <div className="right-content">
                      <button type='button' className="round" onClick={() => setShowQualifications(true)}>
                        <span>
                          <img src="./assets/images/plus.svg" alt="" />
                        </span>
                        Add qualification
                      </button>
                    </div>
                  </div>
                  {showQualifications &&
                    <div className='adddetail mt-3'>
                      <div className='mb-3'>
                        <label htmlFor="addYourQualification"></label>
                        <input value={qualificationsValue} type="text" className='form-control bg-white' placeholder='Add your qualification' onChange={(e) => setQualificationsValue(e.target.value)} id='addYourQualification' />
                        <p className='text-danger'></p>
                      </div>
                      <div className='btssub'>
                        <button type='button' className='btn btn-success' onClick={() => handleAddQualification()}>Submit</button>
                        <button type='button' className='btn btn-danger' onClick={() => setShowQualifications(false)}>Cancel</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="cards">
                <h3>Work experience</h3>
                <div className="gray-crd">
                  <div className="grinner">
                    <div className="left-content">
                      <h4>Showcase your Work experience here!</h4>
                      <p>
                        {detail?.Profile?.instructorDetail != null && `${JSON.parse(detail?.Profile?.instructorDetail)?.workExperience} years`}
                      </p>
                    </div>
                    <div className="right-content">
                      <button type='button' className="round" onClick={() => setShowWorkExperience(true)}>
                        <span>
                          <img src="./assets/images/plus.svg" alt="" />
                        </span>
                        Add work experience
                      </button>
                    </div>
                  </div>
                  {showWorkExperience &&
                    <div className='adddetail mt-3'>
                      <div className='mb-3'>
                        <label htmlFor="addYourWorkExperience"></label>
                        <input value={workExperienceValue} type="number" className='form-control bg-white' placeholder='Add your work experience' id='addYourWorkExperience' onChange={(e) => setWorkExperienceValue(e.target.value)} />
                        <p className='text-danger'></p>
                      </div>
                      <div className='btssub'>
                        <button type='button' className='btn btn-success' onClick={() => handleAddWorkExperience()}>Submit</button>
                        <button type='button' className='btn btn-danger' onClick={() => setShowWorkExperience(false)}>Cancel</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="cards">
                <h3>Fields of interest</h3>
                <div className="gray-crd">
                  <div className="grinner">
                    <div className="left-content">
                      <h4>Showcase your interest here!</h4>
                      <p>
                        {detail?.Profile?.instructorDetail != null && JSON.parse(detail?.Profile?.instructorDetail)?.interest}
                      </p>
                    </div>
                    <div className="right-content">
                      <button type='button' className="round" onClick={() => setShowInterest(true)}>
                        <span>
                          <img src="./assets/images/plus.svg" alt="" />
                        </span>
                        Add field of interest
                      </button>
                    </div>
                  </div>
                  {showInterest &&
                    <div className='adddetail mt-3'>
                      <div className='mb-3'>
                        <label htmlFor="addYourFieldInterest"></label>
                        <input value={interestValue} type="text" className='form-control bg-white' placeholder='Add field of interest' id='addYourFieldInterest' onChange={(e) => setInterestValue(e.target.value)} />
                        <p className='text-danger'></p>
                      </div>
                      <div className='btssub'>
                        <button type='button' className='btn btn-success' onClick={() => handleAddInterest()}>Submit</button>
                        <button type='button' className='btn btn-danger' onClick={() => setShowInterest(false)}>Cancel</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
      {/* <section class="my-course ">
        <div class="container">
          <div class="course-card round progress-card">
            <div class="left-content">
              <div class="card-image round"><img src="./assets/images/pics.png" alt="" /></div>
              <div class="card-detail">
                <h4>Most of these courses will be offered in offline mode and will involve ample practical
                  learning.</h4>
                <p class="oph">Ophthalmology Specialty</p>
                <div class="prog-bar">
                  <div class="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar bg-success" style={{ width: '25%' }}>25%</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dropdown close-btn">
              <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img class="close" src="./assets/images/close-btn.svg" alt="" />
                <img class="dot" src="./assets/images/tdbtn.svg" alt="" />
              </button>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Share course</a></li>
                <li><a class="dropdown-item" href="#">Download certificate</a></li>
                <li><a class="dropdown-item" href="#">Rate this course</a></li>
              </ul>
            </div>
          </div>
          <div class="course-card round certificate-card">
            <div class="top">
              <div class="left-content">
                <div class="card-image round"><img src="./assets/images/pics.png" alt="" /></div>
                <div class="card-detail">
                  <h4>Most of these courses will be offered in offline mode and will involve ample practical
                    learning.</h4>
                  <p class="oph">Ophthalmology Specialty</p>
                  <div class="congo">
                    <h5><span><img src="./assets/images/trophy.svg" alt="" /></span>Congratulations on getting
                      your certificate!</h5>
                  </div>
                </div>
              </div>

              <div class="dropdown close-btn">
                <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img class="close" src="./assets/images/close-btn.svg" alt="" />
                  <img class="dot" src="./assets/images/tdbtn.svg" alt="" />
                </button>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Share course</a></li>
                  <li><a class="dropdown-item" href="#">Download certificate</a></li>
                  <li><a class="dropdown-item" href="#">Rate this course</a></li>
                </ul>
              </div>
            </div>
            <div class="bottom">
              <div class="sharebtn">
                <button class="share"><span><img src="./assets/images/share.svg" alt="" /></span>Share
                  certificate</button>
                <button class="download"><span><img src="./assets/images/download.svg" alt="" /></span>Download
                  certificate</button>
              </div>
              <div class="eve">
                <button class="add-eve"><span><img src="./assets/images/eve.svg" alt="" /></span> Add evolution
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <Loader /> */}
    </Layout>
  )
}

export default Profile