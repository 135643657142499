import React, { useEffect } from 'react';
import { createFastboard, mount } from "@netless/fastboard";
import { config } from "../utils/config";
import { useDispatch } from "react-redux";
import { DASHBOARD_DATA } from "../../../store/types";
const FastboardComponent = ({ roomUid, roomToken, isAdmin }) => {
  const dispatch = useDispatch()
  let app;

  useEffect(() => {
    const mountFastboard = async (div) => {
      try {
        app = await createFastboard({
          sdkConfig: {
            appIdentifier: process.env.REACT_APP_WHITEBOARD_IDENTIFIER,
            region: "us-sv",
          },
          joinRoom: {
            uid: config.uid,
            uuid: roomUid,
            roomToken: roomToken,
            region: "us-sv",
            isWritable: isAdmin ? true : false
          },
          managerConfig: {
            cursor: isAdmin ? true : false,
          },
        });



        console.log("whiteboard app", app.room.state.roomMembers);

        // Set roles on room join
        app.room.state.roomMembers.forEach((member) => {
          // Example: Assign "admin" role to a specific user
          console.log("members", member)
          // if (isAdmin) {
          //   app.room.setMemberState(member.memberId, { role: "admin" });
          // }

          // // Example: Assign "reader" role to another user
          // if (!isAdmin) {
          //   app.room.setMemberState(member.memberId, { role: "reader" });
          // }
        });

        window.app = app;
        return mount(app, div);
      } catch (error) {
        console.error("Error creating Fastboard:", error);
        if (app) {
          app.destroy();
        }
        dispatch({
          type: DASHBOARD_DATA,
          payload: null
        });
        // Handle the error as needed
        // You might want to dispatch an action to update the state with an error message
      }
    };

    const appDiv = document.getElementById("whiteboard_app");

    if (appDiv) {
      mountFastboard(appDiv);
    }
    return () => {
      if (app) {
        app.destroy();
      }
      dispatch({
        type: DASHBOARD_DATA,
        payload: null
      });
    }
  }, []);
  return <div style={{
    width: "100%",
    height: "93vh",
  }} id="whiteboard_app"></div>;
};

export default FastboardComponent;