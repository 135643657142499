// store/index.js

// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
// import { loadState } from "../utility/LocalStorage";
// import reducer from "./reducers/userReducers";
// export const initStore = (initialState = {}) => {
//   const persistedState = loadState();
//   return configureStore({
//     reducer: rootReducer,
//     preloadedState: persistedState,

//     middleware: [thunkMiddleware],
//     devTools: process.env.NODE_ENV !== "production", // Enable Redux DevTools in development mode
//   });
// };

// const rootReducer = combineReducers({
//   root: reducer,
// });

import { configureStore } from "@reduxjs/toolkit";
import userReducers from "./reducers/userReducers";
import { loadState } from "../utility/LocalStorage";

const persistedState = loadState();

const rootReducer = {
  root: userReducers,
  
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

export default store;
