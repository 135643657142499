import React from "react";
import Layout from "../../components/common/Layout";

const GetZidniApp = () => {
  return (
    <Layout>
      <section className="my-3 my-md-5">
        <div className="container">
          <h2 className="text-center text-primary">
            Improving your skill in digitalised era. — on iOS and Android
          </h2>
        </div>
      </section>
      <section className="getzidnipg mb-3 mb-md-5">
        <div className="container">
          <div className="item">
            <img src="./assets/images/Group 1182.png" alt="" />
          </div>
          <div className="content">
            <p>Multiple universities under the one roof</p>
            <p>Diploma courses and certificate course with credit hours</p>
            <p>Event management system with multiple programs in each events</p>
            <div class="app-info">
              <div class="app-img">
                <a href="#" class="google"></a>
                <a href="#" class="app-store"></a>
              </div>
              <div class="bar-code">
                <img src="./assets/images/bar-code.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default GetZidniApp;
