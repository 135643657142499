

import { AUDIO_DIRECTORY, CHAT_MESSAGES, LOGGED_IN_MODAL, LOGGED_IN_USER_DETAILS, SET_LOGIN_ACTIVE_TAB, UPDATE_LIVE_ALL_MEDIA, VIDEO_DIRECTORY, NOTIFICATIONS} from "../types";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_IN_USER_DETAILS,
    payload,
  };
};

export const saveNotifications = (payload) => {
  return {
    type: NOTIFICATIONS,
    payload,
  };
};


export const setShowLoginPopup = (payload) => {
  return {
    type: LOGGED_IN_MODAL,
    payload,
  };
};

export const setLoginTab = (payload) => {
  return {
    type: SET_LOGIN_ACTIVE_TAB,
    payload,
  };
};



export function liveAudioDirectory(payload) {
  return { type: AUDIO_DIRECTORY, payload }
}

export function liveVideoDirectory(payload) {
  return { type: VIDEO_DIRECTORY, payload }
}

export function updateLiveAllMedia(payload) {
  return { type: UPDATE_LIVE_ALL_MEDIA, payload }
}

export function CallUserChat(payload) {
  return { type: CHAT_MESSAGES, payload }
}