// Import necessary components and hooks from Agora SDK and React
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
} from "react";
import {
    LocalVideoTrack,
    RemoteUser,
    useJoin,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    usePublish,
    useRTCClient,
    useRemoteUsers,
    useClientEvent,
    useLocalScreenTrack,
    useTrackEvent,
    AgoraRTCScreenShareProvider,
} from "agora-rtc-react";

import AgoraRTC from "agora-rtc-sdk-ng";
import { config } from "../utils/config";
import { fetchRTCToken } from "../utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { leaveRoom, setupSocketCallback, updateMedia, updateMediaAll, whiteboardOnOff } from "../utils/SocketAction";
import Chat from "../utils/Chat";
import UsersList from "../utils/UsersList";
import { CLEAR_ALL_DATA_ON_LEAVE } from "../../../store/types";
import { callApi } from "../../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../../api/apiUrl";
import WhiteboardComponent from "./WhiteboardComponent";
import { sendErrorInfo } from "../../../utility/helperFunctions";
import { useNavigate } from "react-router-dom";

const AgoraContext = createContext(null);

// Create the Agora context
export const AgoraProvider = ({
    children,
    localCameraTrack,
    localMicrophoneTrack,
}) => (
    <AgoraContext.Provider
        value={{ localCameraTrack, localMicrophoneTrack, children }}
    >
        {children}
    </AgoraContext.Provider>
);

// Custom hook to access the Agora context
export const useAgoraContext = () => {
    const context = useContext(AgoraContext);
    if (!context)
        throw new Error("useAgoraContext must be used within an AgoraProvider");
    return context;
};

// AgoraConferenceManager component responsible for handling Agora-related logic and rendering UI
const AgoraConferenceManager = ({
    isHost,
    config,
    children,
    client,
    webinarData,
    openChatModel = () => { },
    userInfo,
    chatModal
}) => {
    // Retrieve local camera and microphone tracks and remote users
    const agoraEngine = useRTCClient();
    const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
    const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack();

    const remoteUsers = useRemoteUsers();
    const [localAudioMuted, setLocalAudioMuted] = useState(true);
    const [localVideoEnabled, setLocalVideoEnabled] = useState(true);
    const [screenShare, setScreenShare] = useState(false);
    const [whiteboard, setWhiteBoard] = useState(null);

    const [usersModal, setUsersModal] = useState(false);
    const [speakingUsers, setSpeakingUsers] = useState({});
    const streams = useSelector((state) => state.root.streams);
    const mediaAll = useSelector((state) => state.root.allMedia);
    const userDetails = useSelector((state) => state.root.userDetails);
    const dashboardData = useSelector(state => state.root.dashboardData)
    const [hostScreenShare, setHostScreenShare] = useState();
    const [screenShared, setScreenShared] = useState(null);

    const navigate = useNavigate()
    const videoContainerRef = useRef(null);

    const dispatch = useDispatch();
    const { screenTrack, isLoading, error } = useLocalScreenTrack(
        screenShare,
        {},
        "enabled",
        client
    );




    useEffect(() => {
        setupSocketCallback((data) => {
            if (data.isShare === true) {
                setScreenShared(data)
            } else {
                setScreenShared(null)
            }
            if (data.client == 'web') {
                setHostScreenShare(null)
            } else {
                setHostScreenShare(data.isShare ? {
                    ...data.shareInfo
                } : null)
            }

        })
    }, [])

    useEffect(() => {
        if (screenTrack) {
            let obj = {
                isShare: true,
                type: 'share',
                client: "web",
                shareInfo: {
                    height: window.screen.height,
                    width: window.screen.width
                }
            }
            dispatch(updateMedia(obj));
            setScreenShared(userInfo)
        } else {
            let obj = {
                type: 'share',
                isShare: false,
                shareInfo: null
            }
            dispatch(updateMedia(obj));
            setScreenShared(null)
        }
    }, [screenTrack])



    const handleVolumeIndicator = (volumes) => {
        const newSpeakingUsers = {};

        volumes.forEach((volume, index) => {
            // If the volume level is above a certain threshold,
            // mark the user as speaking.
            if (volume.level > 65) {
                newSpeakingUsers[volume.uid] = true;
            } else {
                newSpeakingUsers[volume.uid] = false;
            }
        });

        setSpeakingUsers(newSpeakingUsers);
    };
    client.on("volume-indicator", handleVolumeIndicator);



    // Function to toggle local user's audio
    const toggleLocalAudio = () => {
        dispatch(updateMedia({ audio: localAudioMuted ? 0 : 1 }));
        localMicrophoneTrack.setEnabled(!localAudioMuted);
        setLocalAudioMuted((prev) => !prev);
    };

    // Function to toggle local user's video
    const toggleLocalVideo = () => {
        dispatch(updateMedia({ video: localVideoEnabled ? 0 : 1 }));
        localCameraTrack.setEnabled(!localVideoEnabled);
        setLocalVideoEnabled((prev) => !prev);
    };

    usePublish(
        screenShare ? [screenTrack] : [localMicrophoneTrack, localCameraTrack]
    );
    // usePublish([localMicrophoneTrack, screenTrack], screenTrack !== null, screenShareClient.current);
    // Join the Agora channel with the specified configuration

    useJoin({
        appid: config.appId,
        channel: config.channelName,
        token: config.rtcToken,
        uid: config.uid,
    });
    // AgoraRTC.setParameter("AUDIO_VOLUME_INDICATION_INTERVAL", 2000);
    client.enableAudioVolumeIndicator();
    useTrackEvent(screenTrack, "track-ended", () => {
        stopScreenSharing();
    });

    useClientEvent(agoraEngine, "user-joined", (user) => {
        console.log("The user", user.uid, " has joined the channel", user);
    });

    useClientEvent(agoraEngine, "user-left", (user) => {
        console.log("The user", user.uid, " has left the channel");
    });

    useClientEvent(agoraEngine, "user-published", (user, mediaType) => {
        console.log("The user", user.uid, " has published media in the channel");
    });

    useEffect(() => {
        return () => {
            dispatch({
                type: CLEAR_ALL_DATA_ON_LEAVE,
            });
            client && client.off("volume-indicator", handleVolumeIndicator);
            localCameraTrack?.close();
            localMicrophoneTrack?.close();
        };
    }, []);

    const startScreenShare = async () => {
        try {
            setScreenShare(true);

            // Stop the camera track if it's currently active

            if (localCameraTrack) {

                // localMicrophoneTrack.stop();
                // localMicrophoneTrack.close();
                // localCameraTrack.stop();
                // localCameraTrack.close();
                await client.unpublish(localCameraTrack);
                // await client.unpublish(localMicrophoneTrack);
            }

            // Create and publish the screen share track
            // const screenTrack = await AgoraRTC.createScreenVideoTrack();

            await client.publish(screenTrack);

            // Update the UI and state
        } catch (error) {
            console.error("Failed to start screen sharing:", error);
        }
    };

    const stopScreenSharing = async () => {
        if (screenShare && screenTrack) {
            screenTrack.stop(); // Stops the screen sharing track
            screenTrack.close(); // Closes the screen sharing track
            await client.unpublish(screenTrack); // Unpublish the screen track

            setScreenShare(false);
            // Start the camera track if it was stopped earlier

            await client.publish(localCameraTrack); // Publish the new camera track
            await client.publish(localMicrophoneTrack); // Publish the new camera track

            // setLocalVideoTrack(newCameraTrack); // Update your local track with new camera track
        }
    };

    const toggleScreenShare = () => {
        if (screenShared) {
            return sendErrorInfo("Someone Already share there screen")
        } else
            if (screenShare) {
                stopScreenSharing();
            } else {
                startScreenShare();

            }
    };

    const toggleWhiteboard = () => {
        if (dashboardData) {
            return sendErrorInfo("Someone already shared their whiteboard")
        }
        if (!whiteboard) {
            let url =
                APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + "create-room";
            callApi(url, { method: "GET" }).then((response) => {

                if (response.status === true) {
                    joinWhiteBoardRoom(response.data.roomId)
                    // let data = {
                    //   roomId: response.data.roomId,
                    //   roomToken: response.data.token
                    // }
                    // setWhiteBoard(data);
                    // dispatch(whiteboardOnOff(data))
                } else {
                    dispatch(whiteboardOnOff(null))
                    setWhiteBoard(null)
                }
            });
        } else {
            dispatch(whiteboardOnOff(null))
            setWhiteBoard(null);
        }
    };

    const joinWhiteBoardRoom = (roomId) => {
        if (!whiteboard) {
            let role = isLocalUserHost ? 'admin' : 'writer'
            let url =
                APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + `create-whiteboard?roomId=${roomId}&role=${role}`;
            callApi(url, { method: "GET" }).then((response) => {

                let roomData = {
                    roomId: roomId,
                    roomToken: response.roomToken
                }
                setWhiteBoard(roomData);
                dispatch(whiteboardOnOff(roomData))
            });

        } else {
            dispatch(whiteboardOnOff(null))
            setWhiteBoard(null);
        }
    }

    function getRandomIntInRange() {
        const randomFraction = Math.random();
        const randomNumberInRange = Math.floor(randomFraction * 65536);
        return randomNumberInRange;
    }
    const fetchTokenFunction = async (channelName, uid, isHost) => {
        const intId = getRandomIntInRange();
        if (config.serverUrl !== "" && channelName !== "") {
            try {
                const token = await fetchRTCToken(channelName, intId, isHost);
                config.uid = intId;
                config.rtcToken = token;
                config.channelName = channelName;
                setScreenShare(!screenShare);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log(
                "Please make sure you specified the token server URL in the configuration file"
            );
        }
    };

    const getHostStream = () => {
        return streams.find((stream) => stream.adminId === webinarData.organisedBy);
    };


    const getUserData = (uid) => {
        return streams.find((stream) => stream.agoraUid === uid);
    };
    const isLocalUserHost = userDetails.id === webinarData.organisedBy;
    let hostStream = getHostStream();

    useEffect(() => {
        if (dashboardData && !isLocalUserHost) {
            joinWhiteBoardRoom(dashboardData?.roomId)
        } else {
            setWhiteBoard(null)
        }
    }, [dashboardData])
    const updateMediaAllInfo = () => {
        if (isLocalUserHost === true) {
            return;
        }
        let audio = true;
        let video = true;
        if (mediaAll.audio === 1) {
            audio = true;
        } else {
            audio = false;
        }
        if (mediaAll.video === 1) {
            video = true;
        } else {
            video = false;
        }
        dispatch(updateMedia({ audio: audio ? 1 : 0 }));
        localMicrophoneTrack?.setEnabled(audio);
        setLocalAudioMuted(audio);
        dispatch(updateMedia({ video: video ? 1 : 0 }));
        localCameraTrack?.setEnabled(video);
        setLocalVideoEnabled(video);
    };



    const handleLeaveRoom = () => {
        dispatch(leaveRoom());
        setTimeout(() => {
            navigate(-1)
        }, 1000);
    };

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            videoContainerRef.current.requestFullscreen().catch((err) => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };


    // Check if devices are still loading
    const deviceLoading = isLoadingMic || isLoadingCam;
    if (deviceLoading) return <div>Loading devices...</div>;

    return (
        <AgoraProvider
            localCameraTrack={screenShare ? screenTrack : localCameraTrack}
            localMicrophoneTrack={localMicrophoneTrack}
        >
            {/* {whiteboard != null && (
                <WhiteboardComponent
                    roomUid={whiteboard?.roomId}
                    roomToken={whiteboard?.roomToken}
                    isAdmin={true}
                />
            )} */}
            <section class="container my-lg-4 mb-5 agora_classroom py-2 px-3 px-lg-4" >
                {
                    screenShared ?
                        <div className="row">
                            <div className="col-lg-7 mb-4">
                                {remoteUsers.filter(user => (user.uid === '1' && hostScreenShare) || (user.uid === screenShared.agoraUid && !hostScreenShare)).map((user) => (
                                    <div
                                        className="video-container"
                                        ref={videoContainerRef}
                                        style={
                                            !!speakingUsers[user.uid] ? { boxShadow: "0 0 10px #00ff00" } : {}
                                        }
                                        key={user.uid}
                                    >
                                        <div className="instructor-top bg-green text-white p-2">
                                            <p>Screen Share</p>
                                            <div className="d-flex align-items-center gap-3">
                                                {user.hasAudio ? (
                                                    <i className="fa-solid fa-microphone"></i>
                                                ) : (
                                                    <i className="fa-solid fa-microphone-slash text-danger"></i>
                                                )}
                                                {user.hasVideo ? (
                                                    <i className="fa-solid fa-video"></i>
                                                ) : (
                                                    <i className="fa-solid fa-video-slash text-danger"></i>
                                                )}
                                                <i className="fa-solid fa-expand" onClick={(e) => toggleFullscreen(e)} ></i>
                                            </div>
                                        </div>

                                        <RemoteUser user={user} playVideo playAudio style={hostScreenShare != undefined ? hostScreenShare : {}} />

                                    </div>
                                ))}
                                {
                                    remoteUsers.filter(user => screenShared.agoraUid == user.uid).length === 0 &&
                                    <div className="video-container" ref={videoContainerRef}>
                                        <div className="instructor-top bg-green text-white p-2">
                                            <p>me</p>
                                        </div>
                                        <LocalVideoTrack
                                            track={screenShare ? screenTrack : localCameraTrack}
                                            play
                                        />
                                    </div>
                                }
                            </div>
                            <div class="col-lg-5">
                                <div class="row guest-scroll">
                                    {
                                        remoteUsers.filter(user => screenShared.agoraUid == user.uid).length > 0 &&
                                        <div className="col-6 col-md-4 col-lg-6 col-xl-4 mb-4">
                                            <div className="video-container" ref={videoContainerRef}>
                                                <div className="instructor-top bg-green text-white p-2">
                                                    <p>{userDetails?.profile?.firstname || "me"}</p>
                                                </div>
                                                <LocalVideoTrack

                                                    track={screenShare ? screenTrack : localCameraTrack}
                                                    play
                                                />
                                            </div>
                                        </div>
                                    }
                                    {
                                        remoteUsers.filter(user => (user.uid != '1' && user.uid != screenShared.agoraUid)).map((remoteUser) => (
                                            <div
                                                key={remoteUser.uid}
                                                className="col-6 col-md-4 col-lg-6 col-xl-4 mb-4"

                                            >
                                                <div className="video-container" ref={videoContainerRef} style={
                                                    !!speakingUsers[remoteUser.uid]
                                                        ? { boxShadow: "0 0 10px #00ff00" }
                                                        : {}
                                                }>
                                                    <div className="instructor-top bg-green text-white p-2">
                                                        <p>{getUserData(remoteUser.uid)?.name || "Guest"}</p>
                                                        <div className="d-flex align-items-center gap-2">
                                                            {remoteUser.hasAudio ? (
                                                                <i class="fa-solid fa-microphone"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-microphone-slash text-danger"></i>
                                                            )}
                                                            {remoteUser.hasVideo ? (
                                                                <i class="fa-solid fa-video"></i>
                                                            ) : (
                                                                <i class="fa-solid fa-video-slash text-danger"></i>
                                                            )}
                                                            <i className="fa-solid fa-expand" onClick={(e) => toggleFullscreen(e)} ></i>
                                                        </div>
                                                    </div>
                                                    <RemoteUser user={remoteUser} playVideo playAudio />
                                                </div>
                                            </div>)

                                        )}
                                </div>
                            </div>

                        </div> :
                        <div class="row" >
                            <div className={getLayoutClass(remoteUsers.length + 1)}  >
                                <div className="video-container mx-5" ref={videoContainerRef}>
                                    <div className="instructor-top bg-green text-white p-2">
                                        <p>me</p>
                                    </div>
                                    <LocalVideoTrack
                                        track={screenShare ? screenTrack : localCameraTrack}
                                        play
                                    />
                                </div>
                            </div>
                            {/* Now map over the remote users, starting their index at 1 */}
                            {remoteUsers.filter(user => user.uid != '1').map((remoteUser) => {
                                // Exclude the host from the small tiles
                                if (
                                    isLocalUserHost
                                        ? remoteUser.uid !== config.uid
                                        : remoteUser.uid !== hostStream?.agoraUid
                                ) {
                                    return (
                                        <div
                                            key={remoteUser.uid}
                                            className={`${getLayoutClass(remoteUsers.length + 1)} mb-4`}


                                        >
                                            <div className="video-container mx-5" ref={videoContainerRef} style={
                                                !!speakingUsers[remoteUser.uid]
                                                    ? { boxShadow: "0 0 10px #00ff00", }
                                                    : {}

                                            }>
                                                <div className="instructor-top bg-green text-white p-2">
                                                    <p>{getUserData(remoteUser.uid)?.name || "Guest"}</p>
                                                    <div className="d-flex align-items-center gap-2">
                                                        {remoteUser.hasAudio ? (
                                                            <i class="fa-solid fa-microphone"></i>
                                                        ) : (
                                                            <i class="fa-solid fa-microphone-slash text-danger"></i>
                                                        )}
                                                        {remoteUser.hasVideo ? (
                                                            <i class="fa-solid fa-video"></i>
                                                        ) : (
                                                            <i class="fa-solid fa-video-slash text-danger"></i>
                                                        )}
                                                        <i className="fa-solid fa-expand" onClick={(e) => toggleFullscreen(e)} ></i>
                                                    </div>
                                                </div>
                                                <RemoteUser user={remoteUser} playVideo playAudio />
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}

                        </div>
                }


                <div class="container-fluid bg-green text-light fixed-bottom p-2">
                    <div class="live-footer d-flex align-items-center justify-content-center gap-2">
                        <div class="content d-flex align-items-center justify-content-center gap-2">
                            <button
                                class="btn btn-icon btn-light"
                                onClick={() => toggleLocalAudio()}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={localAudioMuted ? 'Mute' : 'Unmute'}
                            >
                                {localAudioMuted ? (
                                    <i class="fa-solid fa-microphone"></i>
                                ) : (
                                    <i class="fa-solid fa-microphone-slash text-danger"></i>
                                )}
                            </button>
                            <button
                                class="btn btn-icon btn-light"
                                onClick={() => toggleLocalVideo()}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={localVideoEnabled ? 'Video Off' : 'Video On'}
                            >
                                {localVideoEnabled ? (
                                    <i class="fa-solid fa-video"></i>
                                ) : (
                                    <i class="fa-solid fa-video-slash text-danger"></i>
                                )}
                            </button>
                            <button
                                class="btn btn-icon btn-light"
                                onClick={() => toggleScreenShare()}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={screenShare ? 'Screenshare off' : 'Screenshare'}
                            >
                                <i class="fa-solid fa-desktop"></i>
                            </button>

                            {/* <button
                                class="btn btn-icon btn-light"
                                onClick={() => toggleWhiteboard()}
                                
                            >
                                <i class="fa-solid fa-chalkboard-user"></i>
                            </button> */}

                        </div>
                        <div class="content d-flex align-items-center justify-content-center gap-2">
                            <button class="btn bg-gold" onClick={() => handleLeaveRoom()}>
                                Leave Room
                                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                            </button>
                        </div>
                        <div class="content d-flex align-items-center justify-content-center gap-2">
                            <button
                                class="btn btn-icon btn-light"
                                onClick={() => openChatModel()}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={chatModal ? 'Close Chat' : 'Open Chat'}
                            >
                                <i class="fa-solid fa-comment"></i>
                            </button>
                            <button
                                class="btn btn-icon btn-light"
                                onClick={() => setUsersModal(!usersModal)}
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={usersModal ? 'Close participants list' : 'Open participants list'}
                            >
                                <i class="fa-solid fa-users"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <UsersList
                usersModal={usersModal}
                usersList={streams}
                remoteUsers={remoteUsers}
            />
        </AgoraProvider>
    );
};

export default AgoraConferenceManager;

const getLayoutClass = (count) => {
    switch (count) {
        case 1:
            return 'col-lg-12';
        case 2:
            return 'col-lg-6';
        case 4:
            return 'col-lg-4';
        default:
            return 'col-lg-3';

    }
}



