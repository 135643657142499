
import React, { useState } from 'react'
import { callApi } from '../../api/ApiHelper'
import { APIENDPOINTS, APIURL } from '../../api/apiUrl'
import { useLocation, useNavigate } from 'react-router-dom'
import { sendErrorInfo, sendSuccessInfo } from '../../utility/helperFunctions'

const Evaluation = () => {
  const [showAdditionalComment, setShowAdditionalComment] = useState(false)
  const [showAdditionalComment1, setShowAdditionalComment1] = useState(false)
  const [showAdditionalComment2, setShowAdditionalComment2] = useState(false)
  const [showAdditionalComment3, setShowAdditionalComment3] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [allCommentsData, setAllCommentsData] = useState({
    evaluationForm: [
      {
        evaluation: "I understand the content of this training session",
        rating: 0,
        comment: ""
      },
      {
        evaluation: "This course content has prepared me well for work",
        rating: 0,
        comment: ""
      },
      {
        evaluation: "This course has range of services to support learners",
        rating: 0,
        comment: ""
      },
      {
        evaluation: "The course sequence was easy to follow",
        rating: 0,
        comment: ""
      },
    ],
    additional_note: ''
  })

  const handleSubmitRating = () =>{
    if (allCommentsData.additional_note != '') {
      for (let i = 0; i < allCommentsData.evaluationForm.length; i++) {
        if (allCommentsData.evaluationForm[i].rating == 0) {
          sendErrorInfo(`Please fill ${allCommentsData.evaluationForm[i].evaluation} rating`)
          return;
        }
      }
      const apiUrl1 =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `user-course/update/${location?.state?.courseId}`;

      let data = {
        ...allCommentsData,
      }

      console.log("sdfsd",data)
    callApi(apiUrl1, { method: "PUT" , data:{ completedAt:new Date().toISOString().split("T")[0], evaluationForm: JSON.stringify(data)}}).then((response) => {
      if (response.status === true) {
        sendSuccessInfo(response.message);
        navigate('/my-purchase-course')
      } else {
        sendErrorInfo(response.data.message);
      }
    });

    }else{
      sendErrorInfo('Please fill additional note')
    }
  }
  return (
    <>
      <section className='ratesdetails'>
        <form >
          <div className="container">
            <h3 className='text-center'>Course evaluation form</h3>
            <p className='text-center phead text-secondary'>Please answer the followinng question where applicable to your course and select the <br /> option which best represent your response</p>
            <div className="rating-info">
              <ul>
                <li>
                  <p>I understand the content of this training session</p>
                  <div className='ratesmsgs'>
                    <div className='rinner'>
                      <div className='rate'>
                        <input type="radio" id="star1" name="rate" value={5} onChange={(e)=>{
                          allCommentsData.evaluationForm[0].rating =e.target.value
                        }} />
                        <label for="star1" title="text"></label>
                        <input type="radio" id="star2" name="rate" value={4} onChange={(e)=>{
                          allCommentsData.evaluationForm[0].rating = e.target.value
                        }} />
                        <label for="star2" title="text"></label>
                        <input type="radio" id="star3" name="rate" value={3}  onChange={(e)=>{
                          allCommentsData.evaluationForm[0].rating = e.target.value
                        }}/>
                        <label for="star3" title="text"></label>
                        <input type="radio" id="star4" name="rate" value={2} onChange={(e)=>{
                          allCommentsData.evaluationForm[0].rating = e.target.value
                        }}/>
                        <label for="star4" title="text"></label>
                        <input type="radio" id="star5" name="rate" value={1} onChange={(e)=>{
                          allCommentsData.evaluationForm[0].rating = e.target.value
                        }}/>
                        <label for="star5" title="text"></label>
                      </div>
                      <div>Rate this course</div>
                    </div>

                    <div className='msgr' onClick={() => setShowAdditionalComment(true)}>
                      <div><img src="/zidni/assets/images/msgicon.svg" alt="" /></div>
                      <div>Leave your comments</div>
                    </div>
                  </div>
                </li>
                {showAdditionalComment &&
                  <div className='addcomment'>
                    <div>
                      <label for="addcomments" class="form-label"><h4>Leave your comment here</h4></label>
                      <textarea class="form-control" placeholder='Add comment.....' id="addcomments" rows="3" onChange={(e)=>{
                          allCommentsData.evaluationForm[0].comment = e.target.value
                        }}></textarea>
                    </div>
                    <div className=''>
                      <button type='button' className='btn btn-light' onClick={() => setShowAdditionalComment(false)} >Cancel</button>
                    </div>
                  </div>
                }
                <li>
                  <p>This course content has prepared me well for work</p>
                  <div className='ratesmsgs'>
                    <div className='rinner'>
                      <div className='rate'>
                        <input type="radio" id="star6" name="rate1" value={5} onChange={(e)=>{
                          allCommentsData.evaluationForm[1].rating = e.target.value
                        }} />
                        <label for="star6" title="text"></label>
                        <input type="radio" id="star7" name="rate1" value={4} onChange={(e)=>{
                          allCommentsData.evaluationForm[1].rating = e.target.value
                        }} />
                        <label for="star7" title="text"></label>
                        <input type="radio" id="star8" name="rate1" value={3} onChange={(e)=>{
                          allCommentsData.evaluationForm[1].rating = e.target.value
                        }}/>
                        <label for="star8" title="text"></label>
                        <input type="radio" id="star9" name="rate1" value={2} onChange={(e)=>{
                          allCommentsData.evaluationForm[1].rating = e.target.value
                        }}/>
                        <label for="star9" title="text"></label>
                        <input type="radio" id="star10" name="rate1" value={1} onChange={(e)=>{
                          allCommentsData.evaluationForm[1].rating = e.target.value
                        }}/>
                        <label for="star10" title="text"></label>
                      </div>
                      <div>Rate this course</div>
                    </div>

                    <div className='msgr' onClick={() => setShowAdditionalComment1(true)}>
                      <div><img src="/zidni/assets/images/msgicon.svg" alt="" /></div>
                      <div>Leave your comments</div>
                    </div>
                  </div>
                </li>
               {showAdditionalComment1 && <div className='addcomment'>
                  <div>
                    <label for="addcomments" class="form-label"><h4>Leave your comment here</h4></label>
                    <textarea class="form-control" placeholder='Add comment.....' id="addcomments" rows="3" onChange={(e)=>{
                          allCommentsData.evaluationForm[1].comment = e.target.value
                        }}></textarea>
                  </div>
                  <div className=''>
                    <button type='button' className='btn btn-light' onClick={() => setShowAdditionalComment1(false)}>Cancel</button>
                  </div>
                </div>}
                <li>
                  <p>This course has range of services to support learners</p>
                  <div className='ratesmsgs'>
                    <div className='rinner'>
                      <div className='rate'>
                        <input type="radio" id="star11" name="rate2" value={5} onChange={(e)=>{
                          allCommentsData.evaluationForm[2].rating = e.target.value
                        }} />
                        <label for="star11" title="text"></label>
                        <input type="radio" id="star12" name="rate2" value={4} onChange={(e)=>{
                          allCommentsData.evaluationForm[2].rating = e.target.value
                        }}/>
                        <label for="star12" title="text"></label>
                        <input type="radio" id="star13" name="rate2" value={3} onChange={(e)=>{
                          allCommentsData.evaluationForm[2].rating = e.target.value
                        }}/>
                        <label for="star13" title="text"></label>
                        <input type="radio" id="star14" name="rate2" value={2} onChange={(e)=>{
                          allCommentsData.evaluationForm[2].rating = e.target.value
                        }}/>
                        <label for="star14" title="text"></label>
                        <input type="radio" id="star15" name="rate2" value={1} onChange={(e)=>{
                          allCommentsData.evaluationForm[2].rating = e.target.value
                        }}/>
                        <label for="star15" title="text"></label>
                      </div>
                      <div>Rate this course</div>
                    </div>

                    <div className='msgr' onClick={() => setShowAdditionalComment2(true)}>
                      <div><img src="/zidni/assets/images/msgicon.svg" alt="" /></div>
                      <div>Leave your comments</div>
                    </div>
                  </div>
                </li>
             {showAdditionalComment2 &&   <div className='addcomment'>
                  <div>
                    <label for="addcomments" class="form-label"><h4>Leave your comment here</h4></label>
                    <textarea class="form-control" placeholder='Add comment.....' id="addcomments" rows="3" onChange={(e)=>{
                          allCommentsData.evaluationForm[2].comment = e.target.value
                        }}></textarea>
                  </div>
                  <div className=''>
                    <button type='button' className='btn btn-light' onClick={() => setShowAdditionalComment2(false)}>Cancel</button>
                  </div>
                </div>}
                <li>
                  <p>The course sequence was easy to follow</p>
                  <div className='ratesmsgs'>
                    <div className='rinner'>
                      <div className='rate'>
                        <input type="radio" id="star16" name="rate3" value={5} onChange={(e)=>{
                          allCommentsData.evaluationForm[3].rating = e.target.value
                        }}/>
                        <label for="star16" title="text"></label>
                        <input type="radio" id="star17" name="rate3" value={4} onChange={(e)=>{
                          allCommentsData.evaluationForm[3].rating = e.target.value
                        }}/>
                        <label for="star17" title="text"></label>
                        <input type="radio" id="star18" name="rate3" value={3} onChange={(e)=>{
                          allCommentsData.evaluationForm[3].rating = e.target.value
                        }}/>
                        <label for="star18" title="text"></label>
                        <input type="radio" id="star19" name="rate3" value={2} onChange={(e)=>{
                          allCommentsData.evaluationForm[3].rating = e.target.value
                        }}/>
                        <label for="star19" title="text"></label>
                        <input type="radio" id="star20" name="rate3" value={1} onChange={(e)=>{
                          allCommentsData.evaluationForm[3].rating = e.target.value
                        }}/>
                        <label for="star20" title="text"></label>
                      </div>
                      <div>Rate this course</div>
                    </div>

                    <div className='msgr' onClick={() => setShowAdditionalComment3(false)}>
                      <div><img src="/zidni/assets/images/msgicon.svg" alt="" /></div>
                      <div>Leave your comments</div>
                    </div>
                  </div>
                </li>
                {showAdditionalComment3 && <div className='addcomment'>
                  <div>
                    <label for="addcomments" class="form-label"><h4>Leave your comment here</h4></label>
                    <textarea class="form-control" placeholder='Add comment.....' id="addcomments" rows="3" onChange={(e)=>{
                          allCommentsData.evaluationForm[3].comment = e.target.value
                        }}></textarea>
                  </div>
                  <div className=''>
                    <button type='button' className='btn btn-light' onClick={() => setShowAdditionalComment3(false)}>Cancel</button>
                  </div>
                </div>}
              </ul>
            </div>
            <div className='addnotes'>
              <div className='my-3'>
                <label for="additionalNotes" class="form-label"><h4>Additional notes</h4></label>
                <textarea class="form-control" placeholder='Add comment.....' id="additionalNotes" rows="3" onChange={(e)=>{
                          allCommentsData.additional_note = e.target.value
                        }}></textarea>
              </div>
              {/* <div className=''>
                <button className='btn btn-light'>Submit</button>
              </div> */}
            </div>
          </div>
          <div className='fombtns'>
            <div className="container">
              <button type='button' className='btn btn-light' onClick={()=>navigate(-1)}>Cancel</button>
              <button type='button' className='btn btn-primary' onClick={()=>handleSubmitRating()}>Submit</button>
            </div>

          </div>
        </form>
      </section>
    </>
  )
}

export default Evaluation
