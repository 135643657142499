import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import Loader from "../../components/common/Loader";

const Events = () => {
  const [allEvents, setAllEvents] = useState([]);
  const userId = useSelector((state) => state.root.userDetails.id);
  const userDetail = useSelector((state) => state.root.userDetails);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [CallApi, setCallApi] = useState(true);
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);
  useEffect(() => {
    if (CallApi == true) {
      setCallApi(false);
      let eventData = {
        where: {
          eventStatus: 1
        },
        paranoid: true,
        sortField: sortByField,
        sortType: sortByValue,
        pageNumber: currentPage,
        limit: 10,
      };
      if (filterValue != "") {
        if (filterValue !== "all") {
          eventData.where["eventStatus"] = filterValue;
        }
      }
      if (searchValue != "") {
        eventData.where["eventName"] = searchValue;
      }
      if (priceValue != "") {
        eventData.where["eventPricing"] = priceValue;
      }
      setIsLoading(true);
      const apiUrl =
        APIURL.EVENT_URL + APIENDPOINTS.EVENT_END_POINT + `event/list`;
      callApi(apiUrl, { method: "POST", data: eventData }).then((rseponse) => {
        if (rseponse.status === true) {
          setAllEvents(rseponse.data.event);
          setLoader(false);
          setIsLoading(false);
          let arr = [];
          let toTal = rseponse?.data?.totalPages;
          for (let i = 1; i <= toTal; i++) {
            arr.push(i);
          }
          setAllPages(arr);
        } else {
          setLoader(false);
          setIsLoading(false);
        }
      });
    }
  }, [CallApi]);

  const handelFilter = (value) => {
    if (value == "Paid" || value == "Free") {
      setPriceValue(value);
      setFilterValue("");
    } else {
      setPriceValue("");
      setFilterValue(value);
    }
    setCallApi(true);
  };

  const handleSortBy = (value) => {
    if (value !== sortByValue && value !== "all") {
      if (value == "asc" || value == "desc") {
        setSortByField("eventName");
        setSortByValue(value);
      } else {
        if (value == "Date old") {
          setSortByValue("asc");
          setSortByField("createdAt");
        } else {
          setSortByValue("desc");
          setSortByField("createdAt");
        }
      }
      setCallApi(true);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setCallApi(true);
  };
  return (
    <Layout>
      <section className="cr-search-bar">
        <div className="container">
          <div className="search-bar">
            <div className="ser">
              <input
                type="text"
                value={searchValue}
                placeholder="search event"
                onChange={(e) => handleSearch(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="selects">
              <div className="fill-by">
                <select
                  id="fil-by"
                  name="fil_by"
                  className="fil-by replaced"
                  onChange={(e) => handelFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                  <option value={0}>Draft</option>
                  <option value={1}>Publish</option>
                  <option value={2}>Send for review</option>
                  <option value={3}>Inactive</option>
                  <option value={4}>Unapproved</option>
                </select>
              </div>
              <div className="shor-by">
                <select
                  id="sort-by"
                  name="sort_by"
                  className="sort-by replaced"
                  onChange={(e) => handleSortBy(e.target.value)}
                >
                  <option value="all">Sort by</option>
                  <option value={"asc"}>Alphabetically, A-Z</option>
                  <option value="desc">Alphabetically, Z-A</option>
                  {/* <option value="price-ascending">Price, low to high</option>
                  <option value="price-descending">Price, high to low</option> */}
                  <option value="Date old">Date, old to new</option>
                  <option value="Date new">Date, new to old</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loader ? (
        <Loader />
      ) : (
        <>
          <section className="event-serie">
            <div className="container">
              {isLoading ?
                <div style={{ height: "400px" }} className="d-flex justify-content-center m-5">
                  <div className="spinner-border"></div>
                </div> :
                allEvents &&
                  allEvents.length > 0 ?
                  allEvents
                    .filter((e) => e.deletedAt == null)
                    .map((event, index) => (
                      <div className="univ-card round" key={index}>
                        <div className="left-content">
                          <div className="card-image round">
                            <img src={event.eventImage} alt="" />
                          </div>
                          <div className="card-detail">
                            <span
                              className={
                                event.isEventPublic === false
                                  ? "private"
                                  : "public"
                              }
                            >
                              {event.isEventPublic === false
                                ? "Private"
                                : "Public"}
                            </span>
                            <h4 className="headi">{event.eventName}</h4>
                            <p>
                              {event?.eventTimeLocation?.eventStartDate} -{" "}
                              {event?.eventTimeLocation?.eventEndDate}{" "}
                              {event?.eventTimeLocation?.eventStartTime}
                            </p>
                          </div>
                        </div>
                        <div className="right-content">
                          <Link
                            to={`/event-detail/${event?.id}`}
                            // state={{ eventId: event.id }}
                            className="round"
                          >
                            View event
                          </Link>

                          <div className="pri">
                            {event?.eventPricing?.actualValue != undefined ? (
                              <>
                                <div>
                                  <span
                                    className={
                                      event?.eventPricing?.actualValue !=
                                      undefined &&
                                      "text-muted text-decoration-line-through fs-6"
                                    }
                                  >
                                    {" "}
                                    {event?.eventPricing &&
                                      event?.eventPricing?.eventType == "Paid"
                                      ? `$ ${event?.eventPricing?.eventPrice}`
                                      : "Free"}
                                  </span>

                                  <span>
                                    {" "}
                                    {event?.eventPricing &&
                                      event?.eventPricing?.eventType == "Paid"
                                      ? `$ ${event?.eventPricing?.actualValue.toFixed(
                                        2
                                      )}`
                                      : "Free"}
                                  </span>
                                </div>
                                <span className="fs-6">
                                  (University discount)
                                </span>
                              </>
                            ) : (
                              <span>
                                {" "}
                                {event?.eventPricing &&
                                  event?.eventPricing?.eventType == "Paid"
                                  ? `$ ${event?.eventPricing?.eventPrice}`
                                  : "Free"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )) :
                  <div className="no-cou-yet text-center">
                    <h3>No event found</h3>
                    <p>
                      Here’s where you’ll see enents’
                    </p>
                  </div>}
            </div>
          </section>

          <section className="pt-0">
            <div className="container">
              {allPages?.length > 0 && (
                <div className="pagination-pointer ">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li className="page-item ">
                        <a
                          href="javascript:void(0);"
                          className="page-link"
                          onClick={() => {
                            if (currentPage > 0) {
                              setCurrentPage(currentPage - 1);
                              setCallApi(true);
                            }
                            if (paginationRange[0] > 1) {
                              setPaginationRange([
                                paginationRange[0] - 1,
                                paginationRange[1] - 1,
                              ]);
                              setCallApi(true);
                            }
                          }}
                        >
                          Previous
                        </a>
                      </li>
                      {allPages &&
                        allPages?.length > 0 &&
                        allPages
                          .slice(paginationRange[0] - 1, paginationRange[1])
                          .map((item, key) => (
                            <li
                              className={
                                currentPage === item
                                  ? "active page-item"
                                  : "page-item"
                              }
                              aria-current="page"
                              key={key}
                            >
                              <a
                                className="page-link"
                                href="javascript:void(0);"
                                onClick={() => {
                                  setCurrentPage(item);
                                  setCallApi(true);
                                }}
                              >
                                {item}
                              </a>
                            </li>
                          ))}
                      <li className="page-item">
                        <a
                          className="page-link"
                          href="javascript:void(0);"
                          onClick={() => {
                            if (currentPage < allPages?.length - 1) {
                              setCurrentPage(currentPage + 1);
                              setPaginationRange([
                                paginationRange[0] + 1,
                                paginationRange[1] + 1,
                              ]);
                              setCallApi(true);
                            }
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default Events;
