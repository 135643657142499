import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useLocation } from "react-router-dom";
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";

const EventPrice = ({
  navigate,
  activeTab,
  toggleTab,
  eventId,
  eventDetail,
  getEventDetail
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({ mode: "all" });

  const [eventType, setEventType] = useState("Paid");

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.eventId !== undefined || eventDetail?.id !== undefined) {
      reset({
        // startDate: eventDetail.eventTimeLocation.eventStartDate,
        eventType: eventDetail?.eventPricing?.eventType,
        eventPrice:
          eventDetail?.eventPricing?.eventType === "Free"
            ? null
            : eventDetail?.eventPricing?.eventPrice,
      });
      setEventType(eventDetail?.eventPricing?.eventType)
    }
  }, []);

  const onSubmit = (data) => {
    let eventPricingData = {
      eventPricing: {
        eventType: eventType,
        eventPrice: eventType === "Free" ? null : data.eventPrice,
      },
    };

    console.log("eventTimeLocation", eventPricingData);
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/update/${eventId ? eventId : eventDetail?.id}`;

    callApi(apiUrl, { method: "PUT", data: eventPricingData }).then(
      (response) => {
        if (response.status === true) {
          sendSuccessMessage(response);
          getEventDetail()
          toggleTab(activeTab + 1);
        } else {
          sendErrorMessage(response);
        }
      }
    );
  };

  return (
    <>
      <div className="new-cont nnn">
        <div className="pr-head">
          <h4>Set a price for your event</h4>
          <p>
            Please select the price for your event. If you’d like to offer your
            event for free you can choose the type free
          </p>
        </div>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        ></a>
      </div>
      <div className="course-details">
        <form>
          <div className="mb-3">
            <label className="select-label" for="eventtype">
              Event type
            </label>
            <select
              className="form-select"
              id="eventtype"
              aria-label="Default select example"
              {...register("eventType", {
                required: "Event type is required",
                onChange: (e) => setEventType(e?.target?.value),
              })}
            >
              <option value="" selected disabled>
                Select type
              </option>
              <option value={"Paid"}>
                Paid
              </option>
              <option value={"Free"}>Free</option>
            </select>
            <p className="mb-0 text-danger">{errors?.eventType?.message}</p>
          </div>
          {eventType === "Paid" && (
            <>
              <div className="mb-3">
                <label for="graphicdesign" className="form-label">
                  Set price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="graphicdesign"
                  aria-describedby="emailHelp"
                  placeholder="Enter event price by here..."
                  {...register("eventPrice", {
                    required: "Event price by is required",
                  })}
                />
                <p className="mb-0 text-danger">
                  {errors?.eventPrice?.message}
                </p>
              </div>
              {/* <div className="mb-3">
                <label className="select-label" for="sepri">
                  Set price
                </label>
                <select
                  className="form-select"
                  id="sepri"
                  aria-label="Default select example"
                  {...register("eventPrice", {
                    onChange: (e) => setEventPrice(e?.target?.value),
                  })}
                >
                  <option selected value={100}>
                    $100
                  </option>
                  <option value={200}>$200</option>
                  <option value={300}>$300</option>
                  <option value={400}>$400</option>
                </select>
              </div> */}
            </>
          )}
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 7 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default EventPrice;
