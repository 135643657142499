import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/Layout'
import { Link } from 'react-router-dom'
import { callApi } from '../../api/ApiHelper';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
const AboutUs = () => {
	const [pageNumber, setPageNumber] = useState(0);
	const [allUniversity, setAllUniversity] = useState([])

	const [CallApi, setCallApi] = useState(true);
	useEffect(() => {
		if (CallApi == true) {
			let eventData = {
				where: {
				},
				pageNumber: pageNumber,
				limit: 50,
			};
			const apiUrl =
				APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `university/get`;
			callApi(apiUrl, { method: "POST", data: eventData }).then((rseponse) => {
				if (rseponse.status === true) {
					setAllUniversity(rseponse.data.university);
				}
			});
		}
	}, [CallApi]);
	return (
		<Layout>
			{/* breadcrumb start */}
			<section className="breadcrumb-sec">
				<div className="container">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								<Link to="/">Home</Link>
							</li>
							<li className="breadcrumb-item active" aria-current="page">
								About
							</li>
						</ol>
					</nav>
				</div>
			</section>
			{/* breadcrumb end */}

			{/* <!-- about banner seaction start --> */}
			<section className="about-banner">
				<div className="container round">
					<div className="box">
						<h2>Unlock Your Potential with Online Learning Hub</h2>
						<div className="img-box">
							<img src="./assets/images/about/about_banner.png" alt="" />
						</div>
					</div>
				</div>
			</section>
			{/* <!-- about banner seaction end --> */}

			{/* <!-- join-sec start --> */}
			<section className="join-sec">
				<div className="container">
					<div className="left-section">
						<h2>Join our top leading universities partners that choose zidni for
							<span className="inner-heading"> career growth</span>
						</h2>
						<p>We collaborate with 100+ leading university partners</p>
						<Link to="/universities">View all universities</Link>
					</div>
					<div className="img-sec">
						<ul>
							{allUniversity && allUniversity.length > 0 && allUniversity.map((item, key) => (
								<li key={key}>
									<Link to={'/university-detail'} state={{ id: item?.userId }}>
										<img src={item?.universityImage} alt="" />
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			</section>
			{/* <!-- join-sec end --> */}

			{/* <!-- more about section start --> */}
			<section className="more-about">
				<div className="container">
					<div className="content">
						<h2>More about Zidnimed academy</h2>
						<p>
							The idea of establishing the Board emerged when Arab countries began reviewing their policies
							regarding the reliance on Arab doctors obtaining a prestigious specialisation certificate from one
							of the foreign bodies as a prerequisite for awarding them the title of specialist. This required
							them to undergo extensive training to be eligible for the final exams to obtain such a certificate.
							Thus, the primary objective of establishing the Board was to play the same role that foreign bodies
							played in granting <br /> <br />
							specialisation certificates to highly competent Arab medical personnel and to achieve the ultimate
							goal of retaining Arab citizens and preventing the migration of those working in the medical sector,
							as well as providing the opportunity for the largest possible number of them to obtain higher
							degrees in specialisation's.
						</p>
					</div>
					<div className="img-block">
						<img src="./assets/images/about/moreabout.png" alt="" />
					</div>
				</div>
			</section>
			{/* <!-- more about section end --> */}

			{/* <!-- more about section start --> */}
			<section className="zid-began">
				<div className="container">
					<h4>Zidnimed began its work with four main specializations, and by the end of 2022, it had expanded to 59
						specializations, including 27 major and 32 minor specializations, thanks to the support it received from
						various organizations.</h4>
					<p>Accordingly, the Arab Health Ministers took on this matter and gave it their utmost attention starting
						from the first session of their council until they reached a historic decision in the third session held
						in Kuwait from 20-24 February 1978, where they approved the establishment of the Arab Board of Medical
						Specialisation's and mandated the Minister of Health of the State of Kuwait, who was the head of the
						session at the time, to take the necessary steps to form the Board's supreme body and invite it to hold
						its first meeting under his chairmanship. They also authorized this body to review the adopted statute
						during the meeting and amend it if necessary, and to prepare the internal regulations for the Board's
						work</p>
				</div>
			</section>
			{/* <!-- more about section end --> */}

			{/* <!-- board member section start --> */}
			<section className="board-member">
				<div className="container">
					<div className="member-imgs">
						<div className="img-box"><img src="assets/images/bimg1.jpeg" alt="" /></div>
						<div className="img-box"><img src="assets/images/bimg2.jpeg" alt="" /></div>
						<div className="img-box"><img src="assets/images/bimg3.jpeg" alt="" /></div>
						<div className="img-box"><img src="assets/images/bimg4.jpeg" alt="" /></div>

					</div>
					<div className="member-details">
						<h2>Zidnimed Board Members</h2>
						<p>The Zidnimed is an Arab scientific and professional body established by the Arab Health Ministers'
							Council under the umbrella of the Arab League. It has an independent legal personality and its
							general headquarters is in Damascus, Syrian Arab Republic, while its temporary headquarters for
							conducting business is in Amman, Hashemite Kingdom of Jordan, and it has a liaison office in Cairo,
							Arab Republic of Egypt.</p>
					</div>
				</div>
			</section>

		</Layout>
	)
}

export default AboutUs
