import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { callApi } from "../../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../../api/apiUrl";
import { sendErrorInfo, sendSuccessInfo } from "../../../utility/helperFunctions";
import { CHAT_RESPONSE } from "../../../store/types";
import { sendMessage } from "./SocketAction";


const Chat = ({ chatModal, setChatModal, data, lectureId, roomName }) => {
    const dispatch = useDispatch();
    const messagesEndRef = useRef(null);
    const inputRef = useRef();
    const [message, setMessage] = useState("");
    const [imageData, setImageData] = useState([]);


    const [chatList, setChatList] = useState([]);

    const chatResponse = useSelector((state) => state.root.chatResponse);

    const getItem = (data, index) => data[index];

    const getItemCount = (data) => chatList.length;


    useEffect(() => {
        if (chatResponse != null) {
            if (Object.keys(chatResponse).length > 0) {
                let temp = JSON.parse(JSON.stringify(chatList))
                dispatch({
                    type: CHAT_RESPONSE,
                    payload: {},
                });
                if (!chatModal) {
                    sendSuccessInfo(`${chatResponse.name}: ${chatResponse.type !== "media" ? chatResponse.message : chatResponse.mediaType}`)

                }
                setChatList([chatResponse, ...temp]);

                // setTimeout(() => {

                // }, 100);
            }
        }
    }, [chatResponse]);
    // Scroll to Bottom of Message List
    useEffect(() => {
        scrollToBottom();
    }, [chatList]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const sendMessages = (e) => {
        e.preventDefault();
        if (e.key === "Enter" && e.target.value.length > 0) {
            let msgInfo = { ...data, message: e?.target?.value, type: "message", mediaType: null };
            dispatch(sendMessage(e.target.value, "message", null));
            setChatList([msgInfo, ...chatList]);
            setMessage("");
        }
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (message.length > 0) {
            let msgInfo = { ...data, message: message, type: "message", mediaType: null };
            dispatch(sendMessage(message, "message", null));
            setChatList([msgInfo, ...chatList]);
            setMessage("");
        }
    };
    //

    const handleChangeInputMessage = (e) => {
        setMessage(e.target.value);
    };

    const handleUploadFile = (e) => {
        if (e?.target?.files[0]?.size > 10000000) {
            return sendErrorInfo('File size must be less than 10 mb')
        }
        const getFileType = (type) => {
            if (type.includes("image")) {
                return "images";
            } else if (type.includes("video")) {
                return "video";
            } else if (type.includes("pdf")) {
                return "pdf";
            } else {
                return "doc";
            }
        };
        let fileType = getFileType(e?.target?.files[0]?.type);
        let file = e?.target?.files[0];
        const formData = new FormData();
        formData.append('media', file);
        formData.append('type', fileType);

        callApi(APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + `upload-media?roomName=${roomName}`,
            { method: "POST", data: formData, multipart: true })
            .then((response) => {
                if (response.status == true) {
                    let msgInfo = { ...data, message: response.data, type: "media", mediaType: fileType };
                    dispatch(sendMessage(response.data, "media", fileType));
                    setChatList([msgInfo, ...chatList]);
                    setImageData(response.data)
                }
            })
            .catch((error) => {
                // setUploadLoad(false);
                console.log("errorAWS:", error);
                // AppConst.showConsoleLog('errorAWS:', error);
            });
    };



    const handleDownload = (url, index) => {
        const parts = url.split('/')
        const fileName = parts[parts.length - 1];
        fetch(url)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {
                // Save the array buffer to a file
                var file = new Blob([arrayBuffer], { type: "application/octet-stream" });
                var downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(file);
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch(error => {
                // Handle error
            });
    };

    return (
        <div className={`ChatContainer ${chatModal ? "d-flex" : "d-none"}`}>
            <div className="TopHeader chat_header">Group Chat Room</div>
            <div className="ChatArea">
                <div className="MessageList">
                    {chatList &&
                        chatList
                            .slice(0)
                            .reverse()
                            .map((item, idx) => {
                                const isCurrentUser = item?.userId === data?.userId;

                                return (
                                    <div key={idx} className={`Message ${isCurrentUser ? "UserMessage" : ""}`}>

                                        <strong>{item?.name}</strong>
                                        {item?.type === "media" && item?.mediaType === "images" ? (
                                            <img src={item.message} style={{ width: "170px", height: "150px" }} />
                                        ) : item?.type === "media" && (item?.mediaType === "video" || item?.mediaType === "pdf") ? (
                                            <iframe src={item.message} title="description"></iframe>
                                        ) : item?.type === "media" && item?.mediaType === "doc" ? (
                                            <i class="fa-solid fa-file" style={{ width: "170px", height: "150px" }} ></i>
                                        ) : (
                                            item?.type === "message" && item?.mediaType === null && <p>{item.message}</p>
                                        )}
                                        {item?.type === "media" && item?.mediaType != null && (
                                            <i
                                                className="fa-solid fa-download text-success mr-2"
                                                style={{ fontSize: "18px", cursor: 'pointer' }}
                                                onClick={() => handleDownload(item.message)}
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                    </div>
                                );
                            })}
                    <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} />
                </div>
            </div>
            <div className="p-2 d-flex pb-1 align-items-center LiveMsgBtn">
                <input
                    value={message}
                    className="BottomInput form-control"
                    ref={inputRef}
                    onChange={(e) => handleChangeInputMessage(e)}
                    onKeyUp={(e) => sendMessages(e)}
                    placeholder="Enter your message"
                />
                <div>
                    <label for="upload-media" className="btn default-btn ml-1"
                        style={{ lineHeight: "34px" }}
                    >
                        <i className="fas fa-paperclip"></i>
                        <input type="file" id="upload-media" onChange={(e) => handleUploadFile(e)} hidden />
                    </label>

                </div>
                <button
                    type="button"
                    className="btn default-btn ml-1"
                    style={{ lineHeight: "34px" }}
                    onClick={(e) => {
                        handleSendMessage(e);
                    }}
                >
                    <i className="fas fa-paper-plane"></i>
                </button>
            </div>
        </div>
    );
};


export default Chat;
