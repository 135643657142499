import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* footer start */}
      <footer className="footer bg-green">
        <div className="container">
          <div className="footer-logo-section">
            <Link to="/" className="foot-logo d-block">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/zidni.png`}
                alt=""
              />
            </Link>
            <div className="follow">
              <p>Follow us on:</p>
              <ul>
                <li>
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/twitter-icon.svg`}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/instagram-icon.svg`}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/linkedin-icon.svg`}
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/facebook-icon.svg`}
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="right-sec">
            <div className="links">
              <h6>ZIDNI</h6>
              <ul className="zidni-links">
                <li>
                  <Link to="/about">About zidni</Link>
                </li>
                <li>
                  <Link to="/get-zidni-app">Get zidni app</Link>
                </li>
                {/* <li>
                  <a href="#">Teach on zidni</a>
                </li> */}
                <li>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="links">
              <h6>RESOURCES</h6>
              <ul className="resources-links">
                <li>
                  <Link to={'/courses'} state={{ priceValue: 'Free' }}>Free courses</Link>
                </li>
                {/* <li>
                  <a href="#">Diploma courses</a>
                </li> */}
                {/* <li>
                  <a href="#">Announcements</a>
                </li>
                <li>
                  <a href="#">Academic affairs</a>
                </li> */}
                <li>
                  <Link to={'/webinars'}>Webinar’s</Link>
                </li>
                {/* <li>
                  <Link to={'/'}>Live lectures</Link>
                </li> */}
              </ul>
            </div>
            <div className="links">
              <h6>OTHER</h6>
              <ul className="other-links">
                <li>
                  <Link to="/termsconditions">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/Faq">FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright round">
          <p>Copyright © {new Date().getFullYear()} Zidnimed academy</p>
          <p>
            Made by <a href="https://www.iwebwiser.com/">iwebwiser.com</a>
          </p>
        </div>
      </footer>
      {/* footer end */}
    </>
  );
};

export default Footer;
