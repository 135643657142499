import { APIURL } from "../../../api/apiUrl";
import IO from "socket.io-client";
import store from '../../../store/index'
import { ADD_STREAM, AUDIO_DIRECTORY, CHAT_RESPONSE, DASHBOARD_DATA, LEAVE_STREAM, LOCAL_STREAM, UPDATE_LIVE_ALL_MEDIA, VIDEO_DIRECTORY } from "../../../store/types";
import { sendErrorInfo } from "../../../utility/helperFunctions";
export const APPURL = APIURL.SOCKET_URL
export let myInfo = {};
export let socketCallback = null;
export let socketId;

export let socket = IO(`${APPURL}`);
export let streamIdsJsonObject = {};



export const socketInitialized = (userInfoValue) => {
    console.log("socket url", APPURL)
    myInfo = {
        ...userInfoValue,
        isShare: false,
        mediaInfo: { audio: 1, video: 1 },
        mediaInfoAll: { audio: 1, video: 1 }, //mute all and video all
    };
    return (dispatch) => {
        if (socket == null) {
            socket = IO.connect(APPURL);
        }
        if (socket.connected) {
            socketId = socket.id;
            socket.emit("join", { socketId: socket.id, ...myInfo });
            console.log("sockets data joined =====>", { socketId: socket.id, ...myInfo })
        }
        socket.on("connect", () => {
            console.log("connect:", socket.id);
            socketId = socket.id;
            socket.emit("join", { socketId: socket.id, ...myInfo });
            console.log("sockets data connect =====>", { socketId: socket.id, ...myInfo })
        });
        socket.on("error", (error) => {
            // console.log("--error--:", error);
        });
        socket.on("connect_error", (error) => {
            // console.log("--connect_error--:", error);
        });
        socket.on("disconnect", () => {
            // console.log("disconnect");
        });

        socket.on("session-expired", () => {
            if (socket) {
                socket.close();
                socket = null;
            }

            store.dispatch({
                type: ADD_STREAM,
                payload: null,
            });
            store.dispatch({
                type: LOCAL_STREAM,
                payload: null,
            });

            setTimeout(() => {
                sendErrorInfo("Session has been expired...")
                window.location.replace("/zidni");
            }, 100);
        });

        socket.on("updatemedia", (data) => {
            console.log("update media socket", data)
            if (data?.type === 'share') {

                socketCallback && socketCallback(data)
            } else {
                store.dispatch({
                    type: AUDIO_DIRECTORY,
                    payload: data,
                });
                store.dispatch({
                    type: VIDEO_DIRECTORY,
                    payload: data,
                });
            }
        });

        socket.on('whiteBoard', (data) => {
            store.dispatch({
                type: DASHBOARD_DATA,
                payload: data.dashboardData
            })
        })

        // socket.on("shareScreen", (data) => {

        // })

        socket.on("isAllMute", (data) => {

            store.dispatch({
                type: UPDATE_LIVE_ALL_MEDIA,
                payload: {
                    audio: data.audio,
                    video: data.video,
                },
            });
        });

        socket.on("leave_room", (socketId) => {

            if (socket != null && socket.id != socketId) {
                console.log("not same user ", socketId, socket.id)
                store.dispatch({
                    type: LEAVE_STREAM,
                    payload: socketId,
                });
            } else {
                //Host
                let isSameUser =
                    myInfo.userId == myInfo.adminId
                if (isSameUser) {

                    store.dispatch({
                        type: ADD_STREAM,
                        payload: null,
                    });
                    store.dispatch({
                        type: LOCAL_STREAM,
                        payload: null,
                    });

                    setTimeout(() => {
                        window.location.replace("/zidni");
                    }, 100);
                }
            }
        });

        socket.on("leaveAll_room", () => {
            console.log("leaveAll_room========>")
            try {
                if (socket != null && myInfo.userId != myInfo.adminId && !myInfo.isConference) {

                    store.dispatch({
                        type: ADD_STREAM,
                        payload: null,
                    });
                    store.dispatch({
                        type: LOCAL_STREAM,
                        payload: null,
                    });

                    window.location.replace("/zidni");
                }
            } catch (error) {
                console.error("Error in leaveAll_chime_room:", error);
            }
            // console.log("leaveAll:");

        });

        socket.on("remove", (streamId, socketId) => {
            if (socket != null) {
                socket.emit("leave", myInfo.roomName, socket.id);
            }
        })

        socket.on("receiveMessage", (data) => {
            console.log("receiveMessage", data)
            store.dispatch({
                type: CHAT_RESPONSE,
                payload: data,
            });
        });

        socket.on("screenShare", (data) => {
            // store.dispatch({
            //     type: SHARE_SCREEN,//
            //     payload: data.isShare,
            // });
        });

        socket.on("created", function () {

            let tempStream = {
                isSelf: true,
                ...myInfo,
            };
            console.log("socket      created");
            console.log("socket      created tempStream", tempStream);
            if (myInfo.adminId != myInfo.userId) {
                store.dispatch({
                    type: ADD_STREAM,
                    payload: tempStream,
                });
            } else {
                store.dispatch({
                    type: LOCAL_STREAM,
                    payload: tempStream,
                });
            }

            // console.log("joined");
            socket.emit("ready", myInfo.roomName, socket.id);
        })

        socket.on("joined", (data) => {
            console.log("socket      joined", data);
            let tempStream = {
                isSelf: true,
                ...myInfo,
            };
            if (data && data['whiteBoardDetail'] & data && data['whiteBoardDetail'].dashboardData) {
                store.dispatch({
                    type: DASHBOARD_DATA,
                    payload: data['whiteBoardDetail'].dashboardData
                })
            }

            const dataArray = Object.values(data);
            const filteredArray = dataArray.filter(item => {
                return item.agoraUid !== undefined && item.agoraUid !== myInfo.agoraUid;
            });

            store.dispatch({
                type: ADD_STREAM,
                payload: filteredArray,
            });

            // console.log("joined");
            socket.emit("ready", myInfo.roomName, socket.id);
        })

        socket.on("user-joined", (data) => {
            console.log("user-joined", data)
            let streams = store.getState().root.streams;
            let newStreams = [...streams, data]
            store.dispatch({
                type: ADD_STREAM,
                payload: newStreams,
            });
        })


        socket.on("ready", function (socketCandidateId) {
            if (socketCandidateId != socket.id) {
                console.log("do nothing")
            }
        })
    }
}

export function screenShareStatus(val, streamId) {
    return (dispatch) => {
        let obj = {
            roomName: myInfo.roomName,
            isShare: val,
            streamId: streamId,
        };
        socket.emit("screenShare", obj);
    };
}

export const whiteboardOnOff = (data) => {

    return (dispatch) => {
        let obj = {
            roomName: myInfo.roomName,
            dashboardData: data
        };
        socket.emit('whiteBoard', obj)
    }
}

export const updateMediaAll = (data, streamId) => {
    return (dispatch) => {
        // AppConst.showConsoleLog('updateMedia---:', data);

        myInfo = {
            ...myInfo,
            mediaInfoAll: { ...myInfo.mediaInfoAll, ...data },
        };
        socket.emit("isAllMute", {
            roomName: myInfo.roomName,
            socketId: socket.id,
            ...myInfo.mediaInfoAll,
        });

        console.log("myInfo--------------update-", myInfo);
        store.dispatch({
            type: UPDATE_LIVE_ALL_MEDIA,
            payload: {
                audio: myInfo.mediaInfoAll.audio,
                video: myInfo.mediaInfoAll.video,
            },
        });
    };
};
export const updateMedia = (data) => {
    return (dispatch) => {
        if (data.type == 'share') {
            myInfo = {
                ...myInfo,
                ...data,
            };
        } else {
            myInfo = {
                ...myInfo,
                mediaInfo: { ...myInfo.mediaInfo, ...data },
            };
        }

        console.log("update media-------------", {
            roomName: myInfo.roomName,
            socketId: socket.id,
            ...myInfo
        });
        socket.emit("updatemedia", {
            roomName: myInfo.roomName,
            socketId: socket.id,
            ...myInfo
        });
    };
};

export const setupSocketCallback = (data) => {
    console.log("setupSocketCallback", data)
    socketCallback = data

};

export const sendMessage = (msg, type, mediaType) => {
    console.log("send message----------------", {
        ...myInfo,
        message: msg,
        socketId: socket.id,
        type: type,
        mediaType: mediaType,

    });
    return (dispatch) => {
        socket.emit("sendMessage", {
            ...myInfo,
            message: msg,
            socketId: socket.id,
            type: type,
            mediaType: mediaType,
        });
    };
};


export const leaveRoom = (isConference = false, singleBack = false) => {
    return (dispatch) => {
        if (socket != null) {
            console.log("my info-----", myInfo)
            if (myInfo?.userId != myInfo?.adminId || isConference) {
                socket.emit("leave_room", myInfo.roomName, socketId);
            } else {

                socket.emit("leaveAll_room", myInfo.roomName);
            }
        }
        // leaveStream();
        // store.dispatch({
        //   type: LOCAL_STREAM,
        //   payload: null,
        // });
        // window.location.replace("/zidni");
    };
};