import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useLocation } from "react-router-dom";
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";

const EventProgram = ({
  navigate,
  activeTab,
  eventDetail,
  toggleTab,
  eventId,
  getEventDetail
}) => {

  console.log("eventDetail>>>>>>>>>>>>>>>", eventDetail)
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({ mode: "all" });

  const [fields, setFields] = useState([
    {
      programTitle: "",
      programDescription: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  ]);

  const addMore = () => {
    setFields([
      ...fields,
      {
        programTitle: "",
        programDescription: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
      },
    ]);
  };

  const handleChangeInput = (value, index, name) => {
    console.log("event", value, index, name.split('.')?.[1])
    const values = [...fields];
    values[index][name.split('.')?.[1]] = value

    setFields(values);
    // console.log("time value", inputField)
  }

  const removeField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);

  };

  const startDate = new Date().toISOString().split("T")[0];
  const [endDate, setEndDate] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.eventId !== undefined || eventDetail?.id !== undefined) {
      if (eventDetail?.eventProgram && Array.isArray(eventDetail?.eventProgram)) {
        console.log("in")
        setFields(eventDetail?.eventProgram)
      } else {
        setFields([{
          programTitle: "",
          programDescription: "",
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
        },])
      }
    }
  }, []);

  console.log("eventDetail", eventDetail)

  const onSubmit = (data) => {
    console.log("data", data);
    const eventProgramData = {
      eventProgram: fields,
    };
    console.log("eventProgramData", eventProgramData);
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/update/${eventDetail?.id ? eventDetail?.id : eventId}`;
    callApi(apiUrl, {
      method: "PUT",
      data: eventProgramData,
    }).then((response) => {
      if (response.status === true) {
        sendSuccessMessage(response);
        getEventDetail()
        toggleTab(activeTab + 1);
      } else {
        sendErrorMessage(response);
      }
    });
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4></h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      {fields.map((field, index) => (
        <>
          <div className="course-details learning-cri">
            <div className="submi d-flex align-items-center justify-content-end gap-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={addMore}
              >
                <span>
                  <img src="./assets/images/pluswhite.svg" alt="" />
                </span>
                Add more
              </button>
              {fields.length > 1 && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => removeField(index)}
                >
                  <span>
                    <img src="./assets/images/trash.svg" alt="" />
                  </span>
                </button>
              )}
            </div>
            <form>
              <div className="mb-3">
                <label for={`programTitle_${index}`} className="form-label">
                  Programe title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={`programTitle_${index}`}
                  name={`programTitle`}
                  value={field.programTitle}
                  placeholder="Enter programe title"
                  {...register(`fields[${index}].programTitle`, {
                    required: "Programe title is required",
                    onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                  })}
                />
                <p className="mb-0 text-danger">
                  {errors?.programTitle?.message}
                </p>
              </div>

              <div className="mb-3">
                <label for={`programDescription_${index}`} className="form-label">
                  Programe description
                </label>
                <textarea
                  className="form-control"
                  id={`programDescription_${index}`}
                  name={`programDescription`}
                  value={field.programDescription}
                  placeholder="Enter programe description"
                  {...register(`fields[${index}].programDescription`, {
                    required: "Program description is required",
                    onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                  })}
                />
                <p className="mb-0 text-danger">
                  {errors?.programDescription?.message}
                </p>
              </div>
              <div className="mb-3">
                <div className="date-picking">
                  <ul>
                    <li>
                      <label className="select-label" htmlFor={`startDate_${index}`}>
                        Start date
                      </label>
                      <input
                        type="date"
                        className="form-control date"
                        id={`startDate_${index}`}
                        name={`startDate`}
                        value={field.startDate}
                        min={eventDetail?.eventTimeLocation?.eventStartDate}
                        max={eventDetail?.eventTimeLocation?.eventEndDate}
                        {...register(`fields[${index}].startDate`, {
                          required: "Start date is required",
                          onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                        })}
                      />
                      <p className="mb-0 text-danger">
                        {errors?.startDate?.message}
                      </p>
                    </li>
                    <li>
                      <label className="select-label" htmlFor={`startTime_${index}`}>
                        Start time
                      </label>
                      <input
                        type="time"
                        className="form-control time"
                        value={field.startTime}
                        id={`startTime_${index}`}
                        name={`startTime`}
                        {...register(`fields[${index}].startTime`, {
                          required: "Start time is required",
                          onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                        })}
                      />
                      <p className="mb-0 text-danger">
                        {errors?.startTime?.message}
                      </p>
                    </li>
                    <li>
                      <label className="select-label" htmlFor={`endDate_${index}`}>
                        End date
                      </label>
                      <input
                        type="date"
                        className="form-control date"
                        id={`endDate_${index}`}
                        value={field.endDate}
                        name={`endDate`}
                        min={eventDetail?.eventTimeLocation?.eventStartDate}
                        max={eventDetail?.eventTimeLocation?.eventEndDate}
                        {...register(`fields[${index}].endDate`, {
                          required: "End date is required",
                          onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                        })}
                      />
                      <p className="mb-0 text-danger">
                        {errors?.endDate?.message}
                      </p>
                    </li>
                    <li>
                      <label className="select-label" htmlFor={`endTime_${index}`}>
                        End time
                      </label>
                      <input
                        type="time"
                        className="form-control time"
                        value={field.endTime}
                        id={`endTime_${index}`}
                        name={`endTime`}
                        {...register(`fields[${index}].endTime`, {
                          required: "End time is required",
                          onChange: (e) => handleChangeInput(e.target.value, index, e.target.name)
                        })}
                      />
                      <p className="mb-0 text-danger">
                        {errors?.endTime?.message}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <section className="step-section">
                <div className="step-full">
                  <div className="step-content">
                    <h5 className="steps">
                      Step / <span>{activeTab}</span>
                    </h5>
                    {activeTab !== 1 && activeTab !== 7 && (
                      <a
                        href="javascript:void(0)"
                        onClick={handleSubmit(onSubmit)}
                        className="next"
                      >
                        Next
                      </a>
                    )}
                  </div>
                </div>
              </section>
            </form>
          </div>
        </>
      ))}
    </>
  );
};

export default EventProgram;
