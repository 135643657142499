import React from "react";
import { Route, Routes } from "react-router-dom";

// Dashboard
import Home from "../pages/CommonPages/Home";
// University
import University from "../pages/UniversityPages/University";
import UniversityDetail from "../pages/UniversityPages/UniversityDetail";
// About
import AboutUs from "../pages/CommonPages/AboutUs";
// Events
import Events from "../pages/CommonPages/Events";
import EventsDetail from "../pages/CommonPages/EventsDetail";
import MyEvents from "../pages/InstructorPages/MyEvents";
import CreateEvent from "../pages/InstructorPages/CreateEvent";
// Course
import CourseDetail from "../pages/CommonPages/CourseDetail";
import CourseInnerDetail from "../pages/CommonPages/CourseInnerDetail";
import CourseList from "../pages/CommonPages/CourseList";
import MyCourses from "../pages/InstructorPages/MyCourses";
import CreateCourse from "../pages/InstructorPages/CreateCourse";
// Profile
import Profile from "../pages/CommonPages/Profile";
// Purchase
import MyPurchase from "../pages/CommonPages/MyPurchase";
import StudentPurchasedCourse from "../pages/StudentPages/StudentPurchasedCourse";
import Evaluation from "../pages/CommonPages/Evaluation";
import QuizPage from "../components/common/QuizPage";
import CreateWebinar from "../pages/live/webinar/CreateWebinar";
import LiveWebinar from "../pages/live/webinar/LiveWebinar";
import Checkout from "../components/common/Checkout";
import PaymentSuccess from "../components/common/PaymentSuccess";
import SuccessPage from "../components/common/SuccessPage";
import CheckoutMobile from "../components/common/CheckoutMobile";
import WebinarList from "../pages/live/webinar/WebinarList";
import CreateConference from "../pages/live/conference/CreateConference";
import LiveConference from "../pages/live/conference/LiveConference";
import PrivacyPolicy from "../pages/CommonPages/PrivacyPolicy";
import TermsConditions from "../pages/CommonPages/TermsConditions";
import ContactUs from "../pages/CommonPages/ContactUs";
import FAQ from "../pages/CommonPages/FAQ";
import GetZidniApp from "../pages/CommonPages/GetZidniApp";
import InstructorDashboars from "../pages/InstructorPages/InstructorDashboars";
import CourseReviews from "../pages/CommonPages/CourseReviews";

const AllRoutes = () => {
  return (
    <Routes>
      {/* Dashboard */}
      <Route path="/" element={<Home />} />

      {/* University */}
      <Route path="/universities" element={<University />} />
      <Route path="/university-detail" element={<UniversityDetail />} />

      {/* Course */}
      <Route path="/courses" element={<CourseList />} />
      <Route path="/my-courses" element={<MyCourses />} />
      <Route path="/create-courses" element={<CreateCourse />} />
      <Route path="/course-detail/:courseId" element={<CourseDetail />} />
      <Route path="/course-inner-detail" element={<CourseInnerDetail />} />
      <Route path="/course-reviews" element={<CourseReviews />} />

      {/* About */}
      <Route path="/about" element={<AboutUs />} />

      {/* Privacy Policy - Terms and Condition */}
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/termsconditions" element={<TermsConditions />} />

      {/* FAQ page */}
      <Route path="/Faq" element={<FAQ />} />

      {/* Get Zidni App */}
      <Route path="/get-zidni-app" element={<GetZidniApp />} />

      {/* Event */}
      <Route path="/events" element={<Events />} />
      <Route path="/my-events" element={<MyEvents />} />
      <Route path="/create-event" element={<CreateEvent />} />
      <Route path="/event-detail/:eventId" element={<EventsDetail />} />

      {/* Profile */}
      <Route path="/profile" element={<Profile />} />

      {/* Contact us page */}
      <Route path="/contact-us" element={<ContactUs />} />

      {/* Purchase */}
      <Route path="/my-purchase" element={<MyPurchase />} />
      <Route path="/my-purchase-course" element={<StudentPurchasedCourse />} />
      <Route path="/course-evalution" element={<Evaluation />} />
      <Route path="/course-quize" element={<QuizPage />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/checkout-mobile" element={<CheckoutMobile />} />
      <Route path="/payment-status" element={<PaymentSuccess />} />
      <Route path="/success" element={<SuccessPage />} />

      {/* Live Links */}
      <Route path="/create-webinar" element={<CreateWebinar />} />
      <Route path="/live-webinar/:id" element={<LiveWebinar />} />
      <Route path="/webinars" element={<WebinarList />} />
      <Route path="/conference" element={<CreateConference />} />
      <Route path="/live-conference/:id" element={<LiveConference />} />
      <Route path="/instructor-dashboars" element={<InstructorDashboars />} />
    </Routes>
  );
};

export default AllRoutes;
