import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { stubFalse } from "lodash";
import TimeAgo from "../../components/common/TimeAgo";
import { useSelector } from "react-redux";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";
import Cart from "../../components/common/Cart";

const EventsDetail = () => {
  const location = useLocation();
  let { eventId } = useParams();
  const userDetail = useSelector((state) => state.root.userDetails);

  // const eventId = location?.state?.eventId;
  const [eventDetail, setEventDetail] = useState({});
  const [eventTags, setEventTags] = useState([]);
  const [likedData, setLikedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [CallApi, setCallApi] = useState(true);
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(true)
  const [showCart, setShowCart] = useState(false)
  const toggle = () => {
    setShowCart(!showCart)
  }


  const Details = useRef();
  const resources = useRef();
  const reviews = useRef();

  const handleClickDetail = () => {
    Details.current.scrollIntoView();
  };
  const handleClickresources = () => {
    resources.current.scrollIntoView();
  };
  const handleClickreviews = () => {
    reviews.current.scrollIntoView();
  };

  useEffect(() => {
    if (CallApi) {
      setCallApi(false);
      // setLoader(true)
      getEventDetail()
    } else {
      // setLoader(true)
      getEventDetail();
    }
  }, [CallApi, eventId]);

  const getEventDetail = () => {
    const apiUrl =
      APIURL.EVENT_URL + APIENDPOINTS.EVENT_END_POINT + `event/${eventId}`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response.status === true) {
        setEventDetail(response?.data);
        setLikedData(response?.data?.Likes.filter((item) => item.isLiked));
        setEventTags(JSON.parse(response?.data?.eventTags));
        setLoader(false)
      } else {
        setLoader(false)
      }
    });
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const HandelBuyEvent = () => {

    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/purchase/${eventId}?quantity=${quantity}`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response.status === true) {
        setQuantity(1);
        sendSuccessInfo("Event purchased successfully");
        toggleModal();
        navigate("/my-purchase");
      } else {
        sendErrorInfo(response.data.message);
      }
    });
  };

  const handleAddToCart = () => {
    if (userDetail?.type) {
      if (eventDetail?.createdById !== userDetail?.id) {
        const apiUrl =
          APIURL.CART_URL +
          APIENDPOINTS.CART_END_POINT +
          `cart/add`;
        let price = eventDetail?.eventPricing?.actualValue != undefined || eventDetail?.eventPricing?.actualValue != null ? eventDetail?.eventPricing?.actualValue.toFixed(2) : eventDetail?.eventPricing?.eventPrice ? eventDetail?.eventPricing?.eventPrice : 0
        let finalPrice = price * quantity
        let Data = {
          itemId: eventId,
          itemType: 'event',
          quantity: quantity,
          price: finalPrice
        }
        callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
          if (response.status === true) {
            setQuantity(1);
            sendSuccessInfo("Event purchased successfully");
            toggleModal();
            setShowCart(true)
          } else {
            sendErrorInfo(response.data.message)
          }
        });
      } else {
        sendErrorInfo('You not purchased your owan event')
      }
    } else {
      sendErrorInfo('Please login first')
    }
  }

  const handelBuy = () => {
    if (userDetail?.type) {
      if (eventDetail?.createdById !== userDetail?.id) {
        toggleModal()
      } else {
        sendErrorInfo('You not purchased your owan event')
      }
    } else {
      sendErrorInfo('Please login first')
    }
  }

  const handleInterested = () => {
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/interest/${eventId}`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response.status === true) {
        sendSuccessInfo("Event Interested successfully");
        // setCallApi(true);
        getEventDetail()
      } else {
        sendErrorInfo(response.data.message);
      }
    });
  };

  const handleSendComment = () => {
    if (userDetail?.type) {

      if (comment !== '') {

        let commentData = {
          comment: comment,
        };
        const apiUrl =
          APIURL.EVENT_URL +
          APIENDPOINTS.EVENT_END_POINT +
          `event/comment/${eventId}`;
        callApi(apiUrl, { method: "POST", data: commentData }).then((response) => {
          if (response.status === true) {
            setComment("");
            sendSuccessInfo("comment added successfully!");
            // setCallApi(true);
            getEventDetail()
          } else {
            sendErrorInfo(response.data.message);
          }
        });
      } else {
        sendErrorInfo('Please fill comment')
      }
    } else {
      sendErrorInfo('Please login then comment on event')
    }
  };

  const handleLikeAndDislike = (isLike, type) => {
    let apiUrl = "";
    if (userDetail?.type) {

      if (eventDetail.isCourseLiked === 0 || eventDetail.isCourseLiked !== type) {
        apiUrl =
          APIURL.EVENT_URL +
          APIENDPOINTS.EVENT_END_POINT +
          `event/like/${eventId}?isLiked=${isLike}`;
      } else {
        apiUrl =
          APIURL.EVENT_URL +
          APIENDPOINTS.EVENT_END_POINT +
          `event/like/${eventId}?isLiked=${isLike}&type=remove`;
      }


      callApi(apiUrl, { method: "GET" }).then((response) => {
        if (response.status === true) {
          setCallApi(true);
        } else {
          sendErrorInfo(response.data.message);
        }
      });
    } else {
      sendErrorInfo('Please login then like and dislike event')
    }
    // console.log("apiUrl", apiUrl);

  };

  return (
    <Layout>
      {loader ? <Loader /> : <>
        <section className="view-banner" id="Details" ref={Details}>
          <div className="container">
            <div className="course-details">
              <div className="category-btn">
                <span className="badge-btn">
                  {eventDetail?.isEventPublic === false ? "Private" : "Public"}
                </span>
              </div>
              <h2 className="text-capitalize">{eventDetail?.eventName}</h2>
              <div className="by">
                <h6 className="text-capitalize">
                  Organised By: {eventDetail?.organisedBy}
                  <span>
                    {" "}
                    <img src="./assets/images/linking-green.svg" alt="" />
                  </span>
                </h6>
              </div>
              <ul className="eveul">
                <li className="loc">
                  Date &amp; time:{" "}
                  <span className="time">
                    {eventDetail?.eventTimeLocation?.eventStartDate} -{" "}
                    {eventDetail?.eventTimeLocation?.eventStartTime}
                  </span>
                </li>
                <li className="loc">
                  Contact Person:{" "}
                  <span className="text-capitalize">
                    {eventDetail?.user?.Profile?.firstName}{" "}
                    {eventDetail?.user?.Profile?.lastName}
                  </span>
                </li>
                <li className="loc">
                  Event Enquiries: <span>{eventDetail?.user?.email}</span>
                </li>
                <li className="loc">
                  Location:{" "}
                  <span>
                    {eventDetail?.eventTimeLocation?.selectedAddress?.name}
                  </span>
                </li>

                <li className="d-flex align-items-center">
                  {/* <a href="#">
                  <span>
                    <img src="./assets/images/ppl.svg" alt="" />
                  </span>
                  12,345 joining{" "}
                </a> */}
                  {/* <a href="#"> */}
                  <img className="me-2" src="./assets/images/ppl.svg" alt="" />
                  {eventDetail?.Interests?.length} Interested
                  {/* </a>   */}
                </li>
              </ul>
            </div>
            <div className="img-sec eventdeban">
              <div className="img">
                <img className="rounded-4" src={eventDetail?.eventImage} alt="" />
                {eventDetail?.isEventInterested ? (
                  <button className="btn border btn-light">
                    <img
                      src="./assets/images/yellowstar.svg"
                      width={"30px"}
                      height={"30px"}
                      alt=""
                    />
                    <span className="text-warning">You are Interested</span>
                  </button>
                ) : (
                  <button
                    className="btn border btn-light"
                    onClick={() => handleInterested()}
                  >
                    <img
                      src="./assets/images/blackstar.svg"
                      width={"30px"}
                      height={"30px"}
                      alt=""
                    />
                    <span>I am Interested</span>
                  </button>
                )}
                {/* <Link className="round" to="/course-inner-detail">
                Join event
              </Link> */}
              </div>
            </div>
          </div>
        </section>
        <section className="evdes">
          <div className="container">
            <div className="des-sec" id="resources" ref={resources}>
              <h2>Description</h2>
              <p>{eventDetail?.eventDescription}</p>
            </div>
            <ul className="linsec">
              {eventTags?.map((tag, index) => (
                <li key={index}>
                  <Link to="/courses">{tag.categoryName}</Link>
                </li>
              ))}
            </ul>
            <div className="rew-sec" id="reviews" ref={reviews}>
              <h2>Reviews</h2>
              <div className="likesec">
                <div className="review-content">
                  <div className="revi">
                    <div className="like">
                      <button
                        className={eventDetail.isEventLiked === 1 && "active"}
                        onClick={() => handleLikeAndDislike(true, 1)}
                      >
                        <span>
                          <img src="./assets/images/like.svg" alt="" />
                        </span>
                        Like {likedData?.length}
                      </button>
                    </div>
                    <div className="dislike">
                      <button
                        className={eventDetail.isEventLiked === 2 && "active"}
                        onClick={() => handleLikeAndDislike(false, 2)}
                      >
                        <span>
                          <img src="./assets/images/dislike.svg" alt="" />
                        </span>
                        Dislike
                      </button>
                    </div>
                    <div className="ques">
                      <button>
                        <span>
                          <img src="./assets/images/messaicon.svg" alt="" />
                        </span>
                        Comment {eventDetail?.Comments?.length} &amp; answer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="levcom">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Leave your comment
                </label>
                <textarea
                  onChange={(e) => setComment(e?.target?.value)}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  value={comment}
                  rows={4}
                  placeholder="Leave your comment here..."
                />
              </div>
              <button
                className="btn btn-primary"
                onClick={handleSendComment}
              >
                Send comment
              </button>
            </div>
            <div className="show-com overflow-y-scroll" style={{ maxHeight: "400px" }}>
              {eventDetail?.Comments?.length > 0 &&
                eventDetail?.Comments?.map((comment, index) => (
                  <div className="comss" key={index}>
                    <div className="img-box">
                      {/* <img src="./assets/images/eveavatar.png" alt="" /> */}
                      <div className="avatar-box">
                        <span className="avatar">
                          {comment.user.firstName.charAt(0) +
                            comment.user.lastName.charAt(0)}
                        </span>
                      </div>
                    </div>
                    <div className="desde">
                      <h5>
                        {comment.user.firstName} {comment.user.lastName}
                      </h5>
                      <p>{comment.comment}</p>
                      <div className="tago">
                        <TimeAgo createdAt={comment.createdAt} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
        <section class="specialities">
          <div class="spec-button">
            <div class="specbtn">
              <button
                class="sp-btn"
                data-bs-toggle="modal"
                data-bs-target="#eventpage_popup"
              >
                Programs{" "}
                <span>
                  <img src="./assets/images/hamsp.svg" alt="" />
                </span>
              </button>
            </div>
            <div class="mid">
              <a href="javascript:void(0);" onClick={handleClickDetail}>Details</a>
              <a href="javascript:void(0);" onClick={handleClickresources}>Resources</a>
              <a href="javascript:void(0);" onClick={handleClickreviews}>Reviews </a>
            </div>
            <div class="shop-ca">
              {eventDetail?.eventPricing?.eventType === "Paid" &&
                eventDetail?.isEventPurchased == false ? (
                <button type="button" class="buy" onClick={() => handelBuy()}>
                  {eventDetail?.eventPricing?.actualValue != undefined ? (
                    <>
                      <div>
                        <span
                          className={
                            eventDetail?.eventPricing
                              ?.actualValue != undefined &&
                            "text-muted text-decoration-line-through fs-6"
                          }
                        >
                          {" "}
                          {eventDetail?.eventPricing &&
                            eventDetail?.eventPricing?.eventType ==
                            "Paid"
                            ? `$ ${eventDetail?.eventPricing?.eventPrice}`
                            : "Free"}
                        </span>

                        <span>
                          {" "}
                          {eventDetail?.eventPricing &&
                            eventDetail?.eventPricing?.eventType ==
                            "Paid"
                            ? `$ ${eventDetail?.eventPricing?.actualValue.toFixed(
                              2
                            )}`
                            : "Free"}
                        </span>
                      </div>
                      {/* <span className="fs-6">
                        (University discount)
                      </span> */}
                    </>
                  ) : (
                    <span>
                      {" "}
                      {eventDetail?.eventPricing &&
                        eventDetail?.eventPricing?.eventType ==
                        "Paid"
                        ? `$ ${eventDetail?.eventPricing?.eventPrice}`
                        : "Free"}
                    </span>
                  )}
                </button>
              ) : eventDetail?.isEventPurchased == true ? (
                <button type="button" class="buy">
                  Event Purchased
                </button>
              ) : (
                <button type="button" class="buy" onClick={() => handelBuy()}>
                  Free
                </button>
              )}
              <button class="cart">
                <img src="./assets/images/sharebtn.svg" alt="" />
              </button>
            </div>
          </div>
        </section>
      </>}

      {/* event modal start */}
      <div
        class="modal fade event_pop"
        id="eventpage_popup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-xl">
          <div class="modal-content">
            <div class="modal-body">
              <div class="evemain">
                <div class="inner">
                  <h4>Timing</h4>
                  <ul>
                    {eventDetail?.eventProgram && Array.isArray(eventDetail?.eventProgram) &&
                      eventDetail?.eventProgram?.length > 0 &&
                      eventDetail?.eventProgram?.map((program, index) => (
                        <li key={index}>
                          {console.log("program", program)}
                          <a href="javascript:void(0)">
                            {program?.startDate}, {program?.startTime} -{" "}
                            {program?.endTime}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
                <div class="inner">
                  <h4>Programs</h4>
                  <ul class="lst">
                    {eventDetail?.eventProgram && Array.isArray(eventDetail?.eventProgram) &&
                      eventDetail?.eventProgram?.length > 0 &&
                      eventDetail?.eventProgram?.map((program, index) => (
                        <li key={index}>
                          <a href="javascript:void(0)">
                            {program?.programTitle} -{" "}
                            {program?.programDescription}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* event modal end */}

      {showCart && <Cart closecart={toggle} />}

      <Modal isOpen={showModal} centered toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Enter number of ticket buy
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label for="exampleInputEmail1" className="form-label">
              Set quantity{" "}
            </label>
            <input
              type="number"
              className="form-control"
              value={quantity}
              min={1}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Add quantity "
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <button
              type="button"
              onClick={() => handleAddToCart()}
              class="btn btn-success"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => toggleModal()}
              class="btn btn-danger ms-2"
            >
              Close
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Layout>
  );
};

export default EventsDetail;
