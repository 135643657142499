import React from "react";
import { Link } from "react-router-dom";
const ZidniContactBanner = () => {
  return (
    <>
      <section className="zidni-banner">
        <div className="container round">
          <h3 className="gotham">
            Zidni academy online platform by following our proven ways to
            achieve the Best Skills for your present and future career.&nbsp;
          </h3>
          <div className="button">
            <Link to="/courses" state={{priceValue:'Free'}} className="bg-green round oswald600">
              Explore free courses
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZidniContactBanner;
