import React, { useEffect, useState } from 'react'
import { fetchRTCToken, useTokenWillExpire } from '../utils/helpers';
import { config } from '../utils/config';
import AgoraManager from './AgoraManager';
import { socket, socketInitialized } from '../utils/SocketAction';
import { useSelector, useDispatch } from "react-redux";
import Chat from '../utils/Chat';
import UsersList from '../utils/UsersList';
import { useLocation } from 'react-router-dom';
import AgoraConferenceManager from './AgoraConferenceManager';

const AgoraAuthenticationManager = ({ children, webinarData, client }) => {

    const [joined, setJoined] = useState(false)
    const [chatModal, setChatModal] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const dispatch = useDispatch()
    const streams = useSelector(state => state.root.streams)
    const userDetails = useSelector(state => state.root.userDetails)
    const location = useLocation();

    useTokenWillExpire();
    useEffect(() => {

        if (webinarData) {
            fetchTokenFunction(webinarData.channelName, userDetails?.id, userDetails?.id === webinarData.organisedBy)
        }
    }, [webinarData])
    function getRandomIntInRange() {
        const randomFraction = Math.random();
        const randomNumberInRange = Math.floor(randomFraction * 65536);
        return randomNumberInRange;
    }
    const fetchTokenFunction = async (channelName, uid, isHost) => {
        const intId = getRandomIntInRange();

        if (config.serverUrl !== "" && channelName !== "") {
            try {
                const token = await fetchRTCToken(channelName, uid, isHost);
                config.uid = uid ? uid : intId
                config.rtcToken = token;
                config.channelName = channelName;
                initializeSocketInfo(webinarData)
                setJoined(true)
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log("Please make sure you specified the token server URL in the configuration file");
        }
    };
    const initializeSocketInfo = (data) => {
        let userInfo = {}
        userInfo.title = data.channelName;
        userInfo.description = data.liveDescription;
        userInfo.adminId = location.pathname.includes('conference') ? userDetails.id : data.organisedBy;
        userInfo.userId = userDetails.id;
        userInfo.lectureId = data.id;
        userInfo.name = userDetails?.Profile?.firstName || 'Guest';
        userInfo.email = userDetails?.email || '';
        userInfo.profilePhoto = userDetails?.Profile?.profilePhoto
        userInfo.roomName = data.channelName;
        userInfo.attendeeId = userDetails.id;
        userInfo.agoraUid = config.uid
        userInfo.client = 'web'
        userInfo.isConference = location.pathname.includes('conference') ? true : false
        setUserInfo(userInfo)
        dispatch(socketInitialized(userInfo));
    }

    return (
        <>
            {
                joined === false ? <h4>Connecting</h4> :
                    <>
                        {
                            location.pathname.includes('conference') ? <AgoraConferenceManager
                                config={config}
                                client={client}
                                webinarData={webinarData}
                                openChatModel={() => setChatModal(!chatModal)}
                                userInfo={userInfo}
                                chatModal={chatModal}
                            >
                                {children}
                            </AgoraConferenceManager> :
                                <AgoraManager
                                    config={config}
                                    client={client}
                                    webinarData={webinarData}
                                    chatModal={chatModal}
                                    openChatModel={() => setChatModal(!chatModal)}

                                >
                                    {children}
                                </AgoraManager>
                        }

                        <Chat
                            chatModal={chatModal}
                            setChatModal={() => setChatModal(false)}
                            // chatList={chatList}
                            data={userInfo}
                            lectureId={userInfo.lectureId}
                            roomName={userInfo.roomName}
                        // setChatList={(chat) => setChatList([chat, ...chatList])}
                        />

                    </>
            }
        </>

    )
}
export default AgoraAuthenticationManager

