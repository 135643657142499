import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useLocation } from "react-router-dom";
import { sendErrorMessage, sendSuccessInfo, sendSuccessMessage } from "../../utility/helperFunctions";

const EventHostSponsor = ({ navigate, activeTab, toggleTab, eventId, getEventDetail, eventDetail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ mode: "all" });
  const [coHost, setCoHost] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const [coHostList, setCoHostList] = useState([]);
  const [sponsorList, setSponsorList] = useState([]);
  const location = useLocation()


  useEffect(() => {
    if (location?.state?.eventId !== undefined || eventDetail?.id !== undefined) {
      if (eventDetail?.eventCohostAndSponsor) {
        if (eventDetail?.eventCohostAndSponsor?.coHost?.length > 0) {
          setTimeout(() => {

            setCoHost(eventDetail?.eventCohostAndSponsor?.coHost)
          }, 500);
        }
        if (eventDetail?.eventCohostAndSponsor?.sponsor?.length > 0) {
          setTimeout(() => {

            setSponsor(eventDetail?.eventCohostAndSponsor?.sponsor)
          }, 500);
        }
      }
    }
  }, [])


  const getCoHost = () => {
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `get-cohost?type=cohost`;

    callApi(apiUrl, { method: "GET", data: {} }).then((res) => {
      if (res.status == true) {
        setCoHostList(res?.data);
      }
    });
  }
  const getSponsor = () => {
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `get-cohost?type=sponser`;

    callApi(apiUrl, { method: "GET", data: {} }).then((res) => {
      if (res.status == true) {
        setSponsorList(res?.data);
      }
    });
  }

  useEffect(() => {
    getCoHost();
    getSponsor();
  }, [])
  const coHostOptions = coHostList;
  const sponsorOptions = sponsorList;



  const onSelectCoHost = (selectedList) => {
    setCoHost(selectedList);
  };

  const onRemoveCoHost = (selectedList) => {
    setCoHost(selectedList);
  };

  const onSelectSponsor = (selectedList) => {
    setSponsor(selectedList);
  };

  const onRemoveSponsor = (selectedList) => {
    setSponsor(selectedList);
  };

  const onSubmit = (data) => {
    const eventCohostAndSponsorData = {
      eventCohostAndSponsor: {
        coHost: coHost,
        sponsor: sponsor,
      },
    };
    console.log("eventCohostAndSponsorData", eventCohostAndSponsorData);
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/update/${location.state?.eventId !== undefined ? location.state?.eventId : eventId}`;
    callApi(apiUrl, {
      method: "PUT",
      data: eventCohostAndSponsorData,
    }).then((response) => {
      if (response.status === true) {
        sendSuccessMessage(response);
        getEventDetail()
        toggleTab(activeTab + 1);
      } else {
        sendErrorMessage(response);
      }
    });
  };
  return (
    <>
      <div className="new-cont nnn">
        <h4></h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          <div className="mb-3">
            <label className="select-label" for="inviteco">
              Invite co-hosts
            </label>
            <Multiselect
              displayValue="name"
              options={coHostOptions}
              onSelect={onSelectCoHost}
              selectedValues={coHost}
              onRemove={onRemoveCoHost}
              showArrow
            />
          </div>

          <div className="mb-3">
            <label className="select-label" for="invco">
              Invite sponsors
            </label>
            <Multiselect
              displayValue="name"
              options={sponsorOptions}
              selectedValues={sponsor}
              onSelect={onSelectSponsor}
              onRemove={onRemoveSponsor}
              showArrow
            />
          </div>
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 7 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default EventHostSponsor;
