import React, { useEffect, useState } from "react";

const TimeAgo = ({ createdAt }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      const currentTime = new Date();
      const commentTime = new Date(createdAt);
      const timeDifference = currentTime - commentTime;
      const seconds = Math.floor(timeDifference / 1000);

      if (seconds < 60) {
        setTimeAgo(`${seconds} seconds ago`);
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        setTimeAgo(`${minutes} minute${minutes !== 1 ? "s" : ""} ago`);
      } else {
        // Handle other time intervals if needed
        setTimeAgo("more than an hour ago");
      }
    };

    calculateTimeAgo();

    // Refresh time every minute
    const intervalId = setInterval(calculateTimeAgo, 60000);

    return () => clearInterval(intervalId);
  }, [createdAt]);

  return <span className="text-muted" style={{ fontSize: "14px" }}>{timeAgo}</span>;
};

export default TimeAgo;
