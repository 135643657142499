import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useLocation } from "react-router-dom";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const CoursePricing = ({
  navigate,
  activeTab,
  allCourseData,
  getCourseDetail,
  setAllCourseData,
  toggleTab,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({ mode: "all" });


  const location = useLocation()
  const checkPriceType = watch('coursePaymentType')


  useEffect(() => {
    if (location.state?.courseId !== undefined || allCourseData.id !== undefined) {
      reset({
        coursePricing: JSON.parse(allCourseData.coursePricing)?.coursePricing,
        coursePaymentType: JSON.parse(allCourseData.coursePricing)?.coursePaymentType
      })
    }
  }, [])

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    let finalData = {
      coursePricing: data.coursePricing,
      coursePaymentType: data.coursePaymentType
    }
    const Data = new FormData();

    Data.append("coursePricing", JSON.stringify(finalData));

    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/update/${allCourseData.id}`;
    const multipart = true;

    callApi(apiUrl, { method: "PUT", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        sendSuccessInfo(res.message);
        toggleTab(activeTab + 1);
        getCourseDetail();
      } else {
        sendErrorInfo(res.data.message);
      }
    });
  };

  return (
    <>
      <div className="new-cont nnn">
        <div className="pr-head">
          <h4>let's find out what type of course you're making.</h4>
          <p>
            Please select the price for your course. If you’d like to offer your
            course for free you can choose the type free
          </p>
        </div>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          <div className="mb-3">
            <label className="select-label" for="cour-type">
              Course type
            </label>
            <select
              id="cour-type"
              className="form-select"
              aria-label="Default select example"
              {...register("coursePaymentType", {
                required: "Price is required",
              })}
            >
              <option value={"Paid"}>Paid</option>
              <option value={'Free'}>Free</option>
            </select>
            <p className="mb-0 text-danger">{errors?.coursePaymentType?.message}</p>

          </div>
          {checkPriceType == 'Paid' &&
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Set price{" "}
              </label>
              <input
                type="number"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Add course price "
                {...register("coursePricing", {
                  required: "course Price is required",
                })}
              />
              <p className="mb-0 text-danger">{errors?.coursePricing?.message}</p>
            </div>
          }
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 8 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default CoursePricing;
