import React from 'react'
import { noImage } from './helpers'

const UsersList = ({ usersModal, usersList = [], remoteUsers = [] }) => {
    const getRemoteUser = (agoraUid) => {

        return remoteUsers.find(user => user.uid == agoraUid);
    }
    return (
        <div className={`ChatContainer ${usersModal ? "d-flex" : "d-none"}`}>
            <div className="TopHeader chat_header">Participant List</div>
            <div className="ChatArea">
                <ul class="list-group">
                    {
                        usersList.map((user, index) => (
                            <li class="list-group-item d-flex align-items-center justify-content-between mb-2" key={index} >
                                <div>
                                    <img src={user?.profilePhoto ? user?.profilePhoto : noImage} alt="Profile Pic" class="profile-pic" />
                                    <span class="mr-auto">{user?.name}</span>
                                </div>
                                <div className="d-flex align-items-center gap-3">
                                    {getRemoteUser(user.agoraUid)?.hasVideo ? <i class="fa-solid fa-video"></i> : <i class="fa-solid fa-video-slash text-danger"></i>}
                                    {getRemoteUser(user.agoraUid)?.hasAudio ? < i class="fa-solid fa-microphone"></i> : <i class="fa-solid fa-microphone-slash text-danger"></i>}
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div >
    )
}

export default UsersList