import React from "react";

const CourseType = ({ toggleTab, setCoursePublic, allCourseData }) => {
  return (
    <>
      <section className="create-new-steps">
        <div className="container">
          <div className="lets-find">
            <h3>let's find out what type of course you're making.</h3>
            <div className="in-boxs">
              <div className={allCourseData.isCoursePublic == true ? "form-check active" : "form-check"} onClick={() => { toggleTab(2); setCoursePublic(true) }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="course"
                  id="publiccourse1"
                />
                <label className="form-check-label" for="publiccourse1">
                  <h4>Public course</h4>
                  <p>Create learning experiences for all audiences</p>
                </label>
              </div>
              <div className={allCourseData.isCoursePublic == false ? "form-check active" : "form-check"} onClick={() => { toggleTab(2); setCoursePublic(false) }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="course"
                  id="privatecourse2"
                />
                <label className="form-check-label" for="privatecourse2">
                  <h4>Private course</h4>
                  <p>Create learning experiences for selected audiences</p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseType;
