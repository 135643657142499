import React from "react";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useLocation } from "react-router-dom";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const PublishEvent = ({ navigate, eventId, isEventPublic }) => {
  const location = useLocation()
  const onSubmit = (status) => {
    let isEventPublicData = {
      isEventPublic: isEventPublic,
      eventStatus: status
    };
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/update/${location.state?.eventId !== undefined ? location.state?.eventId : eventId}`;

    callApi(apiUrl, { method: "PUT", data: isEventPublicData }).then(
      (response) => {
        if (response.status === true) {
          sendSuccessInfo(response.message);
          navigate("/my-events");
        } else {
          sendErrorInfo(response.data.message);
        }
      }
    );
  };
  return (
    <>
      {/* <div className="see-pre">
        <button>
          <img src="./assets/images/preview-icon.svg" alt="" />
          <span>See preview</span>
        </button>
      </div> */}
      <div className="course-details publish-course">
        <h3>
          Congratulation you have successfully created a public event would you
          like to publish it now or you want to do it latter after some
          amendments{" "}
        </h3>
        <p>
          Your event has been successfully reviewed by the experts now its ready
          to publish
        </p>
        <div className="shar">
          <button className="later" onClick={() => onSubmit(0)}>
            i’ll do it later
          </button>
          <button className="revie" id="sendforreview" onClick={() => onSubmit(2)}>
            Send for review
          </button>
        </div>
      </div>
      <section className="step-section">
        <div className="step-full">
          <div className="step-content">
            <h5 className="steps">
              Step / <span> 7</span>
            </h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublishEvent;
