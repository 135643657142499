import React, { useEffect, useState } from "react";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { PostCheckoutRequest, callApi } from "../../api/ApiHelper";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Cart = ({ closecart }) => {
  const [cartData, setCartData] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [CallApi, setCallApi] = useState(true);
  const userDetail = useSelector((state) => state.root.userDetails);
  const [universityDiscount, setUniversityDiscount] = useState("");
  const [discountValue, setDiscountValue] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (CallApi) {
      setCallApi(false);
      handleGetCartData();
    }
  }, [CallApi]);

  useEffect(() => {
    const apiUrl =
      APIURL.CART_URL +
      APIENDPOINTS.CART_END_POINT +
      `discount/get/${userDetail?.Profile?.University?.id}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((res) => {
      if (res && res?.data) {
        setDiscountValue(res?.data?.discountValue);
      }
    });
  }, []);

  const handleGetCartData = () => {
    const apiUrl = APIURL.CART_URL + APIENDPOINTS.CART_END_POINT + `cart/get`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response?.status === true) {
        setCartData(response?.data);
        let Totalprice = response?.data?.reduce(
          (sum, item) => sum + item.price,
          0
        );
        let univDiscount =
          response?.data?.length > 0
            ? response?.data?.reduce(
              (total, item) =>
                +total +
                (+item?.itemDetail?.itemPricing?.eventPrice * item?.quantity ||
                  +item?.itemDetail?.itemPricing?.coursePricing),
              0
            )
            : 0;
        setUniversityDiscount(univDiscount);
        setTotalPrice(Totalprice);
      } else {
        sendErrorInfo(response?.data?.message);
      }
    });
  };

  const handleDeleteCartItem = (id) => {
    const apiUrl =
      APIURL.CART_URL + APIENDPOINTS.CART_END_POINT + `cart/remove/${id}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        // sendSuccessInfo(response.message)
        handleGetCartData();
      } else {
        sendErrorInfo(response?.data?.message);
      }
    });
  };

  const handleBuy = () => {
    if (cartData.length > 0) {
      let Data = {
        // amount:cartData?.[0]?.price,
        amount: totalPrice,
        paymentType: "DB",
      };
      GetCheckoutId(Data);
    } else {
      sendErrorInfo("Please add item in cart");
    }
  };

  const GetCheckoutId = async (payload) => {
    const url =
      APIURL.CART_URL + APIENDPOINTS.CART_END_POINT + `create-checkout`;
    const data = {
      grandTotal: payload.amount,
      paymentType: payload.paymentType,
    };

    try {
      const res = await callApi(url, { method: "POST", data: data });
      console.log("res", res);
      if (res && res?.data?.responseDetail?.id) {
        if (totalPrice == 0) {
          const apiUrl =
            APIURL.CART_URL +
            APIENDPOINTS.CART_END_POINT +
            `get-checkout/${res?.data?.responseDetail?.id}`;
          callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
            if (response) {
              sendSuccessInfo("Order Purchased");
              navigate("/my-purchase");
            }
          });
        } else {
          navigate("/checkout", {
            state: { id: res?.data?.responseDetail?.id },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandelBuyEvent = (eventId, quantity) => {
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/purchase/${eventId}?quantity=${quantity}`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response?.status === true) {
        handleDeleteCartItem(cartData?.[0]?.id);
        sendSuccessInfo("Event purchased successfully");
        navigate("/my-purchase");
      } else {
        sendErrorInfo(response?.data?.message);
      }
    });
  };

  const handlePurchaseCourse = (id) => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/purchase/${id}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response?.status === true) {
        handleDeleteCartItem(cartData?.[0]?.id);
        sendSuccessInfo("Course purchased successfully");
        navigate("/my-purchase");
      } else {
        sendErrorInfo(response?.data?.message);
      }
    });
  };

  return (
    <Offcanvas
      direction="end"
      isOpen={true}
      toggle={() => closecart()}
      className="cart-pop"
    >
      <OffcanvasHeader toggle={() => closecart()}>
        <h4 className="offcanvas-title" id="offcanvasRightLabel">
          Your cart <span class="qtys">{cartData.length}</span>
        </h4>
      </OffcanvasHeader>
      <OffcanvasBody>

        {cartData && cartData.length == 0 ?
          <div class="cartisEmpty h-100 d-flex align-items-center justify-content-center">
            <h4>Your Cart is empty</h4>
          </div> :
          <div class="items">
            {cartData &&
              cartData.map((item, key) => (
                <div class="item" key={key}>
                  <div class="lef">
                    <div class="img-box">
                      <img src={item?.itemDetail?.itemImage} alt="" />
                    </div>
                    <div class="contents">
                      <h5>{item?.itemDetail?.itemName}</h5>
                      <p
                        className="d-inline-block text-truncate "
                        style={{ maxWidth: "170px" }}
                      >
                        {item?.itemDetail?.itemDescription}
                      </p>
                    </div>
                  </div>
                  <div class="rig">
                    ${item?.price}{" "}
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteCartItem(item?.id)}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/trash bin trash.svg`}
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        }

      </OffcanvasBody>
      {cartData && cartData.length != 0 &&
        <div class="offcanvas-footer">
          <div class="tot mb-2">
            <h5>Price:</h5>
            <h5 class="tot-qty">
              ${discountValue != null ? universityDiscount : totalPrice}
            </h5>
          </div>
          {discountValue !== null && (
            <div class="tot mb-2">
              <h5>University discount:</h5>
              <h5 class="tot-qty"> ${totalPrice - universityDiscount}</h5>
            </div>
          )}
          <div class="tot">
            <h5>Total:</h5>
            <h5 class="tot-qty">${totalPrice}</h5>
          </div>
          <button class="chkout" onClick={() => handleBuy()}>
            Checkout
          </button>
          <p>Taxes are calculated at checkout.</p>
          <button class="cont-shop" onClick={() => closecart()}>Continue shopping</button>
        </div>
      }
    </Offcanvas>
  );
};

export default Cart;
