import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TabContent, TabPane } from "reactstrap";
import { callApi } from "../../api/ApiHelper";
import { useDispatch } from "react-redux";
import {
  saveUserDetails,
  setShowLoginPopup,
} from "../../store/actions/userActions";
import Cookies from "js-cookie";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";
// import { getTokens } from "../../firebase";
import { v4 as uuidv4 } from 'uuid';

const RegistrationForm = () => {
  const [activeRegistrationTab, setActiveRegistrationTab] = useState("1");
  const [allRegistrationData, setAllRegistrationData] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [allUniversity, setAllUniversity] = useState([]);
  const [fcmToken, setFcmToken] = useState();
  const [isTokenFound, setTokenFound] = useState(false);
  const randomDeviceId = uuidv4();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({ mode: "all" });

  const watchedFields = watch(["firstName", "lastName", "email", "gender"]);
  const isTab1Complete = Object.values(watchedFields).every(
    (value) => value !== ""
  );

  // useEffect(() => {
  //   let data;
  //   async function tokenFunc() {
  //     data = await getTokens(setTokenFound);
  //     if (data) {
  //       console.log("token generated from registrationForm >>>>> ", data);
  //     }
  //     setFcmToken(data);
  //     return data;
  //   }

  //   tokenFunc();
  // }, []);

  useEffect(() => {
    const apiUrl =
      APIURL.COURSE_URL + APIENDPOINTS.AUTH_END_POINT + "university/get";
    callApi(apiUrl, {
      method: "POST",
      data: { sortField: "id", sortType: "ASC", limit: 50 },
    }).then((response) => {
      if (response.status === true) {
        setAllUniversity(response.data?.university);
      }
    });
  }, []);

  const toggleRegistrationTab = (tab) => {
    if (activeRegistrationTab != tab) {
      setActiveRegistrationTab(tab);
    }
  };
  const handleRegister = () => {
    if (otp.length === 6 && otp !== "") {
      setLoading(true);
      let Data = {
        ...allRegistrationData,
        otp: otp,
      };
      const apiUrl =
        APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "registration";

      callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
        if (response.status === true) {
          setLoading(false);
          sendSuccessMessage(response);
          dispatch(saveUserDetails(response.data));
          Cookies.set("deviceId", response.data.deviceId);
          Cookies.set("zidniToken", response.data.accessToken);
          Cookies.set("refreshZidniToken", response.data.refreshToken);

          Cookies.set("userDetail", response.data);
          dispatch(setShowLoginPopup(false));
        } else {
          sendErrorMessage(response);
          setLoading(false);
        }
      });
    } else {
      // console.log("otp", otp)
    }
  };

  // function generateRandomString() {
  //   const characters =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let randomString = "";
  //   for (let i = 0; i < 12; i++) {
  //     const randomIndex = Math.floor(Math.random() * characters.length);
  //     randomString += characters.charAt(randomIndex);
  //   }
  //   return randomString;
  // }

  const handleRegisterData = (data) => {
    setLoading(true);
    let deviceId = "";
    if (localStorage.getItem('deviceId')) {
      deviceId = localStorage.getItem('deviceId');
    } else {
      deviceId = "2024_" + randomDeviceId;
    }
    localStorage.setItem("deviceId", deviceId);

    let Data = {
      ...data,
      deviceId: deviceId,
      deviceToken: fcmToken ? fcmToken : randomDeviceId.slice(0, 10),
      type: 1
    };

    // let Data = { ...data };
    // let randomString = generateRandomString();
    // Data["deviceToken"] = fcmToken ? fcmToken : randomString;
    // Data["deviceId"] = randomString;
    // Data["type"] = 1;


    console.log("Data>>>>>>>>>>>>>>>>", Data);
    setAllRegistrationData(Data);
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "registration";
    // callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
    //   if (response.status == true) {
    //     setLoading(false);

    //     sendSuccessMessage(response);
    //     toggleRegistrationTab("3");
    //   } else {
    //     sendErrorMessage(response);
    //     setLoading(false);
    //   }
    // });
  };

  return (
    <form
      id="signupForm"
      className="fade-in sig-form"
    >
      <TabContent activeTab={activeRegistrationTab}>
        <TabPane tabId={"1"}>
          <div className="top-pop">
            <div className="sign-up-one">
              <h4>Sign up</h4>
              {/* <label className="select-label" htmlFor="signupgender">
                Select roll
              </label>
              <select
                id="signupgender"
                name="signupgender"
                className="signupgender form-select"
                aria-label="Default select example"
                {...register("type", {
                  required: "Field is required",
                })}
              >
                <option value={""} selected="selected">
                  Select roll
                </option>
                <option value={1}>Student</option>
                <option value={2}>Instructor</option>
              </select>
              <p className="mb-0 text-danger">{errors?.type?.message}</p> */}

              <div className="mb-3">
                <label htmlFor="signupfullname" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupfullname"
                  name="signupfullname"
                  placeholder="Enter first name"
                  required=""
                  {...register("firstName", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.firstName?.message}</p>

              </div>
              <div className="mb-3">
                <label htmlFor="signupfullname" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupfullname"
                  name="signupfullname"
                  placeholder="Enter last name"
                  required=""
                  {...register("lastName", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.lastName?.message}</p>
              </div>
              <div className="mb-3">
                <label htmlFor="selectemai" className="form-label">
                  Email address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="selectemai"
                  name="selectemai"
                  placeholder="zidni@gmail.com"
                  required=""
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                <p className="mb-0 text-danger">{errors?.email?.message}</p>
              </div>
              <div className="mb-3">
                <label className="select-label" htmlFor="signupgender">
                  Gender
                </label>
                <select
                  id="signupgender"
                  name="signupgender"
                  className="signupgender form-select"
                  aria-label="Default select example"
                  {...register("gender", {
                    required: "Field is required",
                  })}
                >
                  <option value={""} selected="selected">
                    Select gender
                  </option>
                  <option value={1}>Female</option>
                  <option value={0}>Male</option>
                  <option value={2}>Other</option>
                </select>
                <p className="mb-0 text-danger">{errors?.gender?.message}</p>
              </div>
            </div>
          </div>
          <div className="bottom-pop">
            <div className="check-d">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="exclusivedeals"
                  {...register("emailSend", {
                    required: "Field is required",
                  })}
                />
                <label className="form-check-label" htmlFor="exclusivedeals">
                  Yes! I want to get the most out of{" "}
                  <b>
                    <a href="#">Zidni.academy </a>
                  </b>
                  by receiving emails with exclusive deals, personal
                  recommendations and learning tips!
                </label>
              </div>
              <p className="mb-0 text-danger">{errors?.emailSend?.message}</p>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultValue=""
                  id="checkterms"
                  {...register("term", {
                    required: "Field is required",
                  })}
                />
                <label className="form-check-label" htmlFor="checkterms">
                  By signing up, you agree to{" "}
                  <b>
                    <a href="#" className="terms">
                      Terms and conditions
                    </a>
                  </b>
                </label>
              </div>
              <p className="mb-0 text-danger">{errors?.term?.message}</p>

            </div>
            <button
              type="button"
              className="vercon btn"
              onClick={() => isTab1Complete && toggleRegistrationTab("2")}
              disabled={!isTab1Complete}
            >
              Verify and continue
            </button>
          </div>
        </TabPane>
        <TabPane tabId={"2"}>
          <div className="sign-up-two d-flex justify-content-between flex-column">
            <div className="top-pop">
              <h4>Sign up</h4>
              <div className="mb-3">
                <label htmlFor="signupQualifications" className="form-label">
                  Qualifications
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupQualifications"
                  name="signupQualifications"
                  placeholder="Add Qualifications"
                  {...register("qualification", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">
                  {errors?.qualification?.message}
                </p>
              </div>

              <div className="mb-3">
                <label htmlFor="signfullname" className="form-label">
                  Institution
                </label>
                <select
                  id="select_country"
                  name="select Institution"
                  class="sort-by form-control replaced"
                  fdprocessedid="5kzl6i"
                  {...register("universityId", {
                    required: "Field is required",
                  })}
                >
                  <option value={""} selected="selected">
                    Select Institution
                  </option>
                  {allUniversity &&
                    allUniversity.map((item, key) => (
                      <option key={key} value={item?.id}>
                        {item?.universityName}
                      </option>
                    ))}
                </select>
                <p className="mb-0 text-danger">
                  {errors?.universityId?.message}
                </p>
              </div>

              <div className="mb-3">
                <label htmlFor="signupprofession" className="form-label">
                  Id Card Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupprofession"
                  name="signupprofession"
                  placeholder="Enter ID number"
                  {...register("idNumber", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.idNumber?.message}</p>
              </div>

              <div className="mb-3">
                <label htmlFor="signupemployment" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="signupemployment"
                  name="signupemployment"
                  placeholder="Password"
                  {...register("password", {
                    required: "Field is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                />
                <p className="mb-0 text-danger">{errors?.password?.message}</p>
              </div>

            </div>
            <div className="bottom-pop">
              {/* <div className="illdo text-end">I’ll do it later</div> */}
              <div className="btnss d-flex justify-content-between">
                <button
                  type="button"
                  className="back"
                  onClick={() => toggleRegistrationTab("1")}
                >
                  Back
                </button>
                <button type="button" onClick={handleSubmit(handleRegisterData)} className="next">
                  {loading == true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={"3"}>
          <div className="sign-up-two d-flex justify-content-between flex-column">
            <div className="top-pop">
              <div className="mb-3">
                <label htmlFor="signupQualifications" className="form-label">
                  Enter otp to verify account
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="otp"
                  name="otp"
                  placeholder="Enter otp"
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              {otp === "" ||
                (otp.length < 6 && (
                  <p className="mb-0 text-danger">Please fill valid otp</p>
                ))}
              <div className="btnss d-flex justify-content-between">
                <button type="button" className="next" onClick={handleRegister}>
                  {loading === true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </form>
  );
};

export default RegistrationForm;
