import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link, useNavigate } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useSelector } from "react-redux";
import Header from "../../components/common/Header";
import Swal from "sweetalert2";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const MyEvents = () => {
  const [myEvents, setMyEvents] = useState([]);
  const [CallApi, setCallApi] = useState(true);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.root.userDetails.id);
  const userDetail = useSelector((state) => state.root.userDetails);
  const [loader, setLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [sortByValue, setSortByValue] = useState("");
  const [sortByField, setSortByField] = useState("");
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);
  useEffect(() => {
    if (CallApi == true) {
      setCallApi(false);
      let eventData = {
        where: {
          createdById: userDetail.id,
        },
        paranoid: true,
        sortField: sortByField,
        sortType: sortByValue,
        pageNumber: currentPage,
        limit: 10,
      };
      if (filterValue != "") {
        if (filterValue !== "all") {
          eventData.where["eventStatus"] = filterValue;
        }
      }
      if (searchValue != "") {
        eventData.where["eventName"] = searchValue;
      }
      if (priceValue != "") {
        eventData.where["eventPricing"] = priceValue;
      }
      setIsLoading(true);
      const apiUrl =
        APIURL.EVENT_URL + APIENDPOINTS.EVENT_END_POINT + `event/list`;
      callApi(apiUrl, { method: "POST", data: eventData }).then((rseponse) => {
        if (rseponse.status === true) {
          setMyEvents(rseponse.data.event);
          setLoader(false);
          setIsLoading(false);
          let arr = [];
          let toTal = rseponse?.data?.totalPages;
          for (let i = 1; i <= toTal; i++) {
            arr.push(i);
          }
          setAllPages(arr);
        } else {
          setLoader(false);
          setIsLoading(false);
        }
      });
    }
  }, [CallApi]);

  const handelFilter = (value) => {
    if (value == "Paid" || value == "Free") {
      setPriceValue(value);
      setFilterValue("");
    } else {
      setPriceValue("");
      setFilterValue(value);
    }
    setCallApi(true);
  };

  const handleSortBy = (value) => {
    if (value !== sortByValue && value != "") {
      if (value == "asc" || value == "desc") {
        setSortByField("eventName");
        setSortByValue(value);
      } else {
        if (value == "Date old") {
          setSortByValue("asc");
          setSortByField("createdAt");
        } else {
          setSortByValue("desc");
          setSortByField("createdAt");
        }
      }
      setCallApi(true);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setCallApi(true);
  };

  const handleDeleteEvent = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to delete this event!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#156b68",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.EVENT_URL +
          APIENDPOINTS.EVENT_END_POINT +
          `event/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response.status === true) {
            sendSuccessInfo(response.message);
            setCallApi(true);
          } else {
            sendErrorInfo(response.data.message);
          }
        });
      }
    });
  };

  return (
    <>
      <Header />
      {/* <section className="create-course event-course">
        <div className="container">
          <div className="header-tab">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  <img src="./assets/images/homeiconblack.svg" alt="" />
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-msg-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-msg"
                  type="button"
                  role="tab"
                  aria-controls="pills-msg"
                  aria-selected="false"
                >
                  <img src="./assets/images/messageiconblack.svg" alt="" />
                </button>
              </li>
            </ul>

            <div className="instru">
              <div className="img">
                <img src="./assets/images/inslogo.svg" alt="" />
              </div>
              <span> Instructor</span>
              <button>
                {" "}
                <img src="./assets/images/bell.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </section> */}
      <section className="cr-search-bar">
        <div className="container">
          <div className="search-bar">
            <div className="ser">
              <input
                type="text"
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="selects">
              <div className="fill-by">
                <select
                  id="fil-by"
                  name="fil_by"
                  className="fil-by replaced"
                  onChange={(e) => handelFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                  <option value={0}>Draft</option>
                  <option value={1}>Publish</option>
                  <option value={2}>Send for review</option>
                  <option value={3}>Inactive</option>
                  <option value={4}>Unapproved</option>
                </select>
              </div>
              <div className="shor-by">
                <select
                  id="sort-by"
                  name="sort_by"
                  className="sort-by replaced"
                  onChange={(e) => handleSortBy(e.target.value)}
                >
                  <option value="">Sort by</option>
                  <option value={"asc"}>Alphabetically, A-Z</option>
                  <option value="desc">Alphabetically, Z-A</option>
                  {/* <option value="price-ascending">Price, low to high</option>
                  <option value="price-descending">Price, high to low</option> */}
                  <option value="Date old">Date, old to new</option>
                  <option value="Date new">Date, new to old</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      {loader ? (
        <Loader />
      ) : (
        <div className="tab-content event-tab" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabindex="0"
          >
            <section className="create-new-steps evasd">
              <div className="container">
                <div className="new-cont">
                  <h4>My event</h4>
                  <Link to="/create-event" className="cre-new-btn">
                    Create new event
                  </Link>
                </div>

                <div className="events-added">
                  {isLoading ?
                    <div style={{ height: "400px" }} className="d-flex justify-content-center m-5">
                      <div className="spinner-border"></div>
                    </div> :
                    myEvents &&
                      myEvents.length > 0 ?
                      myEvents
                        .filter((e) => e.deletedAt == null)
                        .map((event, index) => (
                          <div class="course-box" key={index}>
                            <div
                              class="img-box"
                              onClick={() =>
                                navigate(`/event-detail`, {
                                  state: { eventId: event.id },
                                })
                              }
                            >
                              <img src={event.eventImage} alt="" />
                            </div>
                            <div
                              class="contents"
                              onClick={() =>
                                navigate(`/event-detail`, {
                                  state: { eventId: event.id },
                                })
                              }
                            >
                              <div className="prdc">
                                {event?.isEventPublic ? (
                                  <span className="public">Public</span>
                                ) : (
                                  <span class="private">Private</span>
                                )}
                                {/* {event?.eventPricing?.eventType == 'Free' ?
                              <span className="pr-free">Free</span> :
                              <span className="pr-paid">${event?.eventPricing?.eventPrice}</span> 
                            } */}
                              </div>
                              <h4>{event.eventName}</h4>
                              <p>
                                {event?.eventTimeLocation?.eventStartDate} -{" "}
                                {event?.eventTimeLocation?.eventEndDate}{" "}
                                {event?.eventTimeLocation?.eventStartTime}
                              </p>
                            </div>
                            <div class="overlay-box">
                              <div class="iner-btns">
                                <button
                                  class="edit-course"
                                  onClick={() => {
                                    navigate("/create-event", {
                                      state: { eventId: event?.id },
                                    });
                                  }}
                                >
                                  Edit event /
                                </button>
                                <Link to={`/event-detail/${event?.id}`}
                                // onClick={() => {
                                //   navigate("/event-detail", {
                                //     state: { eventId: event?.id },
                                //   });
                                // }}
                                >
                                  <button>
                                    View /
                                  </button>
                                </Link>
                                <button
                                  onClick={() => handleDeleteEvent(event.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        )) :
                      <div className="no-cou-yet">
                        <h3>No event found</h3>
                        <p>
                          Here’s where you’ll see all your events’
                        </p>
                      </div>
                  }
                </div>
              </div>
            </section>
            <section className="pt-0">
              <div className="container">
                {allPages?.length > 0 && (
                  <div className="pagination-pointer mt-3">
                    <nav aria-label="...">
                      <ul className="pagination">
                        <li className="page-item ">
                          <a
                            href="javascript:void(0);"
                            className="page-link"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                setCallApi(true);
                              }
                              if (paginationRange[0] > 1) {
                                setPaginationRange([
                                  paginationRange[0] - 1,
                                  paginationRange[1] - 1,
                                ]);
                                setCallApi(true);
                              }
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        {allPages &&
                          allPages?.length > 0 &&
                          allPages
                            .slice(paginationRange[0] - 1, paginationRange[1])
                            .map((item, key) => (
                              <li
                                className={
                                  currentPage === item
                                    ? "active page-item"
                                    : "page-item"
                                }
                                aria-current="page"
                                key={key}
                              >
                                <a
                                  className="page-link"
                                  href="javascript:void(0);"
                                  onClick={() => {
                                    setCurrentPage(item);
                                    setCallApi(true);
                                  }}
                                >
                                  {item}
                                </a>
                              </li>
                            ))}
                        <li className="page-item">
                          <a
                            className="page-link"
                            href="javascript:void(0);"
                            onClick={() => {
                              if (currentPage < allPages?.length) {
                                setCurrentPage(currentPage + 1);
                                setPaginationRange([
                                  paginationRange[0] + 1,
                                  paginationRange[1] + 1,
                                ]);
                                setCallApi(true);
                              }
                            }}
                          >
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade"
            id="pills-msg"
            role="tabpanel"
            aria-labelledby="pills-msg-tab"
            tabindex="0"
          >
            <section className="message-sec">
              <div className="container">
                <div className="no-msgs">
                  <h4>
                    Direct messages are for you to communicate with your
                    students or other instructors privately. Here’s where you’ll
                    see them.
                  </h4>
                </div>
                <div className="messages-part">
                  <div className="msg-contents">
                    <div className="tds">
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:30 PM</span>
                          </h6>
                          <p>
                            Sint dolorem pariatur ut id numquam aut magni cum.
                          </p>
                        </div>
                      </div>
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Terry Altenwerth
                            <span className="msg-time">02:45 PM</span>
                          </h6>
                          <p>Adipisci et at quos ut aspernatur.</p>
                        </div>
                      </div>
                    </div>
                    <div className="dates-sec">
                      <div className="date">Wednesday july 19th</div>
                    </div>
                    <div className="tds">
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:30 PM</span>
                          </h6>
                          <p>
                            Aut est quis. Assumenda et praesentium quibusdam
                            voluptatem veniam iusto. Quae praesentium quasi ad.
                            Nihil totam rerum placeat ullam consectetur sapiente
                            provident.
                          </p>
                        </div>
                      </div>
                      <div className="person-msgs">
                        <div className="imgs">
                          <img src="./assets/images/avatarimg.svg" alt="" />
                        </div>
                        <div className="names">
                          <h6>
                            Roger Larson{" "}
                            <span className="msg-time">02:45 PM</span>
                          </h6>
                          <p>
                            Aut est quis. Assumenda et praesentium quibusdam
                            voluptatem veniam iusto. Quae praesentiu
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="newinputpartsevent">
              <div className="input-parts">
                <form action="">
                  <div className="inputevens">
                    <div className="msgbox">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Message...."
                        id="communication"
                      />
                    </div>
                    <div className="btnss">
                      <button className="send">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="none"
                        >
                          <path
                            d="M0.768483 19.2791L23.1652 9.6759L0.768483 0.0727539L0.757812 7.54187L16.7631 9.6759L0.757812 11.8099L0.768483 19.2791Z"
                            fill="#BAB9B9"
                          />
                        </svg>
                      </button>
                      <button className="add">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            d="M8.96094 1.67578V15.6758M1.96094 8.67578H15.9609"
                            stroke="#BAB9B9"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        {/* <img src="./assets/images/plusic.svg" alt="" /> */}
                      </button>
                      <button className="option">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="22"
                          viewBox="0 0 6 22"
                          fill="none"
                        >
                          <path
                            d="M2.96021 5.48584C4.38744 5.48584 5.55518 4.3181 5.55518 2.89087C5.55518 1.46364 4.38744 0.295898 2.96021 0.295898C1.53297 0.295898 0.365234 1.46364 0.365234 2.89087C0.365234 4.3181 1.53297 5.48584 2.96021 5.48584ZM2.96021 8.08081C1.53297 8.08081 0.365234 9.24855 0.365234 10.6758C0.365234 12.103 1.53297 13.2708 2.96021 13.2708C4.38744 13.2708 5.55518 12.103 5.55518 10.6758C5.55518 9.24855 4.38744 8.08081 2.96021 8.08081ZM2.96021 15.8657C1.53297 15.8657 0.365234 17.0335 0.365234 18.4607C0.365234 19.8879 1.53297 21.0557 2.96021 21.0557C4.38744 21.0557 5.55518 19.8879 5.55518 18.4607C5.55518 17.0335 4.38744 15.8657 2.96021 15.8657Z"
                            fill="#BAB9B9"
                          />
                        </svg>
                        {/* <img src="./assets/images/optionbt.svg" alt="" /> */}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabindex="0"
          >
            <div className="dashboard">
              <div className="container">
                <ul className="count">
                  <li>
                    <span className="num">(+125)</span>
                    <span className="name">Universities partners</span>
                  </li>
                  <li>
                    <span className="num">(1,234)</span>
                    <span className="name">Expert Instructors </span>
                  </li>
                  <li>
                    <span className="num">(22,345)</span>
                    <span className="name">Registered Students</span>
                  </li>
                  <li>
                    <span className="num">(2034)</span>
                    <span className="name">Course enrolments</span>
                  </li>
                </ul>
                <div className="dash-content">
                  <div className="top">
                    <div className="co">Course</div>
                    <div className="amounts">Amount</div>
                    <div className="dates">Date</div>
                  </div>
                  <div className="cr-main">
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                    <div className="bottom">
                      <div className="most">
                        <div className="of">
                          <h3>
                            Most of these courses will be offered in offline
                            mode and will involve ample practical learning.
                          </h3>
                          <div className="cate">Ophthalmology Specialty</div>
                        </div>
                      </div>
                      <h3 className="am">$235</h3>
                      <h3 className="da">24 feb, 2023</h3>
                    </div>
                  </div>
                  <div className="total-dash">
                    <div className="new">
                      <div className="co">Total</div>
                      <div className="amounts">$705</div>
                      <div className="dates"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-watch"
            role="tabpanel"
            aria-labelledby="pills-watch-tab"
            tabindex="0"
          >
            ...
          </div>
        </div>
      )}
    </>
  );
};

export default MyEvents;
