import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { TabContent, TabPane } from "reactstrap";
import { PostData, PostImageDataWithToken, callApi } from "../../api/ApiHelper";
import {
  saveUserDetails,
  setShowLoginPopup,
} from "../../store/actions/userActions";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { sendErrorInfo, sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";

const InstructorForm = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [allDocs, setAllDosc] = useState([]);
  const [allFile, setAllFile] = useState({});
  const [allInsData, setAllInsData] = useState({});
  const [firstFormData, setFirstFromData] = useState({});
  const dispatch = useDispatch();
  const [allUniversity, setAllUniversity] = useState([]);
  const [checkStatus, setCheckStatus] = useState(false);
  const [selectedUnivName, setSelectedUnivName] = useState('');

  useEffect(() => {
    let Data = {
      where: {
        for: 2,
      },
      sortField: "type",
      sortType: "DESC", //DESC/ASC,
    };
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "doc/get-type";
    callApi(apiUrl, { method: "POST", data: Data }).then((res) => {
      if (res.status == true) {
        setAllDosc(res.data.rows);
        // console.log("mnxshs", res.data.rows);
      }
    });
    const apiUrl1 =
      APIURL.COURSE_URL + APIENDPOINTS.AUTH_END_POINT + "university/get";
    callApi(apiUrl1, {
      method: "POST",
      data: { sortField: "id", sortType: "ASC", limit: 50 },
    }).then((response) => {
      if (response.status === true) {
        setAllUniversity(response.data?.university);
      }
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({ mode: "all" });

  const {
    register: register3,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({ mode: "all" });
  const toggleTab = (tab) => {
    if (activeTab != tab) {
      setActiveTab(tab);
    }
  };
  const handleData = (data) => {
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "get-user";

    callApi(apiUrl, { method: "POST", data: { email: data.email }, }).then((res) => {
      if (res.status == true) {
        // console.log("ffdbjfd", res)
        if (res?.data == null) {
          setFirstFromData(data);
          toggleTab("2");
        } else {
          if (res?.data?.isVerified == false && res?.data?.UserDocuments?.length > 0) {
            setFirstFromData({ ...res.data, ...res.data.Profile });
            toggleTab("4");
            return
          }
          sendErrorInfo('User already exists')
          // if (res?.data?.InstructorRequest == 2) {
          //     setFirstFromData({ ...res.data, ...res.data.Profile });
          //     toggleTab("4");
          //     return
          // }

          // if (res?.data?.InstructorRequest !== null) {
          //   setFirstFromData({ ...res.data, ...res.data.Profile });
          //   setCheckStatus(true)
          //   toggleTab("3");
          //   return
          // }
        }
      } else {
        console.log(res);
      }
    });
    // setFirstFromData(data);
    // toggleTab("2");

  };

  const handleInstructorForm = (data1) => {
    let universityName = firstFormData.universityId.split("_#_")[1];
    setSelectedUnivName(universityName);
    let data = {
      ...data1,
      ...firstFormData,
    };
    // console.log("data", data);
    setLoading(true);
    let Data = new FormData();
    Data.append("email", data.email);
    Data.append("universityId", firstFormData.universityId.split("_#_")[0]);
    Data.append("firstName", data.firstName);
    Data.append("lastName", data.lastName);
    Data.append("idNumber", data.idNumber);
    Data.append("phone", data.phone);
    Data.append("type", 2);
    Object.keys(allFile).forEach((key) => {
      Data.append(key, allFile[key]);
    });
    const multipart = true;
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "sendInstructorRequest";
    callApi(apiUrl, { method: "POST", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        // sendSuccessMessage(res);
        setAllInsData(res.data);
        setLoading(false);
        toggleTab("3");
      } else {
        sendErrorMessage(res);
        setLoading(false);
      }
    });
  };

  const handleInstructorForm1 = (data1) => {
    let data = {
      ...data1,
      ...firstFormData,
    };
    // console.log("data", data);
    setLoading(true);
    let Data = new FormData();
    Data.append("email", data.email);
    Data.append("universityId", data.universityId);
    Data.append("firstName", data.firstName);
    Data.append("lastName", data.lastName);
    Data.append("idNumber", data.idNumber);
    Data.append("phone", data.phone);
    Data.append("type", 2);
    Object.keys(allFile).forEach((key) => {
      Data.append(key, allFile[key]);
    });
    const multipart = true;
    const apiUrl =
      APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "update/document/" + firstFormData?.userId;
    callApi(apiUrl, { method: "POST", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        sendSuccessMessage(res);
        setAllInsData(res.data);
        setLoading(false);
        toggleTab("3");
      } else {
        sendErrorMessage(res);
        setLoading(false);
      }
    });
  };

  const handleUploadDocs = (e, key) => {
    if (!e.target.files[0]?.type.includes("image")) {
      // console.log("Please Upload Image File");
      return;
    }
    let Data = {};
    Data[`${key}`] = e.target.files[0];

    setAllFile({
      ...allFile,
      ...Data,
    });
  };

  const handleUploadDocs2 = (e, key) => {
    if (!e.target.files[0]?.type.includes("image")) {
      // console.log("Please Upload Image File");
      return;
    }
    let Data = {};
    Data[`${key}`] = e.target.files[0];
    Data[`${key}-preview`] = URL.createObjectURL(e.target.files[0]);

    setAllFile({
      ...allFile,
      ...Data,
    });
  };

  // console.log("allInsData...>>>>>>>>>>", allInsData)

  return (
    <form id="zidniinstructorForm" className="fade-in zidform">
      <TabContent activeTab={activeTab}>
        <TabPane tabId={"1"}>
          <div className="new-inn" id="zidniA">
            <div className="top-pop">
              <h4>Zidni Instructor Form</h4>

              <div className="mb-3">
                <label htmlFor="signupfullname" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupfullname"
                  name="signupfullname"
                  placeholder="Enter first name"
                  required=""
                  {...register("firstName", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.firstName?.message}</p>

              </div>
              <div className="mb-3">
                <label htmlFor="signupfullname" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupfullname"
                  name="signupfullname"
                  placeholder="Enter last name"
                  required=""
                  {...register("lastName", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.lastName?.message}</p>
              </div>
              <div className="mb-3">
                <label htmlFor="selectemai" className="form-label">
                  Email address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="selectemai"
                  name="selectemai"
                  placeholder="zidni@gmail.com"
                  required=""
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                <p className="mb-0 text-danger">{errors?.email?.message}</p>
              </div>

              <div className="mb-3">
                <label htmlFor="selectPhone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="selectPhone"
                  name="selectPhone"
                  placeholder="Enter phone number"
                  required=""
                  {...register("phone", {
                    pattern: {
                      value: /^\d{10}$/,
                      message: `Please fill valid phone no`,
                    },
                    required: "Phone number is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.phone?.message}</p>
              </div>

              <div className="mb-3">
                <label htmlFor="signfullname" className="form-label">
                  University
                </label>
                <select
                  id="select_country"
                  name="select Institution"
                  class="sort-by form-control replaced"
                  fdprocessedid="5kzl6i"
                  {...register("universityId", {
                    required: "Field is required",
                  })}
                >
                  <option value={""} selected="selected">
                    Select Institution
                  </option>
                  {allUniversity &&
                    allUniversity.map((item, key) => (
                      <option key={key} value={`${item?.id}_#_${item?.universityName}`}>
                        {item?.universityName}
                      </option>
                    ))}
                </select>
                <p className="mb-0 text-danger">
                  {errors?.universityId?.message}
                </p>
              </div>
            </div>
            <div className="bottom-pop">
              <button
                type="button"
                onClick={handleSubmit(handleData)}
                className="sent-reeq"
              >
                Next
              </button>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={"2"}>
          <div className="new-inn" id="zidniA">
            <div className="top-pop uplodbts">
              <h4>Zidni Instructor Form</h4>
              <div className="mb-3">
                <label htmlFor="signupprofession" className="form-label">
                  Id Card Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signupprofession"
                  name="signupprofession"
                  placeholder="Enter ID number"
                  {...register1("idNumber", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors1?.idNumber?.message}</p>
              </div>
              {allDocs &&
                allDocs.map((item, key) => {
                  return (
                    <div className="mb-3" key={key}>
                      <input
                        className="form-control"
                        type="file"
                        accept="image/*"
                        placeholder={item?.type}
                        id={item?.type}
                        {...register1(`${item?.id}`, {
                          required: "Field is required",
                          onChange: (e) => handleUploadDocs(e, item?.id),
                        })}
                      />
                      <label className="form-label nmn" htmlFor={item?.type}>
                        {allFile?.[`${item?.id}`]?.name
                          ? allFile?.[`${item?.id}`]?.name
                          : item?.type}
                      </label>
                      <p className="mb-0 text-danger">
                        {errors1?.[`${item?.id}`]?.message}
                      </p>
                    </div>
                  );
                })}
            </div>
            <div className="bottom-pop">
              <div className="btnss d-flex justify-content-between">
                {/* <button
                type="button"
                className="sent-reeq"
                onClick={handleSubmit1(handleInstructorForm)}
              >
                {loading == true ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Send Request"
                )}
              </button> */}
                <button
                  type="button"
                  className="back"
                  onClick={() => toggleTab("1")}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="next"
                  onClick={handleSubmit1(handleInstructorForm)}
                >
                  {loading == true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Send Request"
                  )}
                </button>
              </div>
            </div>
          </div>
        </TabPane>
        {/* {console.log("fhkshfk", activeTab)} */}
        <TabPane tabId={"4"}>
          <div className="new-inn" id="zidniA">
            <div className="top-pop uplodbts">
              <h4>Zidni Instructor Form</h4>
              {firstFormData &&
                firstFormData?.UserDocuments?.filter(e => e.isApproved == false)?.map((item, key) => {
                  return (
                    <>
                      {/* <div className="mb-3" key={key}>
                    <input
                      className="form-control"
                      type="file"
                      accept="image/*"
                      placeholder={item?.DocumentType?.type}
                      id={item?.DocumentType?.type}
                      {...register1(`${item?.DocumentType?.id}`, {
                        required: "Field is required",
                        onChange: (e) => handleUploadDocs(e, item?.DocumentType?.id),
                      })}
                    />
                    <label className="form-label nmn" htmlFor={item?.type}>
                      {allFile?.[`${item?.id}`]?.name
                        ? allFile?.[`${item?.id}`]?.name
                        : item?.type}
                    </label>
                    <p className="mb-0 text-danger">
                      {errors1?.[`${item?.id}`]?.message}
                    </p>
                  </div> */}

                      <div className="mb-3 d-block upload-img" key={key}>
                        <p>{item?.DocumentType?.type}</p>
                        <label htmlFor={`${item?.DocumentType?.type}-priv`} className="form-label">
                          <div className="updv">
                            {allFile[`${item?.DocumentType?.id}-preview`] ?
                              <img className="mainuploadimg " src={allFile[`${item?.DocumentType?.id}-preview`]} alt="" />
                              :
                              <img className="mainuploadimg " src={item?.imageUrl} alt="" />


                            }
                          </div>
                          <div type="button" className="btn btn-primary mt-2">
                            Reupload
                          </div>
                        </label>
                        <input
                          className="form-control "
                          type="file"
                          accept="image/*"
                          placeholder={item?.DocumentType?.type}
                          id={`${item?.DocumentType?.type}-priv`}
                          {...register3(`${item?.DocumentType?.id}`, {
                            required: "Field is required",
                            onChange: (e) => handleUploadDocs2(e, item?.DocumentType?.id),
                          })}
                        />


                        <p className="mb-0 text-danger">{errors2?.[`${item?.id}`]?.message ? errors2?.[`${item?.id}`]?.message : item?.remark}</p>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="bottom-pop">
              <div className="btnss d-flex justify-content-between">
                {/* <button
                type="button"
                className="sent-reeq"
                onClick={handleSubmit1(handleInstructorForm)}
              >
                {loading == true ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  "Send Request"
                )}
              </button> */}
                <button
                  type="button"
                  className="back"
                  onClick={() => toggleTab("1")}
                >
                  Back
                </button>
                <button
                  type="button"
                  className="next"
                  onClick={handleSubmit2(handleInstructorForm1)}
                >
                  {loading == true ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : (
                    "Send Request"
                  )}
                </button>
              </div>
            </div>
          </div>
        </TabPane>
        <TabPane tabId={"3"}>
          <div className="ssf" id="ssf">
            <h4>Try for instructor</h4>
            <div className="btm-baar">
              {firstFormData?.InstructorRequest == 0 ?
                <h5>Your request has been sent to admin wait for admin confirmation</h5> :
                firstFormData?.InstructorRequest == 1 ?
                  <h5>Your request has been approved please login</h5> :
                  <h5>Your request has been sent successfully</h5>
              }
              {checkStatus == false &&
                <div className="univ-block">
                  <ul className="univ">
                    <li className="univer">University:</li>
                    <li className="univ-name">{selectedUnivName}</li>
                  </ul>
                  <ul className="appli">
                    <li className="apl">Applied </li>
                    <li className="apl-date">
                      {allInsData?.createdAt?.split("T")?.[0]}
                    </li>
                  </ul>
                </div>
              }
              <button
                className="cancel-req"
                onClick={() => dispatch(setShowLoginPopup(false))}
              >
                Close
              </button>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </form>
  );
};

export default InstructorForm;
