import React, { useEffect, useState } from "react";
import { GetData, PostImageDataWithToken, callApi } from "../../api/ApiHelper";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useLocation } from "react-router-dom";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const CourseOutline = ({
  navigate,
  activeTab,
  allCourseData,
  setAllCourseData,
  toggleTab,
  coursePublic,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({ mode: "all" });

  const [category, setCategory] = useState([]);
  const [courseImg, setCourseImg] = useState(null);
  const [courseImgPrev, setCourseImgPrev] = useState(null);
  const [btnActive, setBtnActive] = useState(false)

  const location = useLocation();

  useEffect(() => {
    if (location.state?.courseId !== undefined || allCourseData.id !== undefined) {
      reset({
        courseName: allCourseData.courseName,
        courseImage: allCourseData.courseImage,
        courseDescription: allCourseData.courseDescription,
      });
      setCourseImg(allCourseData.courseImage);
      setCourseImgPrev(allCourseData.courseImage);
    }
  }, []);

  useEffect(() => {
    const apiUrl =
      APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "category/list";
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setCategory(response.data);
        if (
          location.state?.courseId !== undefined ||
          allCourseData.id !== null
        ) {
          reset({
            categoryId: allCourseData.categoryId,
          });
        }
      }
    });
  }, []);

  console.log("fhdshs", courseImg)

  const onSubmit = (data) => {
    if (courseImg !== null) {
      setBtnActive(true)
      console.log("Form Data:", data);
      let Data = new FormData();
      Data.append("courseName", data.courseName);
      Data.append("courseDescription", data.courseDescription);
      Data.append("categoryId", data.categoryId);
      Data.append("courseImage", courseImg);
      Data.append("isCoursePublic", coursePublic);

      if (location.state?.courseId !== undefined) {
        const apiUrl =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `course/update/${location.state.courseId}`;
        const multipart = true;

        callApi(apiUrl, { method: "PUT", data: Data, multipart }).then(
          (res) => {
            if (res.status == true) {
              sendSuccessInfo(res.message);
              toggleTab(activeTab + 1);
              setBtnActive(false)

            } else {
              sendErrorInfo(res.data.message);
              setBtnActive(false)
            }
          }
        );
      } else {
        const apiUrl =
          APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "course/create";
        const multipart = true;

        callApi(apiUrl, { method: "POST", data: Data, multipart }).then(
          (res) => {
            if (res.status == true) {
              setAllCourseData(res.data);
              sendSuccessInfo(res.message);
              toggleTab(activeTab + 1);
              setBtnActive(false)

            } else {
              sendErrorInfo(res.data.message);
              setBtnActive(false)
            }
          }
        );
      }
    } else {
      sendErrorInfo("Please Upload Image File");
    }
  };

  const handleUploadDocs = (e, key) => {
    if (!e.target.files[0]?.type.includes("image")) {
      sendErrorInfo("Please Upload Image File");
      return;
    }

    setCourseImg(e.target.files[0]);
    setCourseImgPrev(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4>let's find out what type of course you're making.</h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          <div className="mb-3">
            <label for="graphicdesign" className="form-label">
              Course title
            </label>
            <input
              type="text"
              className="form-control"
              id="graphicdesign"
              placeholder="e.g. learn graphic design from scratch"
              {...register("courseName", {
                required: "Course title is required",
              })}
            />
            {/* <div id="graphicdesign" className="form-text"></div> */}
            <p className="mb-0 text-danger">{errors?.courseName?.message}</p>
          </div>
          <div className="mb-3">
            <label className="select-label" for="pricede">
              Choose a category
            </label>
            <select
              id="pricede"
              className="form-select"
              aria-label="Default select example"
              {...register("categoryId", {
                required: "Category is required",
              })}
            >
              <option value={""} className="d-none">
                Choose a category
              </option>
              {category &&
                category.length > 0 &&
                category.map((cat, index) => (
                  <option value={cat.id} index={index}>
                    {cat.categoryName}
                  </option>
                ))}
            </select>
            <p className="mb-0 text-danger">{errors?.categoryId?.message}</p>
          </div>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Course description
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Description"
              {...register("courseDescription", {
                required: "Course description is required",
              })}
            ></textarea>
            <p className="mb-0 text-danger">
              {errors?.courseDescription?.message}
            </p>
          </div>

          <div className="mb-3 upload-img">
            <label for="formFile" className="form-label">
              <div className="updv">
                {courseImgPrev == null ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20 1V39M1 19.9999H39"
                      stroke="#C1C1C1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <img className="mainuploadimg " src={courseImgPrev} alt="" />
                )}
              </div>
              <div type="button" className="btn btn-primary">
                Course image
              </div>
            </label>
            <input
              className="form-control "
              type="file"
              id="formFile"
              accept="image/*"
              placeholder="Upload your event image here..."
              {...register("courseImage", {
                // required: "Course image is required",
                onChange: (e) => handleUploadDocs(e),
              })}
            />


            <p className="mb-0 text-danger">{errors?.courseImage?.message}</p>
          </div>
          {/* <div className="mb-3 upload-img">
            <label for="formFile" className="form-label">
              Course image
            </label>
            {location.state?.courseId !== undefined ? (
              <input
                className="form-control "
                type="file"
                id="formFile"
                accept="image/*"
                placeholder="Upload your course image here..."
                {...register("courseImage", {
                  // required: "Course image is required",
                  onChange: (e) => handleUploadDocs(e),
                })}
              />
            ) : (
              <input
                className="form-control "
                type="file"
                id="formFile"
                accept="image/*"
                placeholder="Upload your course image here..."
                {...register("courseImage", {
                  required: "Course image is required",
                  onChange: (e) => handleUploadDocs(e),
                })}
              />
            )}
            <p className="mb-0 text-danger">{errors?.courseImage?.message}</p>
          </div> */}
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 8 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"

                  >
                    {btnActive == true ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Next"
                    )}
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default CourseOutline;
