import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, TabContent, TabPane } from "reactstrap";
import LoginFrom from "./LoginFrom";
import RegistrationForm from "./RegistrationForm";
import InstructorForm from "./InstructorForm";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginPopup } from "../../store/actions/userActions";
import StudentInstructorFrom from "./StudentInstructorFrom";

const LoginModal = () => {
  const logintab = useSelector((state) => state.root.setActiveLoginTab);
  const [activeTab, setActiveTab] = useState(logintab);
  const dispatch = useDispatch();

  const toggleTab = (tab) => {
    if (activeTab != tab) {
      setActiveTab(tab);
    }
  };
  const closeModal = () => {
    dispatch(setShowLoginPopup(false));
  };
  return (
    <Modal
      isOpen={true}
      centered
      toggle={closeModal}
      className="modal-xl login-form"
    >
      <ModalBody className="">
        <div className="hero">
          <div className="form-banner">
            <div className="top-sec">
              <div onclick="login()" className="img">
                <img src={`${process.env.PUBLIC_URL}/assets/images/zidni.png`} alt="" />
              </div>
              <h3>Now is the time to start something</h3>
            </div>
            <div className="btn-sec">
              <button className="try-zidni" onClick={() => toggleTab("3")}>
                Try for Zidni instructor
              </button>
              {activeTab == "1" ? (
                <p>
                  Not a member yet?{" "}
                  <button id="register" onClick={() => toggleTab("2")}>
                    register now
                  </button>
                </p>
              ) : (
                <p>
                  Are you a member?{" "}
                  <button id="register" onClick={() => toggleTab("1")}>
                    Log in now
                  </button>
                </p>
              )}
            </div>
          </div>
          <div className="form-box">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <LoginFrom />
              </TabPane>
              <TabPane tabId="2">
                <RegistrationForm />
              </TabPane>
              <TabPane tabId="3">
                <InstructorForm />
              </TabPane>
              <TabPane tabId="4">
                <StudentInstructorFrom />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
