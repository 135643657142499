import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import { TabContent, TabPane } from "reactstrap";
import CourseType from "../../components/createCourse/CourseType";
import CourseOutline from "../../components/createCourse/CourseOutline";
import CourseAssessment from "../../components/createCourse/CourseAssessment";
import LearningCreteria from "../../components/createCourse/LearningCreteria";
import CourseResources from "../../components/createCourse/CourseResources";
import CourseCreditHouors from "../../components/createCourse/CourseCreditHouors";
import CoursePricing from "../../components/createCourse/CoursePricing";
import PublishCourse from "../../components/createCourse/PublishCourse";
import { useLocation, useNavigate } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { sendErrorInfo } from "../../utility/helperFunctions";


const CreateCourse = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [allCourseData, setAllCourseData] = useState({});
  const [coursePublic, setCoursePublic] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (location.state?.courseId !== undefined) {
      getCourseDetail();
    }
  }, [])


  const getCourseDetail = () => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/${location?.state?.courseId ? location?.state?.courseId : allCourseData.id}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setAllCourseData(response.data);
      }
    });
  };

  console.log("allCourseData", allCourseData);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const HandleCourseTab = (tab) => {
    if (allCourseData.id !== undefined) {
      toggleTab(tab)
    } else {
      sendErrorInfo('First create course than go next tab')
    }
  }

  return (
    <>
      <Header />

      <section className="tab-section">
        <div className="container">
          {activeTab === 1 && (
            <div className="maincor">
              <div className="new-cont nnn">
                <h4>Courses</h4>
                <a
                  href="javascript:void(0)"
                  onClick={() => navigate(-1)}
                  className="close-btn"
                >
                  Close
                </a>
              </div>
            </div>
          )}
          <div className={activeTab != 1  ? "view-tabs-content" : '' }>
            {activeTab != 1 && (
              <ul className="tab">
                <li
                  className={activeTab === 2 ? "active tablinks" : "tablinks"}
                  onClick={() => toggleTab(2)}
                >
                  Course Outlines
                </li>
                <li
                  className={activeTab === 3 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(3)}
                >
                  Course assessments{" "}
                </li>
                <li
                  className={activeTab === 4 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(4)}
                >
                  Learning criteria{" "}
                </li>
                <li
                  className={activeTab === 5 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(5)}
                >
                  Resources{" "}
                </li>
                <li
                  className={activeTab === 6 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(6)}
                >
                  Certification & credit hours
                </li>
                <li
                  className={activeTab === 7 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(7)}
                >
                  Pricing{" "}
                </li>
                <li
                  className={activeTab === 8 ? "active tablinks" : "tablinks"}
                  onClick={() => HandleCourseTab(8)}
                >
                  Publish the course{" "}
                </li>
              </ul>
            )}
            <div className={activeTab === 1 ? "pt-0 tab-iner" : "tab-iner"}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <CourseType toggleTab={toggleTab} allCourseData={allCourseData} setCoursePublic={setCoursePublic} />
                </TabPane>
                <TabPane tabId={2}>
                  {activeTab == 2 &&
                    <CourseOutline
                      navigate={navigate}
                      coursePublic={coursePublic}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={3}>
                  {activeTab == 3 &&
                    <CourseAssessment
                      navigate={navigate}
                      activeTab={activeTab}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={4}>
                  {activeTab == 4 &&
                    <LearningCreteria
                      navigate={navigate}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={5}>
                  {activeTab == 5 &&
                    <CourseResources
                      navigate={navigate}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={6}>
                  {activeTab == 6 &&
                    <CourseCreditHouors
                      navigate={navigate}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={7}>
                  {activeTab == 7 &&
                    <CoursePricing
                      navigate={navigate}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                      toggleTab={toggleTab}
                    />
                  }
                </TabPane>
                <TabPane tabId={8}>
                  {activeTab == 8 &&

                    <PublishCourse
                      navigate={navigate}
                      activeTab={activeTab}
                      getCourseDetail={getCourseDetail}
                      setAllCourseData={setAllCourseData}
                      allCourseData={allCourseData}
                    />
                  }
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default CreateCourse;
