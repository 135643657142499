import { Modal } from 'reactstrap';
import { useForm } from "react-hook-form";
import { useState } from 'react';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
import { callApi } from '../../api/ApiHelper';
import { v4 as uuidv4 } from 'uuid';
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";


const ForgetPassModal = (props) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const randomDeviceId = uuidv4();

    const handleReset = () => {
        reset({ email: "" })
        props?.toggleForgetPassModal()
        setIsLoading(false)
    }

    const onSubmit = (data) => {
        let deviceId = "";
        if (localStorage.getItem('deviceId')) {
            deviceId = localStorage.getItem('deviceId');
        } else {
            deviceId = "2024_" + randomDeviceId;
        }

        const userCheckSubmitData = {
            email: data?.email
        }
        const submitData = {
            email: data?.email,
            deviceId: deviceId,
            deviceToken: randomDeviceId.slice(0, 10),
        }

        const apiUrl = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "get-user";
        const apiUrl2 = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "forget-reset";

        setIsLoading(true);
        callApi(apiUrl, { method: "POST", data: userCheckSubmitData }).then((response) => {
            if (response.status == true) {
                callApi(apiUrl2, { method: "POST", data: submitData }).then((res) => {
                    if (res.status == true) {
                        handleReset();
                        props?.toggleSetNewPassModal();
                        props?.setEmail(data?.email);
                        sendSuccessMessage(res);
                    } else {
                        sendErrorMessage(res)
                        setIsLoading(false);
                    }
                });
            } else {
                sendErrorMessage(response)
                setIsLoading(false);
            }
        });
    }


    return (
        <>
            <Modal isOpen={props.showForgetPassModal}
            // toggle={handleReset}
            >
                <div >
                    <div className="d-flex justify-content-end p-1">
                        <button type="button" className="btn-close" onClick={handleReset} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="twm-tabs-style-2">
                                <div className="mb-3 text-center">
                                    <img style={{ width: "60px" }} src={`${process.env.PUBLIC_URL}/assets/images/otp.png`} alt="" />
                                </div>
                                <h4 className="modal-title text-center mb-3" id="OTP_popupLabel">
                                    Forget Password
                                </h4>
                                <p className="text-center">
                                    Enter your email address
                                    <br className="d-none d-md-block" />
                                </p>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <input
                                                name="email"
                                                type="text"
                                                className="form-control "
                                                placeholder="Email"
                                                {...register("email", {
                                                    required: "Please fill in your email",
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                                        message: "Invalid email address",
                                                    },
                                                    validate: (value) => value === value.toLowerCase() || 'Email must be in lowercase',
                                                })}
                                            />
                                            <p className="text-danger m-0">{errors?.email?.message}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center p-2">
                                    {isLoading === true ? (
                                        <div className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                            <span className="spinner-border spinner-border-sm mx-1"></span>
                                        </div>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg btn-block"
                                            tabIndex={4}
                                        >
                                            <span className='fw-bold'>Submit</span>
                                        </button>
                                    )
                                    }
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </Modal >
        </>
    );
}

export default ForgetPassModal;







