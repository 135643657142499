import React from 'react'
import { noImage } from '../../pages/live/utils/helpers'
import { Link, useNavigate } from 'react-router-dom'

const LiveLectureSection = ({liveLecture}) => {
  const navigate = useNavigate()
  return (
    <>
      {/* live-lecture start */}
      {liveLecture && liveLecture?.length > 0 && 
      <section className="live-lecture">
        <div className="heading">
          <h2>Explore up-coming live lecture </h2>
        </div>
        <div className="container">
          {liveLecture.map((item,key) =>{
            if (key <= 1 ) {
              return(

          <div className="card round cursor-pointer" key={key} onClick={()=>navigate(`/live-webinar/${item.channelName}`)}>
           
            <div className="img round">
              <img src={item?.liveImage ? item?.liveImage : noImage} alt="" />
            </div>
            <div className="content">
              <div className="heading">
                <h5>
                  {item?.channelName}
                </h5>
                <p>{item?.liveDescription}</p>
                <div className="date">{item?.createdAt?.split('T')?.[0]}</div>
              </div>
              <div className="detail-cus">
                <div className="img">
                  <img src={item?.userDetail?.Profile?.profilePhoto} alt="" />
                </div>
                <div className="name">
                  <h6>{item?.userDetail?.Profile?.firstName} {item?.userDetail?.Profile?.lastName}</h6>
                  <p className="speaker">Speaker</p>
                </div>
              </div>
            </div>
          </div>
              )
              
            }

          })}
          {/* <div className="card round">
            <div className="img round">
              <img src={`${process.env.PUBLIC_URL}/assets/images/Rectangle 23.png`} alt="" />
            </div>
            <div className="content">
              <div className="heading">
                <h5>
                  smoking cessation &amp; the role of nicotine replacement therapy
                </h5>
                <div className="date">20 September 2023</div>
              </div>
              <div className="detail-cus">
                <div className="img">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/Ellipse 7.png`} alt="" />
                </div>
                <div className="name">
                  <h6>Madeline Gerhold</h6>
                  <p className="speaker">Speaker</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      
      }
      {/* live-lecture end */}
    </>

  )
}

export default LiveLectureSection