import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { sendSuccessMessage } from '../../utility/helperFunctions';

function CheckoutMobile() {
  const location = useLocation()
  const checkoutId = location?.state?.id;
  const [loadScript, setLoadScript] = useState(true);

  useEffect(() => {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ res: "api Called" })
    );
  }, []);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
  }, []);


  const handleMessage = (event) => {
    if (event && event?.data) {
      const data = event?.data;
      const checkoutId = data?.checkoutId ? data?.checkoutId : data?.res;
      const token = data?.token ?? ""
      if (checkoutId) {
        Cookies.set("zidniCheckoutToken", token);

        const script = document.createElement("script");

        script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
        script.async = true;

        document.body.appendChild(script);
        return () => {
          if (loadScript) {
            document.body.removeChild(script);
            setLoadScript(false);
          }
        };
      }
    }
  };

  // useEffect(() => {
  //   if (location?.state?.id) {

  //     const script = document.createElement("script");

  //     script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
  //     script.async = true;

  //     document.body.appendChild(script);
  //     return () => {
  //       if (loadScript) {
  //         document.body.removeChild(script);
  //         setLoadScript(false);
  //       }
  //     };
  //   }
  // }, []);
  return (
    <form action={`${window.location.origin}/success`} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
  )
}
// http://192.168.1.95:3000/zidni

export default CheckoutMobile