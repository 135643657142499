import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi, cancelRequest } from "../../api/ApiHelper";
import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const CourseAssessment = ({
  navigate,
  activeTab,
  allCourseData,
  setAllCourseData,
  toggleTab,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({ mode: "all" });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({ mode: "all" });

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    reset: reset3,
  } = useForm({ mode: "all" });

  const [showAssessmentInput, setShowAssessmentInput] = useState(true);
  const [allAssessmentList, setAllAssessmentList] = useState([]);
  const [assessmentData, setAssessmentData] = useState({});
  const [showLectureInput, setShowLectureInput] = useState(false);
  const [showVideoInput, setShowVideoInput] = useState(false);
  const [showQuizeInput, setShowQuizeInput] = useState(false);
  const [showQuestionInput, setShowQuestionInput] = useState(false);
  const [LectureData, setLectureData] = useState({});
  const [videoData, setVideoData] = useState({});
  const [quizeData, setQuizeData] = useState({});
  const [questionData, setQuestionData] = useState({});
  const [progress, setProgress] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [activeInputIndex, setActiveInputIndex] = useState(null);
  const location = useLocation();

  useEffect(() => {
    console.log("location", location.state?.courseId);
    if (location.state?.courseId !== undefined || allCourseData.id !== undefined) {
      if (allCourseData.Assessments !== undefined) {
        setAllAssessmentList(allCourseData.Assessments);
      }
    }
  }, []);

  useEffect(() => {
    if (allAssessmentList?.length > 0) {
      setShowAssessmentInput(false);
    } else {
      setShowAssessmentInput(true);
    }
  }, [allAssessmentList]);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    toggleTab(activeTab + 1);
    setAllCourseData({
      ...allCourseData,
      courseAssessment: {
        ...data,
      },
    });
  };

  const handleEditAssessment = (item) => {
    // console.log("item", item);
    reset({
      lessonName: item?.lessonName,
      lessonDescription: item?.lessonDescription,
    });
    setShowAssessmentInput(true);
    setAssessmentData(item);
  };

  const handleAddAssessment = (data) => {
    if (assessmentData?.lessonDescription) {
      let Data = {
        courseId: assessmentData.courseId,
        lessonDescription: data?.lessonDescription,
        lessonName: data.lessonName,
        lessonIndex: assessmentData?.lessonIndex,
      };
      const apiUrl =
        APIURL.COURSE_URL +
        APIENDPOINTS.COURSE_END_POINT +
        `assessment/update/${assessmentData.id}`;
      callApi(apiUrl, { method: "PUT", data: Data }).then((response) => {
        if (response?.status === true) {
          sendSuccessInfo(response?.message);
          getCourseDetail();
          setShowAssessmentInput(false);
          reset({});
          setAssessmentData({});
        } else {
          sendErrorInfo(response?.data?.message);
        }
      });
    } else {
      const length =
        allAssessmentList?.length == 0 ? 0 : allAssessmentList?.length - 1 + 1;
      let Data = {
        courseId: allCourseData.id,
        lessonDescription: data?.lessonDescription,
        lessonName: data.lessonName,
        lessonIndex: length,
      };
      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "assessment/create";
      callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response?.message);
          setShowAssessmentInput(false);
          reset({});
          getCourseDetail();
        } else {
          sendErrorInfo(response?.data?.message);
        }
      });
    }
  };
  const getCourseDetail = () => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/${allCourseData?.id}`;
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setAllCourseData(response?.data);
        setAllAssessmentList(response?.data?.Assessments);
      }
    });
  };

  const handleEditLecture = (lec, item) => {
    reset1({
      lecture: lec?.curriculumPath,
    });
    setShowLectureInput(true);
    setAssessmentData(item);
    setLectureData(lec);
  };

  const HandelAddLecture = (data) => {
    if (LectureData?.curriculumType) {
      let Data = new FormData();
      Data.append("curriculumIndex", LectureData.curriculumIndex);
      Data.append("curriculumType", LectureData.curriculumType);
      Data.append("curriculumPath", data.lecture);
      Data.append("assessmentId", LectureData.assessmentId);
      const apiUrl =
        APIURL.COURSE_URL +
        APIENDPOINTS.COURSE_END_POINT +
        `curriculum/update/${LectureData.id}`;
      callApi(apiUrl, { method: "PUT", data: Data, multipart: true }).then(
        (response) => {
          if (response.status === true) {
            sendSuccessInfo(response?.message);
            setShowLectureInput(false);
            reset1({});
            getCourseDetail();
            setAssessmentData(null);
          } else {
            sendErrorInfo(response?.data?.message);
          }
        }
      );
    } else {
      let Data = new FormData();

      Data.append(
        "curriculumIndex",
        assessmentData.Curriculums.length > 0
          ? assessmentData.Curriculums.length - 1 + 1
          : 0
      );
      Data.append("curriculumType", "1");
      Data.append("curriculumPath", data.lecture);
      Data.append("assessmentId", assessmentData.id);

      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `curriculum/create`;
      callApi(apiUrl, { method: "POST", data: Data, multipart: true }).then(
        (response) => {
          if (response.status === true) {
            sendSuccessInfo(response?.message);
            setShowLectureInput(false);
            setAssessmentData(null);

            reset1({});
            getCourseDetail();
          } else {
            sendErrorInfo(response?.data?.message);
          }
        }
      );
    }
  };

  const handleEditVideo = async () => { };
  const handleAddVideo = (e, item, fileName) => {
    console.log("e", e.target.files[0], item);
    if (!e.target.files[0].type.includes("video")) {
      console.log("Please Upload Image File");
      return;
    }
    setShowVideoInput(true);
    let Data = new FormData();

    Data.append(
      "curriculumIndex",
      item.Curriculums.length > 0 ? item.Curriculums.length - 1 + 1 : 0
    );
    Data.append("curriculumType", 2);
    Data.append("curriculumPath", e.target.files[0]);
    Data.append("assessmentId", item.id);
    Data.append("curriculumName", fileName)

    const apiUrl =
      APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `curriculum/create`;

    callApi(apiUrl, {
      method: "POST",
      data: Data,
      multipart: true,
      progressEvent: (value) => {
        const percentCompleted = Math.round((value.loaded * 100) / value.total);
        setProgress(percentCompleted);
      },
    }).then((response) => {
      if (response.status === true) {
        sendSuccessInfo(response?.message);
        setShowVideoInput(false);
        setProgress(0);
        getCourseDetail();
        setAssessmentData(null);
      } else {
        sendErrorInfo(response?.message);
        setShowVideoInput(false);
        setProgress(0);
      }
    });
  };

  const cancelUpload = () => {
    cancelRequest();
  };

  const handleEditQuiz = (qui) => {
    reset2({
      quizName: qui.Quiz.quizName,
    });

    setShowQuizeInput(true);
    setQuizeData(qui);
  };

  const handleAddQuiz = (data) => {
    if (quizeData?.Quiz?.quizName) {
      let Data1 = {
        curriculumId: quizeData.Quiz.curriculumId,
        quizName: data.quizName,
      };

      const apiUrl1 =
        APIURL.COURSE_URL +
        APIENDPOINTS.COURSE_END_POINT +
        `quiz/update/${quizeData.Quiz.id}`;
      callApi(apiUrl1, { method: "PUT", data: Data1 }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response?.message);
          setShowQuizeInput(false);
          reset2({});
          getCourseDetail();
          setAssessmentData(null);
        } else {
          sendErrorInfo(response?.data?.message);
        }
      });
    } else {
      let Data = new FormData();

      Data.append(
        "curriculumIndex",
        assessmentData.Curriculums.length > 0
          ? assessmentData.Curriculums.length - 1 + 1
          : 0
      );
      Data.append("curriculumType", 3);
      Data.append("curriculumPath", "");
      Data.append("assessmentId", assessmentData.id);

      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `curriculum/create`;
      callApi(apiUrl, { method: "POST", data: Data, multipart: true }).then(
        (response) => {
          if (response.status === true) {
            let Data1 = {
              curriculumId: response?.data.id,
              quizName: data.quizName,
            };

            const apiUrl1 =
              APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `quiz/create`;
            callApi(apiUrl1, { method: "POST", data: Data1 }).then(
              (response) => {
                if (response.status === true) {
                  sendSuccessInfo(response?.message);
                  setShowQuizeInput(false);
                  reset2({});
                  getCourseDetail();
                  setAssessmentData(null);
                } else {
                  sendErrorInfo(response?.data?.message);
                }
              }
            );
          } else {
            sendErrorInfo(response?.data?.message);
          }
        }
      );
    }
  };

  const handleEditQuestion = (ques) => {
    reset3({
      question: ques.question,
      correctAnswer: ques.correctAnswer,
      options1: JSON.parse(ques.options)?.[0],
      options2: JSON.parse(ques.options)?.[1],
      options3: JSON.parse(ques.options)?.[2],
      options4: JSON.parse(ques.options)?.[3],
    });

    setCorrectAnswer(ques.correctAnswer);
    setShowQuestionInput(true);
    setQuestionData(ques);
  };

  const handleAddQuestion = (data) => {
    if (questionData?.question) {
      let Data = {
        question: data.question,
        options: [data.options1, data.options2, data.options3, data.options4],
        quizId: questionData.quizId,
      };

      Data["correctAnswer"] = data.correctAnswer;
      Data["options"] = JSON.stringify(Data.options);
      console.log("data", Data);
      const apiUrl1 =
        APIURL.COURSE_URL +
        APIENDPOINTS.COURSE_END_POINT +
        `question/update/${questionData.id}`;
      callApi(apiUrl1, { method: "PUT", data: Data }).then((response) => {
        if (response?.status === true) {
          sendSuccessInfo(response?.message);
          setShowQuestionInput(false);
          reset3({});
          getCourseDetail();
        } else {
          sendErrorInfo(response?.data?.message);
        }
      });
    } else {
      let Data = {
        question: data.question,
        options: [data.options1, data.options2, data.options3, data.options4],
        quizId: quizeData.Quiz.id,
      };

      Data["correctAnswer"] = data.correctAnswer;
      Data["options"] = JSON.stringify(Data.options);
      console.log("data", Data);
      const apiUrl1 =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + `question/create`;
      callApi(apiUrl1, { method: "POST", data: Data }).then((response) => {
        if (response.status === true) {
          sendSuccessInfo(response?.message);
          setShowQuestionInput(false);
          reset3({});
          getCourseDetail();
        } else {
          sendErrorInfo(response?.data?.message);
        }
      });
    }
  };

  const handleDeleteAssessment = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the assessmnet!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `assessment/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response?.status === true) {
            sendSuccessInfo(response?.message);
            getCourseDetail();
            setAssessmentData(null);
          } else {
            sendErrorInfo(response?.data?.message);
          }
        });
      }
    });
  };

  const handleDeleteLecture = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `curriculum/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response?.status === true) {
            sendSuccessInfo(response?.message);
            getCourseDetail();
          } else {
            sendErrorInfo(response?.data?.message);
          }
        });
      }
    });
  };

  const handleDeleteQuiz = (id, quizId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `curriculum/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response?.status === true) {
            const apiUrl2 =
              APIURL.COURSE_URL +
              APIENDPOINTS.COURSE_END_POINT +
              `quiz/delete/${quizId}`;
            callApi(apiUrl1, { method: "DELETE" }).then((response) => {
              if (response?.status === true) {
                sendSuccessInfo(response?.message);
                getCourseDetail();
              } else {
                sendErrorInfo(response?.data?.message);
              }
            });
          } else {
            sendErrorInfo(response?.data?.message);
          }
        });
      }
    });
  };

  const handleDeleteQuestion = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this question!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `question/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response?.status === true) {
            sendSuccessInfo(response?.message);
            getCourseDetail();
          } else {
            sendErrorInfo(response?.data?.message);
          }
        });
      }
    });
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4>
          Gather your course by creating sections, lectures and practice
          (quizzes and assignments).
        </h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        {allAssessmentList &&
          allAssessmentList.sort((a, b) => a.lessonIndex - b.lessonIndex).map((item, key) => (
            <div className="top-box mb-3" key={key}>
              <div className="into-block newcresetup">
                <div className="les">
                  <button>Lesson:{key + 1}</button>
                  <span className="inro py-1">{item?.lessonName}</span>
                </div>
                <div className="edit-btn">
                  <div
                    className="ed"
                    onClick={() => {
                      handleEditAssessment(item);
                    }}
                  >
                    Edit
                  </div>
                  <div
                    className="dele"
                    onClick={() => handleDeleteAssessment(item?.id)}
                  >
                    Delete
                  </div>

                  <button
                    type="submit"
                    class="btn btn-success"
                    onClick={() => {
                      reset({
                        lessonName: "",
                        lessonDescription: "",
                      });
                      setShowAssessmentInput(true);
                      setAssessmentData(null);
                    }}
                  >
                    <img src="./assets/images/pluswhite.svg" alt="" /> Add New
                    Lesson
                  </button>
                </div>
              </div>
              <div className="lectu">
                <span
                  className="lec"
                  onClick={() => {
                    setShowLectureInput(true);
                    setAssessmentData(item);
                    setActiveInputIndex(key);
                    setLectureData({});
                    reset1({
                      lecture: "",
                    });
                  }}
                >
                  <img src="./assets/images/plus.svg" alt="" />{" "}
                  <span>Lecture</span>
                </span>
                <span className="vid">
                  <img src="./assets/images/playnew.svg" alt="" />{" "}
                  <label className=" cursor-pointer" htmlFor={`formFile${key}`} >
                    Add video
                  </label>
                  <input
                    className="form-control d-none"
                    type="file"
                    id={`formFile${key}`}
                    accept="video/*"
                    onChange={(e) => {
                      const fileName = e.target.files[0].name;
                      handleAddVideo(e, item, fileName);
                      setActiveInputIndex(key);
                    }}
                  />
                </span>
                <span
                  className="qui"
                  onClick={() => {
                    setShowQuizeInput(true);
                    setAssessmentData(item);
                    setActiveInputIndex(key);
                    setQuizeData({});
                    reset2({
                      quizName: "",
                    });
                  }}
                >
                  <img src="./assets/images/newbook.svg" alt="" />{" "}
                  <span>Add Quiz</span>
                </span>
              </div>
              <div>
                {/* lacturebox start */}
                <div className="addlacturebox">
                  {item.Curriculums.length > 0 &&
                    item.Curriculums.map((lec, key1) => {
                      if (lec.curriculumType == 1) {
                        return (
                          <div
                            className="d-flex align-items-center m-3 p-2 bg-secondary justify-content-between nsad"
                            key={key1}
                          >
                            <span className="position-relative ps-3 py-2">
                              <img
                                className="rgtim"
                                src="./assets/images/right-icon.svg"
                                alt=""
                              />{" "}
                              {lec.curriculumPath}
                            </span>{" "}
                            <div className="d-flex align-items-start justify-content-center ms-1 btnstars">
                              <button
                                className="btn btn-success"
                                onClick={() => {
                                  handleEditLecture(lec, item);
                                  setActiveInputIndex(key);
                                }}
                              >
                                Edit
                              </button>{" "}
                              <button
                                className="btn btn-danger ms-2"
                                onClick={() => {
                                  handleDeleteLecture(lec.id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {showLectureInput && activeInputIndex == key && (
                    <div className="my-3 px-3">
                      <label for="addLacture" className="form-label">
                        New Lecture:
                      </label>
                      <div className="d-flex gap-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addLacture"
                          aria-describedby="lacturehelp"
                          {...register1("lecture", {
                            required: "Field is required",
                          })}
                          placeholder="Enter title"
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors1?.lecture?.message}
                      </p>
                      <div className="mt-3">
                        <button
                          type="button"
                          onClick={handleSubmit1(HandelAddLecture)}
                          class="btn btn-success"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowLectureInput(false)}
                          class="btn btn-danger ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {/* lacture box end */}

                {/* add video start */}
                <div className="addlacturevideo">
                  {item.Curriculums.length > 0 &&
                    item.Curriculums.map((vid, key1) => {
                      if (vid.curriculumType == 2) {
                        return (
                          <div
                            key={key1}
                            className="d-flex align-items-center m-3 p-2 bg-secondary justify-content-between"
                          >
                            <span className="d-flex align-items-center">
                              <video
                                src={vid?.curriculumPath}
                                height={"100%"}
                                width={"100px"}
                              />
                              <span className="mx-2">{vid?.curriculumName}</span>
                            </span>{" "}
                            <button
                              className="btn btn-danger ms-2"
                              onClick={() => {
                                handleDeleteLecture(vid.id);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        );
                      }
                    })}


                  {/* video progressbar start */}
                  {showVideoInput && activeInputIndex == key && (
                    <div className="card-body p-2 p-md-4 card border-0">
                      <p className="text-muted" style={{ fontSize: '13px' }}>Uploading...</p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progress}%` }}
                        >
                          {progress}%
                        </div>
                      </div>
                      <div className="text-end mt-1">
                        <button
                          className="btn btn-danger"
                          style={{ width: "100px" }}
                          onClick={cancelUpload}
                        >
                          Cancel
                        </button>
                      </div>

                    </div>

                  )}
                  {/* video progressbar end */}
                </div>
                {/* add video end */}

                {/* add quiz start */}
                <div className="addlacturebox">
                  {item.Curriculums.length > 0 &&
                    item.Curriculums.map((qui, key1) => {
                      if (qui.curriculumType == 3) {
                        return (
                          <div key={key1}>
                            <div className="d-flex align-items-start m-3 p-2 bg-secondary justify-content-between nsad">
                              <span className="d-flex align-items-start ps-3 py-2 position-relative">
                                <img
                                  className="rgtim"
                                  src="./assets/images/right-icon.svg"
                                  alt=""
                                />{" "}
                                {qui.Quiz.quizName}
                              </span>{" "}
                              <div className="d-flex align-items-start justify-content-center ms-1 btnstars">
                                <button
                                  className="btn btn-success"
                                  onClick={() => {
                                    handleEditQuiz(qui);
                                    setActiveInputIndex(key);
                                  }}
                                >
                                  Edit
                                </button>{" "}
                                <button
                                  className="btn btn-danger ms-2"
                                  onClick={() => {
                                    handleDeleteQuiz(qui.id, qui.Quiz.id);
                                  }}
                                >
                                  Delete
                                </button>
                                <button
                                  className=" ms-2 btn btn-success"
                                  onClick={() => {
                                    setShowQuestionInput(true);
                                    setQuizeData(qui);
                                    setActiveInputIndex(key);
                                    setCorrectAnswer(null);
                                    setQuestionData({});
                                    reset3({
                                      correctAnswer: "",
                                      options4: "",
                                      options3: "",
                                      options2: "",
                                      options1: "",
                                      question: "",
                                    });
                                  }}
                                >
                                  Add question
                                </button>{" "}
                              </div>
                            </div>
                            {qui.Quiz.Questions.length > 0 &&
                              qui.Quiz.Questions.map((question, key2) => (
                                <div key={key2}>
                                  <div className="d-flex align-items-center m-3 p-2 bg-secondary justify-content-between">
                                    <span className="position-relative ps-3 py-2">
                                      <img
                                        className="rgtim"
                                        src="./assets/images/right-icon.svg"
                                        alt=""
                                      />{" "}
                                      {question.question}
                                    </span>
                                    <div className="d-flex align-items-start justify-content-center ms-1 btnstars">
                                      <button
                                        className="btn btn-success"
                                        onClick={() => {
                                          handleEditQuestion(question);
                                          setActiveInputIndex(key);
                                        }}
                                      >
                                        Edit
                                      </button>
                                      <button
                                        className="btn btn-danger ms-2"
                                        onClick={() => {
                                          handleDeleteQuestion(question.id);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                  <div className="my-3 px-3">
                                    <div className="my-3 d-flex  nsad">
                                      <span>Option: </span>
                                      <ul class="options d-flex gap-4 ms-4">
                                        {JSON.parse(question.options).map(
                                          (option, key5) => (
                                            <li
                                              class="option form-check ps-0 d-flex gap-2"
                                              key={key5}
                                            >
                                              <input
                                                className="form-check-input ms-1"
                                                type="radio"
                                                id={`optionA${option}-${key5}`}
                                                name={`answer${option}-${key5}`}
                                                value={key5}
                                                disabled={true}
                                                checked={
                                                  key5 == question.correctAnswer
                                                    ? true
                                                    : false
                                                }
                                              />
                                              <label
                                                htmlFor={`optionA${option}-${key5}`}
                                              >
                                                {option}
                                              </label>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        );
                      }
                    })}
                  {showQuizeInput && activeInputIndex == key && (
                    <div className="my-3 px-3">
                      <label for="addLacture" className="form-label">
                        Add Quiz:
                      </label>
                      <div className="d-flex gap-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addLacture"
                          aria-describedby="lacturehelp"
                          placeholder="Quiz title"
                          {...register2("quizName", {
                            required: "Field is required",
                          })}
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors2?.quizName?.message}
                      </p>
                      <div className="mt-3">
                        <button
                          type="button"
                          class="btn btn-success"
                          onClick={handleSubmit2(handleAddQuiz)}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowQuizeInput(false)}
                          class="btn btn-danger ms-2"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                  {showQuestionInput && activeInputIndex == key && (
                    <div className="my-3 px-3">
                      <label for="addQuiz" className="form-label">
                        Add question:
                      </label>
                      <div className="d-flex gap-3 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="addQuiz"
                          aria-describedby="lacturehelp"
                          placeholder="Add question"
                          {...register3("question", {
                            required: "Field is required",
                          })}
                        />
                        {/* <button type="submit" class="btn btn-success">
                        <img src="./assets/images/pluswhite.svg" alt="" />
                      </button>
                      <button type="submit" class="btn btn-danger">
                        <img src="./assets/images/trash.svg" alt="" />
                      </button> */}
                      </div>
                      <p className="mb-0 text-danger">
                        {errors3?.question?.message}
                      </p>

                      <div className="mb-3 d-flex align-items-center gap-3">
                        <label htmlFor="addQuizA">A</label>
                        <input
                          type="text"
                          className="form-control"
                          id="addQuizA"
                          aria-describedby="lacturehelp"
                          placeholder="Option A"
                          {...register3("options1", {
                            required: "Field is required",
                          })}
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors3?.options1?.message}
                      </p>
                      <div className="mb-3 d-flex align-items-center gap-3">
                        <label htmlFor="addQuizB">B</label>
                        <input
                          type="text"
                          className="form-control"
                          id="addQuizB"
                          aria-describedby="lacturehelp"
                          placeholder="Option B"
                          {...register3("options2", {
                            required: "Field is required",
                          })}
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors3?.options2?.message}
                      </p>
                      <div className="mb-3 d-flex align-items-center gap-3">
                        <label htmlFor="addQuizC">C</label>
                        <input
                          type="text"
                          className="form-control"
                          id="addQuizC"
                          aria-describedby="lacturehelp"
                          placeholder="Option C"
                          {...register3("options3", {
                            required: "Field is required",
                          })}
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors3?.options3?.message}
                      </p>
                      <div className="mb-3 d-flex align-items-center gap-3">
                        <label htmlFor="addQuizD">D</label>
                        <input
                          type="text"
                          className="form-control"
                          id="addQuizD"
                          aria-describedby="lacturehelp"
                          placeholder="Option D"
                          {...register3("options4", {
                            required: "Field is required",
                          })}
                        />
                      </div>
                      <p className="mb-0 text-danger">
                        {errors3?.options4?.message}
                      </p>
                      <div className="my-3 d-flex">
                        <span>Correct Answer : </span>
                        <ul class="options d-flex  gap-2 ms-2">
                          <li class="option form-check ps-0 d-flex gap-2">
                            <input
                              className="form-check-input ms-1 mt-0"
                              type="radio"
                              id="optionA"
                              name="answer"
                              defaultChecked={correctAnswer == 0 ? true : false}
                              value={0}
                              {...register3("correctAnswer", {
                                required: "Field is required",
                              })}
                            />
                            <label for="optionA"> A</label>
                          </li>
                          <li class="option form-check ps-0 d-flex gap-2">
                            <input
                              className="form-check-input ms-1 mt-0"
                              type="radio"
                              id="optionB"
                              name="answer"
                              value={1}
                              defaultChecked={correctAnswer == 1 ? true : false}
                              {...register3("correctAnswer", {
                                required: "Field is required",
                              })}
                            />
                            <label for="optionB">B</label>
                          </li>
                          <li class="option form-check ps-0 d-flex gap-2">
                            <input
                              className="form-check-input ms-1 mt-0"
                              type="radio"
                              id="optionC"
                              name="answer"
                              value={2}
                              defaultChecked={correctAnswer == 2 ? true : false}
                              {...register3("correctAnswer", {
                                required: "Field is required",
                              })}
                            />
                            <label for="optionC">C</label>
                          </li>
                          <li class="option form-check ps-0 d-flex gap-2">
                            <input
                              className="form-check-input ms-1 mt-0"
                              type="radio"
                              id="optionD"
                              name="answer"
                              defaultChecked={correctAnswer == 3 ? true : false}
                              value={3}
                              {...register3("correctAnswer", {
                                required: "Field is required",
                              })}
                            />
                            <label for="optionD"> D</label>
                          </li>
                          <p className="mb-0 text-danger">
                            {errors3?.correctAnswer?.message}
                          </p>
                        </ul>
                      </div>
                      <button
                        className="btn btn-success"
                        onClick={handleSubmit3(handleAddQuestion)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowQuestionInput(false)}
                        class="btn btn-danger ms-2"
                      >
                        Close
                      </button>
                    </div>
                  )}
                </div>
                {/* add quiz end */}
              </div>
            </div>
          ))}
        {showAssessmentInput && (
          <div className="bottom-box">
            <div className="top-boxs">
              {/* <div className="into-block">
              <div className="les">
                <button>
                  <span>
                    <img src="./assets/images/plus.svg" alt="" />
                  </span>
                  New lesson
                </button>
                <span className="inro">Introduction</span>
              </div>
              <div className="edit-btn">
                <div className="dele">Delete</div>
              </div>
            </div> */}
              <div className="lectures">
                <form>
                  <div className="mb-3">
                    <label for="entertitle" className="form-label">
                      Enter title
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="entertitle"
                      aria-describedby="emailHelp"
                      placeholder="Enter title"
                      {...register("lessonName", {
                        required: "Field is required",
                      })}
                    />
                    <p className="mb-0 text-danger">
                      {errors?.lessonName?.message}
                    </p>

                    <div id="emailHelp" className="form-text"></div>
                  </div>

                  <div className="mb-3">
                    <label for="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      rows="3"
                      placeholder="Lecture description"
                      {...register("lessonDescription", {
                        required: "Field is required",
                      })}
                    ></textarea>
                    <p className="mb-0 text-danger">
                      {errors?.lessonDescription?.message}
                    </p>
                  </div>

                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSubmit(handleAddAssessment)}
                  >
                    Save
                  </button>

                  {allAssessmentList?.length > 0 && (
                    <button
                      type="button"
                      onClick={() => setShowAssessmentInput(false)}
                      class="btn btn-danger ms-2"
                    >
                      Close
                    </button>
                  )}
                </form>
              </div>
            </div>
            {/* <div className="lectu">
            <span className="lec">
              <img src="./assets/images/plus.svg" alt="" /> <span>Lecture</span>
            </span>
            <span className="vid">
              <img src="./assets/images/playnew.svg" alt="" />{" "}
              <span>Add Video</span>
            </span>
            <span className="qui">
              <img src="./assets/images/newbook.svg" alt="" />{" "}
              <span>Add Quiz</span>
            </span>

          </div> */}
          </div>
        )}
      </div>
      <section className="step-section">
        <div className="step-full">
          <div className="step-content">
            <h5 className="steps">
              Step / <span>{activeTab}</span>
            </h5>
            {activeTab !== 1 && activeTab !== 8 && (
              <a
                href="javascript:void(0)"
                onClick={() => toggleTab(activeTab + 1)}
                className="next"
              >
                Next
              </a>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default CourseAssessment;
