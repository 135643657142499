import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';

const Checkout = () => {
  const location = useLocation()
  const checkoutId = location?.state?.id;
  const [loadScript, setLoadScript] = useState(true);

  useEffect(() => {
    if (location?.state?.id) {
      
      const script = document.createElement("script");
  
      script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;
  
      document.body.appendChild(script);
      return () => {
        if (loadScript) {
          document.body.removeChild(script);
          setLoadScript(false);
        }
      };
    }
  }, []);

  return (
    <form action={`${window.location.origin}/payment-status`} className="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
  )
}

export default Checkout