import React from 'react'
// import { useTranslation } from 'react-i18next'
import { Modal } from 'reactstrap'
import moment from "moment/moment";

const AllNotificationsModal = (props) => {
    // const { t } = useTranslation();
    return (
        <Modal className="modal-dialog modal-dialog-scrollable modal-md" isOpen={props.showAllNotification} toggle={props.toggleShowAllNotification} >
            <div className="modal-content ">
                <div className="d-flex justify-content-between p-1 border border-botom">
                    <h5 className="modal-title text-body-secondary">
                        {"All Notifications"}
                    </h5>
                    <div
                        type="button"
                        className="btn-close"
                        style={{fontSize:"12px"}}
                        onClick={props.toggleShowAllNotification}
                    >
                        <span aria-hidden="true"></span>
                    </div>
                </div>
                <div className='modal-body'>
                {props?.notifications && props?.notifications?.length > 0 ? (
                            props?.notifications?.map((item) => (
                              <div
                                key={item.id}
                                
                                className="p-2 border-bottom "
                              >
                                <div className="d-flex justify-content-between align-items-center gap-1 mb-2">
                                  <div className="text-wrap" style={{fontSize:"14px"}}>
                                     {item?.notificationData?.message}
                                  </div>
                                  <div
                                    className="btn-close"
                                    style={{fontSize:"10px"}}
                                    type="button"
                                    onClick={() =>
                                      props?.handleDeleteNotification(item?.id)
                                    }
                                  >
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <span className="text-muted" style={{fontSize:"12px"}}>
                                    {moment(item?.createdAt).format(
                                      "DD-MM-yyyy hh:mm A"
                                    )}
                                  </span>
                                </div>
                                <div className="dropdown-divider" />
                              </div>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center font-14 text-muted p-3">
                              No Notifications
                            </div>
                          )}
                </div>
            </div>
        </Modal>
    )
}

export default AllNotificationsModal