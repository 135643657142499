import React from "react";

const EventType = ({ toggleTab, setIsEventPublic, eventDetail }) => {
  return (
    <>
      <section className="create-new-steps asswac">
        <div className="container">
          <div className="lets-find">
            <h3>let's find out what type of event you're making.</h3>
            <div className="in-boxs">
              <div
                className={
                  eventDetail.isEventPublic === true
                    ? "form-check active"
                    : "form-check"
                }
                onClick={() => {
                  toggleTab(2);
                  setIsEventPublic(false);
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="course"
                  id="publiccourse1"
                />
                <label className="form-check-label" for="publiccourse1">
                  <h4>Private</h4>
                  <p>Only people who are invited</p>
                </label>
              </div>
              <div
                className={
                  eventDetail.isEventPublic === false
                    ? "form-check active"
                    : "form-check"
                }
                onClick={() => {
                  toggleTab(2);
                  setIsEventPublic(true);
                }}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="course"
                  id="privatecourse2"
                />
                <label className="form-check-label" for="privatecourse2">
                  <h4>Public</h4>
                  <p>Anyone on Zidni </p>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventType;
