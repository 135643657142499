import { useEffect, useState } from "react";
import AllRoutes from "./routes/AllRoutes";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "./api/apiUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { onMessageListener } from "./firebase";
import { callApi } from "./api/ApiHelper";
import { saveNotifications } from "./store/actions/userActions";
function App() {
  const userDetail = useSelector((state) => state.root.userDetails);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const getnotificationList = () => {
    const apiURL =
    APIURL.NOTIFICATION_URL + APIENDPOINTS.NOTIFICATION_END_POINT + "get-notification";
    const options = {
      method: "GET",
    };
    callApi(apiURL, options).then((response) => {
      if (response?.status === true) {
        dispatch(saveNotifications (response?.data?.notification));
      }
    });
  };

  // onMessageListener()
  //   .then((payload) => {
  //     getnotificationList();
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log("notification payload", payload)

  //   })
  //   .catch((err) => console.log("failed: ", err));

  // if (show == true) {
  //   sendNotificationInfo(`${notification?.title},  ${notification?.body}`);
  //   setShow(false)
  //   setNotification({ title: "", body: "" })
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // useEffect(()=>{
  //   if (Cookies.get('zidniToken')) {
  //     const apiUrl =
  //     APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "auth-check";
  //     callApi(apiUrl, { method: "GET", data:{} }).then((res)=>{
  //       if (res?.status == true) {
  //         console.log("comming")
  //       }else{
  //         if (res.data.message == "jwt expired") {
  //           GetAccessData(9000,'Z0001/api/v1/create-access',{}).then((res)=>{
  //             if (res.status == true) {
  //               Cookies.set("zidniToken", res.data.accessToken);
  //               Cookies.set('refreshZidniToken',res.data.refreshToken)
  //             }else{
  //               console.log("err",res.data)
  //             }
  //           })
  //         }
  //       }
  //     })
  //   }
  // },[])
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
