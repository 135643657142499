import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { APIENDPOINTS, APIURL } from '../../../api/apiUrl';
import { callApi } from '../../../api/ApiHelper';
import { sendErrorInfo, sendSuccessInfo } from '../../../utility/helperFunctions';
import Layout from '../../../components/common/Layout.js';

const CreateWebinar = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [searchTerm, setSearchTerm] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isLivePrivate, setIslivePrivate] = useState(false)
    const [scheduleLetter, setSchduleLetter] = useState(false)
    const navigate = useNavigate();
    const userDetails = useSelector(state => state.root.userDetails)
    const [image, setImage] = useState({
        file: null,
        preview: null
    });

    useEffect(() => {
        let roomname = generateRandomRoomName();
        reset({ channelName: roomname })
    }, [])
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
            if (allowedTypes.includes(file.type)) {
                const previewURL = URL.createObjectURL(file);
                setImage({ file: file, preview: previewURL });
            } else {
                e.target.value = null;
                setImage({
                    file: null,
                    preview: null
                });
                alert('Please select a valid image file (JPEG, PNG, or GIF).');
            }
        }
    };

    const onSubmit = (data) => {
        // Handle form submission logic here
        let postData = {
            ...data,
            isLivePublic: isLivePrivate === true ? 0 : 1,
            organisedBy: userDetails.id,
            liveImage: image.file,
            ...(scheduleLetter === true && { scheduleDateTime: data.scheduleDateTime }),
            ...(scheduleLetter === true && { liveStatus: 2 }),
            ...(isLivePrivate === true && { allowedUser: selectedUsers.map(user => user.email) })
        }
        // console.log("postData>>>>>>>>>>>>>>>>>>>>>", postData);
        const formData = new FormData()
        for (const key in postData) {
            if (key == 'liveImage') {
                formData.append('liveImage', image.file)
            } else {
                formData.append(key, postData[key]);
            }

        }
        let url = APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + 'live/create';
        callApi(url, { data: formData, multipart: true, method: 'POST' })
            .then(response => {
                if (response.status === true) {
                    sendSuccessInfo(response.message);
                    if (scheduleLetter) {
                        navigate(-1)
                    } else {
                        navigate('/live-webinar/' + response.data.channelName)
                    }
                } else {
                    sendErrorInfo(response.message)
                }
            })
    };

    function generateRandomRoomName() {
        const randomString = Math.floor(Math.random() * 9000000000) + 1000000000;
        const roomName = `zacademy_${randomString}`;

        return roomName;
    }

    // Multi Select Functions


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await callApi(APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `users/list?search=${searchTerm}&type=1`)
                setUsersList(response.data.user);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [searchTerm]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleUserSelect = (user) => {
        setSelectedUsers([...selectedUsers, user]);
        setUsersList(usersList.filter((u) => u.id !== user.id));
    };

    const handleUserDeselect = (user) => {
        setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
        setUsersList([...usersList, user]);
    };

    const currentDateTime = new Date().toISOString().slice(0, 16);

    return (
        <>
            <Layout>
                <div className="d-flex align-items-center justify-content-center mt-5 mb-5" >
                    <div className="card p-4 w-50">
                        <h2 className="text-center mb-4">Live Channel Form</h2>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-3">
                                <label htmlFor="channelName" className="form-label">Channel Name</label>
                                <input type="text" className={`form-control ${errors.channelName ? 'is-invalid' : ''}`} readOnly id="channelName" {...register('channelName', { required: 'Channel Name is required' })} />
                                {errors.channelName && <div className="invalid-feedback">{errors.channelName.message}</div>}
                            </div>
                            <div className="mb-3">
                                <div className="form-check">
                                    <input type="checkbox" className={`form-check-input`} id="Schedule" name="scheduleLetter" checked={scheduleLetter} onChange={(e) => setSchduleLetter(e.target.checked)} />
                                    <label className="form-check-label" htmlFor="Schedule">Schedule for later</label>
                                </div>
                            </div>
                            {
                                scheduleLetter &&
                                <div className="mb-3">
                                    <label htmlFor="scheduleDateTime" className="form-label">Schedule Time</label>
                                    <input type="datetime-local" min={currentDateTime} className={`form-control ${errors.scheduleDateTime ? 'is-invalid' : ''}`} id="scheduleDateTime"
                                        {...register('scheduleDateTime', {
                                            required: 'Schedule Time is required',
                                            min: { value: currentDateTime, message: 'Please select a future date and time' }
                                        })} />
                                    {errors.scheduleDateTime && <div className="invalid-feedback">{errors.scheduleDateTime.message}</div>}
                                </div>
                            }
                            <div className="mb-3">
                                <label htmlFor="liveDescription" className="form-label">Live Description</label>
                                <textarea className={`form-control ${errors.liveDescription ? 'is-invalid' : ''}`} id="liveDescription" rows="3" {...register('liveDescription', { required: 'Live Description is required' })}></textarea>
                                {errors.liveDescription && <div className="invalid-feedback">{errors.liveDescription.message}</div>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">Image</label>
                                <input type="file" accept="image/*" className={`form-control ${errors.image ? 'is-invalid' : ''}`} id="image" onChange={(e) => handleImageChange(e)} />
                                {errors.image && <div className="invalid-feedback">{errors.image.message}</div>}
                                {image.preview && <img src={image.preview} style={{ height: "150px", width: "150px" }} alt="Image Preview" className="img-fluid mt-2" />}
                            </div>

                            <div className="mb-3">
                                <div className="form-check">
                                    <input type="checkbox" className={`form-check-input`} id="public" name="isLivePrivate" checked={isLivePrivate} onChange={(e) => setIslivePrivate(e.target.checked)} />
                                    <label className="form-check-label" htmlFor="public">Private</label>
                                </div>
                            </div>
                            {
                                isLivePrivate === true && <div className="mb-3">
                                    <label className="select-label" htmlFor="addeve">
                                        Add Participants
                                    </label>
                                    <input type="text" className="form-control" placeholder="Search users" value={searchTerm} onChange={handleSearchChange} />
                                    <div className='d-flex justify-content-between' >

                                        {searchTerm.trim() !== '' && (
                                            <ul>
                                                {usersList.map((user) => (
                                                    <div className='d-flex justify-content-between'>
                                                        <li key={user.id}>
                                                            {user?.Profile?.firstName + ' ' + user?.Profile?.lastName}
                                                        </li>
                                                        <i style={{ cursor: 'pointer' }} class="fa-solid fa-check text-success" onClick={() => handleUserSelect(user)}></i>
                                                    </div>
                                                ))}
                                            </ul>
                                        )}
                                        {selectedUsers.length > 0 && (
                                            <div>
                                                Selected Users:
                                                {selectedUsers.map((user) => (
                                                    <div className='d-flex justify-content-between'>
                                                        <span key={user.id}>
                                                            {user?.Profile?.firstName + ' ' + user?.Profile?.lastName}

                                                        </span>
                                                        <i style={{ cursor: 'pointer' }} onClick={() => handleUserDeselect(user)} class="fa-regular fa-circle-xmark text-danger"></i>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default CreateWebinar;
