import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import Cookies from "js-cookie";
import Rating from "react-rating";


const FreeCourse = () => {
  const [courseList, setCourseList] = useState([]);
  const [CallApi, setCallApi] = useState(true);
  useEffect(() => {
    if (CallApi == true) {
      setCallApi(false);
      let Data = {
        where: {
          coursePricing: "Free",
          courseStatus: 1
        },
        paranoid: true,
        pageNumber: 0,
        limit: 10,
      };
      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "course/list";
      callApi(apiUrl, { method: "POST", data: Data }).then((res) => {
        if (res?.status == true) {
          setCourseList(res?.data?.course);
        }
      });
    }
  }, [CallApi]);
  return (
    <>
      {/* zidni start */}
      <section className="zidni explore">
        <div className="container">
          <div className="heading">
            <h2>Explore zidni free course</h2>
            <Link to="/courses" state={{ priceValue: 'Free' }}>View all</Link>
          </div>
          <div className="cards">
            {courseList &&
              courseList
                .filter((e) => e.deletedAt == null)
                .map((item, key) => {
                  if (key <= 2) {
                    return (
                      <div className="card round" key={key}>
                        <div className="img">
                          <img src={item?.courseImage} alt="" />
                        </div>
                        <div className="univ">
                          <div className="university">
                            {/* <span className="img round">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/univ-logo.svg`} alt="" />
                  </span> */}
                            <h5>
                              <Link
                                to={`/course-detail/${item?.id}`}
                                state={{
                                  insDetail: item?.instructor,
                                }}
                              >
                                {item?.courseName}
                              </Link>
                            </h5>
                          </div>
                          {/* <div className="specialty">Ophthalmology Specialty</div> */}
                          <Link
                            to={`/course-detail/${item?.id}`}
                            className="oph"
                          >
                            {item?.Category?.categoryName}
                          </Link>
                        </div>
                        <div className="review">
                          <span className="star">
                            <Rating
                              readonly
                              initialRating={item?.averageRating}
                              emptySymbol={<i className="fa-regular fa-star" style={{ color: 'grey' }}></i>}
                              fullSymbol={<i className="fa-solid fa-star" style={{ color: 'gold' }}></i>}
                            />
                            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/star.svg`} alt="" />
                  4.8 (233K Reviews) */}
                          </span>
                          <div className="credit">Credit hr:{item?.courseTimeDurationAndCreditHour ? JSON.parse(item?.courseTimeDurationAndCreditHour)?.courseCreditHour : ''}</div>
                          {/* <div className="credit">Credit hr:20</div> */}
                        </div>
                        <div className="price">
                          <div className="name">
                            By: {item?.instructor?.firstName}{" "}
                            {item?.instructor?.lastName}
                          </div>
                          <div className="pr">
                            {JSON?.parse(item?.coursePricing)?.actualValue ? (
                              <>
                                <span
                                  className={
                                    JSON?.parse(item?.coursePricing)
                                      ?.actualValue &&
                                    "text-muted text-decoration-line-through fs-6"
                                  }
                                >
                                  {" "}
                                  {item?.coursePricing &&
                                    JSON?.parse(item?.coursePricing)
                                      ?.coursePaymentType == "Paid"
                                    ? `$ ${JSON?.parse(item?.coursePricing)
                                      ?.coursePricing
                                    }`
                                    : "Free"}
                                </span>

                                <span>
                                  {" "}
                                  {item?.coursePricing &&
                                    JSON?.parse(item?.coursePricing)
                                      ?.coursePaymentType == "Paid"
                                    ? `$ ${JSON?.parse(item?.coursePricing)
                                      ?.actualValue.toFixed(2)
                                    }`
                                    : "Free"}
                                </span>
                              </>
                            ) : (
                              <span>
                                {" "}
                                {item?.coursePricing &&
                                  JSON?.parse(item?.coursePricing)
                                    ?.coursePaymentType == "Paid"
                                  ? `$ ${JSON?.parse(item?.coursePricing)
                                    ?.coursePricing
                                  }`
                                  : "Free"}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
          </div>
        </div>
      </section>
      {/* zidni end */}
    </>
  );
};

export default FreeCourse;
