
import { ADD_STREAM, AUDIO_DIRECTORY, CHAT_MESSAGES, CHAT_RESPONSE, CLEAR_ALL_DATA_ON_LEAVE, DASHBOARD_DATA, LEAVE_STREAM, LOCAL_STREAM, LOGGED_IN_MODAL, LOGGED_IN_USER_DETAILS, SET_LOGIN_ACTIVE_TAB, SOCKET_STREAM, UPDATE_LIVE_ALL_MEDIA, VIDEO_DIRECTORY,NOTIFICATIONS } from "../types";

const initialState = {
  userDetails: {},
  notifications:[],
  showLoginPopup: false,
  setActiveLoginTab: "1",
  serviceType: "",
  audioDirectories: {},
  videoDirectories: {},
  localStream: null,
  dashboardData: null,
  streams: [],
  allMedia: {
    audio: 1,
    video: 1
  },
  chat: [],
}

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_IN_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
      case NOTIFICATIONS:
                return {
                    ...state,
                    notifications: action.payload,
                };
    case LOGGED_IN_MODAL:
      return {
        ...state,
        showLoginPopup: action.payload,
      };
    case SET_LOGIN_ACTIVE_TAB:
      return {
        ...state,
        setActiveLoginTab: action.payload,
      };

    case SOCKET_STREAM:
      return {
        ...state,
        payload: action.payload,
        serviceType: action.type,
      };
    case ADD_STREAM:
      console.log("Type of state.streams:", typeof state.streams);
      console.log("Contents of state.streams:", state.streams);
      const newStream = action.payload;
      if (action.payload === null) {
        return {
          ...state,
          streams: [],
          serviceType: action.type
        }
      }
      console.log("newstream state.streams:", newStream);
      let val = action.payload
      //   alert(action.payload.userId)

      //   state.streams.filter(e => e.userId != action.payload.userId)
      //   state.streams.splice(state.streams.findIndex(e => e.userId === action.payload.userId),1);
      return {
        ...state,
        streams: val
          .slice()
          .reverse()
          .filter((v, i, a) => a.findIndex((t) => t.userId === v.userId) === i)
          .reverse(),
        serviceType: action.type,
      };
    case DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload
      }
    case LEAVE_STREAM:
      let tempList = [];
      state.streams.map((item) => {
        if (action.payload !== item.socketId) {
          tempList.push(item);
        }
      });
      console.log("not same user ", action.payload)
      console.log("not same user ", tempList)
      let audiosDic = { ...state.audioDirectories }
      let videoDic = { ...state.videoDirectories }
      delete audiosDic[action.payload]
      delete videoDic[action.payload]
      return {
        ...state,
        streams: [...tempList],
        serviceType: action.type,
        audioDirectories: audiosDic,
        videoDirectories: videoDic
      };
    case LOCAL_STREAM:
      return {
        ...state,
        localStream: action.payload,
        serviceType: action.type,
      };
    case CHAT_RESPONSE:
      return {
        ...state,
        chatResponse: action.payload,
        serviceType: action.type,
      };
    case AUDIO_DIRECTORY:
      let audios = { ...state.audioDirectories }
      audios[action.payload.socketId] = action.payload.audio
      return {
        ...state,
        audioDirectories: audios
      }
    case VIDEO_DIRECTORY:
      let videos = { ...state.videoDirectories }
      videos[action.payload.socketId] = action.payload.video
      return {
        ...state,
        videoDirectories: videos
      }
    case UPDATE_LIVE_ALL_MEDIA:
      return { ...state, allMedia: action.payload }
    case CHAT_MESSAGES:
      return {
        ...state,
        chat: action.payload == null ? [] : [...state.chat, action.payload]
      }
    case CHAT_RESPONSE:
      return {
        ...state,
        chatResponse: action.payload,
        serviceType: action.type,
      };
    case CLEAR_ALL_DATA_ON_LEAVE:
      return {
        ...state,
        serviceType: "",
        audioDirectories: {},
        videoDirectories: {},
        localStream: null,
        streams: [],
        allMedia: {
          audio: 1,
          video: 1
        },
        chat: [],
      }
    default:
      return state;
  }
};

export default userReducers;
