// export const LOGGED_IN_USER_DETAILS = "LOGGED_IN_USER_DETAILS";
// export const SET_USER_TOKEN = "SET_USER_TOKEN";
// export const SET_TOKEN_TYPE = "SET_TOKEN_TYPE";
// export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";
// export const SET_SOCIAL_LOGIN = "SET_SOCIAL_LOGIN";

export const LOGGED_IN_USER_DETAILS = "LOGGED_IN_USER_DETAILS";
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const LOGGED_IN_MODAL = "LOGGED_IN_MODAL";
export const SET_LOGIN_ACTIVE_TAB = "SET_LOGIN_ACTIVE_TAB";


// For live 
export const AUDIO_DIRECTORY = "AUDIO_DIRECTORY";
export const VIDEO_DIRECTORY = "VIDEO_DIRECTORY";
export const UPDATE_LIVE_ALL_MEDIA = "UPDATE_LIVE_ALL_MEDIA";
export const CHAT_RESPONSE = 'CHAT_RESPONSE';
export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const SOCKET_STREAM = 'SOCKET_STREAM';
export const ADD_STREAM = 'ADD_STREAM';
export const LOCAL_STREAM = 'LOCAL_STREAM';
export const LEAVE_STREAM = 'LEAVE_STREAM'
export const DASHBOARD_DATA = 'DASHBOARD_DATA'
export const CLEAR_ALL_DATA_ON_LEAVE = 'CLEAR_ALL_DATA_ON_LEAVE'