import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  saveNotifications,
  saveUserDetails,
  setLoginTab,
  setShowLoginPopup,
} from "../../store/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { sendSuccessInfo } from "../../utility/helperFunctions";
import Cart from "./Cart";
import moment from "moment/moment";
import AllNotificationsModal from "./AllNotificationsModal";

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.root.userDetails);
  const [searchValue, setSearchValue] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [courseSearchData, setCourseSearchData] = useState([]);
  const [eventSearchData, setEventSearchData] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [category, setCategory] = useState([]);
  const toggle = () => {
    setShowCart(!showCart);
  };
  const notifications = useSelector((state) => state?.root?.notifications);
  const dropdownRef = useRef(null);
  const [toggleDrop, setToggleDtrop] = useState(false);
  const toggleD = () => setToggleDtrop(!toggleDrop);
  const [showAllNotification, setShowAllNotification] = useState(false);
  const toggleShowAllNotification = () => {
    setShowAllNotification(!showAllNotification);
    setToggleDtrop(false);
  };

  useEffect(() => {
    const apiUrl =
      APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "category/list";
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setCategory(response.data);
      }
    });
  }, []);

  useEffect(() => {
    getnotificationList();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setToggleDtrop(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getnotificationList = () => {
    const apiURL =
      APIURL.NOTIFICATION_URL +
      APIENDPOINTS.NOTIFICATION_END_POINT +
      "get-notification";
    const options = {
      method: "GET",
    };
    callApi(apiURL, options).then((response) => {
      if (response?.status === true) {
        dispatch(saveNotifications(response?.data?.notification));
      }
    });
  };

  const handleDeleteNotification = (id) => {
    const apiURL =
      APIURL.NOTIFICATION_URL +
      APIENDPOINTS.NOTIFICATION_END_POINT +
      `delete-notification`;
    const body = { id: [id] };
    const options = {
      method: "POST",
      data: body,
    };
    callApi(apiURL, options).then((response) => {
      if (response?.status === true) {
        getnotificationList();
      }
    });
  };
  const handleClearAllNotification = () => {
    const apiURL =
      APIURL.NOTIFICATION_URL +
      APIENDPOINTS.NOTIFICATION_END_POINT +
      `delete-all-notification`;
    let body = { userId: userDetails?.id };
    const options = {
      method: "GET",
      data: body,
    };
    callApi(apiURL, options).then((response) => {
      if (response?.status === true) {
        getnotificationList();
      }
    });
  };
  const handleSeenNotification = () => {
    toggleD();
    if (notifications?.filter((item) => !item?.seen)?.length > 0) {
      const apiURL =
        APIURL.NOTIFICATION_URL +
        APIENDPOINTS.NOTIFICATION_END_POINT +
        `seen-notification`;
      let body = { userId: userDetails?.id };
      const options = {
        method: "GET",
        data: body,
      };
      callApi(apiURL, options).then((response) => {
        if (response?.status === true) {
          getnotificationList();
        }
      });
    }
  };

  const handleNavigateNotification = (item) => {
    // if (item?.notificationData?.body?.data) {
    //   if (item?.notificationData?.body?.data?.type === "eventDetail") {
    //     navigate("/event-detail",{
    //       state:{
    //         id:item?.notificationData?.body?.data?.id
    //       }
    //     })
    //   }
    // }
  };

  const handleLogout = () => {
    sendSuccessInfo("Logout successfully");
    dispatch(saveUserDetails({}));
    dispatch(saveNotifications([]));
    Cookies.remove("deviceId");
    Cookies.remove("zidniToken");
    Cookies.remove("userDetail");
  };

  const handelSearch = (value) => {
    setSearchValue(value);
    let Data = {
      where: {
        coursestatus: 1,
      },
      paranoid: true,
      limit: 50,
    };
    if (value != "") {
      Data.where["courseName"] = value;
    }

    let eventData = {
      where: {
        eventstatus: 1,
      },
      paranoid: true,
      limit: 50,
    };
    if (value != "") {
      eventData.where["eventName"] = value;
    }
    const apiUrl =
      APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "course/list";
    callApi(apiUrl, { method: "POST", data: Data }).then((res) => {
      if (res.status == true) {
        setCourseSearchData([...res.data.course]);

        const apiUrl2 =
          APIURL.EVENT_URL + APIENDPOINTS.EVENT_END_POINT + `event/list`;
        callApi(apiUrl2, { method: "POST", data: eventData }).then(
          (rseponse) => {
            if (rseponse.status === true) {
              setEventSearchData([...rseponse.data.event]);
            }
          }
        );
      }
    });
  };

  const onSearchResultClick = (item, type) => {
    if (type === "course") {
      navigate(`/course-detail/${item?.id}`, {
        state: {
          insDetail: item?.instructor,
        },
      });
    } else {
      navigate(`/event-detail/${item?.id}`);
    }

    setSearchValue("");
  };

  return (
    <>
      <section className="search-bar">
        <div className="container">
          <div className="bottom-bar">
            <div className="log">
              <div className="ser">
                <form className="dropdown-s" action="#">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="What you want to learn?"
                    value={searchValue}
                    onChange={(e) => handelSearch(e.target.value)}
                  />
                  {searchValue?.length > 0 && (
                    <div className="searchdropdown-menu">
                      <div className="search-menu">
                        <div className="inner-search">
                          <div className="top">
                            <div className="row">
                              {courseSearchData &&
                                courseSearchData?.length > 0 && (
                                  <div className="col-md-6">
                                    <h4 className="text-muted mb-2 p-1 bg-light">
                                      Courses
                                    </h4>
                                    <ul>
                                      {courseSearchData &&
                                        courseSearchData.length > 0 &&
                                        searchValue?.length > 0 &&
                                        courseSearchData
                                          .filter((e) => e.deletedAt == null)
                                          .map((item, key) => (
                                            <li className="newli" key={key}>
                                              <div
                                                onClick={() =>
                                                  onSearchResultClick(
                                                    item,
                                                    "course"
                                                  )
                                                }
                                                className="newcontent"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div
                                                  style={{
                                                    width: "70px",
                                                    height: "50px  ",
                                                  }}
                                                >
                                                  <img
                                                    className="w-100 h-100"
                                                    src={item?.courseImage}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="btn-con">
                                                  <h6>{item?.courseName}</h6>
                                                  <div className="univv ">
                                                    {
                                                      item?.Category
                                                        ?.categoryName
                                                    }
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                    </ul>
                                  </div>
                                )}
                              {eventSearchData &&
                                eventSearchData?.length > 0 && (
                                  <div className="col-md-6">
                                    <h4 className="text-muted mb-2 p-1 bg-light">
                                      Events
                                    </h4>
                                    <ul>
                                      {eventSearchData &&
                                        eventSearchData.length > 0 &&
                                        searchValue?.length > 0 &&
                                        eventSearchData
                                          .filter((e) => e.deletedAt == null)
                                          .map((item, key) => (
                                            <li className="newli" key={key}>
                                              <div
                                                onClick={() =>
                                                  onSearchResultClick(
                                                    item,
                                                    "event"
                                                  )
                                                }
                                                className="newcontent"
                                                style={{ cursor: "pointer" }}
                                              >
                                                <div
                                                  style={{
                                                    width: "70px",
                                                    height: "50px  ",
                                                  }}
                                                >
                                                  <img
                                                    className="w-100 h-100"
                                                    src={item.eventImage}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="btn-con">
                                                  <h6>{item?.eventName}</h6>
                                                  <div className="univv text-truncate">
                                                    {item?.eventDescription}
                                                  </div>
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                    </ul>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {courseList.map((item, key) => (
                                  <li key={key + 2}>
                                    <Link to="/course-detail"
                                      state={{ courseId: item.id }}>
                                      <div className="img-b">
                                        <img src={item?.courseImage} alt="" />
                                      </div>
                                      <div className="btn-con">
                                        <h6>{item?.courseName}</h6>
                                        <div className="univv">
                                          {item?.Category?.categoryName}
                                        </div>
                                      </div>
                                    </Link>
                                  </li>

                                ))} */}
                  {/* <div className="recent-serch">
                          <h4>Most popular specialities</h4>
                          <div className="btnrec">
                            <a href="./universities.html">
                              <div className="img-b">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/sp7.png`} alt="" />
                              </div>
                              <div className="btn-con">
                                <h6>
                                  Pediatric Endocrinology &amp; Community
                                  Medicine
                                </h6>
                              </div>
                            </a>
                            <a href="./universities.html">
                              <div className="img-b">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/sp8.png`} alt="" />
                              </div>
                              <div className="btn-con">
                                <h6>Family Medicine</h6>
                              </div>
                            </a>
                            <a href="./universities.html">
                              <div className="img-b">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/sp9.png`} alt="" />
                              </div>
                              <div className="btn-con">
                                <h6>Anesthesia and Intensive Care</h6>
                              </div>
                            </a>
                          </div>
                        </div> */}
                  {/* </div>
                      </div>
                    </div>
                  } */}
                </form>
              </div>
              <div href="/" className="exp">
                <span className="mobex">Explore Specialities</span>
                <div className="mega-menuc">
                  <div className="contentss">
                    <div className="link-box">
                      <ul className="lin">
                        {category &&
                          category.length > 0 &&
                          category.map((cat, index) => (
                            <li key={index}>
                              <Link to={"/courses"} state={{ catId: cat?.id }}>
                                {cat.categoryName}
                              </Link>
                            </li>
                          ))}
                      </ul>
                      {/* <a href="/" className="btnspe">
                        View all specialities
                      </a> */}
                    </div>
                    <div className="img-box">
                      <div className="img-boxn">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/Rectangle 25.png`}
                          alt=""
                        />
                      </div>
                      <div className="logab">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/z.svg`}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/gridd.svg`}
                  alt=""
                />
              </div>
            </div>
            {userDetails?.type ? (
              <div className="loggedin-btns">
                <div className="cus-name">
                  {userDetails?.firstName
                    ? userDetails?.firstName
                    : userDetails?.Profile?.firstName}
                  <span>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/downarrow.svg`}
                      alt=""
                    />
                  </span>
                  <div className="customer-menu-drop">
                    {userDetails.type === 1 && (
                      <>
                        <ul>
                          <li className="dthov">
                            <a href="#">
                              <h6 className="text-primary">Student</h6>
                            </a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/my-purchase">My purchase</Link>
                          </li>
                          <li>
                            <Link to="/profile">Account settings</Link>
                          </li>
                          <li>
                            <Link to="/my-purchase-course">My Courses</Link>
                          </li>
                          {/* <li>
                            <a href="/">Profile</a>
                          </li> */}
                          {/* <li>
                            <a href="/">Help centre</a>
                          </li> */}
                          <li>
                            <a
                              href="javascript:void(0);"
                              onClick={handleLogout}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <a
                              className="d-flex align-items-center justify-content-between"
                              onClick={() => {
                                dispatch(setShowLoginPopup(true));
                                dispatch(setLoginTab("4"));
                              }}
                            >
                              <span className="fw-bold text-primary">
                                Zidni instructor
                              </span>
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/indicatoricon.svg`}
                                alt=""
                              />
                            </a>
                            <p className=" text-secondary">
                              Try to become a Zidni instructor
                            </p>
                          </li>
                        </ul>
                      </>
                    )}
                    {userDetails.type === 2 && (
                      <>
                        <ul>
                          <li>
                            <Link to="/my-purchase">My purchase</Link>
                          </li>
                          <li>
                            <Link to="/profile">Account settings</Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/instructor-dashboars">
                              Instructor dashboard
                            </Link>
                          </li>
                          <li>
                            <Link to="/my-courses">My course</Link>
                          </li>
                          <li>
                            <Link to="/create-courses">Create course</Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/create-event">Create event</Link>
                          </li>
                          <li>
                            <Link to="/my-events">My events</Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/create-webinar">Create webinar</Link>
                          </li>
                          <li>
                            <Link to="/conference">Create conference </Link>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <Link to="/my-purchase-course">My learning</Link>
                          </li>
                          {/* <li>
                            <a href="/">Help centre</a>
                          </li> */}
                          <li>
                            <a
                              href="javascript:void(0);"
                              onClick={handleLogout}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="cart-btns">
                  <a
                    href="javascript:void(0);"
                    onClick={toggle}
                    className="btn-menu"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/cart.svg`}
                      alt=""
                    />
                  </a>

                  <div className="navbar-nav navbar-right" ref={dropdownRef}>
                    <div
                      className={
                        toggleDrop
                          ? "dropdown position-relative show"
                          : "dropdown position-relative"
                      }
                    >
                      <button
                        onClick={handleSeenNotification}
                        className="nav-link btn-menu dropdown-toggle nav-link-lg nav-link-user position-relative"
                      >
                        {/* <img
                alt="image"
                src="assets/img/bell.png"
                className="shadow rounded"
                style={{ width: "23px", height: "23px", objectFit: "cover" }}
              /> */}
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/bell.svg`}
                          alt=""
                        />
                        {notifications?.filter((item) => !item?.seen)?.length >
                          0 && <span className="badge bg-danger"></span>}
                        <span className="d-sm-none d-lg-inline-block" />
                      </button>
                      <div
                        style={{ minWidth: "350px", maxHeight: "350px" }}
                        className={
                          toggleDrop
                            ? "dropdown-menu dropdown-menu-right show position-absolute end-0"
                            : "dropdown-menu dropdown-menu-right position-absolute end-0"
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center px-1 border-bottom">
                          <h6>Notifications</h6>
                          {notifications && notifications?.length > 0 && (
                            <button
                              className="btn"
                              style={{ fontSize: "14px" }}
                              onClick={handleClearAllNotification}
                            >
                              Clear All
                            </button>
                          )}
                        </div>
                        <div
                          className="navbar-nav-scroll"
                          style={{ maxHeight: "250px" }}
                        >
                          {notifications && notifications?.length > 0 ? (
                            notifications?.map((item) => (
                              <div key={item.id} className="p-2">
                                <div className="d-flex justify-content-between align-items-center gap-1 mb-2">
                                  <Link
                                    to={
                                      item?.notificationData?.body?.data && item?.notificationData?.body?.data?.type === "eventDetail" && 
                                      `/event-detail/${item?.notificationData?.body?.data?.id}` ||
                                      item?.notificationData?.body?.data && item?.notificationData?.body?.data?.type === "courseDetail" && 
                                      `/course-detail/${item?.notificationData?.body?.data?.id}` ||
                                      item?.notificationData?.body?.data && item?.notificationData?.body?.data?.type === "EventList" && 
                                      `/events` ||
                                      item?.notificationData?.body?.data && item?.notificationData?.body?.data?.type === "CourseList" && 
                                      `/courses` ||
                                      item?.notificationData?.body?.data && item?.notificationData?.body?.data?.type === "liveList" && 
                                      `/webinars`
                                    }
                                    className="text-wrap"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {item?.notificationData?.message}
                                  </Link>
                                  <div
                                    className="btn-close"
                                    style={{ fontSize: "10px" }}
                                    type="button"
                                    onClick={() =>
                                      handleDeleteNotification(item?.id)
                                    }
                                  ></div>
                                </div>
                                <div className="d-flex justify-content-end">
                                  <span
                                    className="text-muted"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {moment(item?.createdAt).format(
                                      "DD-MM-yyyy hh:mm A"
                                    )}
                                  </span>
                                </div>
                                <div className="dropdown-divider" />
                              </div>
                            ))
                          ) : (
                            <div className="d-flex justify-content-center font-14 text-muted p-3">
                              No Notifications
                            </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-center">
                          {notifications && notifications?.length > 5 && (
                            <button
                              className="btn"
                              style={{ fontSize: "14px" }}
                              onClick={() => toggleShowAllNotification()}
                            >
                              View All
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <a href="javascript:void(0);">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/bell.svg`} alt="" />
                  </a> */}

                  {/* <a href="/">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/Language.svg`} alt="" />
                  </a> */}
                </div>
              </div>
            ) : (
              <div className="btn loginbtns">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    dispatch(setShowLoginPopup(true));
                    dispatch(setLoginTab("1"));
                  }}
                >
                  Login
                </a>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    dispatch(setShowLoginPopup(true));
                    dispatch(setLoginTab("3"));
                  }}
                >
                  <span className="name">Teach on zidni</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </section>
      {showCart && <Cart closecart={toggle} />}

      <AllNotificationsModal
        showAllNotification={showAllNotification}
        toggleShowAllNotification={toggleShowAllNotification}
        notifications={notifications}
        handleDeleteNotification={handleDeleteNotification}
      />
    </>
  );
};

export default SearchBar;
