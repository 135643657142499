import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginTab, setShowLoginPopup } from "../../store/actions/userActions";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import Cookies from "js-cookie";

const HomepageBanner = ({ dashboardData }) => {
  const userDetails = useSelector((state) => state.root.userDetails);
  const dispatch = useDispatch();
  // console.log("DashboardData>>>", dashboardData);
  // const [pageNumber, setPageNumber] = useState(0);
  // const [Banners, setBanners] = useState([])
  // const [CallApi, setCallApi] = useState(true);

  // useEffect(() => {
  //   if (CallApi == true) {
  //     setCallApi(false);
  //     let Data = {
  //       where: {
  //       },
  //       pageNumber: pageNumber,
  //       limit: 50,
  //     };
  //     const apiUrl =
  //       APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `banner/list`;
  //     callApi(apiUrl, { method: "POST", data: Data }).then((rseponse) => {
  //       if (rseponse.status === true) {
  //         setBanners(rseponse?.data?.banner);
  //       }
  //     });
  //   }
  // }, [CallApi]);

  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner">
            <div className="img mb-3">
              <img src={`${process.env.PUBLIC_URL}/assets/images/Group 1185.png`} alt="" />
            </div>
            <div className="banner-content">
              <h1>Enhancing Your Skills in the Digital Age.</h1>
              {!Cookies.get('zidniToken') &&
                <div className="banner-btn">
                  <a href="javascript:void(0);" className="try-btn bg-primary text-white round" onClick={() => { dispatch(setShowLoginPopup(true)); dispatch(setLoginTab("3")) }}>
                    Try for Zidni instructor
                  </a>
                  <a href="javascript:void(0);" className="join-btn round" onClick={() => { dispatch(setShowLoginPopup(true)); dispatch(setLoginTab("2")) }}>
                    Join as student
                  </a>
                </div>
              }
            </div>
          </div>
          <ul className="count">
            <li>
              <span className="num martelsans">({dashboardData?.university ? `${dashboardData?.university}` : 0})</span>
              <span className="name">Universities partners</span>
            </li>
            <li>
              <span className="num martelsans">({dashboardData?.instructor ? dashboardData?.instructor : 0})</span>
              <span className="name">Expert Instructors </span>
            </li>
            <li>
              <span className="num martelsans">({dashboardData?.student ? dashboardData?.student : 0})</span>
              <span className="name">Registered Students</span>
            </li>
            <li>
              <span className="num martelsans">({dashboardData?.courseData?.allPublishedCourses ? dashboardData?.courseData?.allPublishedCourses : 0})</span>
              <span className="name">Course enrolments</span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default HomepageBanner;
