import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import Multiselect from "multiselect-react-dropdown";
import { useLocation } from "react-router-dom";
import {
  sendErrorInfo,
  sendErrorMessage,
  sendSuccessMessage,
} from "../../utility/helperFunctions";

const CreateEventDetail = ({
  navigate,
  activeTab,
  toggleTab,
  setEventId,
  eventDetail,
  setEventDetail,
  getEventDetail,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all" });
  const [eventImg, setEventImg] = useState(null);
  const [eventImgPreview, setEventImgPreview] = useState(null);
  const [eventTags, setEventTags] = useState([]);
  const [category, setCategory] = useState([]);
  const [btnActive, setBtnActive] = useState(false);

  const onSelect = (selectedList) => {
    setEventTags(selectedList);
  };

  const onRemove = (selectedList) => {
    setEventTags(selectedList);
  };

  const location = useLocation();
  useEffect(() => {
    if (
      location?.state?.eventId !== undefined ||
      eventDetail?.id !== undefined
    ) {
      reset({
        eventName: eventDetail?.eventName,
        eventDescription: eventDetail?.eventDescription,
        organisedBy: eventDetail?.organisedBy,
        eventImage: eventDetail?.eventImage,
      });

      setEventImg(eventDetail?.eventImage);
      setEventImgPreview(eventDetail?.eventImage);
    }
  }, []);

  useEffect(() => {
    const url =
      APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "category/list";
    callApi(url).then((response) => {
      if (response.status === true) {
        setCategory(response?.data);
        if (
          location?.state?.eventId !== null ||
          eventDetail?.id !== undefined
        ) {
          if (eventDetail?.eventTags) {
            if (JSON.parse(eventDetail?.eventTags).length) {
              setEventTags(JSON.parse(eventDetail?.eventTags || "[]"));
            }
          }
        }
      }
    });
  }, []);

  const onSubmit = (data) => {
    if (eventImg !== null) {
      setBtnActive(true);
      let eventData = new FormData();
      eventData.append("eventName", data?.eventName);
      eventData.append("eventDescription", data?.eventDescription);
      eventData.append("eventImage", eventImg);
      eventData.append("eventTags", JSON.stringify(eventTags));
      eventData.append("organisedBy", data?.organisedBy);

      if (location.state?.eventId !== undefined) {
        const apiUrl =
          APIURL.EVENT_URL +
          APIENDPOINTS.EVENT_END_POINT +
          `event/update/${eventDetail?.id}`;
        const multipart = true;
        callApi(apiUrl, { method: "PUT", data: eventData, multipart }).then(
          (res) => {
            if (res.status == true) {
              sendSuccessMessage(res);
              getEventDetail();
              toggleTab(activeTab + 1);
              setBtnActive(false);
            } else {
              sendErrorMessage(res);
              setBtnActive(false);
            }
          }
        );
      } else {
        const apiUrl =
          APIURL.EVENT_URL + APIENDPOINTS.EVENT_END_POINT + "event/create";
        const multipart = true;
        callApi(apiUrl, { method: "POST", data: eventData, multipart }).then(
          (res) => {
            if (res.status === true) {
              //  setAllCourseData(res.data);
              sendSuccessMessage(res);
              toggleTab(activeTab + 1);
              setEventId(res.data?.id);
              setEventDetail(res.data);
              setBtnActive(false);
            } else {
              sendErrorMessage(res);
              setBtnActive(false);
            }
          }
        );
      }
    } else {
      sendErrorInfo("Please upload image file");
    }
  };

  const handleUploadEventImg = (e) => {
    const allowedExtensions = /\.(jpg|jpeg|png|gif)$/i;

    if (!e?.target?.files[0]) {
      sendErrorInfo("Please Upload File");
      return;
    }

    const fileType = e.target.files[0].type;
    console.log("File type:", fileType);

    if (!allowedExtensions.test(e.target.files[0].name)) {
      sendErrorInfo("Please Upload Image File");
      return;
    } else {
      setEventImg(e.target.files[0]);
      setEventImgPreview(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4></h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          <div className="mb-3">
            <label for="graphicdesign" className="form-label">
              Event title
            </label>
            <input
              type="text"
              className="form-control"
              id="graphicdesign"
              aria-describedby="emailHelp"
              placeholder="Enter event title here..."
              {...register("eventName", {
                required: "Event title is required",
              })}
            />
            <p className="mb-0 text-danger">{errors?.eventName?.message}</p>
          </div>

          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              Event description
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              placeholder="Enter event description here..."
              {...register("eventDescription", {
                required: "Event title is required",
              })}
            ></textarea>
            <p className="mb-0 text-danger">
              {errors?.eventDescription?.message}
            </p>
          </div>
          <div className="mb-3">
            <label className="select-label" for="addeve">
              Add event tags
            </label>
            <Multiselect
              displayValue="categoryName"
              options={category}
              onSelect={onSelect}
              onRemove={onRemove}
              selectedValues={eventTags}
              showArrow
            />
          </div>
          <div className="mb-3 upload-img">
            <label for="formFile" className="form-label">
              <div className="updv">
                {eventImgPreview == null ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M20 1V39M1 19.9999H39"
                      stroke="#C1C1C1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <img
                    className="mainuploadimg "
                    src={eventImgPreview}
                    alt=""
                  />
                )}
              </div>
              <div type="button" className="btn btn-primary">
                Upload image
              </div>
            </label>
            <input
              className="form-control "
              type="file"
              id="formFile"
              accept="image/*"
              placeholder="Upload your event image here..."
              {...register(`eventImage`, {
                // required: "Event image is required",
                onChange: (e) => handleUploadEventImg(e),
              })}
            />

            <p className="mb-0 text-danger">{errors?.eventImage?.message}</p>
          </div>
          <div className="mb-3">
            <label for="graphicdesign" className="form-label">
              Organised By
            </label>
            <input
              type="text"
              className="form-control"
              id="graphicdesign"
              aria-describedby="emailHelp"
              placeholder="Enter event organised by here..."
              {...register("organisedBy", {
                required: "Organised by is required",
              })}
            />
            <p className="mb-0 text-danger">{errors?.organisedBy?.message}</p>
          </div>
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 7 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    {btnActive == true ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Next"
                    )}
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default CreateEventDetail;
