import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import Slider from "react-slick";
import Cookies from "js-cookie";
import Rating from "react-rating";
import { noImage } from "../../pages/live/utils/helpers";

const WhatsNewSection = ({ dashboardData, liveLecture }) => {
  const [loadScript, setLoadScript] = useState(true);
  const [CallApi, setCallApi] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [activeList, setActiveList] = useState("course");

  useEffect(() => {
    if (CallApi) {
      setCallApi(false);
      let Data = {
        where: {
          courseStatus: 1
        },
        paranoid: true,
        pageNumber: 0,
        limit: 50,
      };
      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "course/list";
      callApi(apiUrl, { method: "POST", data: Data }).then((res) => {
        if (res?.status === true) {
          setCourseList(res?.data?.course);
          // console.log("mnxshs", res.data.course);
        }
      });

      let Data2 = {
        where: {
          eventStatus: 1
        },
        paranoid: true,
        pageNumber: 0,
        limit: 50,
      };
      const apiUrl1 =
        APIURL.COURSE_URL + APIENDPOINTS.EVENT_END_POINT + "event/list";
      callApi(apiUrl1, { method: "POST", data: Data2 }).then((res) => {
        if (res?.status === true) {
          setEventList(res?.data?.event);
        }
      });
    }
  }, [CallApi]);

  const getCourseValue = () => {
    const progress = Math.round(
      (dashboardData?.courseData?.allPublishedCourses /
        dashboardData?.courseData?.allCourses) *
      100
    );
    return `${progress}%` ?? "0%";
  };

  const getEventValue = () => {
    const progress = Math.round(
      (dashboardData?.eventData?.allPublishedEvents /
        dashboardData?.eventData?.allEvent) *
      100
    );
    return `${progress}%` ?? "0%";
  };

  return (
    <>
      {/* whats-new start */}
      <section className="whats-new">
        <div className="heading">
          <h2>What’s new on Zidni </h2>
          <p>
            We are presenting all in one solution for everyone on Zidni academy , Explore courses, events, webinars and more.
          </p>
        </div>
        <div className="container">
          <div className="tab-section">
            <div className="left">
              <h4>Explore new courses</h4>
              <ul className="tab">
                <li
                  className={
                    activeList == "course" ? "tablinks active" : "tablinks"
                  }
                  id="defaultOpen"
                  onClick={() => setActiveList("course")}
                >
                  Courses
                </li>
                {/* <li className="tablinks">Module</li>
                <li className="tablinks">Certificates</li> */}
                <li
                  className={
                    activeList == "Lecture" ? "tablinks active" : "tablinks"
                  }
                  id="defaultOpen"
                  onClick={() => setActiveList("Lecture")}
                >Live Lecture </li>
                <li
                  className={
                    activeList == "event" ? "tablinks active" : "tablinks"
                  }
                  onClick={() => setActiveList("event")}
                >
                  Events
                </li>
              </ul>
              <h4>
                Courses focused on building strong foundational skills for
                career growth
              </h4>
            </div>
            {activeList == "course" ? (
              <div className="contents">
                <div className="right tabcontent" id="tabA">
                  <div className="progress-sec">
                    <div className="progress-bar-a">
                      <p>Total courses</p>
                      <div className="progress">
                        <div
                          className="progress-value"
                          style={{ width: `${getCourseValue()}` }}
                        />
                        <span>
                          {dashboardData?.courseData?.allPublishedCourses}
                        </span>
                      </div>
                    </div>
                    <div className="progress-bar-b">
                      <p>
                        Courses added in this month{" "}


                      </p>
                      <div className="progress">
                        <div
                          className="progress-value"
                          style={{ width: `${getCourseValue()}` }}
                        />
                        <span>
                          {dashboardData?.courseData?.currentMonthCourseCount}
                        </span>
                      </div>
                      {/* <div className="progress">
                        <div className="progress-value" style={{ width: `${getLectureProgress(item)}` }}/>
                        <span> 120</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="slide-bar">
                    <div className="head">
                      <h4>Recommend course for you</h4>{" "}
                      <Link to="/courses">View all</Link>
                    </div>
                    <div>
                      {courseList && courseList.length > 0 && (
                        <Slider {...settings1}>
                          {courseList &&
                            courseList
                              .filter((e) => e.deletedAt == null)
                              .map((item, key) => (
                                <>
                                  <div className="card" key={key}>
                                    <div className="img">
                                      <img src={item?.courseImage} alt="" />
                                    </div>
                                    <div className="bottom-sec">
                                      <div className="content">
                                        <Link
                                          to={`/course-detail/${item?.id}`}
                                          state={{
                                            insDetail: item?.instructor,
                                          }}
                                        >
                                          <div className="heading">
                                            {item?.courseName}
                                          </div>
                                        </Link>
                                        <button className="category">
                                          {item?.Category?.categoryName}
                                        </button>
                                        <div className="review  justify-content-between d-flex">
                                          <span className="star">
                                            <Rating
                                              readonly
                                              initialRating={
                                                item?.averageRating
                                              }
                                              emptySymbol={
                                                <i
                                                  className="fa-regular fa-star"
                                                  style={{ color: "grey" }}
                                                ></i>
                                              }
                                              fullSymbol={
                                                <i
                                                  className="fa-solid fa-star"
                                                  style={{ color: "gold" }}
                                                ></i>
                                              }
                                            />
                                            {/* <img src={`${process.env.PUBLIC_URL}/assets/images/star.svg`} alt="" />
                                               4.8 (233K Reviews) */}
                                          </span>
                                          <div className="credit">Credit hr:{item?.courseTimeDurationAndCreditHour ? JSON.parse(item?.courseTimeDurationAndCreditHour)?.courseCreditHour : ''}</div>
                                          {/* <div className="credit">Credit hr:20</div> */}
                                        </div>
                                      </div>
                                      <div className="price-sec">
                                        <div className="card-price">
                                          {JSON?.parse(item?.coursePricing)
                                            ?.actualValue != null ||
                                            JSON?.parse(item?.coursePricing)
                                              ?.actualValue != undefined ? (
                                            <>
                                              <div>
                                                <span
                                                  className={
                                                    JSON?.parse(
                                                      item?.coursePricing
                                                    )?.actualValue !=
                                                    undefined &&
                                                    "text-muted text-decoration-line-through fs-6"
                                                  }
                                                >
                                                  {" "}
                                                  {item?.coursePricing &&
                                                    JSON?.parse(
                                                      item?.coursePricing
                                                    )?.coursePaymentType == "Paid"
                                                    ? `$ ${JSON?.parse(
                                                      item?.coursePricing
                                                    )?.coursePricing
                                                    }`
                                                    : "Free"}
                                                </span>

                                                <span>
                                                  {" "}
                                                  {item?.coursePricing &&
                                                    JSON?.parse(
                                                      item?.coursePricing
                                                    )?.coursePaymentType == "Paid"
                                                    ? `$ ${JSON?.parse(
                                                      item?.coursePricing
                                                    )?.actualValue.toFixed(
                                                      2
                                                    )}`
                                                    : "Free"}
                                                </span>
                                              </div>
                                              <span className="fs-6">
                                                (University discount)
                                              </span>
                                            </>
                                          ) : (
                                            <span>
                                              {" "}
                                              {item?.coursePricing &&
                                                JSON?.parse(item?.coursePricing)
                                                  ?.coursePaymentType == "Paid"
                                                ? `$ ${JSON?.parse(
                                                  item?.coursePricing
                                                )?.coursePricing
                                                }`
                                                : "Free"}
                                            </span>
                                          )}
                                        </div>
                                        <div className="price-category">
                                          Course by:{" "}
                                          {item?.instructor?.firstName}{" "}
                                          {item?.instructor?.lastName}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                        </Slider>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : activeList == "event" ? (
              activeList == "event" && (
                <div className="contents">
                  <div className="right tabcontent" id="tabA">
                    <div className="progress-sec">
                      <div className="progress-bar-a">
                        <p>Total events</p>
                        <div className="progress">
                          <div
                            className="progress-value"
                            style={{ width: `${getEventValue()}` }}
                          />
                          <span>
                            {" "}
                            {dashboardData?.eventData?.allPublishedEvents}
                          </span>
                        </div>
                      </div>
                      <div className="progress-bar-b">
                        <p>
                          Events added in this month :{" "}
                          <span>
                            {" "}
                            {dashboardData?.eventData?.currentMonthEventCount}
                          </span>
                        </p>
                        {/* <div className="progress">
                          <div className="progress-value" />
                          <span> 120</span>
                        </div> */}
                      </div>
                    </div>
                    <div className="slide-bar">
                      <div className="head">
                        <h4>Recommend events for you</h4>{" "}
                        <Link to="/events">View all</Link>
                      </div>
                      <Slider {...settings1}>
                        {eventList &&
                          eventList
                            .filter((e) => e.deletedAt == null)
                            .map((item, key) => (
                              <>
                                <div className="card" key={key}>
                                  <div className="img">
                                    <img src={item?.eventImage} alt="" />
                                  </div>
                                  <div className="bottom-sec">
                                    <div className="content">
                                      <Link
                                        to={`/event-detail/${item?.id}`}
                                      >
                                        <div className="heading">
                                          {item?.eventName}
                                        </div>
                                      </Link>
                                      {item?.eventTags &&
                                        JSON.parse(item?.eventTags)?.map(
                                          (item2, key2) => (
                                            <button
                                              className="category"
                                              key={key2}
                                            >
                                              {item2?.categoryName}
                                            </button>
                                          )
                                        )}
                                    </div>
                                    <div className="price-sec">
                                      <div className="card-price">
                                        {item?.eventPricing?.actualValue !=
                                          undefined ? (
                                          <>
                                            <div>
                                              <span
                                                className={
                                                  item?.eventPricing
                                                    ?.actualValue !=
                                                  undefined &&
                                                  "text-muted text-decoration-line-through fs-6"
                                                }
                                              >
                                                {" "}
                                                {item?.eventPricing &&
                                                  item?.eventPricing?.eventType ==
                                                  "Paid"
                                                  ? `$ ${item?.eventPricing?.eventPrice}`
                                                  : "Free"}
                                              </span>

                                              <span>
                                                {" "}
                                                {item?.eventPricing &&
                                                  item?.eventPricing?.eventType ==
                                                  "Paid"
                                                  ? `$ ${item?.eventPricing?.actualValue.toFixed(
                                                    2
                                                  )}`
                                                  : "Free"}
                                              </span>
                                            </div>
                                            <span className="fs-6">
                                              (University discount)
                                            </span>
                                          </>
                                        ) : (
                                          <span>
                                            {" "}
                                            {item?.eventPricing &&
                                              item?.eventPricing?.eventType ==
                                              "Paid"
                                              ? `$ ${item?.eventPricing?.eventPrice}`
                                              : "Free"}
                                          </span>
                                        )}
                                      </div>
                                      <div className="price-category">
                                        Organizer by: {item?.organisedBy}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="contents">
                <div className="right tabcontent" id="tabA">
                  <div className="slide-bar">
                    <div className="head">
                      <h4>Recommend Live Lecture for you</h4>{" "}
                      <Link to="/webinars">View all</Link>
                    </div>
                    <Slider {...settings1}>
                      {liveLecture &&
                        liveLecture
                          .filter((e) => e.deletedAt == null)
                          .map((item, key) => (
                            <>
                              <div className="card" key={key}>
                                <div className="img">
                                  <img src={item?.liveImage ? item?.liveImage : noImage} alt="" />
                                </div>
                                <div className="bottom-sec">
                                  <div className="content">
                                    <Link
                                      to={"/live-webinar/" + item.channelName}
                                    >
                                      <div className="heading">
                                        {item?.channelName}
                                      </div>
                                    </Link>
                                    <p>{item?.liveDescription}</p>
                                    {/* <div className="date">{item?.createdAt?.split('T')?.[0]}</div> */}
                                  </div>
                                  <div className="price-sec">
                                    <div className="price-category">
                                      Organizer by: {item?.userDetail?.Profile?.firstName} {item?.userDetail?.Profile?.lastName}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                    </Slider>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* whats-new end */}
    </>
  );
};

export default WhatsNewSection;

var settings1 = {
  dots: false,
  navs: true,
  infinite: true,
  speed: 300,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};