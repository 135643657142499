import React, { useEffect, useState } from "react";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { Link } from "react-router-dom";

const JoinSection = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [allUniversity, setAllUniversity] = useState([])

  const [CallApi, setCallApi] = useState(true);
  useEffect(() => {
    if (CallApi === true) {
      setCallApi(false);
      let eventData = {
        where: {
        },
        pageNumber: pageNumber,
        limit: 50,
      };
      const apiUrl =
        APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + `university/get`;
      callApi(apiUrl, { method: "POST", data: eventData }).then((rseponse) => {
        if (rseponse.status === true) {
          setAllUniversity(rseponse.data.university);
        }
      });
    }
  }, [CallApi]);
  return (
    <>
      <section className="join-sec">
        <div className="container">
          <div className="left-section">
            <h2>
              Join our top leading universities partners that choose zidni for
              <span className="inner-heading"> career growth</span>
            </h2>
            <p>We collaborate with 100+ leading university partners</p>
            <Link to="/universities">View all universities</Link>
          </div>
          <div className="img-sec">
            <ul>
              {allUniversity && allUniversity.length > 0 && allUniversity.map((item, key) => (
                <li key={key}>
                  <Link to={'/university-detail'} state={{ id: item?.userId }}>
                    <img src={item?.universityImage} alt="" />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinSection;
