import axios from "axios";
import { getToken } from "../utility/helperFunctions.js";
import Cookies from "js-cookie";

//Services URL
// const baseURL = {
//   auth: process.env.NEXT_PUBLIC_BASE_URL_AUTH,
//   catalog: process.env.NEXT_PUBLIC_BASE_URL_CATELOG,
//   fileservice: process.env.NEXT_PUBLIC_BASE_URL_FILESERVICE,
// };

/**
 * This function call apis via axios.
 *
 * @param {string} url  - base url apis.
 * @param {object} options - api options like method data and multipart .
 * @returns response of api
 */

let source = null;

export const callApi = async (url, options, tokens) => {
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  // const token = getToken();
  let token;
  let headers = {}
  if (Cookies.get("zidniToken") || tokens) {
    token = Cookies.get("zidniToken") || tokens;
    headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type":
        options?.multipart === true ? "multipart/form-data" : "application/json",
    };
  } else {
    headers = {
      "Content-Type":
        options?.multipart === true ? "multipart/form-data" : "application/json",
    };
  }

  if (options?.headers) {
    headers = { ...headers, ...options.headers };
  }
  try {
    const res = await axios(url, {
      headers: headers,
      cancelToken: source.token,
      ...(options?.multipart === true && {
        onUploadProgress: options.progressEvent,
      }),
      method: options?.method || "GET",

      data:
        options?.data && options?.multipart === true
          ? options?.data
          : options?.data
            ? JSON.stringify(options?.data)
            : undefined,
    });

    // if (!res.statusText) throw new Error(res.data.message);
    // console.log("resss", res.data);
    return res.data;
  } catch (error) {
    if (error?.response?.data && error?.response?.data?.message == "jwt expired") {
      GetAccessData(9000, 'Z0001/api/v1/create-access', {}).then((res) => {
        if (res?.status == true) {
          Cookies.set("zidniToken", res?.data?.accessToken);
          Cookies.set('refreshZidniToken', res?.data?.refreshToken)
          axios({
            url,
            method: options?.method || 'GET',
            headers: {
              Authorization: `Bearer ${res?.data?.accessToken}`,
              'Content-Type': options?.multipart ? 'multipart/form-data' : 'application/json',
            },
            data: options?.multipart ? options?.data : options?.data ? JSON.stringify(options?.data) : undefined,
            cancelToken: source.token,
          }).then((res) => {
            return res?.data;

          });


        } else {
          return error.response;
        }
      })

    } else if (axios.isCancel(error)) {
      return { status: false, message: 'Request canceled by user' };
    }
    else if (error?.response) {
      return error.response;
    } else {
      let err = {
        status: false,
        error: error
      }
      // console.log("error", error);
      return err;
    }
  } finally {
    // dispatch(setLoading(false))
  }
};

export const cancelRequest = () => {
  if (source) {
    source.cancel('Request canceled by user');
    source = null;
  }
};

const AuthorizeApiHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer OGE4Mjk0MTc0YjdlY2IyODAxNGI5Njk5MjIwMDE1Y2N8c3k2S0pzVDg=`,
  // Authorization: `Bearer OGFjN2E0Yzk4Y2Y1NmYwMzAxOGNmN2I4Yzk3ZjAyY2V8ZENQZFlHNlRueUhrQ05ONA==`,
};


export const PostCheckoutRequest = async ({ body, url }) => {
  console.log('url:', url);
  console.log('body:', body);
  try {

    const controller = new AbortController();
    setTimeout(() => controller.abort(), 30000);
    const config = {
      method: 'POST',
      headers: AuthorizeApiHeader,
      body: body,
      signal: controller.signal,
    };
    console.log('config:', config);
    const response = await fetch(url, config);
    const result = await response.json();

    return result;
  } catch (e) {
    console.log('error:', e);
  }
};

export function GetAccessData(Port, url, data) {
  let tokens = "";

  if (Cookies.get("refreshZidniToken")) {
    tokens = Cookies.get("refreshZidniToken");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
    },
    params: {},
  };
  return axios
    .get(`https://dev.zidni.academy/` + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus;
      if (error.response) {
        errorStatus = JSON.parse(JSON.stringify(error.response));
      }
      //console.log(errorStatus.data);
      return errorStatus;
    });
}




// function getCookiesAsObject() {
//   return document.cookie.split(';').reduce((cookies, cookie) => {
//     const [name, value] = cookie.trim().split('=');
//     cookies[name] = value;
//     return cookies;
//   }, {});
// }
