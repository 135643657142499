import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header";
import { Link, useNavigate } from "react-router-dom";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const StudentPurchasedCourse = () => {
  const userDetail = useSelector((state) => state.root.userDetails);
  const [courseList, setCourseList] = useState([]);
  const navigate = useNavigate();
  const [CallApi, setCallApi] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [filterValue, setFilterValue] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [priceValue, setPriceValue] = useState('')
  const [sortByValue, setSortByValue] = useState('')
  const [sortByField, setSortByField] = useState('')
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    if (CallApi == true) {
      setCallApi(false);
      setLoader(true)
      let Data = {
        where: {
        },
        sortField: sortByField,
        sortType: sortByValue,
        pageNumber: pageNumber,
        limit: 100,
      };
      if (filterValue != '') {
        if (filterValue !== 'all') {
          Data.where['courseStatus'] = filterValue
        }
      }
      if (searchValue != '') {
        Data.where['courseName'] = searchValue
      }
      if (priceValue != '') {
        Data.where['coursePricing'] = priceValue
      }
      const apiUrl =
        APIURL.COURSE_URL + APIENDPOINTS.COURSE_END_POINT + "my-course/";
      callApi(apiUrl, { method: "GET", data: Data }).then((res) => {
        if (res.status == true) {
          setCourseList(res.data);
          console.log("mnxshs", res.data);
          setLoader(false)
        } else {
          setLoader(false)
        }
      });
    }
  }, [CallApi]);

  const handleDeleteCourse = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this course!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL.COURSE_URL +
          APIENDPOINTS.COURSE_END_POINT +
          `course/delete/${id}`;
        callApi(apiUrl1, { method: "DELETE" }).then((response) => {
          if (response.status === true) {
            sendSuccessInfo(response.message);
            setCallApi(true);
          } else {
            sendErrorInfo(response.data.message);
          }
        });
      }
    });
  };

  const handelFilter = (value) => {
    if (value == 'Paid' || value == 'Free') {
      setPriceValue(value)
      setFilterValue('')

    } else {
      setPriceValue('')
      setFilterValue(value)
    }
    setCallApi(true)
  }

  const handleSortBy = (value) => {
    if (value != sortByValue && value !== 'manual') {

      if (value == 'asc' || value == 'desc') {
        setSortByField('courseName')
        setSortByValue(value)
      } else {
        if (value == 'Date old') {
          setSortByValue('asc')
          setSortByField('createdAt')
        } else {
          setSortByValue('desc')
          setSortByField('createdAt')
        }
      }
      setCallApi(true)
    }
  }

  const handleSearch = (value) => {

    setSearchValue(value)
    setCallApi(true)
  }

  const getLectureProgress = (item) => {
    const length = item?.Progresses?.length;
    const isTrueItem = (length && length > 0) ? item?.Progresses?.filter(progress => progress?.isCompleted == true) : [];
    const numberOfIsTrueItem = isTrueItem?.length;
    const progress = Math.round(numberOfIsTrueItem / length * 100)
    return `${progress}%` ?? "0%"
  }

  const GetCertificate = (id) => {
    console.log("id", id)
    window.open(`https://dev.zidni.academy/Z0002/api/v1/download/certifcate/${id}`, '_blank');
  }

  return (
    <>
      <Header />
      {/* <section className="cr-search-bar">
        <div className="container">
          <div className="search-bar">
            <div className="ser">
              <input type="text" value={searchValue} onChange={(e) => handleSearch(e.target.value)} className="form-control" />
            </div>
            <div className="selects">
              <div className="fill-by">
                <select id="fil-by" name="fil_by" className="fil-by replaced" onChange={(e) => handelFilter(e.target.value)}>
                  <option value="all" >
                    All
                  </option>
                  <option value="Paid">Paid</option>
                  <option value="Free">Free</option>
                  <option value={0}>Draft</option>
                  <option value={1}>Publish</option>
                  <option value={2}>Send for review</option>
                  <option value={3}>Inactive</option>
                  <option value={4}>Unapproved</option>
                </select>
              </div>
              <div className="shor-by">
                <select
                  id="sort-by"
                  name="sort_by"
                  className="sort-by replaced"
                  onChange={(e) => handleSortBy(e.target.value)}
                >
                  <option value="manual">
                    Sort by
                  </option>
                  <option value={'asc'}>Alphabetically, A-Z</option>
                  <option value="desc">Alphabetically, Z-A</option>
                  <option value="Date old">Date, old to new</option>
                  <option value="Date new">Date, new to old</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {loader ? <Loader /> :
        <section className="my-course ">
          <div className="container">
            <div className="newscs mb-3">
              <div className="new-cont createboxcourses">
                <h4>Courses</h4>
                {/* <Link to="/create-courses" className="cre-new-btn">
                       Create new course
                     </Link> */}
              </div>
            </div>
            {courseList && courseList.length > 0 ? (
              <>
                {courseList.filter((e) => e.Course.deletedAt == null).map((item, key) => (
                  <div className="course-card round certificate-card" key={key}>
                    <div className="top">
                      <div className="left-content cursor-pointer" onClick={() => {
                        navigate(`/course-detail/${item?.Course?.id}`, {
                          state: { insDetail: item?.Course?.instructor },
                        });
                      }}>
                        <div className="card-image round"><img src={item?.Course?.courseImage} alt="" /></div>
                        <div className="card-detail">
                          <h4>{item?.Course?.courseName}</h4>
                          <p className="oph">{item?.Course?.Category?.categoryName}</p>
                          {getLectureProgress(item) == '100%' ?
                            (item.evaluationForm == null ?
                              <div className="congo">
                                <h5><span><img src="./assets/images/trophy.svg" alt="" /></span>Congratulations for the completion of this course</h5>
                              </div>
                              :
                              <div className="congo">
                                <h5><span><img src="./assets/images/trophy.svg" alt="" /></span>Congratulations on getting your certificate!</h5>
                              </div>
                            )
                            :
                            <div className="prog-bar">
                              <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                <div className="progress-bar bg-success" style={{ width: `${getLectureProgress(item)}` }}>{getLectureProgress(item)}</div>
                              </div>
                            </div>
                          }


                        </div>
                      </div>
                      {getLectureProgress(item) == '100%' &&
                        <div className="bottom justify-content-end">
                          <div className="sharebtn">
                            {/* <button className="share"><span><img src="./assets/images/share.svg" alt="" /></span>Share
                          certificate</button> */}
                            {item?.completedAt !== null && <button className="download" onClick={() => GetCertificate(item?.id)} ><span><img src="./assets/images/download.svg" alt="" /></span>
                              Download certificate</button>}
                          </div>
                          {item?.evaluationForm == null &&
                            <div className="eve">
                              <button className="add-eve" onClick={() => navigate("/course-evalution", {
                                state: { courseId: item?.Course?.id },
                              })}><span><img src="./assets/images/eve.svg" alt="" /></span> Add evolution
                              </button>
                            </div>
                          }
                        </div>}
                      {/* <div className="dropdown close-btn">
                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <img className="close" src="./assets/images/close-btn.svg" alt="" />
                          <img className="dot" src="./assets/images/tdbtn.svg" alt="" />
                        </button>
                        <ul className="dropdown-menu">
                          <li><a className="dropdown-item" href="#">Share course</a></li>
                          <li><a className="dropdown-item" href="#">Download certificate</a></li>
                          <li><a className="dropdown-item" href="#">Rate this course</a></li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="no-cou-yet">
                  <h3>No Course yet!</h3>
                  <p>Here’s where you’ll see your courses’</p>
                </div>
              </>
            )}
          </div>
        </section>
      }

    </>
  );
};

export default StudentPurchasedCourse;
