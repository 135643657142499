import React, { useState } from "react";
import Layout from "../../components/common/Layout";
import ZidniContactBanner from "../../components/homepage/ZidniContactBanner";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { sendSuccessInfo } from "../../utility/helperFunctions";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const resetForm = () => {
    reset();
  };

  const submitHandler = (data) => {
    // console.log("Data>>>>>>>>>>>>>>>>", data);
    // setIsLoading(true);
    const apiUrl = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "send-email";
    let Data = { ...data };
    callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
      if (response.status == true) {
        // setIsLoading(false);
        
      }
      sendSuccessInfo("YOUR QUERY SENT TO ZIDNI AUTHORITY");
      resetForm();
    });
  };

  return (
    <Layout>
      <section className="hdseccontact">
        <div className="container my-2 my-md-5">
          <div className="tpsec">
            <h2 className="text-center text-primary d-block d-md-flex align-items-center justify-content-center gap-3">
              Contact with
              <span
                className="d-block mt-3 mt-md-0 p-2 rounded"
                style={{ backgroundColor: "#156B68" }}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/zidni.png`}
                />
              </span>
            </h2>
            <p>
              If you have a question or would like to give feedback, you can get
              in touch with us by email, phone or by mail. You can also find us
              on social media.
            </p>
          </div>
        </div>
      </section>
      <section className="contactuspage">
        <div className="container">
          <div className="conmap">
            <div className="itembox">
              <h5>General Secretariat</h5>
              <p>
                The Arab Board of Health Specializations General Secretariat{" "}
              </p>
              <p>
                Phone : <a href="tel:+963 11 6119740">+963 11 6119740</a>
              </p>
              <p>
                Fax : <a href="tel:+963 11 6119259">+963 11 6119259</a>/{" "}
                <a href="tel:+963 11 6119739">6119739</a>
              </p>
            </div>
            <div className="itembox">
              <h5>General Secretariat</h5>
              <p>
                The Arab Board of Health Specializations General Secretariat{" "}
              </p>
              <p>
                Phone : <a href="tel:+963 11 6119740">+963 11 6119740</a>
              </p>
              <p>
                Fax : <a href="tel:+963 11 6119259">+963 11 6119259</a>/{" "}
                <a href="tel:+963 11 6119739">6119739</a>
              </p>
            </div>
            <div className="itembox">
              <h5>General Secretariat</h5>
              <p>
                The Arab Board of Health Specializations General Secretariat{" "}
              </p>
              <p>
                Phone : <a href="tel:+963 11 6119740">+963 11 6119740</a>
              </p>
              <p>
                Fax : <a href="tel:+963 11 6119259">+963 11 6119259</a>/{" "}
                <a href="tel:+963 11 6119739">6119739</a>
              </p>
            </div>
          </div>
          <div className="conforms">
            <form onSubmit={handleSubmit(submitHandler)}>
              <div className="mb-3">
                <label htmlFor="ContactStudentname" className="form-label mx-3">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  id="ContactStudentname"
                  name="name"
                  placeholder="your name "
                  {...register("name", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.name?.message}</p>
              </div>
              <div className="mb-3">
                <label htmlFor="ContactEmail" className="form-label mx-3">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control bg-white"
                  id="ContactEmail"
                  name="email"
                  placeholder="zidni@gmail.com"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                <p className="mb-0 text-danger">{errors?.email?.message}</p>
              </div>
              <div className="mb-3">
                <label htmlFor="contactSubject" className="form-label mx-3">
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control bg-white"
                  id="ContactEmail"
                  name="subject"
                  placeholder="subject"
                  {...register("subject", {
                    required: "Field is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.subject?.message}</p>
              </div>
              <div className="mb-3">
                <label htmlFor="contactmessage" className="form-label mx-3">
                  Message
                </label>
                <textarea
                  className="form-control bg-white"
                  placeholder="Type your message..."
                  id="contactmessage"
                  rows="5"
                  name="message"
                  {...register("message", {
                    required: "Field is required",
                  })}
                ></textarea>
                <p className="mb-0 text-danger">{errors?.message?.message}</p>
              </div>

              {isLoading ? (
                <div
                  className="btn btn-success m-t-15"
                  style={{ width: "200px" }}
                >
                  <span className="spinner-border spinner-border-sm mx-1"></span>
                </div>
              ) : (
                <button
                  type="submit"
                  className="btn btn-success m-t-15 "
                  style={{ width: "200px" }}
                >
                  Verify and continue
                </button>
              )}
              {/* <div>
                <button
                  class="btn btn-primary w-100 py-2 py-md-3"
                  type="submit"
                >
                  Verify and continue
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </section>

      <ZidniContactBanner />
    </Layout>
  );
};

export default ContactUs;
