import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useLocation } from "react-router-dom";
import { sendErrorMessage, sendSuccessMessage } from "../../utility/helperFunctions";

const EventDateTime = ({
  navigate,
  activeTab,
  toggleTab,
  eventId,
  eventDetail,
  getEventDetail
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch
  } = useForm({ mode: "all" });

  const startDate = new Date().toISOString().split("T")[0];
  const [endDate, setEndDate] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [searchTextLength, setSearchTextLength] = useState("");
  const [showAddress, setShowAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    name: "",
    latlng: {
      lat: null,
      lng: null,
    },
  });

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.eventId !== undefined || eventDetail?.id !== undefined) {
      reset({
        startDate: eventDetail?.eventTimeLocation?.eventStartDate,
        endDate: eventDetail?.eventTimeLocation?.eventEndDate,
        startTime: eventDetail?.eventTimeLocation?.eventStartTime,
        endTime: eventDetail?.eventTimeLocation?.eventEndTime,
        location: eventDetail?.eventTimeLocation?.selectedAddress?.name,
      });
      setSelectedAddress({
        name: eventDetail?.eventTimeLocation?.selectedAddress?.name,
        latlng: {
          lat: eventDetail?.eventTimeLocation?.selectedAddress?.latlng?.lat,
          lng: eventDetail?.eventTimeLocation?.selectedAddress?.latlng?.lng,
        },
      })
    }
  }, []);
  let startFinalDate = watch("startDate");

  const handleSearch = async (text) => {
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `fetch-locations/${text}`;

    setSearchTextLength(text);
    if (text == "") {
      setAddressList([]);
    }
    if (text.length > 3) {
      callApi(apiUrl, { method: "GET" }).then((response) => {
        if (response.status === true) {
          if (response.data.predictions.length > 0) {
            setAddressList(response.data.predictions);
            setShowAddress(false);
          } else {
            setShowAddress(true);
          }
        }
      });
    }
  };

  const handleSelectPlace = (placeId) => {
    const selectedPlace = addressList.find(
      (place) => place.place_id === placeId
    );

    if (selectedPlace) {
      setValue("location", selectedPlace.description);
    }
    console.log("selectedPlace", selectedPlace.description);
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `fetch-lat-long/${placeId}`;
    callApi(apiUrl, { method: "GET" }).then((response) => {
      if (response.status === true) {
        // console.log("res.......", response);
        let res = response.data.result;
        setSelectedAddress({
          name: selectedPlace.description,
          latlng: {
            lat: res.geometry.location.lat,
            lng: res.geometry.location.lng,
          },
        });
      }
    });
  };

  const onSubmit = (data) => {
    let eventTimeLocationData = {
      eventTimeLocation: {
        eventStartDate: data.startDate,
        eventEndDate: data.endDate,
        eventStartTime: data.startTime,
        eventEndTime: data.endTime,
        selectedAddress: selectedAddress ,
      },
    };

    // console.log("eventTimeLocation", eventTimeLocationData);
    const apiUrl =
      APIURL.EVENT_URL +
      APIENDPOINTS.EVENT_END_POINT +
      `event/update/${eventId ? eventId : eventDetail?.id}`;

    callApi(apiUrl, { method: "PUT", data: eventTimeLocationData }).then(
      (response) => {
        if (response.status === true) {
          sendSuccessMessage(response);
          getEventDetail()
          toggleTab(activeTab + 1);
        } else {
          sendErrorMessage(response);
        }
      }
    );
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4></h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details">
        <form>
          <div className="date-picking">
            <ul>
              <li>
                <label className="select-label" htmlFor="startdate">
                  Start date
                </label>
                <input
                  className="date"
                  id="startdate"
                  name="startdate"
                  type="date"
                  min={startDate}
                  {...register("startDate", {
                    required: "Start date is required",
                    onChange: (e) => setEndDate(e.target.value),
                  })}
                />
                <p className="mb-0 text-danger">{errors?.startDate?.message}</p>
              </li>
              <li>
                <label className="select-label" htmlFor="startTime">
                  End time
                </label>
                <input
                  className="time"
                  type="time"
                  id="startTime"
                  {...register("startTime", {
                    required: "Start time is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.startTime?.message}</p>
              </li>
              <li>
                <label className="select-label" htmlFor="enddate">
                  End date
                </label>
                <input
                  className="date"
                  type="date"
                  id="enddate"
                  {...register("endDate", {
                    required: "End date is required",
                  })}
                  min={startFinalDate}
                />
                <p className="mb-0 text-danger">{errors?.endDate?.message}</p>
              </li>
              <li>
                <label className="select-label" htmlFor="endtime">
                  End time
                </label>
                <input
                  className="time"
                  type="time"
                  id="endtime"
                  {...register("endTime", {
                    required: "End time is required",
                  })}
                />
                <p className="mb-0 text-danger">{errors?.endTime?.message}</p>
              </li>
            </ul>
          </div>

          <div className="mb-3 position-relative">
            <label for="graphicdesign" className="form-label">
              Event Location
            </label>
            <input
              type="text"
              className="form-control"
              id="graphicdesign"
              aria-describedby="emailHelp"
              placeholder="Enter location here"
              {...register("location", {
                onChange: (e) => handleSearch(e?.target?.value),
                required: "Location is required",
              })}
            />
            <p className="mb-0 text-danger">{errors?.location?.message}</p>
            {showAddress == false && (
              <>
                {searchTextLength?.length > 3 && (
                  <div className="searchaddresslisting rounded-4">
                    <ul className="list-unstyled">
                      {addressList &&
                        addressList?.length > 0 &&
                        addressList?.map((place, index) => (
                          <li key={index}>
                            <a
                              href="javascript:void(0)"
                              onClick={() => {
                                handleSelectPlace(place?.place_id);
                                setShowAddress(true);
                              }}
                              className="w-100 text-truncate"
                              title={place?.description}
                            >
                              {place?.description}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </>
            )}
          </div>

          {/* <div className="mb-3">
            <label className="select-label" for="evenetloc">
              Event Location
            </label>
            <select
              className="form-select"
              id="evenetloc"
              aria-label="Default select example"
            >
              <option selected>Pick your location here.. </option>
              <option value="1">e.g.Ophthalmology </option>
            </select>
          </div> */}
          <section className="step-section">
            <div className="step-full">
              <div className="step-content">
                <h5 className="steps">
                  Step / <span>{activeTab}</span>
                </h5>
                {activeTab !== 1 && activeTab !== 7 && (
                  <a
                    href="javascript:void(0)"
                    onClick={handleSubmit(onSubmit)}
                    className="next"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
};

export default EventDateTime;
