import React, { useEffect, useState } from "react";
import { callApi } from "../../api/ApiHelper";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { useLocation } from "react-router-dom";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";

const CourseResources = ({
  navigate,
  activeTab,
  allCourseData,
  setAllCourseData,
  getCourseDetail,
  toggleTab,
}) => {
  const [fields, setFields] = useState(['']);

  const location = useLocation()

  useEffect(() => {
    if (location.state?.courseId !== undefined || allCourseData.id !== undefined) {
      let data = JSON.parse(allCourseData.courseResources)
      if (data.length == 0) {
        setFields([""])
      } else {
        setFields(JSON.parse(allCourseData.courseResources))
      }
    }
  }, [])

  const addMore = () => {
    setFields([...fields, ""]);
  };

  const removeField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  const handleChange = (index, value) => {
    const values = [...fields];
    values[index] = value;
    setFields(values);
  };

  const onSubmit = () => {
    console.log("fikjk", fields);

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] == "") {
        sendErrorInfo("please fill valid input");
        return;
      }
    }
    const Data = new FormData();

    Data.append("courseResources", JSON.stringify(fields));

    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/update/${allCourseData.id}`;
    const multipart = true;

    callApi(apiUrl, { method: "PUT", data: Data, multipart }).then((res) => {
      if (res.status == true) {
        sendSuccessInfo(res.message);
        toggleTab(activeTab + 1);
        getCourseDetail();
      } else {
        sendErrorInfo(res.data.message);
      }
    });
  };

  return (
    <>
      <div className="new-cont nnn">
        <h4>What resources will the audience get in this course?</h4>
        <a
          href="javascript:void(0)"
          onClick={() => navigate(-1)}
          className="close-btn"
        >
          Close
        </a>
      </div>
      <div className="course-details learning-cri">
        {/* <form> */}
        <h5>This course includes </h5>
        {fields.map((field, key) => (
          <div key={key} className="mb-3">
            <div className="detail">
              <input
                type="text"
                className="form-control"
                value={field}
                placeholder="e.g. Learn most used Front End technologies and tools"
                onChange={(e) => handleChange(key, e.target.value)}
              />
              <div className="submi d-flex">
                <button className="btn btn-primary" onClick={addMore}>
                  <span>
                    <img src="./assets/images/pluswhite.svg" alt="" />
                  </span>
                  Add more
                </button>
                {fields.length > 1 && (
                  <button
                    className="btn btn-danger"
                    onClick={() => removeField(key)}
                  >
                    <span>
                      <img src="./assets/images/trash.svg" alt="" />
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
        {/* </form> */}
        <section className="step-section">
          <div className="step-full">
            <div className="step-content">
              <h5 className="steps">
                Step / <span>{activeTab}</span>
              </h5>
              {activeTab !== 1 && activeTab !== 8 && (
                <a
                  href="javascript:void(0)"
                  onClick={onSubmit}
                  className="next"
                >
                  Next
                </a>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CourseResources;
