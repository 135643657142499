import React, { useState } from 'react'
import Layout from './Layout'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { sendErrorInfo, sendSuccessInfo } from '../../utility/helperFunctions';
import { callApi } from '../../api/ApiHelper';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';


const QuizPage = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({ mode: "all" });
  const [allAnswer, setAllAnswer] = useState({ quizId: '', questions: [] })

  const handleChangeInput = (value, name, id) => {
    let answer = [...allAnswer.questions]
    if (answer.find((e) => e.id == id) && allAnswer?.questions.length>0) {
      let answerIndex = answer.findIndex((e) => e.id == id)
      // console.log("first",answer.questions[answerIndex])
      answer[answerIndex].correctAnswer = value
      setAllAnswer({
        quizId:location?.state?.quize?.Quiz?.id,
        questions:answer
        })
    } else {
      answer.push({
        id: id,
        correctAnswer: value
      })
      setAllAnswer({
      quizId:location?.state?.quize?.Quiz?.id,
      questions:answer
      })
    }
  }

  const HandleFillQuestion = (data) => {
    console.log(data)
    console.log("allAsswer",allAnswer)

    const Data = {
      curriculumId:location?.state?.quize?.Progresses?.[0]?.curriculumId,
      userCourseId:location?.state?.quize?.Progresses?.[0]?.userCourseId,
      isCompleted:true
    }
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `update-progress`;
    callApi(apiUrl, { method: "POST", data: Data, }).then((res) => {
      if (res.status == true) {
  
          sendSuccessInfo(res.message);
          navigate(-1)
        } else {
        sendErrorInfo(res.data.message);
      }
    });
  }
  return (
    <Layout>
      <div>
        <section className="quizsection">
          <form onSubmit={handleSubmit(HandleFillQuestion)}>
            <div className="container">
              <div className="quheading">
                <h3>{location?.state?.quize?.Quiz?.quizName}</h3>
                <span className="close"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" onClick={() => navigate(-1)}>
                  <path d="M32 3.38404L28.8444 0.228455L16.334 12.7389L3.82355 0.228455L0.667969 3.38404L13.1784 15.8945L0.667969 28.4049L3.82355 31.5605L16.334 19.0501L28.8444 31.5605L32 28.4049L19.4896 15.8945L32 3.38404Z" fill="black" />
                </svg></span>
              </div>
              <div className="totalques">
                Quiz:<span>{location?.state?.quize?.Quiz?.Questions?.length}</span>question
              </div>
              <div className="quizblock">
                <ul className="quizlists">
                  {location?.state?.quize?.Quiz?.Questions && location?.state?.quize?.Quiz?.Questions.length > 0 && location?.state?.quize?.Quiz?.Questions.map((item, key) => (
                    <li className="quizitem" key={key}>
                      <div className="quizques">{item?.question}?</div>
                      <div className="quoptions">
                        <div className="option">
                          {item?.options && JSON.parse(item?.options)?.map((option, index) => (
                            <div className="form-check" key={index}>
                              <label className="form-check-label" htmlFor={`quizA-${key}-${index}`}>
                                <span>{`${index + 1}>`}</span> {option}
                              </label>
                              <input className="form-check-input" type="radio" name={`quizQues1-${key}`} id={`quizA-${key}-${index}`}
                                value={index}
                                {...register(`question[${key}].correctAnswer`, {
                                  required: "question is required",
                                  onChange: (e) => handleChangeInput(e.target.value, e.target.name, item?.id)
                                })}
                              />
                            </div>

                          ))}
                          <p className="mb-0 text-danger">
                            {errors?.question?.[key]?.correctAnswer?.message}
                          </p>
                        </div>
                      </div>
                    </li>

                  ))}
                  {/* <li className="quizitem">
                  <div className="quizques">What is the condition when light rays focus in front of the retina instead of on? Also when looking through glasses of someone with this condition everything will appear small.</div>
                  <div className="quoptions">
                    <div className="option">
                      <div className="form-check">
                        <label className="form-check-label" for="quizA">
                          <span>A</span> Both
                        </label>
                        <input className="form-check-input" type="radio" name="quizQues2" id="quizA" />
                      </div>
                      <div className="form-check">
                        <label className="form-check-label" for="quizB">
                          <span>B</span> Never
                        </label>
                        <input className="form-check-input" type="radio" name="quizQues2" id="quizB" />
                      </div>
                      <div className="form-check">
                        <label className="form-check-label" for="quizC">
                          <span>C</span> Always
                        </label>
                        <input className="form-check-input" type="radio" name="quizQues2" id="quizC" />
                      </div>
                      <div className="form-check">
                        <label className="form-check-label" for="quizD">
                          <span>D</span> Only when determining medical decision making
                        </label>
                        <input className="form-check-input" type="radio" name="quizQues2" id="quizD" />
                      </div>
                    </div>
                  </div>
                </li> */}
                </ul>
                <div className="subbtn"><button type='submit' className="btn btn-success">Submit</button></div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </Layout>
  )
}

export default QuizPage
