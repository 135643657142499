import React from "react";
import Layout from "../../components/common/Layout";

const TermsConditions = () => {
  return (
    <Layout>
      <section className="terms-pgsec">
        <div className="container">
          <h2 className="mb-5">Terms & Conditions</h2>
          <div className="row">
            <div className="col-12">
              <div class="trems_descripstion">
                <h4 className="mb-2 font-weight-bold">Eligibility</h4>
                <p class="section__desc mb-4"> By using this website, you confirm that you are at least 13 years of age or older. If you are under the age of 13, you must obtain parental consent before accessing or using this website.</p>

                <h4 className="mb-2 font-weight-bold">Intellectual Property</h4>
                <p class="section__desc mb-4">All content on this website, including but not limited to text, graphics, images, logos, and software, is the property of The <strong>Arab Board of Health Specializations</strong> and is protected by international copyright laws.</p>

                <h4 className="mb-2 font-weight-bold">User Conduct</h4>
                <p class="section__desc mb-4">You agree to use this website only for lawful purposes and in a manner that does not infringe the rights of others or inhibit their use and enjoyment of the website. You must not upload or transmit any materials that are illegal, obscene, defamatory, or that violate any intellectual property rights.</p>

                <h4 className="mb-2 font-weight-bold">User Accounts</h4>
                <p class="section__desc mb-4">To access certain features of this website, you are required to create a user account. You are responsible for maintaining the confidentiality of your account login credentials and are liable for any activities that occur under your account.</p>

                <h4 className="mb-2 font-weight-bold">Privacy Policy</h4>
                <p class="section__desc mb-4">The website owner collects and uses personal information in accordance with its privacy policy, which can be found on this website.</p>

                <h4 className="mb-2 font-weight-bold">Limitation of Liability</h4>
                <p class="section__desc mb-4">The website owner will not be liable for any damages arising from the use or inability to use this website, including but not limited to direct, indirect, incidental, punitive, or consequential damages.</p>

                <h4 className="mb-2 font-weight-bold">Termination</h4>
                <p class="section__desc mb-4">The Arab Board of Health Specializations may terminate your access to this website at any time without notice for any reason.</p>
                <p class="section__desc mb-4">By accessing or using this website, you acknowledge that you have read, understood, and agree to be bound by these terms of use. If you do not agree to these terms, please do not use this website.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TermsConditions;
