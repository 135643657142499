import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/common/Layout';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { sendErrorInfo } from '../../../utility/helperFunctions';
const CreateConference = () => {
    const [activeTab, setActiveTab] = useState('create');
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        reset({ roomNameCreate: generateRandomRoomName() })
    }, [])
    const onSubmitCreate = data => {
        // Process create conference data
        navigate('/live-conference/' + data.roomNameCreate)
    };

    const onSubmitJoin = data => {
        // Process join conference data
        const match = pastedRoomNumber.match(/\zacademy(\w+)/);
        if (!match) {
            return sendErrorInfo("Incorrect room name")
        }
        navigate('/live-conference/' + pastedRoomNumber)
    };
    function generateRandomRoomName() {
        const randomString = Math.floor(Math.random() * 9000000000) + 1000000000;
        const roomName = `zacademy_${randomString}`;

        return roomName;
    }
    const [pastedRoomNumber, setPastedRoomNumber] = useState('');
    const pasteInputRef = useRef(null);

    const handlePaste = (pastedText) => {
        const match = pastedText.match(/\zacademy(\w+)/);

        if (match) {
            const roomName = match[0]; // This will now capture the entire room name, including the "zacademy" prefix
            console.log(roomName);
            setPastedRoomNumber(roomName);
        } else {
            // Handle invalid URL format
            setPastedRoomNumber(pastedText)
        }
    };
    const handleClick = () => {
        if (navigator.clipboard) {
            navigator.clipboard.readText()
                .then(pastedText => handlePaste(pastedText))
                .catch(err => console.error('Failed to read text from clipboard', err));
        } else {
            // Fallback for browsers that do not support navigator.clipboard
            console.error('Clipboard API not supported');
        }
    };
    return (
        <Layout>
            <section className="create-conpg">
                <div className="container ">
                    <div className='img-box'>
                        <img src="./zidni/assets/images/confbglogo.jpeg" className='rounded-3' alt="" />
                    </div>
                    <div>
                        <ul className="nav nav-tabs" id="conferenceTabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'create' ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => setActiveTab('create')}
                                >
                                    Create Conference
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'join' ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => setActiveTab('join')}
                                >
                                    Join Conference
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            {activeTab === 'create' && (
                                <div className="tab-pane fade show active" id="createConference">
                                    <form className="mt-3" onSubmit={handleSubmit(onSubmitCreate)}>
                                        <div className="mb-3">
                                            <label className=' form-label' htmlFor="title">Title</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.title ? 'is-invalid' : ''}`}
                                                id="title"
                                                placeholder="Enter conference title"
                                                {...register("title", { required: true })}
                                            />
                                            {errors.title && <div className="invalid-feedback">Title is required</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label className=' form-label' htmlFor="roomNameCreate">Room Name</label>
                                            <input
                                                type="text"
                                                className={`form-control ${errors.roomNameCreate ? 'is-invalid' : ''}`}
                                                id="roomNameCreate"
                                                placeholder="Enter room name"
                                                {...register("roomNameCreate", { required: true })}
                                            />
                                            {errors.roomNameCreate && <div className="invalid-feedback">Room Name is required</div>}
                                        </div>
                                        <button type="submit" className="btn btn-primary px-5 py-2 w-100">Create</button>
                                    </form>
                                </div>
                            )}
                            {activeTab === 'join' && (
                                <div className="tab-pane fade show active" id="joinConference">
                                    <form className="mt-3" onSubmit={handleSubmit(onSubmitJoin)}>
                                        <div className="mb-3">
                                            <label for="validationTooltipUsername" className=' form-label'>Room Name</label>
                                            <div class="input-group">
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    id="validationTooltipUsername" placeholder="Enter room name"
                                                    readOnly
                                                    value={pastedRoomNumber}
                                                    ref={pasteInputRef} />
                                                <div class="input-group-postpend">
                                                    <span onClick={() => handleClick()} style={{ cursor: 'pointer' }} class=" input-group-text btn btn-primary h-100 d-flex align-items-center justify-content-center" id="validationTooltipUsernamePrepend">Paste Here</span>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary px-5 py-2 w-100">Join</button>
                                    </form>

                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default CreateConference;
