import React, { useEffect, useState } from "react";
import HomepageBanner from "../../components/homepage/HomepageBanner";
import JoinSection from "../../components/homepage/JoinSection";
import WhatsNewSection from "../../components/homepage/WhatsNewSection";
import TrendingCourse from "../../components/homepage/TrendingCourse";
import LiveLectureSection from "../../components/homepage/LiveLectureSection";
import FreeCourse from "../../components/homepage/FreeCourse";
import Layout from "../../components/common/Layout";
import { Link } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import ZidniContactBanner from "../../components/homepage/ZidniContactBanner";
import { useDispatch, useSelector } from "react-redux";
import { setLoginTab, setShowLoginPopup } from "../../store/actions/userActions";
import CreateConference from "../live/conference/CreateConference";
// import Evaluation from "./Evaluation";

const Home = () => {
  const [callingApi, setCallingApi] = useState(true)
  const [dashboardData, setDashboardData] = useState({})
  const [liveLecture, setLiveLecture] = useState([])
  const userDetails = useSelector((state) => state.root.userDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (callingApi == true) {
      setCallingApi(false);
      let Data = {
        where: {
          coursePricing: "Free",
        },
        paranoid: true,
        pageNumber: 0,
        limit: 10,
      };
      const apiUrl =
        APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "admin/dashboard";
      const apiUrl1 =
        APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + "live?pageNumber=1&limit=10&status=0";
      callApi(apiUrl, { method: "GET", data: Data }).then((res) => {
        if (res.status == true) {
          setDashboardData(res.data);
          // console.log("dashboard data", res.data)
        }
      });
      callApi(apiUrl1, { method: "GET", data: Data }).then((res) => {
        if (res.status == true) {
          setLiveLecture(res.data.live);
          // console.log("live data", res.data)
        }
      });
    }
  }, [callingApi]);
  return (
    <Layout>
      {/* <Evaluation /> */}
      <HomepageBanner dashboardData={dashboardData} />
      <JoinSection />
      <WhatsNewSection dashboardData={dashboardData} liveLecture={liveLecture} />
      {/* become-banner start */}
      <section className="become-banner">
        <div className="container round">
          <div className="left-sec">
            <h1 className="">Become an Instructor</h1>
            <p>
              We offer 24/7 students support answering all your question to
              guaranteed your success!
            </p>
            {userDetails && userDetails.type === 1 ?
              <a href="javascript:void(0);" className="btn bg-green oswald600 round" onClick={() => { dispatch(setShowLoginPopup(true)); dispatch(setLoginTab("4")) }}>
                Try for zidni instructor
              </a>
              :
              <a href="javascript:void(0);" className="btn bg-green oswald600 round" onClick={() => { dispatch(setShowLoginPopup(true)); dispatch(setLoginTab("3")) }}>
                Try for zidni instructor
              </a>
            }
          </div>
          <div className="img">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/insbanner.png`}
              alt=""
            />
          </div>
        </div>
      </section>
      {/* become-banner end */}
      <TrendingCourse />

      {/* find-top start */}
      <section className="find-top">
        <div className="container bg-green round">
          <h2 className="gotham">
            Find a top speciality certificate that fits your life and level up
            your skills
          </h2>
          <Link to="/courses" className="primary-btn oswald600 btn round">
            Explore certificate courses
          </Link>
        </div>
      </section>
      {/* find-top end */}

      {/* download-sec start */}
      <section className="download-sec">
        <div className="container round">
          <div className="img-sec">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Group 1182.png`}
              alt=""
            />
          </div>
          <div className="content">
            <h2 className="nunito">Download our app</h2>
            <p className="quicksand">
              Multiple universities under the one roof. Diploma courses and certificate course with credit hours. Event management system with multiple programs in each events.
            </p>
            <div className="app-info">
              <div className="app-img">
                <a href="#" className="google" />
                <a href="#" className="app-store" />
              </div>
              <div className="bar-code">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/bar-code.svg`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* download-sec end */}
      <LiveLectureSection liveLecture={liveLecture} />
      <FreeCourse />

      <>
        {/* zidni-banner start */}
        <ZidniContactBanner />
        {/* zidni-banner end */}
      </>
    </Layout>
  );
};

export default Home;
