import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/common/Layout";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { APIENDPOINTS, APIURL } from "../../api/apiUrl";
import { callApi } from "../../api/ApiHelper";
import { useSelector } from "react-redux";
import QuizPage from "../../components/common/QuizPage";
import Loader from "../../components/common/Loader";
import { sendErrorInfo, sendSuccessInfo } from "../../utility/helperFunctions";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import Cart from "../../components/common/Cart";
import Rating from "react-rating";
import TimeAgo from "../../components/common/TimeAgo";

const CourseDetail = () => {

  const location = useLocation();

  let { courseId } = useParams();
  const userDetail = useSelector((state) => state.root.userDetails);

  const [allCourseData, setAllCourseData] = useState({});
  const [courseReviewData, setCourseReviewData] = useState([])
  const navigate = useNavigate()
  const Detail = useRef();
  const Instructor = useRef();
  const Lecture = useRef();
  const [CallApi, setCallApi] = useState(true);
  const [loader, setLoader] = useState(false)
  const [showCart, setShowCart] = useState(false)

  const handleClickDetail = () => {
    Detail.current.scrollIntoView();
  };
  const handleClickInstructor = () => {
    Instructor.current.scrollIntoView();
  };
  const handleClickLecture = () => {
    Lecture.current.scrollIntoView();
  };

  const toggle = () => {
    setShowCart(!showCart)
  }


  useEffect(() => {
    getCourseDetail();
    getCourseReview();
  }, [courseId])


  const getCourseDetail = () => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `course/${courseId}`;
    setLoader(true);
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setAllCourseData(response.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    });
  };

  const getCourseReview = () => {
    const apiUrl =
      APIURL.COURSE_URL +
      APIENDPOINTS.COURSE_END_POINT +
      `rating/list/?id=${courseId}&pageNumber=1&limit=5`;
    setLoader(true);
    callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
      if (response.status === true) {
        setCourseReviewData(response.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    });
  }

  // const handlePurchaseCourse = () => {
  //   if (userDetail?.type) {
  //     if (allCourseData?.createdByInstructorId !== userDetail?.id) {
  //       const apiUrl =
  //         APIURL.COURSE_URL +
  //         APIENDPOINTS.COURSE_END_POINT +
  //         `course/purchase/${location.state?.courseId}`;
  //       callApi(apiUrl, { method: "GET", data: { id: location.state?.courseId } }).then((response) => {
  //         if (response.status === true) {
  //           sendSuccessInfo('Course purchased successfully')
  //           navigate('/my-purchase')
  //         } else {
  //           sendErrorInfo(response.data.message)
  //         }
  //       });
  //     } else {
  //       sendErrorInfo('You not purchased your owan course')
  //     }
  //   } else {
  //     sendErrorInfo('Please login first')
  //   }
  // }

  // const handleGetCartData = () => {
  //   const apiUrl =
  //     APIURL.CART_URL +
  //     APIENDPOINTS.CART_END_POINT +
  //     `cart/get`;
  //   callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
  //     if (response.status === true) {
  //       setCartData(response.data)
  //       let Totalprice = response.data.reduce((sum, item) => sum + item.price, 0) 
  //       setTotalPrice(Totalprice)
  //       toggle()
  //     } else {
  //       sendErrorInfo(response.data.message)
  //     }
  //   });
  // }


  const handleAddToCart = () => {
    if (userDetail?.type) {
      if (allCourseData?.createdByInstructorId !== userDetail?.id) {
        const apiUrl =
          APIURL.CART_URL +
          APIENDPOINTS.CART_END_POINT +
          `cart/add`;
        let price = allCourseData?.coursePricing && (JSON?.parse(allCourseData?.coursePricing)?.actualValue != undefined || JSON?.parse(allCourseData?.coursePricing)?.actualValue != null) ? JSON?.parse(allCourseData?.coursePricing)?.actualValue.toFixed(2) : JSON?.parse(allCourseData?.coursePricing)?.coursePaymentType == 'Paid' ? JSON?.parse(allCourseData?.coursePricing)?.coursePricing : 0
        let Data = {
          itemId: courseId,
          itemType: 'course',
          quantity: 1,
          price: price
        }
        callApi(apiUrl, { method: "POST", data: Data }).then((response) => {
          if (response.status === true) {
            sendSuccessInfo('Course added to cart successfully')
            setShowCart(true)
          } else {
            sendErrorInfo(response.data.message)
          }
        });
      } else {
        sendErrorInfo('You can not purchase your own course')
      }
    } else {
      sendErrorInfo('Please login first')
    }
  }


  return (
    <Layout>
      {loader ? <Loader /> : <>
        {/* breadcrumb start */}
        <section className="breadcrumb-sec">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/my-courses">My courses</Link>
                </li>
                {/* <li className="breadcrumb-item active" aria-current="page">
                Bible Open University of India
              </li> */}
              </ol>
            </nav>
          </div>
        </section>
        {/* breadcrumb end */}

        <section className="view-banner">
          <div className="container">
            <div className="course-details" id="Instructor" ref={Instructor}>
              <div className="category-btn">
                <span className="badge-btn">{allCourseData?.Category?.categoryName}</span>
              </div>
              <h2>
                {allCourseData?.courseName}
              </h2>
                <div className="by" >
                  <h6>
                    By: {allCourseData?.instructor?.firstName} {allCourseData?.instructor?.lastName}
                    <span>
                      {" "}
                      <img src="./assets/images/linking-green.svg" alt="" />
                    </span>
                  </h6>
                </div>
              
              <ul>
                <li>
                  Language: <span>English</span>
                </li>
                <li>
                  Certificate: <span>of completion</span>
                </li>
                <li>
                  Duration: <span>{allCourseData?.courseTimeDurationAndCreditHour && JSON?.parse(allCourseData?.courseTimeDurationAndCreditHour)?.courseTime}</span>
                </li>
                <li>
                  Credit hours: <span>{allCourseData?.courseTimeDurationAndCreditHour && JSON?.parse(allCourseData?.courseTimeDurationAndCreditHour)?.courseCreditHour}</span>
                </li>
                <li>
                  Rating: <Rating
                    readonly
                    initialRating={allCourseData?.avgRating}
                    emptySymbol={<i className="fa-regular fa-star" style={{ color: 'grey' }}></i>}
                    fullSymbol={<i className="fa-solid fa-star" style={{ color: 'gold' }}></i>}
                  />
                </li>
              </ul>
            </div>
            <div className="img-sec">
              <div className="img">
                <img src={allCourseData?.courseImage} alt="" />
                <Link className="round" to="/course-inner-detail" state={{ courseId: allCourseData.id }}>
                  Course preview
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="description-sec">
          <div className="container">
            <div className="des" id="Description" ref={Detail}>
              <h3>Description</h3>
              <p>
                {allCourseData?.courseDescription}
              </p>
            </div>
            <div className="pre-part" >
              <div className="hbox">
                <h3>You will learn</h3>
                <ul>
                  {allCourseData?.cousreLearningCriteria && JSON?.parse(allCourseData?.cousreLearningCriteria)?.map((item, key) => (

                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="hbox">
                <h3>The course includes</h3>
                <ul>
                  {allCourseData?.cousreLearningCriteria && JSON?.parse(allCourseData?.courseResources)?.map((item, key) => (

                    <li key={key}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="module-section" id="Lecture" ref={Lecture}>
          <div className="container">
            <div className="heading">
              <div className="modu">Module</div>
              <h3>What you will learn in this course.</h3>
            </div>
            <div className="lession-sec">
              <div className="accor-part">
                <div className="accordion" id="accordionExample">
                  {allCourseData?.Assessments && allCourseData?.Assessments?.map((item, key) => (
                    <div className="accordion-item" key={key}>
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button accr-bdy"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseOne${key}`}
                          aria-expanded="true"
                          aria-controls={`collapseOne${key}`}
                        >
                          <div className="bt-div">
                            <div className="why module-wh">
                              <div className="less-btn">Lessons-{key + 1}</div>
                              <span className="intr">{item?.lessonName}</span>
                            </div>
                            {/* <div className="con module-con">
                            <div className="qty module-qty">5</div>
                            <div className="min module-min">20:15 min</div>
                          </div> */}
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapseOne${key}`}
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        {item?.Curriculums && item?.Curriculums?.map((item2, key2) => {

                          return (
                            <div className="accordion-body accr-bdy" key={key2}>
                              <div className="why module-wh">

                                {item2?.curriculumType === 1 &&
                                  <p className="pla ml-2 fw-medium text-muted">
                                    <span className="mr-2 ">
                                      <img
                                        style={{ height: "20px", width: "20px" }}
                                        src="./assets/images/lock-btn.svg"
                                        alt=""
                                      />
                                    </span>
                                    {item2?.curriculumType === 1 && item2?.curriculumPath ? item2?.curriculumPath : "Lecture"}
                                  </p>
                                }

                                {item2?.curriculumType === 2 &&
                                  <div>
                                    <p className="pla ml-2 fw-medium text-muted ">
                                      <span className="mr-2">
                                        <img
                                          style={{ height: "20px", width: "20px" }}
                                          src="./assets/images/play-btn.svg"
                                          alt=""
                                        />
                                      </span>
                                      {item2?.curriculumType === 2 && item2?.curriculumName ? item2?.curriculumName : "Video"}
                                    </p>
                                  </div>
                                }

                                {item2?.curriculumType === 3 &&
                                  <p className="pla ml-2 fw-medium text-muted ">
                                    <span className="mr-2">
                                      <img
                                        style={{ height: "20px", width: "20px" }}
                                        src="./assets/images/quiz.png"
                                        alt=""
                                      />
                                    </span>
                                    {item2?.curriculumType === 3 &&
                                      //  item2?.Quiz?.quizName ? item2?.Quiz?.quizName : 
                                      "Quiz"}
                                  </p>
                                }
                              </div>

                              {/* <div className="con module-con">
                                <div className="qty module-qty">
                                  Watch free 05:15 min
                                </div>
                                <div className="min module-min">20:15 min</div>
                              </div> */}
                            </div>
                          )

                        })}
                      </div>
                    </div>

                  ))}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="d-flex justify-content-between">
                <h3>Reviews</h3>
                {courseReviewData?.rating?.length > 5 &&
                  <Link to="/course-reviews" state={{ courseId: location.state?.courseId }}>View All</Link>
                }
              </div>
              <div className="show-com2 overflow-y-scroll mt-2" style={{ maxHeight: "400px" }}>
                {courseReviewData?.rating?.length > 0 ?
                  courseReviewData?.rating?.map((item, index) => (
                    <div className="comss m-lg-3" key={index}>
                      <div className="d-flex gap-2 align-items-center">
                        <div className="avatar-box ">
                          <div className="avatar">
                            {item.user.firstName.charAt(0) +
                              item.user.lastName.charAt(0)}
                          </div>
                        </div>
                        <h5>
                          {item.user.firstName} {item.user.lastName}
                        </h5>
                      </div>
                      <div className=" mx-5">
                        <Rating
                          readonly
                          initialRating={item?.rating}
                          emptySymbol={<i className="fa-regular fa-star" style={{ color: 'grey' }}></i>}
                          fullSymbol={<i className="fa-solid fa-star" style={{ color: 'gold' }}></i>}
                        />
                        <p>{item.comment}</p>
                        <div className="tago">
                          <TimeAgo createdAt={item.createdAt} />
                        </div>
                      </div>
                    </div>
                  )) :
                  <div className="mt-4">
                    <h5 className="text-muted mx-2">No Review Yet!</h5>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>


        {/* specialities section start */}
        <section className="specialities">
          <div className="spec-button">
            <div className="specbtn">
              <button className="sp-btn" data-bs-toggle="modal" data-bs-target="#specialities_popup">Specialities <span>
                <img src="./assets/images/hamsp.svg" alt="" /></span></button>
            </div>
            <div className="mid">
              <a href="javascript:void(0);" onClick={handleClickDetail}>Detail</a>
              <a href="javascript:void(0);" onClick={handleClickLecture}>Lecture</a>
              <a href="javascript:void(0);" onClick={handleClickInstructor}>Instructor</a>
            </div>
            <div className="shop-ca" >
              {allCourseData?.coursePricing && JSON?.parse(allCourseData?.coursePricing)?.coursePaymentType !== undefined && JSON?.parse(allCourseData?.coursePricing)?.coursePaymentType == 'Paid' && allCourseData?.isCoursePurchased == false &&
                <>
                  <button className="buy" onClick={() => handleAddToCart(allCourseData?.id)}>Buy  {JSON?.parse(allCourseData?.coursePricing)
                    ?.actualValue != undefined ?
                    <>
                      <div>
                        <span className={JSON?.parse(allCourseData?.coursePricing)
                          ?.actualValue != undefined && 'text-muted text-decoration-line-through fs-6'}>  {allCourseData?.coursePricing &&
                            JSON?.parse(allCourseData?.coursePricing)
                              ?.coursePaymentType == "Paid"
                            ? `$ ${JSON?.parse(allCourseData?.coursePricing)
                              ?.coursePricing}`
                            : "Free"}
                        </span>

                        <span >  {allCourseData?.coursePricing &&
                          JSON?.parse(allCourseData?.coursePricing)
                            ?.coursePaymentType == "Paid"
                          ? `$ ${JSON?.parse(allCourseData?.coursePricing)
                            ?.actualValue.toFixed(2)}`
                          : "Free"}
                        </span>
                      </div>
                      {/* <span className="fs-6">
                        (University discount)
                      </span> */}
                    </>
                    :

                    <span>  {allCourseData?.coursePricing &&
                      JSON?.parse(allCourseData?.coursePricing)
                        ?.coursePaymentType == "Paid"
                      ? `$ ${JSON?.parse(allCourseData?.coursePricing)
                        ?.coursePricing}`
                      : "Free"}
                    </span>
                  }</button>
                  <button className="cart" onClick={() => toggle()} ><img src="./assets/images/cart.svg" alt="" /></button>
                </>
              }
              {allCourseData?.isCoursePurchased ?
                <button className="buy">Course purchased</button>
                : allCourseData?.coursePricing && JSON?.parse(allCourseData?.coursePricing)?.coursePaymentType == 'Free' &&
                <button className="buy" onClick={() => handleAddToCart(allCourseData?.id)}>Free</button>
              }
              {/* {allCourseData?.coursePricing && JSON?.parse(allCourseData?.coursePricing)?.coursePaymentType == 'Free' &&
              <button className="buy">Free</button>
            } */}
              {/* data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
              aria-controls="offcanvasRight" */}
            </div>
          </div>
        </section>
        {/* specialities section end */}
        {/* <QuizPage /> */}
        {/* Specialities Modal start */}
        <div className="modal fade specialpopup" id="specialities_popup" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-body">
                <div className="inner">
                  <h4>Module</h4>
                  <ul>
                    {allCourseData?.Assessments && allCourseData?.Assessments.map((item, key) => (
                      <li key={key}><a href="#" >{item?.lessonName}</a></li>
                    ))}
                  </ul>
                </div>
                <div className="inner">
                  <h4>Course</h4>
                  <ul className="mobul">
                    {allCourseData?.cousreLearningCriteria && JSON?.parse(allCourseData?.courseResources)?.map((item, key) => (

                      <li key={key}>{item}</li>
                    ))}
                  </ul>
                </div>
                {/* <div className="inner">
                <h4>Certificates</h4>
                <ul>
                  <li><a href="#">Pediatric Specialty</a></li>
                  <li><a href="#">Family Medicine</a></li>
                  <li><a href="#">Internal Medicine Specialty</a></li>
                  <li><a href="#">Dermatology Specialty</a></li>
                </ul>
              </div> */}
                <div className="inner">
                  <h4>Credit hours</h4>
                  <ul className="lst">
                    <li><a href="#">{allCourseData?.courseTimeDurationAndCreditHour && JSON?.parse(allCourseData?.courseTimeDurationAndCreditHour)?.courseCreditHour}</a></li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Specialities Modal End */}
      </>
      }

      {showCart && <Cart closecart={toggle} />}

      {/* <Offcanvas direction='end' isOpen={showCart} toggle={() => toggle()} className="cart-pop">
        <OffcanvasHeader toggle={() => toggle()}>
          <h4 className="offcanvas-title" id="offcanvasRightLabel">Your cart <span class="qtys">{cartData.length}</span></h4>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div class="items">
            {cartData && cartData.map((item,key)=>(
            <div class="item" key={key}>
              <div class="lef">
                <div class="img-box">
                  <img src={item?.itemDetail?.itemImage} alt="" />
                </div>
                <div class="contents">
                  <h5>{item?.itemDetail?.itemName}</h5>
                  <p className="d-inline-block text-truncate " style={{maxWidth: "170px"}}>{item?.itemDetail?.itemDescription}</p>
                </div>
              </div>
              <div class="rig">
                ${item?.itemDetail?.itemPricing?.coursePricing} <span><img src="./assets/images/trash bin trash.svg" alt="" /></span>
              </div>
            </div>

            ))}
          </div>

        </OffcanvasBody>
        <div class="offcanvas-footer">
          <div class="tot">
            <h5>Total:</h5>
            <h5 class="tot-qty">${totalPrice}</h5>
          </div>
          <button class="chkout">Checkout</button>
          <p>Taxes are calculated at checkout.</p>
          <button class="cont-shop">Continue shopping</button>
        </div>
      </Offcanvas> */}

      {/* <!-- off canvas right to left  start --> */}
      {/* <div className="offcanvas cart-pop offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header">
          <h4 className="offcanvas-title" id="offcanvasRightLabel">Your cart <span class="qtys">1</span></h4>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
          <div class="items">
            <div class="item">
              <div class="lef">
                <div class="img-box">
                  <img src="./assets/images/image 3 (1).png" alt="" />
                </div>
                <div class="contents">
                  <h5>Most of these courses will be offered in offline mode and will involve ample
                    practical learning.</h5>
                  <h6>By: Suzanne Weber</h6>
                </div>
              </div>
              <div class="rig">
                $234 <span><img src="./assets/images/trash bin trash.svg" alt="" /></span>
              </div>
            </div>
          </div>

        </div>
        <div class="offcanvas-footer">
          <div class="tot">
            <h5>Total:</h5>
            <h5 class="tot-qty">$234</h5>
          </div>
          <button class="chkout">Checkout</button>
          <p>Taxes are calculated at checkout.</p>
          <button class="cont-shop">Continue shopping</button>
        </div>
      </div> */}
      {/* <!-- offcanvas right to left end --> */}
    </Layout >
  );
};

export default CourseDetail;
