import React, { useEffect, useState } from 'react'
import {
    AgoraRTCProvider,
    useRTCClient
} from "agora-rtc-react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { config } from '../utils/config';
import { callApi } from '../../../api/ApiHelper';
import { useParams } from 'react-router-dom';
import { APIENDPOINTS, APIURL } from '../../../api/apiUrl';
import { useSelector, useDispatch } from "react-redux";
import AgoraAuthenticationManager from '../agora/AgoraAuthenticationManager';
import { generateRandomId } from '../utils/helpers';
import { useForm } from 'react-hook-form';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input } from 'reactstrap';
import { saveUserDetails } from '../../../store/actions/userActions';
import { useNavigate } from 'react-router-dom'
import { sendErrorInfo } from '../../../utility/helperFunctions';
const LiveWebinar = ({ children }) => {

    const [isLoading, setIsloading] = useState(true);
    const [webinarData, setWebinarData] = useState(null)
    const userDetails = useSelector(state => state.root.userDetails)
    const [userDetailForm, setUserDetailsForm] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toggleUserDetailForm = () => setUserDetailsForm(!userDetailForm);
    const { id } = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ mode: "all" });

    const onSubmit = (data) => {
        console.log(data);
        handleJoinUser(webinarData.id, webinarData.organisedBy, data)
        // Close the modal after form submission
    };
    useEffect(() => {
        let url = APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + 'live/' + id;
        callApi(url, { method: "GET" })
            .then(response => {
                if (response.status === true) {
                    if (response.data) {
                        checkUserAllwedOrNot(response.data)
                        setWebinarData(response.data);
                        if (Object.keys(userDetails).length == 0) {

                            toggleUserDetailForm()
                        } else {
                            handleJoinUser(response.data.id, response.data.organisedBy, null)
                        }
                    } else {
                        navigate(-1)
                    }
                }
            })
    }, [])

    const checkUserAllwedOrNot = (data) => {
        if (data.isLivePublic === false && data.organisedBy != userDetails.id) {
            if (!data.allowedUser.includes(userDetails.email)) {
                sendErrorInfo("You are not allowed in this live session");
                setTimeout(() => {
                    navigate(-1)
                }, 1000);

            }
        }
    }


    const handleJoinUser = (roomId, organisedBy, data) => {
        const isLogin = Object.keys(userDetails).length;

        let randomId = generateRandomId(18)
        let postData = {
            userId: isLogin > 0 ? userDetails?.id : randomId,
            isHost: organisedBy === userDetails?.id ? true : false,
            isGuest: isLogin == 0 ? true : false,
            ...(isLogin === 0 && { userDetail: { "firstName": data.firstName, "lastName": data.lastName, "id": randomId } })
        }

        let url = APIURL.LIVE_URL + APIENDPOINTS.LIVE_END_POINT + 'live/join/' + roomId;
        callApi(url, { method: "POST", data: postData }).then(response => {

            if (isLogin === 0) {
                let { id, firstName, lastName } = response.data.userDetail
                let data = {
                    id: id,
                    Profile: {
                        firstName: firstName,
                        lastName: lastName
                    }
                }
                dispatch(saveUserDetails(data));
                toggleUserDetailForm()
                setTimeout(() => {
                    setIsloading(false)
                }, 500);
            } else {
                setIsloading(false)
            }
            console.log(response)
        })
    }


    const agoraEngine = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: config.selectedProduct }));

    return (
        <>
            {isLoading && <p>Loading.....</p>}
            {isLoading === false &&
                <AgoraRTCProvider client={agoraEngine}>
                    <AgoraAuthenticationManager webinarData={webinarData} client={agoraEngine} />
                </AgoraRTCProvider>}
            <Modal isOpen={userDetailForm} toggle={toggleUserDetailForm}>
                <ModalHeader toggle={toggleUserDetailForm}>User Information</ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label for="firstName">First Name</Label>
                            <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                className='form-control'
                                placeholder="Enter your first name"

                                {...register('firstName', { required: 'First Name is required' })}
                            />
                            {errors?.firstName && <span className='text-danger' >{errors.firstName.message}</span>}
                        </FormGroup>

                        <FormGroup>
                            <Label for="lastName">Last Name</Label>
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                className='form-control'
                                placeholder="Enter your last name"
                                {...register('lastName', { required: 'Last Name is required' })}
                            />
                            {errors?.lastName && <span className='text-danger' >{errors.lastName.message}</span>}
                        </FormGroup>

                        <Button type="submit" color="primary">
                            Join
                        </Button>
                    </form>
                </ModalBody>
            </Modal>
        </>


    )

}

export default LiveWebinar