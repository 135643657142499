import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { APIENDPOINTS, APIURL } from "../../../api/apiUrl";
import { callApi } from "../../../api/ApiHelper";
import Loader from "../../../components/common/Loader";
import Layout from "../../../components/common/Layout";
import { noImage } from "../utils/helpers";
import { sendErrorInfo, sendSuccessInfo } from "../../../utility/helperFunctions";
import moment from 'moment'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PaginationComponent from "../../../components/common/paginationComponent";
import Swal from "sweetalert2";

const WebinarList = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(true);
  const userDetail = useSelector((state) => state.root.userDetails);

  const [activeTab, setActiveTab] = useState('1');
  const [completedLecture, setCompletedLecture] = useState([]);
  const [ongoingLecture, setOngoingLecture] = useState([]);
  const [scheduledLecture, setScheduledLecture] = useState([]);
  const [cancelledLecture, setCancelledLecture] = useState([]);
  const [CallApi, setCallApi] = useState(true);
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationRange, setPaginationRange] = useState([1, 4]);
  const [allPages2, setAllPages2] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [paginationRange2, setPaginationRange2] = useState([1, 4]);
  const [allPages3, setAllPages3] = useState([]);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [paginationRange3, setPaginationRange3] = useState([1, 4]);
  const [allPages4, setAllPages4] = useState([]);
  const [currentPage4, setCurrentPage4] = useState(1);
  const [paginationRange4, setPaginationRange4] = useState([1, 4]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const getOngoingLecture = () => {
    setLoader(true);
    const apiUrl =
      APIURL.LIVE_URL +
      APIENDPOINTS.LIVE_END_POINT +
      `live?pageNumber=${currentPage}&limit=10&status=0`;
    callApi(apiUrl, { method: "Get", data: {} }).then((response) => {
      if (response?.status === true) {
        setOngoingLecture(response?.data?.live?.filter(e => e.isLivePublic === true));
        let arr = []
        for (let i = 1; i <= response?.data?.totalPages; i++) {
          arr.push(i)
        }
        setAllPages(arr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
  }
  const getScheduledLecture = () => {
    setLoader(true);
    const apiUrl =
      APIURL.LIVE_URL +
      APIENDPOINTS.LIVE_END_POINT +
      `live?pageNumber=${currentPage2}&limit=10&status=2`;
    callApi(apiUrl, { method: "Get", data: {} }).then((response) => {
      if (response?.status === true) {
        // console.log(" ongoing_Lecture_response...", response);
        setScheduledLecture(response?.data?.live?.filter(e => e.isLivePublic === true));
        let arr = []
        for (let i = 1; i <= response?.data?.totalPages; i++) {
          arr.push(i)
        }
        setAllPages2(arr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
  }
  const getCancelledLecture = () => {
    setLoader(true);
    const apiUrl =
      APIURL.LIVE_URL +
      APIENDPOINTS.LIVE_END_POINT +
      `live?pageNumber=${currentPage3}&limit=10&status=3`;
    callApi(apiUrl, { method: "Get", data: {} }).then((response) => {
      if (response?.status === true) {
        setCancelledLecture(response?.data?.live?.filter(e => e.isLivePublic === true));
        let arr = []
        for (let i = 1; i <= response?.data?.totalPages; i++) {
          arr.push(i)
        }
        setAllPages3(arr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
  }
  const getCompletedLecture = () => {
    setLoader(true);
    const apiUrl =
      APIURL.LIVE_URL +
      APIENDPOINTS.LIVE_END_POINT +
      `live?pageNumber=${currentPage4}&limit=10&status=1`;
    callApi(apiUrl, { method: "Get", data: {} }).then((response) => {
      if (response?.status === true) {
        setCompletedLecture(response?.data?.live?.filter(e => e.isLivePublic === true));
        let arr = []
        for (let i = 1; i <= response?.data?.totalPages; i++) {
          arr.push(i)
        }
        setAllPages4(arr);
        setLoader(false);
      } else {
        setLoader(false);
      }
    })
  }

  useEffect(() => {
    getOngoingLecture();
  }, [currentPage]);
  useEffect(() => {
    getScheduledLecture();
  }, [currentPage2]);
  useEffect(() => {
    getCancelledLecture();
  }, [currentPage3]);
  useEffect(() => {
    getCompletedLecture();
  }, [currentPage4]);

  const handleJoinLive = (live) => {
    if (live.scheduleDateTime) {
      const currentDateTime = new Date();
      const scheduleDateTime = new Date(live.scheduleDateTime);

      if (currentDateTime >= scheduleDateTime) {
        navigate("/live-webinar/" + live.channelName);
      } else {
        sendErrorInfo("The event has not started yet.");
      }
    } else {
      navigate("/live-webinar/" + live.channelName)
    }
  }

  const handleCancelLive = (live) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this live!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiUrl1 =
          APIURL?.LIVE_URL +
          APIENDPOINTS?.LIVE_END_POINT +
          `live/update/${live?.id}?liveStatus=3`;
        callApi(apiUrl1, { method: "GET" }).then((response) => {
          if (response.status === true) {
            sendSuccessInfo(response?.message);
            getScheduledLecture();
            getCancelledLecture();
          } else {
            sendErrorInfo(response?.message);
          }
        });
      }
    });
  }

  return (
    <Layout>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div>
            <section className="pb-0 webinartabs">
              <div className="container">
                <Nav pills>
                  <NavItem style={{ cursor: "pointer" }} className=" text-center">
                    <NavLink
                      className={activeTab === '1' ? 'active ' : ''}
                      onClick={() => toggleTab('1')}
                    >
                      Ongoing Lectures

                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "pointer" }} className="text-center">
                    <NavLink
                      className={activeTab === '2' ? ' active' : ''}
                      onClick={() => toggleTab('2')}
                    >
                      Scheduled Lectures
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "pointer" }} className="text-center">
                    <NavLink
                      className={activeTab === '3' ? ' active' : ''}
                      onClick={() => toggleTab('3')}
                    >
                      Cancelled Lectures
                    </NavLink>
                  </NavItem>
                  <NavItem style={{ cursor: "pointer" }} className="text-center">
                    <NavLink
                      className={activeTab === '4' ? ' active' : ''}
                      onClick={() => toggleTab('4')}
                    >
                      Completed Lectures

                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </section>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {ongoingLecture?.length === 0 && (
                  <div className="no-cou-yet m-5">
                    <h3>No live yet!</h3>
                    <p>
                      Here’s where you’ll see all Live WebinarList
                    </p>
                  </div>
                )}
                <section className="live-serie pb-0">
                  <div className="container">
                    {ongoingLecture &&
                      ongoingLecture.length > 0 &&
                      ongoingLecture
                        .filter((e) => e.deletedAt == null)
                        .map((live, index) => (
                          <div className="univ-card round" key={index}>
                            <div className="left-content">
                              <div className="card-image round">
                                <img
                                  src={live?.liveImage ? live?.liveImage : noImage}
                                  alt=""
                                />
                              </div>
                              <div className="card-detail">
                                <span
                                  className={live.isLivePublic === false ? "private" : "public"}
                                >
                                  {live.isLivePublic === false ? "Private" : "Public"}
                                </span>
                                <h4 className="headi">{live.channelName}</h4>
                                <h6>Created By : <span>{live?.userDetail?.Profile?.firstName} {live?.userDetail?.Profile?.lastName}</span></h6>
                                <p><span className="fw-medium">Started at : </span>{moment(live?.createdAt).format("DD MMM,YYYY hh:mm a")}</p>
                                <p>{live?.liveDescription}</p>
                                {/* {live.scheduleDateTime && <p><span className="fw-medium">Scheduled Date and Time : </span> {moment(live?.scheduleDateTime).format("DD MMM,YYYY hh:mm a")}</p>} */}
                              </div>
                            </div>
                            <div className="right-content">
                              <a

                                href="jacascript:void(0)"
                                onClick={() => handleJoinLive(live)}
                                className="round"
                              >
                                Join live
                              </a>
                            </div>
                          </div>
                        ))}
                  </div>
                </section>
                <PaginationComponent
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  allPages={allPages}
                  paginationRange={paginationRange}
                  setPaginationRange={setPaginationRange}
                />
              </TabPane>

              <TabPane tabId="2">
                {scheduledLecture?.length === 0 && (
                  <div className="no-cou-yet m-5">
                    <h3>No Scheduled webinar yet!</h3>
                    <p>
                      Here’s where you’ll see all Scheduled WebinarList
                    </p>
                  </div>
                )}
                <section className="live-serie pb-0">
                  <div className="container">
                    {scheduledLecture &&
                      scheduledLecture.length > 0 &&
                      scheduledLecture
                        .filter((e) => e.deletedAt == null)
                        .map((live, index) => (
                          <div className="univ-card round" key={index}>
                            <div className="left-content">
                              <div className="card-image round">
                                <img
                                  src={live?.liveImage ? live?.liveImage : noImage}
                                  alt=""
                                />
                              </div>
                              <div className="card-detail">
                                <span
                                  className={
                                    live.isLivePublic === false ? "private" : "public"
                                  }
                                >
                                  {live.isLivePublic === false ? "Private" : "Public"}
                                </span>
                                <h4 className="headi">{live.channelName}</h4>
                                <h6>Created By : <span>{live?.userDetail?.Profile?.firstName} {live?.userDetail?.Profile?.lastName}</span></h6>
                                {live.scheduleDateTime && <p><span className="fw-medium">Scheduled Date and Time : </span> {moment(live?.scheduleDateTime).format("DD MMM,YYYY hh:mm a")}</p>}
                                <p>{live?.liveDescription}</p>
                              </div>
                            </div>
                            <div className="right-content">
                              <a
                                href="jacascript:void(0)"
                                onClick={() => handleJoinLive(live)}
                                className="round mx-1"
                              >
                                Join live
                              </a>
                              {userDetail?.id === live?.userDetail?.id &&
                                <a
                                  href="jacascript:void(0)"
                                  onClick={() => handleCancelLive(live)}
                                  className="round mx-1 bg-danger"
                                >
                                  Cancel live
                                </a>
                              }
                            </div>
                          </div>
                        ))}
                  </div>
                </section>
                <PaginationComponent
                  currentPage={currentPage2}
                  setCurrentPage={setCurrentPage2}
                  allPages={allPages2}
                  paginationRange={paginationRange2}
                  setPaginationRange={setPaginationRange2}
                />
              </TabPane>

              <TabPane tabId="3">
                {cancelledLecture?.length === 0 && (
                  <div className="no-cou-yet m-5">
                    <h3>No Cancelled webinar yet!</h3>
                    <p>
                      Here’s where you’ll see all Cancelled WebinarList
                    </p>
                  </div>
                )}
                <section className="live-serie pb-0">
                  <div className="container">
                    {cancelledLecture &&
                      cancelledLecture.length > 0 &&
                      cancelledLecture
                        .filter((e) => e.deletedAt == null)
                        .map((live, index) => (
                          <div className="univ-card round" key={index}>
                            <div className="left-content">
                              <div className="card-image round">
                                <img
                                  src={live?.liveImage ? live?.liveImage : noImage}
                                  alt=""
                                />
                              </div>
                              <div className="card-detail">
                                <span
                                  className={
                                    live.isLivePublic === false ? "private" : "public"
                                  }
                                >
                                  {live.isLivePublic === false ? "Private" : "Public"}
                                </span>
                                <h4 className="headi">{live.channelName}</h4>
                                <h6>Created By : <span>{live?.userDetail?.Profile?.firstName} {live?.userDetail?.Profile?.lastName}</span></h6>
                                {/* {live.scheduleDateTime && <p><span className="fw-medium">Scheduled Date and Time : </span> {moment(live?.scheduleDateTime).format("DD MMM,YYYY hh:mm a")}</p>} */}
                                <p>{live?.liveDescription}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </section>
                <PaginationComponent
                  currentPage={currentPage3}
                  setCurrentPage={setCurrentPage3}
                  allPages={allPages3}
                  paginationRange={paginationRange3}
                  setPaginationRange={setPaginationRange3}
                />
              </TabPane>

              <TabPane tabId="4">
                {completedLecture?.length === 0 && (
                  <div className="no-cou-yet m-5">
                    <h3>No completed webinar yet!</h3>
                    <p>
                      Here’s where you’ll see all Completed WebinarList
                    </p>
                  </div>
                )}
                <section className="live-serie pb-0">
                  <div className="container">
                    {completedLecture &&
                      completedLecture.length > 0 &&
                      completedLecture
                        // .filter((e) => e.deletedAt == null)
                        .map((live, index) => (
                          <div className="univ-card round" key={index}>
                            <div className="left-content">
                              <div className="card-image round  " >
                                <img
                                className=" img-fluid w-100 h-100 "
                                  src={live?.liveImage ? live?.liveImage : noImage}
                                  alt=""
                                />
                              </div>
                              <div className="card-detail">
                                <span
                                  className={
                                    live.isLivePublic === false ? "private" : "public"
                                  }
                                >
                                  {live.isLivePublic === false ? "Private" : "Public"}
                                </span>
                                <h4 className="headi">{live.channelName}</h4>
                                <h6>Created By : <span>{live?.userDetail?.Profile?.firstName} {live?.userDetail?.Profile?.lastName}</span></h6>
                                <p><span className="fw-medium">Started at : </span>{moment(live?.createdAt).format("DD MMM,YYYY hh:mm a")}</p>
                                <p><span className="fw-medium">Ended at : </span>{moment(live?.deletedAt).format("DD MMM,YYYY hh:mm a")}</p>
                                <p>{live?.liveDescription}</p>
                                {live.scheduleDateTime && <p>{moment(live?.scheduleDateTime).format("DD MMM,YYYY hh:mm a")}</p>}
                              </div>
                            </div>
                            <div className="right-content">
                              <div className="round">
                                Completed <i className="fas fa-check-circle fa-lg" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </section>
                <PaginationComponent
                  currentPage={currentPage4}
                  setCurrentPage={setCurrentPage4}
                  allPages={allPages4}
                  paginationRange={paginationRange4}
                  setPaginationRange={setPaginationRange4}
                />
              </TabPane>
            </TabContent>
          </div>
        </>
      )
      }
    </Layout >
  );
};

export default WebinarList;
