import React from "react";
import Layout from "../../components/common/Layout";

const FAQ = () => {
  return (
    <Layout>
      <section className="faqtps">
        <div className="container">
          <h2 className="text-center text-primary">
            Welcome to Zidni Academy community{" "}
          </h2>
          <p class="text-center">
            We've gathered the most commonly asked questions we see in our Zidni
            Academy Community, and collected the best answers created by our
            community guides
          </p>
          <p class="text-center">
            Whether you're a new learner seeking information or a seasoned
            participant looking for a quick refresher, this comprehensive guide
            aims to address your queries and provide valuable insights. So,
            let's dive in and explore the wealth of knowledge that will empower
            you to make the most of your learning experience on Zidni Academy.
          </p>
        </div>
      </section>

      <section className="faqfrqsec">
        <div className="container">
          <h2 className="text-center text-primary">
            Frequently Asked Questions From the Community
          </h2>

          <ul>
            <li>
              <div className="quesnum">Question-1</div>
              <div className="questionfaq">
                Is there any way to add a course to Wishlist, Favorites or
                something like that?Is there any way to add a course to
                Wishlist, Favorites or something like that?
              </div>
              <div className="faqans">Answer:</div>
              <div className="faqansdetail">
                he option to Save a course for later is available only for
                learners who are part of a learning program.
              </div>
            </li>
            <li>
              <div className="quesnum">Question-2</div>
              <div className="questionfaq">
                Is there any way to add a course to Wishlist, Favorites or
                something like that?Is there any way to add a course to
                Wishlist, Favorites or something like that?
              </div>
              <div className="faqans">Answer:</div>
              <div className="faqansdetail">
                The option to Save a course for later is available only for
                learners who are part of a learning program.
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="faqform">
        <div className="container">
          <form action="">
            <div class="mb-3">
              <label htmlFor="faqaskquestion" className="form-label ps-3">
                Ask Question
              </label>
              <textarea
                className="form-control"
                id="faqaskquestion"
                placeholder="What would you like to know?"
                rows="3"
              ></textarea>
            </div>
            <div>
              <button className="btn btn-secondary">Ask</button>
            </div>
          </form>
        </div>
      </section>
    </Layout>
  );
};

export default FAQ;
