import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from 'uuid';
import { sendErrorMessage, sendSuccessMessage } from '../../utility/helperFunctions';
import { callApi } from '../../api/ApiHelper';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';

const SetNewPassModal = (props) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const [timer, setTimer] = useState(60);
    const randomDeviceId = uuidv4();

    useEffect(() => {
        if (props.showSetNewPassModal) {
            startTimer();
        } else {
            resetTimer();
        }
    }, [props.showSetNewPassModal]);

    useEffect(() => {
        if (timer === 0) {
            setShowResendButton(true);
        } else {
            const timerId = setTimeout(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }
    }, [timer]);

    const resetTimer = () => {
        setTimer(60);
        setShowResendButton(false);
    };

    const handleReset = () => {
        reset({ otp: "", password: "" });
        props?.toggleSetNewPassModal();
        setIsLoading(false);
        resetTimer();
    };

    const onSubmit = (data) => {
        let deviceId = "";
        if (localStorage.getItem('deviceId')) {
            deviceId = localStorage.getItem('deviceId');
        } else {
            deviceId = "2024_" + randomDeviceId;
        }
        const email = props.email;
        const submitData = {
            email: email,
            otp: data?.otp,
            password: data?.password,
            deviceId: deviceId,
            deviceToken: randomDeviceId.slice(0, 10),
        }
        // console.log("submitData...", submitData);
        const apiURL = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "forget-reset";

        setIsLoading(true)
        callApi(apiURL, { method: "POST", data: submitData })
            .then(response => {
                if (response?.status === true) {
                    handleReset();
                    sendSuccessMessage(response)
                } else {
                    sendErrorMessage(response)
                    setIsLoading(false);
                }
            })
    };

    const resendOTP = () => {
        let deviceId = "";
        if (localStorage.getItem('deviceId')) {
            deviceId = localStorage.getItem('deviceId');
        } else {
            deviceId = "2024_" + randomDeviceId;
        }

        const email = props.email;
        const submitData = {
            email: email,
            deviceId: deviceId,
            deviceToken: randomDeviceId.slice(0, 10),
        }
        const apiURL = APIURL.AUTH_URL + APIENDPOINTS.AUTH_END_POINT + "resend";
        callApi(apiURL, { method: "POST", data: submitData, })
            .then(response => {
                if (response?.status === true) {
                    sendSuccessMessage(response)
                } else {
                    sendErrorMessage(response)
                }
            })
        startTimer();
    };

    const startTimer = () => {
        resetTimer();
        const timerId = setTimeout(() => {
            setTimer(prevTimer => prevTimer - 1);
        }, 1000);
        return () => clearTimeout(timerId);
    };

    return (
        <>
            <Modal isOpen={props.showSetNewPassModal}>
                <div>
                    <div className="d-flex justify-content-end p-1">
                        <button type="button" className="btn-close" onClick={handleReset} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="twm-tabs-style-2">
                                <div className="mb-3 text-center">
                                    <img style={{ width: "60px" }} src="./assets/img/otp.png" alt="" />
                                </div>
                                <h4 className="modal-title text-center mb-3" id="OTP_popupLabel">
                                    Create New password
                                </h4>
                                <p className="text-center">
                                    Your new password must be different from previous used password.
                                    <br className="d-none d-md-block" />
                                </p>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <label>OTP</label>
                                            <input
                                                name="otp"
                                                type="number"
                                                className="form-control "
                                                placeholder="Enter otp"
                                                {...register("otp", {
                                                    required: "Please fill in your otp",
                                                    minLength: {
                                                        value: 4,
                                                        message: "otp should be at least 4 characters",
                                                    },
                                                    maxLength: {
                                                        value: 8,
                                                        message: "otp cannot exceed 8 characters",
                                                    },
                                                })}
                                            />
                                            <p className="text-danger m-0">{errors?.otp?.message}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            <label>New password</label>
                                            <input
                                                name="password"
                                                type="password"
                                                className="form-control "
                                                placeholder="Enter password"
                                                {...register("password", {
                                                    required: "Please fill in your password",
                                                    minLength: {
                                                        value: 6,
                                                        message: "Password should be at least 6 characters",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Password cannot exceed 20 characters",
                                                    },
                                                })}
                                            />
                                            <p className="text-danger m-0">{errors?.password?.message}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {showResendButton && (
                                                <button
                                                    type="button"
                                                    className="btn btn-link"
                                                    onClick={resendOTP}
                                                >
                                                    Resend OTP
                                                </button>
                                            )}
                                            {!showResendButton && (
                                                <p>Resend OTP in {timer} seconds</p>
                                            )}
                                        </div>
                                        <div className="d-flex justify-content-center p-2">
                                            {isLoading ? (
                                                <div className="btn btn-primary btn-lg btn-block" tabIndex={4}>
                                                    <span className="spinner-border spinner-border-sm mx-1"></span>
                                                </div>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-lg btn-block"
                                                    tabIndex={4}
                                                >
                                                    <span className='fw-bold'>Submit</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SetNewPassModal;

