import React from "react";
import Layout from "../../components/common/Layout";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <section className="privacy-pgsec">
        <div className="container">
          <h2 className="mb-5">Privacy Policy</h2>
          <div className="row">
            <div class="trems_descripstion">
              <p class="section__desc mb-4">
                We at Zidni  are committed to protecting the privacy of our users. This privacy policy explains how we collect, use, and disclose personal information when you use our website.
              </p>
              <h4 class=" mb-2 font-weight-bold">Information Collection and Use:</h4>
              <p class="section__desc mb-4">
                We collect personal information that you provide to us when you register for an account, make a purchase, or contact us for support. This information may include your name, email address, mailing address, and payment information.
              </p>
              <p class="section__desc mb-4">
                We use this information to provide you with the services you request and to communicate with you about your account and our services. We may also use your personal information to send you promotional emails about our products and services, but you may opt-out of these emails at any time.
              </p>
              <p class="section__desc mb-4">
                We may also collect non-personal information about your use of our website, such as your IP address, browser type, and the pages you visit. We use this information to improve our website and to understand how our users interact with our services.
              </p>
              <h4 class=" mb-2 font-weight-bold">
                Cookies
              </h4>
              <p class="section__desc mb-4">
                We may use cookies to personalize your experience on our website and to improve our services. A cookie is a small file that is stored on your computer or mobile device when you visit our website. Cookies help us remember your preferences and improve your user experience.
              </p>
              <p class="section__desc mb-4">
                You can control the use of cookies on our website through your browser settings. However, please note that disabling cookies may affect the functionality of our website.
              </p>
              <h4 class=" mb-2 font-weight-bold">Information Sharing</h4>
              <p class="section__desc mb-4">
                We do not share your personal information with third parties except as necessary to provide you with our services. We may share your personal information with our service providers, such as payment processors, in order to process your transactions.
              </p>
              <p class="section__desc mb-4">
                We may also disclose your personal information if required by law or if we believe that such disclosure is necessary to protect our rights or the rights of others, to prevent fraud, or to respond to a government request.
              </p>
              <h4 class=" mb-2 font-weight-bold">Information Security</h4>
              <p class="section__desc mb-4">
                We take reasonable measures to protect your personal information from unauthorized access, use, and disclosure. However, please note that no data transmission over the internet can be guaranteed to be 100% secure.
              </p>
              <h4 class=" mb-2 font-weight-bold">Changes to this Privacy Policy</h4>
              <p class="section__desc mb-4">
                We may update this privacy policy from time to time by posting a new version on our website. You should check this page periodically to review any changes.
              </p>
              <h4 class=" mb-2 font-weight-bold">Contact Us</h4>
              <p class="section__desc mb-4">If you have any questions about this privacy policy or our privacy practices, please contact us at: <a href="mailto:info@arabboard.org">info@arabboard.org</a></p>
              <p className="section__desc mb-4">Effective Date: Jan 1, 2023</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
