import React from 'react'

const Loader = () => {
  return (
    <>

      <div class="loader">
        <div class="loaderInner"></div>
        <div class="loaderInner">
          <img  src={`${process.env.PUBLIC_URL}/assets/images/loader.png`} alt="" />
        </div>
        <div class="loaderInner"></div>
      </div>
      {/* <div className='loader'>
        <span>
          <img src="/zidni/assets/images/loader.png" alt="" />
        </span>
      </div> */}
    </>
  )
}

export default Loader
