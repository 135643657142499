import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/Layout'
import { callApi } from '../../api/ApiHelper';
import { APIENDPOINTS, APIURL } from '../../api/apiUrl';
import { useNavigate } from 'react-router';
import Loader from '../../components/common/Loader';
import { useSelector } from 'react-redux';

const MyPurchase = () => {
  const [allCoursePurchase, setAllCoursePurchase] = useState([])
  const [allEventPurchase, setAllEventPurchase] = useState([])
  const [allPurchase, setAllPurchase] = useState([])
  const [loader, setLoader] = useState(true)
  const [CallApi, setCallApi] = useState(true);
  const userDetail = useSelector((state) => state.root.userDetails);


  const navigate = useNavigate()
  useEffect(() => {
    if (CallApi == true) {
      setLoader(true)
      setCallApi(false);
      const apiUrl =
        APIURL.COURSE_URL +
        APIENDPOINTS.COURSE_END_POINT +
        `my-course/`;

      const apiUrl2 =
        APIURL.EVENT_URL +
        APIENDPOINTS.EVENT_END_POINT +
        `my-event/`;
      callApi(apiUrl, { method: "GET", data: {} }).then((response) => {
        if (response.status === true) {
          setAllCoursePurchase(response.data);
          setLoader(false)
        }
        callApi(apiUrl2, { method: "GET", data: {} }).then((response) => {
          if (response.status === true) {
            setAllEventPurchase(response.data);
            setLoader(false)
          }
        });

      });
    }
  }, [CallApi])

  useEffect(() => {
    setAllPurchase([...allCoursePurchase, ...allEventPurchase])
  }, [allEventPurchase, allCoursePurchase])

  return (
    <Layout>
      {loader ? <Loader /> :
        <section className="my-purchase">
          <div className="container">
            <div className="top">
              <div className="co">{userDetail?.type === 1 ? "Courses" : "Courses & Events"}</div>
              <div className="amounts">Amount</div>
              <div className="dates">Date</div>
            </div>
            {allPurchase && allPurchase?.length > 0 &&
              allPurchase.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, key) => {
                if (item?.Course?.id) {
                  return (
                    <div className="bottom" key={key}>
                      <div className="most cursor-pointer" onClick={() => {
                        navigate(`/course-detail/${item?.Course?.id}`, {
                          state: { insDetail: item?.Course?.instructor },
                        });
                      }}>
                        <div className="of">
                          <h3>
                            {item?.Course?.courseName} <span className='text-muted fs-6'>(Course)</span>
                          </h3>
                          <div className="cate">{item?.Course?.courseDescription}</div>
                        </div>

                        <p>Course enrolled by {item?.createdAt?.split('T')?.[0]} </p>
                      </div>
                      <h3 className="am">{item?.Course?.coursePricing && JSON.parse(item?.Course?.coursePricing)?.coursePricing ? `$ ${JSON.parse(item?.Course?.coursePricing)?.coursePricing}` : 'Free'}</h3>
                      <h3 className="da">{item?.createdAt?.split('T')?.[0]}</h3>
                    </div>
                  )
                } else {
                  return (
                    <div className="bottom" key={key + 2}>
                      <div className="most cursor-pointer" onClick={() => {
                        navigate(`/event-detail/${item?.Event?.id}`);
                      }}>
                        <div className="of">
                          <h3>
                            {item?.Event?.eventName} <span className='text-muted fs-6'>(Event)</span>
                          </h3>
                          <div className="cate">{item?.Event?.eventDescription}</div>
                        </div>
                        <p>Event enrolled by {item?.createdAt?.split('T')?.[0]} </p>
                      </div>
                      <h3 className="am">{item?.Event?.eventPricing?.eventPrice ? `$ ${item?.Event?.eventPricing?.eventPrice}` : 'Free'}</h3>
                      <h3 className="da">{item?.createdAt?.split('T')?.[0]}</h3>
                    </div>
                  )
                }

              }
              )}

          </div>
        </section>
      }

    </Layout>
  )
}

export default MyPurchase